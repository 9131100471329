/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { agentActions } from "app/states/agent";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import AgentAPI from "app/apis/agent";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import { commonActions } from "app/states/common";

const AddPrivateChatParticipants = ({ chatId }) => {
	const dispatch = useDispatch();
	const closeAddPrivateChatParticipants = () => dispatch(agentActions.closeAddPrivateChatParticipants());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showAddPrivateChatParticipantsModal = useSelector(({ agent }) => agent.showAddPrivateChatParticipantsModal);

	const [allActiveVisitors, setAllActiveVisitors] = useState([]);
	const [visitorsInPrivateChat, setVisitorsInPrivateChat] = useState([]);
	const [availableVisitors, setAvailableVisitors] = useState([]);

	const [isSelectAll, setIsSelectAll] = useState(false);
	const [selectedVisitorsUserId, setSelectedVisitorsUserId] = useState([]);

	// Get all active visitors and the visitors that have joined private chat
	useEffect(() => {
		getChatDetail();
		getExhibitorRoomVisitorListing();
	}, [showAddPrivateChatParticipantsModal]);

	// To get the active visitors that are not joined in the private chat
	useEffect(() => {
		let privateChatVisitorUserIds = visitorsInPrivateChat.map((visitor) => {
			return visitor.user_id;
		});

		let visitorsNotJoinYet = allActiveVisitors.filter((visitor) => {
			return !privateChatVisitorUserIds.includes(visitor.user_id);
		});

		setAvailableVisitors(visitorsNotJoinYet);
	}, [allActiveVisitors, visitorsInPrivateChat]);

	const getChatDetail = () => {
		AgentAPI.getChatDetail(chatId)
			.then((response) => {
				const { visitors } = response;

				const visitorsJoined = visitors.filter((visitor) => {
					return visitor.accepted === 1;
				});

				setVisitorsInPrivateChat(visitorsJoined);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const getExhibitorRoomVisitorListing = () => {
		let queryParams = {
			limit: 650,
			include_histories: 0,
		};

		AgentAPI.getExhibitorRoomVisitorListing(queryParams)
			.then((response) => {
				const { audiences } = response;

				setAllActiveVisitors(audiences);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleAddParticipants = () => {
		if (selectedVisitorsUserId.length <= 0) {
			openAlertSnackbar("No participants selected", "error");
			return;
		}

		const data = {
			chat_id: chatId,
			add_user_ids: selectedVisitorsUserId,
		};

		AgentAPI.postUpdatePrivateChatUsers(data)
			.then((response) => {
				resetStates();
				openAlertSnackbar("Invited selected participants successfully", "success");
				closeAddPrivateChatParticipants();
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const resetStates = () => {
		setIsSelectAll(false);
		setSelectedVisitorsUserId([]);
	};

	const handleSelectAll = () => {
		// When turn to not select all, clear all selected
		if (isSelectAll) {
			setSelectedVisitorsUserId([]);
		}
		// When turn to select all, add all to selected
		else {
			let availableVisitorsUserId = availableVisitors.map((visitor) => {
				return visitor.user_id;
			});

			setSelectedVisitorsUserId(availableVisitorsUserId);
		}

		setIsSelectAll(!isSelectAll);
	};

	const handleSelectVisitor = (visitor) => {
		const { user_id } = visitor;

		// If already in selected list, remove from it
		if (selectedVisitorsUserId.includes(user_id)) {
			let selectedVisitorsUserIdCopy = [...selectedVisitorsUserId];
			let index = selectedVisitorsUserIdCopy.indexOf(user_id);
			selectedVisitorsUserIdCopy.splice(index, 1);
			setSelectedVisitorsUserId(selectedVisitorsUserIdCopy);

			// Auto untick select all
			if (selectedVisitorsUserIdCopy.length !== availableVisitors.length) {
				setIsSelectAll(false);
			}
		}
		// If not in the selected list, add into it
		else {
			setSelectedVisitorsUserId([...selectedVisitorsUserId, user_id]);
		}
	};

	const Visitor = ({ visitor }) => {
		const { user_id, name, company, job_title, image_url } = visitor;

		const [showBorder, setShowBorder] = useState(false);

		const handlImageOnError = (event) => {
			event.target.onerror = null;
			event.target.src = IconDefaultProfile;
			setShowBorder(false);
		};

		return (
			<div key={user_id} className="visitor-select">
				<div className="flex items-center">
					<img
						src={image_url || IconDefaultProfile}
						alt="profile"
						className={`visitor-image ${!showBorder ? "no-border" : ""}`}
						onError={handlImageOnError}
					/>
					<div>
						<div className="visitor-name">{name}</div>
						<div className="visitor-desc">
							{job_title} at {company}
						</div>
					</div>
				</div>
				<div className="app__checkbox">
					<input
						type="checkbox"
						checked={selectedVisitorsUserId.includes(user_id)}
						onChange={() => handleSelectVisitor(visitor)}
					/>
					<div className="app__checkbox-visible"></div>
				</div>
			</div>
		);
	};

	return (
		<Modal
			open={showAddPrivateChatParticipantsModal}
			onClose={closeAddPrivateChatParticipants}
			aria-labelledby="agent-create-pvc-modal">
			<div className="app-modal agent-create-pvc-modal">
				<div className="modal-content">
					<div className="flex justify-between items-center mb-3">
						<span className="text-to">Add Participants</span>
						<div className="flex items-center">
							<label htmlFor="select_all" className="mr-3">
								Select all
							</label>
							<div className="app__checkbox">
								<input
									id="select_all"
									type="checkbox"
									name="select_all"
									checked={isSelectAll}
									onChange={handleSelectAll}
								/>
								<div className="app__checkbox-visible"></div>
							</div>
						</div>
					</div>
					<div className="visitors-available">
						{availableVisitors.map((visitor) => {
							return (
								<Visitor visitor={visitor} />
							);
						})}
					</div>
				</div>

				<div className="action-bar">
					<div className="grid grid-cols-10 gap-3">
						<div className="col-span-4 md:col-span-6">
							<div className="text-selected">Selected:</div>
							<div className="text-selected-count">{selectedVisitorsUserId.length} people</div>
						</div>
						<div className="col-span-3 md:col-span-2 flex justify-center items-center">
							<button type="button" className="btn-dismiss" onClick={closeAddPrivateChatParticipants}>
								Dismiss
							</button>
						</div>
						<div className="col-span-3 md:col-span-2 flex justify-center items-center">
							<button type="button" className="btn-next" onClick={handleAddParticipants}>
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(AddPrivateChatParticipants);

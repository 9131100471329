/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconStore from "app/assets/Agent/store_dark.png";
import IconChecked from "app/assets/Agent/icon_checked.png";
import AgentProductListItem from "app/views/components/AgentProductListItem/AgentProductListItem";
import { useDispatch, useSelector } from "react-redux";
import AgentAPI from "app/apis/agent";
import { agentActions } from "app/states/agent";
import { commonActions } from "app/states/common";
import { DELIVERY_METHODS_NAME, PAYMENT_METHODS_NAME } from "app/config/apiEnums";

const StoreDetailsContainer = () => {
	const dispatch = useDispatch();
	const refreshStoreProducts = (isRefresh) => dispatch(agentActions.refreshStoreProducts(isRefresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const roomDetails = useSelector(({ agent }) => agent.roomDetails);
	const isRefreshStoreProducts = useSelector(({ agent }) => agent.isRefreshStoreProducts);

	const [inputData, setInputData] = useState({
		store_url: "",
		delivery_methods: [],
		payment_methods: [],
	});

	const [storeProducts, setStoreProducts] = useState([]);
	const [maxStoreSlots, setMaxStoreSlots] = useState(0);

	useEffect(() => {
		if (!roomDetails) return;

		getExhibitorRoomStoreProducts();
	}, [roomDetails]);

	useEffect(() => {
		if (isRefreshStoreProducts) {
			refreshStoreProducts(false);
			getExhibitorRoomStoreProducts();
		}
	}, [isRefreshStoreProducts]);

	const getExhibitorRoomStoreProducts = () => {
		const storeId = roomDetails.store_ids[0];

		AgentAPI.getExhibitorRoomStoreProducts(storeId)
			.then((response) => {
				const { store_url, products, max_store_slots, delivery_method, payment_method } = response;

				setStoreProducts(products);
				setMaxStoreSlots(max_store_slots);

				setInputData({
					...inputData,
					store_url: store_url || "",
					delivery_methods: delivery_method,
					payment_methods: payment_method,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleInputDataChange = (event) => {
		setInputData({
			...inputData,
			[event.target.name]: event.target.value,
		});
	};

	const handleDeliveryMethodChange = (key) => {
		let deliveryMethods = { ...inputData.delivery_methods };
		deliveryMethods[key] = deliveryMethods[key] === 1 ? 0 : 1;

		setInputData({ ...inputData, delivery_methods: deliveryMethods });
	};

	const handlePaymentMethodChange = (key) => {
		let paymentMethods = { ...inputData.payment_methods };
		paymentMethods[key] = paymentMethods[key] === 1 ? 0 : 1;

		setInputData({ ...inputData, payment_methods: paymentMethods });
	};

	const handleSaveChanges = () => {
		const storeId = roomDetails.store_ids[0];

		const data = {
			store_url: inputData.store_url,
			delivery_method: inputData.delivery_methods,
			payment_method: inputData.payment_methods,
		};

		AgentAPI.postUpdateStoreDetails(storeId, data)
			.then((response) => {
				openAlertSnackbar("Store details updated.", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<div id="store-details" className="store-details-container mb-3">
			<div className="grid grid-cols-12 gap-3">
				<div className="col-span-2">
					<h3>
						<img src={IconStore} alt="Store Details" /> Store Details
					</h3>
				</div>
				<div className="col-span-8">
					<form className="form-container mb-3">
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Store URL</label>
							</div>
							<div className="col-span-5">
								<input
									type="text"
									name="store_url"
									value={inputData.store_url}
									onChange={handleInputDataChange}
									required
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-4">
							<div className="col-span-2">
								<label>Delivery Method</label>
							</div>
							<div className="col-span-10 flex items-center">
								{Object.keys(inputData.delivery_methods).map((deliveryMethodKey, index) => {
									return (
										<CustomCheckbox
											key={`delivery-method-${index}`}
											name={DELIVERY_METHODS_NAME[deliveryMethodKey]['name']}
											dataKey={deliveryMethodKey}
											onChange={handleDeliveryMethodChange}
											checked={inputData.delivery_methods[deliveryMethodKey]}
										/>
									);
								})}
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-4">
							<div className="col-span-2">
								<label>Payment Method</label>
							</div>
							<div className="col-span-10 flex items-center">
								{Object.keys(inputData.payment_methods).map((paymentMethodKey, index) => {
									return (
										<CustomCheckbox
											key={`payment-method-${index}`}
											name={PAYMENT_METHODS_NAME[paymentMethodKey]['name']}
											dataKey={paymentMethodKey}
											onChange={handlePaymentMethodChange}
											checked={inputData.payment_methods[paymentMethodKey]}
										/>
									);
								})}
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Product Listing</label>
							</div>
							<div className="col-span-8">
								<div className="grid grid-cols-12 gap-4">
									{storeProducts.map((product) => {
										const { id, name, currency, price, image_url } = product;

										return (
											<div className="col-span-3 mb-3" key={id}>
												<AgentProductListItem
													id={id}
													image={image_url}
													productName={name}
													priceTag={`${currency}${price}`}
												/>
											</div>
										);
									})}

									{storeProducts.length < maxStoreSlots && (
										<div className="col-span-3 mb-3">
											<AgentProductListItem addNew />
										</div>
									)}
								</div>
								<div className="remark">
									You have used <strong>{storeProducts.length}</strong> of your{" "}
									<strong>{maxStoreSlots} available product slots.</strong>
								</div>
							</div>
						</div>
					</form>

					<div className="flex items-center justify-end">
						<button type="button" className="btn-save ml-5" onClick={handleSaveChanges}>
							Save Changes
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

// Custom Checkbox Component
const CustomCheckbox = ({ name, dataKey, checked, onChange }) => {
	return (
		<div
			className={`custom-checkbox-wrapper ${checked ? "checked" : ""}`}
			data-key={dataKey}
			onClick={() => onChange(dataKey)}>
			{checked === 1 && <img src={IconChecked} alt="checked" />} {name}
		</div>
	);
};

export default withRouter(StoreDetailsContainer);

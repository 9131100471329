/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import "./style.scss";
import IconCoin from "app/assets/coin.png";
import IconDeleteWhite from "app/assets/icon_delete_white.png";
import LocalStorageService from "app/services/localStorageService";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import { useMediaQuery } from "react-responsive";
import { PAYMENT_SETTINGS } from "app/config/settings";
import OrderAPI from "app/apis/order";
import { numberWithCommas } from "app/helper/common";
import ReactLoading from "react-loading";

const MySavedOrdersContainer = () => {
	const dispatch = useDispatch();
	const openDeleteSavedOrdersModal = () => dispatch(commonActions.openDeleteSavedOrdersModal());
	const openSwitchExhibitorRoomModal = () => dispatch(commonActions.openSwitchExhibitorRoomModal());
	const setViewingSavedOrderRoomId = (roomId) => dispatch(commonActions.setViewingSavedOrderRoomId(roomId));

	const viewingSavedOrderRoomId = useSelector(({ common }) => common.viewingSavedOrderRoomId);
	const refreshMyCartCount = useSelector(({ order }) => order.refreshMyCartCount);
	const eventSettings = useSelector(({ event }) => event.eventSettings);

	const [loading, setLoading] = useState(true);
	const [savedOrderRooms, setSavedOrderRooms] = useState([]);
	const [orderProducts, setOrderProducts] = useState([]);
	const [currency, setCurrency] = useState("");
	const [totalPrice, setTotalPrice] = useState("");

	// Get list of saved order rooms
	// Refresh list when room id becomes null
	useEffect(() => {
		OrderAPI.getSavedOrderRooms()
			.then((response) => {
				const { rooms } = response;
				setSavedOrderRooms(rooms);

				if (rooms.length > 0) {
					setViewingSavedOrderRoomId(rooms[0].id);
				}
				else {
					setLoading(false);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshMyCartCount]);

	// Get saved order details
	useEffect(() => {
		if (!viewingSavedOrderRoomId) return;

		setLoading(true);
		OrderAPI.getSavedOrderByExhibitorRoom(viewingSavedOrderRoomId)
			.then((response) => {
				const { currency, total_price, products } = response;

				setCurrency(currency);
				setTotalPrice(total_price);
				setOrderProducts(products);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [viewingSavedOrderRoomId]);

	const handleDeleteSavedOrder = () => {
		openDeleteSavedOrdersModal();
	};

	const handleGoToStore = () => {
		openSwitchExhibitorRoomModal();
	};

	return (
		<div className="my-saved-orders-container">
			<section className="content-section">
				<section className="notice-section">
					Due to the variety of exhibitors from different countries which requires different tax calculations
					and shipping charges, each exhibitor checkout must be performed individually.
				</section>
				{savedOrderRooms.length > 0 ? (
					<section className="orders-section grid grid-cols-12 gap-3">
						<div className="saved-order-list-wrapper col-span-12 lg:col-span-4 h-full">
							{savedOrderRooms.map((savedOrderRoom, index) => {
								const { id, name, currency, image_url, price, quantity } = savedOrderRoom;

								return (
									<OrderListItem
										key={`order-room-${index}`}
										roomId={id}
										thumbnail={image_url}
										name={name}
										currency={currency}
										price={price}
										quantity={quantity}
										active={viewingSavedOrderRoomId === id}
									/>
								);
							})}
						</div>
						<div className="saved-order-details-wrapper lg:col-span-8">
							{orderProducts.map((product, index) => {
								const { id, name, unit_price, currency, quantity, sub_total_price, image_url, sku } =
									product;

								return (
									<OrderListItemDetail
										key={id}
										name={name}
										thumbnail={image_url}
										currency={currency}
										unitPrice={unit_price}
										subtotal={sub_total_price}
										quantity={quantity}
										sku={sku}
									/>
								);
							})}
						</div>
					</section>
				) : (
					<section className="flex justify-center items-center mt-10">Your Cart is Empty</section>
				)}
			</section>

			{savedOrderRooms.length > 0 && (
				<section className="store-footer">
					<div className="flex items-center">
						{orderProducts.length > 0 && (
							<div className="order-summary">
								<span className="mr-2">Order Total ({orderProducts.length} items):</span>
								{eventSettings.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
									<span className="flex items-center">
										<img src={IconCoin} alt="coin" className="icon-coin" />
										<span className="text-credit-amount">{totalPrice}</span>
									</span>
								) : (
									<strong className="total-price">
										{currency}
										{numberWithCommas(totalPrice)}
									</strong>
								)}
							</div>
						)}
						<button type="button" className="btn-delete-saved-order" onClick={handleDeleteSavedOrder}>
							<img src={IconDeleteWhite} alt="delete" className="icon-delete" /> Delete Saved Order
						</button>
						<button type="button" className="btn-go-store" onClick={handleGoToStore}>
							Go To Store
						</button>
					</div>
				</section>
			)}

			{loading && (
				<div className="app-loading-overlay">
					<ReactLoading type={"spinningBubbles"} color="grey" />
					<p className="mt-3">Loading</p>
				</div>
			)}
		</div>
	);
};

const OrderListItem = ({ roomId, name, thumbnail, currency, price, quantity, active }) => {
	const history = useHistory();
	const { eventCode } = useParams();
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const dispatch = useDispatch();
	const setViewingSavedOrderRoomId = (roomId) => dispatch(commonActions.setViewingSavedOrderRoomId(roomId));
	const setViewingSavedOrderRoomName = (roomName) => dispatch(commonActions.setViewingSavedOrderRoomName(roomName));

	const eventSettings = useSelector(({ event }) => event.eventSettings);

	const handleViewOrderDetails = () => {
		setViewingSavedOrderRoomId(roomId);
		setViewingSavedOrderRoomName(name);

		if (isTabletOrMobile) {
			history.push(`/events/${eventCode}/profile/orders`);
		}
	};

	return (
		<div className={`order-list-item ${active ? "active" : ""}`} onClick={handleViewOrderDetails}>
			<div className="order-image-wrapper">
				<img src={thumbnail} alt="orders" className="order-image-2" />
			</div>
			<div className="order-item-summary">
				<div className="order-name">{name}</div>
				<div className="order-quantity">
					<span>Quantity: </span> {quantity}
				</div>
				<div className="order-amount">
					<span className="mr-1">Amount: </span>
					{eventSettings.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
						<span className="flex items-center">
							<img src={IconCoin} alt="coin" className="icon-coin" />
							<span className="text-credit-amount">{price}</span>
						</span>
					) : (
						<span>
							{currency}
							{numberWithCommas(price)}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

const OrderListItemDetail = ({ thumbnail, name, currency, unitPrice, quantity, subtotal, sku }) => {
	const eventSettings = useSelector(({ event }) => event.eventSettings);

	return (
		<div className="order-list-item-detail">
			<div className="image-wrapper">
				<img src={thumbnail} alt="product" className="img-product" />
			</div>
			<div className="flex flex-col justify-between items-stretch w-full">
				<div className="grid grid-cols-12 gap-3">
					<div className="col-span-7">
						<div className="product-name mb-2">{name}</div>
						{sku && (
							<div className="flex items-center product-variant flex-wrap">
								{sku.size && (
									<div className="variant-option mb-1">
										Size: <span className="size-value">{sku.size}</span>
									</div>
								)}
								{sku.color && (
									<div className="variant-option mb-1">
										Color: <span className="color-value">{sku.color}</span>
									</div>
								)}
								{sku.option && (
									<div className="variant-option mb-1">
										Option: <span className="option-value">{sku.option}</span>
									</div>
								)}
							</div>
						)}
					</div>
					<div className="col-span-5 text-right">
						<div className="product-price mb-2">
							{eventSettings.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
								<span className="flex items-center">
									<img src={IconCoin} alt="coin" className="icon-coin" />
									<span className="text-credit-amount">{unitPrice}</span>
								</span>
							) : (
								<span>
									{currency}
									{numberWithCommas(unitPrice)}
								</span>
							)}
						</div>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<div className="product-quantity">
						<div className="label-quantity">Quantity</div>
						<div className="quantity-count">{quantity}</div>
					</div>
					<div className="product-subtotal">
						<span>Subtotal: </span>
						{eventSettings.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
							<span className="flex items-center subtotal-price">
								<img src={IconCoin} alt="coin" className="icon-coin" />
								<span className="text-credit-amount">{numberWithCommas(subtotal)}</span>
							</span>
						) : (
							<span className="subtotal-price">
								{currency}
								{numberWithCommas(subtotal)}
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(MySavedOrdersContainer);

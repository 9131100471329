import * as types from "./types"

export const setChannelSessionId = (sessionId) => ({
    type: types.SET_CHANNEL_SESSION_ID,
    params: {
        sessionId
    }
});

export const setChannelSession = (session) => ({
    type: types.SET_CHANNEL_SESSION,
    params: {
        session
    }
});

export const openMobileChannelListModal = () => ({
    type: types.OPEN_MOBILE_CHANNEL_LIST_MODAL,
});

export const closeMobileChannelListModal = () => ({
    type: types.CLOSE_MOBILE_CHANNEL_LIST_MODAL,
});

export const setExpandChannelCategory = (category) => ({
    type: types.SET_EXPAND_CHANNEL_CATEGORY,
    params: {
        category
    }
});

export const setChannelChatId = (chatId) => ({
    type: types.SET_CHANNEL_CHAT_ID,
    params: {
        chatId
    }
});

// Refresh channel list
export const setRefreshChannelList = (isRefresh) => ({
    type: types.SET_REFRESH_CHANNEL_LIST,
    params: {
        isRefresh
    }
});

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "./style.scss";
import VisitorList from "../../VisitorList/VisitorList";
import ChatContainer from "../../ChatContainer/ChatContainer";
import QnAContainer from "../QnAContainer/QnAContainer";
import PollsContainer from "../PollsContainer/PollsContainer";
import IconChatActive from "app/assets/Channels_Exhibitors/chat_active.png";
import IconChatInactive from "app/assets/Channels_Exhibitors/chat_inactive.png";
import IconPollActive from "app/assets/Channels_Exhibitors/poll_active.png";
import IconPollInactive from "app/assets/Channels_Exhibitors/poll_inactive.png";
import IconQnAActive from "app/assets/Channels_Exhibitors/qna_active.png";
import IconQnAInactive from "app/assets/Channels_Exhibitors/qna_inactive.png";
import IconVisitorsActive from "app/assets/Channels_Exhibitors/visitors_active.png";
import IconVisitorsInactive from "app/assets/Channels_Exhibitors/visitors_inactive.png";
import { useSelector, useDispatch } from "react-redux";
import ChannelApi from "app/apis/channel";
import { channelSocket } from "app/services/socketService";
import Badge from "@material-ui/core/Badge";
import { commonActions } from "app/states/common";
import sessionStorageService from "app/services/sessionStorageService";

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`channel-tabpanel-${index}`}
			aria-labelledby={`channel-tab-${index}`}
			{...other}>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

const ChannelTabs = () => {
	const dispatch = useDispatch();
	const setChatUnreadCount = (count) => dispatch(commonActions.setChatUnreadCount(count));

	const channelSessionId = useSelector(({ channel }) => channel.channelSessionId);
	const chatUnreadCount = useSelector(({ common }) => common.chatUnreadCount);
	const channelSession = useSelector(({ channel }) => channel.channelSession);

	const [tabIndex, setTabIndex] = useState(0);
	const [visitors, setVisitors] = useState([]);

	const [newUnreadQuestionCount, setNewUnreadQuestionCount] = useState(0);
	const [newUnreadPollCount, setNewUnreadPollCount] = useState(0);

	const [triggerUnreadQuestionCountUpdate, setTriggerUnreadQuestionCountUpdate] = useState(0);
	const [triggerUnreadPollCountUpdate, setTriggerUnreadPollCountUpdate] = useState(0);

	useEffect(() => {
		sessionStorageService.setChannelTabIndex(0);
	}, []);

	const tabProps = (index) => {
		return {
			id: `channel-tabpanel-${index}`,
			"aria-controls": `channel-tabpanel-${index}`,
		};
	};

	const handleTabChange = (event, newTabIndex) => {
		setTabIndex(newTabIndex);
		sessionStorageService.setChannelTabIndex(newTabIndex);

		// Set unread count to 0 for chat
		if (newTabIndex === 0) {
			setChatUnreadCount(0);
		}
		// Set unread count to 0 for QnA
		else if (newTabIndex === 1) {
			setNewUnreadQuestionCount(0);
		}
		// Set unread count to 0 for Poll
		else if (newTabIndex === 2) {
			setNewUnreadPollCount(0);
		}
	};

	useEffect(() => {
		channelSocket.on("session-update", (message) => {
			// do something with incoming message from backend
			// console.log("channelSocket: ", message);

			const { action_code, data } = message;

			// Attendance update (when new user joins)
			if (action_code === 100) {
				const { audience } = data;
				const { user_id } = audience;

				setVisitors((prevStates) => {
					// Find if there is any duplicate
					let visitorsCopy = [...prevStates];
					let duplicateVisitor = visitorsCopy.findIndex((visitor) => {
						return visitor.user_id === user_id;
					});

					// If no duplicate, only append to the list
					if (duplicateVisitor === -1) {
						return [...prevStates, audience];
					}

					return [...prevStates];
				});
			}

			// Attendance update (when user leaves)
			if (action_code === 101) {
				const { audience } = data;
				const { user_id } = audience;

				setVisitors((prevStates) => {
					let visitorsCopy = [...prevStates];
					let remainingVisitors = visitorsCopy.filter((visitor) => {
						return visitor.user_id !== user_id;
					});

					return remainingVisitors;
				});
			}

			// New question on Q&A
			if (action_code === 200) {
				setTriggerUnreadQuestionCountUpdate((prevState) => prevState + 1);
			}

			// New poll created
			if (action_code === 300) {
				setTriggerUnreadPollCountUpdate((prevState) => prevState + 1);
			}
		});

		// return () => {
		// 	// turning of socket listner on unmount
		// 	channelSocket.off("session-update");
		// };
	}, []);

	// Handle unread qna count
	useEffect(() => {
		if (triggerUnreadQuestionCountUpdate > 0) {
			if (tabIndex !== 1) {
				setNewUnreadQuestionCount((prevState) => prevState + 1);
			}
		}
	}, [triggerUnreadQuestionCountUpdate]);

	// Handle unread poll count
	useEffect(() => {
		if (triggerUnreadPollCountUpdate > 0) {
			if (tabIndex !== 2) {
				setNewUnreadPollCount((prevState) => prevState + 1);
			}
		}
	}, [triggerUnreadPollCountUpdate]);

	useEffect(() => {
		if (!channelSessionId) return;

		ChannelApi.getChannelSessionAudiences(channelSessionId)
			.then((response) => {
				const { audiences } = response;
				setVisitors(audiences);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [channelSessionId]);

	return (
		<div className="channel-tabs">
			<Tabs
				value={tabIndex}
				onChange={handleTabChange}
				aria-label="Channel Tabs"
				indicatorColor="primary"
				textColor="primary"
				variant="fullWidth">
				<Tab
					icon={
						<Badge badgeContent={chatUnreadCount} color="error" variant="dot">
							<img
								src={tabIndex === 0 ? IconChatActive : IconChatInactive}
								alt="chat"
								className="tab-icon"
							/>
						</Badge>
					}
					label={<span className="tab-label">Chat</span>}
					className="tab"
					{...tabProps(0)}
				/>
				<Tab
					icon={
						<Badge badgeContent={newUnreadQuestionCount} color="error">
							<img
								src={tabIndex === 1 ? IconQnAActive : IconQnAInactive}
								alt="chat"
								className="tab-icon"
							/>
						</Badge>
					}
					label={<span className="tab-label">Q&A</span>}
					className="tab"
					{...tabProps(1)}
				/>
				<Tab
					icon={
						<Badge badgeContent={newUnreadPollCount} color="error">
							<img
								src={tabIndex === 2 ? IconPollActive : IconPollInactive}
								alt="chat"
								className="tab-icon"
							/>
						</Badge>
					}
					label={<span className="tab-label">Polls</span>}
					className="tab"
					{...tabProps(2)}
				/>
				<Tab
					icon={
						<img
							src={tabIndex === 3 ? IconVisitorsActive : IconVisitorsInactive}
							alt="chat"
							className="tab-icon"
						/>
					}
					label={
						<span className="tab-label">
							Visitors
							{channelSession && !channelSession.hide_visitor_count ? `(${visitors.length})` : ""}
						</span>
					}
					className="tab"
					{...tabProps(3)}
				/>
			</Tabs>

			{/* Deprecated */}
			{/* <TabPanel value={tabIndex} index={0} className="tab-panel">
				<ChatContainer type="channel" />
			</TabPanel> */}

			<div className="tab-panel" style={tabIndex === 0 ? { display: "block" } : { display: "none" }}>
				<ChatContainer type="channel" />
			</div>
			<TabPanel value={tabIndex} index={1} className="tab-panel">
				<QnAContainer />
			</TabPanel>
			<TabPanel value={tabIndex} index={2} className="tab-panel">
				<PollsContainer />
			</TabPanel>
			<TabPanel value={tabIndex} index={3} className="tab-panel">
				<VisitorList visitors={visitors} />
			</TabPanel>
		</div>
	);
};

export default withRouter(ChannelTabs);

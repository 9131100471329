import * as types from "./types";

const initialState = {
	refreshAddressListCount: 0,
	showMobileProfileModal: false,
	selectedProfileTabIndex: null,
};

const ProfileReducer = (state = initialState, action) => {
	switch (action.type) {
		// Refresh address list
		case types.REFRESH_ADDRESS_LIST:
			return {
				...state,
				refreshAddressListCount: state.refreshAddressListCount + 1,
			};

		// Mobile profile modal
		case types.OPEN_MOBILE_PROFILE_MODAL:
			return {
				...state,
				showMobileProfileModal: true,
			};
		case types.CLOSE_MOBILE_PROFILE_MODAL:
			return {
				...state,
				showMobileProfileModal: false,
			};

		// Selected profile tab
		case types.SET_SELECTED_PROFILE_TAB_INDEX:
			return {
				...state,
				selectedProfileTabIndex: action.params.tabIndex,
			};
		default:
			return state;
	}
};

export default ProfileReducer;

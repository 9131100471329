import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { agentActions } from "app/states/agent";
import { useDispatch, useSelector } from "react-redux";
import IconClose from "app/assets/icon_close.svg";
import IconAdd from "app/assets/Agent/icon_add_blue.png";
import "./style.scss";
import AgentAPI from "app/apis/agent";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MediaUpload from "app/views/components/MediaUpload/MediaUpload";
import { commonActions } from "app/states/common";
import IconVideoThumbnail from "app/assets/Agent/icon-video-upload-selected.png";
import ReactLoading from "react-loading";
import SessionStorageService from "app/services/sessionStorageService";

const AddMediaModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeAddMediaModal = () => dispatch(agentActions.closeAddMediaModal());
	const refreshExhibitorRoomDetails = (refresh) => dispatch(agentActions.refreshExhibitorRoomDetails(refresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showModal = useSelector(({ agent }) => agent.showAddMediaModal);

	const [selectedType, setSelectedType] = useState("video_link");

	const [videoLink, setVideoLink] = useState("");
	const [videoLinkCoverImage, setVideoLinkCoverImage] = useState(null);

	const [videoUpload, setVideoUpload] = useState(null);
	const [videoUploadCoverImage, setVideoUploadCoverImage] = useState(null);

	const [imageUpload, setImageUpload] = useState(null);

	const [fileName, setFileName] = useState("");
	const [loading, setLoading] = useState(false);

	// const [pictures, setPictures] = useState([]);

	const handleClose = () => {
		closeAddMediaModal();
	};

	const handleAddMedia = () => {
		setLoading(true);

		let formData = new FormData();

		if (selectedType === "video_link") {
			formData.append("type", 2); // video
			formData.append("video_type", 2);
			formData.append("content_url", videoLink);
			formData.append("cover_image", videoLinkCoverImage);
		} else if (selectedType === "video_upload") {
			formData.append("type", 2); // video
			formData.append("video_type", 1); // local upload
			formData.append("media_file", videoUpload);
			formData.append("cover_image", videoUploadCoverImage);
		} else if (selectedType === "image_upload") {
			formData.append("type", 1); // image
			formData.append("media_file", imageUpload);
		}

		AgentAPI.postAddExhibitorRoomMediaFiles(formData)
			.then((response) => {
				// Reset state
				setSelectedType("video_link");
				setVideoLink("");
				setVideoLinkCoverImage(null);
				setVideoUpload(null);
				setVideoUploadCoverImage(null);
				setImageUpload(null);
				setLoading(false);

				SessionStorageService.disableUpdateExhibitorDetailsInAgentPage()
				refreshExhibitorRoomDetails(true);
				closeAddMediaModal();
			})
			.catch((error) => {
				setLoading(false);
				openAlertSnackbar(error.data.message, "error");
			});
	};

	// Video link handlers
	const handleUploadVideoLinkCoverImage = (event) => {
		setVideoLinkCoverImage(event.target.files[0]);
	};

	const handleRemoveVideoLinkCoverImage = (event) => {
		setVideoLinkCoverImage(null);
	};

	// Video upload handlers
	const handleUploadVideoFile = (event) => {
		setVideoUpload(event.target.files[0]);
		setFileName(event.target.files[0].name);
	};

	const handleRemoveVideoUploadFile = (event) => {
		setVideoUpload(null);
	};

	const handleUploadVideoCoverImage = (event) => {
		setVideoUploadCoverImage(event.target.files[0]);
	};

	const handleRemoveVideoUploadCoverImage = (event) => {
		setVideoUploadCoverImage(null);
	};

	// Image upload handlers
	const handleUploadImage = (event) => {
		setImageUpload(event.target.files[0]);
	};

	const handleRemoveImageUpload = (event) => {
		setImageUpload(null);
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="agent-add-media-modal">
			<div className="app-modal agent-add-media-modal">
				{loading && (
					<div className="app-loading-overlay">
						<ReactLoading type={"spinningBubbles"} color="grey" />
						<p className="mt-3">Loading</p>
					</div>
				)}
				<div className="modal-header flex justify-between mb-4">
					<h1 className="modal-title flex items-center">
						<img src={IconAdd} alt="Add Product" className="icon-header" /> Add Media
					</h1>
					<span className="btn-close">
						<img src={IconClose} alt="Close" onClick={handleClose} />
					</span>
				</div>
				<div className="modal-content">
					<form className="modal-form-container mb-5">
						<div className="grid grid-cols-12 gap-5">
							<div className="col-span-12">
								<div className="grid grid-cols-12">
									<div className="col-span-12">
										<label>Type</label>
									</div>
									<div className="col-span-12">
										<ButtonGroup
											color="primary"
											aria-label="outlined primary button group"
											disableElevation
											disableFocusRipple
											className="btn-group-type">
											<Button
												onClick={() => setSelectedType("video_link")}
												className={`${selectedType === "video_link" ? "active" : ""}`}>
												Video Link
											</Button>
											<Button
												onClick={() => setSelectedType("video_upload")}
												className={`${selectedType === "video_upload" ? "active" : ""}`}>
												Video Upload
											</Button>
											<Button
												onClick={() => setSelectedType("image_upload")}
												className={`${selectedType === "image_upload" ? "active" : ""}`}>
												Image Upload
											</Button>
										</ButtonGroup>
									</div>
								</div>
							</div>

							{/* Video link form */}
							{selectedType === "video_link" && (
								<>
									<div className="col-span-12">
										<div className="grid grid-cols-12">
											<div className="col-span-12 md:col-span-7">
												<label>Video URL (e.g. YouTube link)</label>
											</div>
											<div className="col-span-12 md:col-span-7">
												<input
													type="text"
													name="video_url"
													value={videoLink}
													onChange={(event) => setVideoLink(event.target.value)}
													required
												/>
											</div>
										</div>
									</div>
									<div className="col-span-12">
										<div className="grid grid-cols-12">
											<div className="col-span-12 md:col-span-7">
												<label>Cover Image</label>
											</div>
											<div className="col-span-12 md:col-span-7">
												<MediaUpload
													thumbnail={
														videoLinkCoverImage
															? URL.createObjectURL(videoLinkCoverImage)
															: null
													}
													onUpload={handleUploadVideoLinkCoverImage}
													onRemove={handleRemoveVideoLinkCoverImage}
												/>
											</div>
										</div>
									</div>
								</>
							)}

							{/* Video Upload */}
							{selectedType === "video_upload" && (
								<>
									<div className="col-span-6">
										<div className="grid grid-cols-12 mb-3">
											<div className="col-span-12">
												<label>Video</label>
											</div>
											<div className="col-span-12">
												<MediaUpload
													thumbnail={videoUpload ? IconVideoThumbnail : null}
													fileName={fileName}
													onUpload={handleUploadVideoFile}
													onRemove={handleRemoveVideoUploadFile}
												/>
											</div>
										</div>
									</div>
									<div className="col-span-6">
										<div className="grid grid-cols-12 mb-3">
											<div className="col-span-12">
												<label>Cover Image</label>
											</div>
											<div className="col-span-12">
												<MediaUpload
													thumbnail={
														videoUploadCoverImage
															? URL.createObjectURL(videoUploadCoverImage)
															: null
													}
													onUpload={handleUploadVideoCoverImage}
													onRemove={handleRemoveVideoUploadCoverImage}
												/>
											</div>
										</div>
									</div>
								</>
							)}

							{/* Image Upload */}
							{selectedType === "image_upload" && (
								<div className="col-span-6">
									<div className="grid grid-cols-12 mb-3">
										<div className="col-span-12">
											<label>Image</label>
										</div>
										<div className="col-span-12">
											<MediaUpload
												thumbnail={imageUpload ? URL.createObjectURL(imageUpload) : null}
												onUpload={handleUploadImage}
												onRemove={handleRemoveImageUpload}
											/>
										</div>
									</div>
								</div>
							)}
						</div>
					</form>
				</div>

				<div className="action-bar">
					<div className="grid grid-cols-12 gap-3">
						<div className="col-span-8"></div>
						<div className="col-span-2 flex justify-center items-center">
							<button type="button" className="btn-cancel" onClick={closeAddMediaModal}>
								Cancel
							</button>
						</div>
						<div className="col-span-2 flex justify-center items-center">
							<button type="button" className="btn-publish" onClick={handleAddMedia}>
								Publish Media
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(AddMediaModal);

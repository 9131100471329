import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { exhibitorActions } from "app/states/exhibitor";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import IconPdf from "app/assets/Agent/icon_pdf_small.png";
import { commonActions } from "app/states/common";

const ExhibitorDownloadFilesModal = ({ files }) => {
	const dispatch = useDispatch();
	const closeExhibitorDownloadFilesModal = () => dispatch(exhibitorActions.closeExhibitorDownloadFilesModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showModal = useSelector(({ exhibitor }) => exhibitor.showExhibitorDownloadFilesModal);

	const handleClose = () => {
		closeExhibitorDownloadFilesModal();
	};

	const onClickDownload = (contentUrl, filename) => {
		if (!contentUrl) {
			return;
		}

		fetch(contentUrl)
			.then((response) => response.blob())
			.then((blob) => {
				const blobURL = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = blobURL;
				a.style = "display: none";

				if (filename && filename.length) {
					a.download = filename;
				}

				document.body.appendChild(a);
				a.click();
			})
			.catch((error) => {
				openAlertSnackbar("Unable to download file", "error");
			});
	};

	const File = ({ filename, contentUrl }) => {
		return (
			<span className="exhibitor-file-download" onClick={() => onClickDownload(contentUrl, filename)}>
				<div className="file-download-display">
					<img src={IconPdf} alt="thumbnail" className="file-download-thumbnail" />
				</div>
				<div className="file-download-name">{filename}</div>
			</span>
		);
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="exhibitor-download-files-modal">
			<div className="app-modal exhibitor-download-files-modal">
				<div className="modal-content">
					<h1>Downloadable Materials</h1>
					<p>Click on any files to download</p>

					<section className="files-wrapper">
						{files.map((file) => {
							const { id, name, content_url } = file;

							return <File key={id} filename={name} contentUrl={content_url} />;
						})}
					</section>

					<button className="btn-primary btn-close" onClick={handleClose}>
						Close
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(ExhibitorDownloadFilesModal);

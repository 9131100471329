export const SET_ROOM_DETAILS = 'agent/SET_ROOM_DETAILS'

export const OPEN_ADD_PRODUCT_MODAL = 'agent/OPEN_ADD_PRODUCT_MODAL'
export const CLOSE_ADD_PRODUCT_MODAL = 'agent/CLOSE_ADD_PRODUCT_MODAL'

export const OPEN_EDIT_PRODUCT_MODAL = 'agent/OPEN_EDIT_PRODUCT_MODAL'
export const CLOSE_EDIT_PRODUCT_MODAL = 'agent/CLOSE_EDIT_PRODUCT_MODAL'

export const REFRESH_STORE_PRODUCTS = 'agent/REFRESH_STORE_PRODUCTS';

export const OPEN_ADD_MEDIA_MODAL = 'agent/OPEN_ADD_MEDIA_MODAL'
export const CLOSE_ADD_MEDIA_MODAL = 'agent/CLOSE_ADD_MEDIA_MODAL'

export const REFRESH_EXHIBITOR_ROOM_DETAILS = 'agent/REFRESH_EXHIBITOR_ROOM_DETAILS'

export const OPEN_LIVE_TOGGLE_MODAL = 'agent/OPEN_LIVE_TOGGLE_MODAL'
export const CLOSE_LIVE_TOGGLE_MODAL = 'agent/CLOSE_LIVE_TOGGLE_MODAL'
export const SET_LIVE_SESSION_ID = 'agent/SET_LIVE_SESSION_ID'

export const OPEN_CREATE_PRIVATE_CHAT_MODAL = 'agent/OPEN_CREATE_PRIVATE_CHAT_MODAL'
export const CLOSE_CREATE_PRIVATE_CHAT_MODAL = 'agent/CLOSE_CREATE_PRIVATE_CHAT_MODAL'

export const REFRESH_CHAT_LISTING = 'agent/REFRESH_CHAT_LISTING'
export const SET_NEW_CHAT_ID = 'agent/SET_NEW_CHAT_ID'
export const SET_NEW_CHAT_INVITED_COUNT = 'agent/SET_NEW_CHAT_INVITED_COUNT'
export const VIEW_NEW_PRIVATE_CHAT = 'agent/VIEW_NEW_PRIVATE_CHAT'

export const OPEN_CREATE_PRIVATE_CHAT_SUCCESS_MODAL = 'agent/OPEN_CREATE_PRIVATE_CHAT_SUCCESS_MODAL'
export const CLOSE_CREATE_PRIVATE_CHAT_SUCCESS_MODAL = 'agent/CLOSE_CREATE_PRIVATE_CHAT_SUCCESS_MODAL'

export const OPEN_DELETE_CHAT_MODAL = 'agent/OPEN_DELETE_CHAT_MODAL'
export const CLOSE_DELETE_CHAT_MODAL = 'agent/CLOSE_DELETE_CHAT_MODAL'
export const SET_DELETE_CHAT_ID = 'agent/SET_DELETE_CHAT_ID'
export const SET_DELETE_CHAT_NAME = 'agent/SET_DELETE_CHAT_NAME'

export const OPEN_ADD_PRIVATE_CHAT_PARTICIPANTS = 'agent/OPEN_ADD_PRIVATE_CHAT_PARTICIPANTS'
export const CLOSE_ADD_PRIVATE_CHAT_PARTICIPANTS = 'agent/CLOSE_ADD_PRIVATE_CHAT_PARTICIPANTS'

export const OPEN_REMOVE_PRIVATE_CHAT_PARTICIPANTS = 'agent/OPEN_REMOVE_PRIVATE_CHAT_PARTICIPANTS'
export const CLOSE_REMOVE_PRIVATE_CHAT_PARTICIPANTS = 'agent/CLOSE_REMOVE_PRIVATE_CHAT_PARTICIPANTS'

export const SET_AGENT_VIEWING_ORDER_ID = 'agent/SET_AGENT_VIEWING_ORDER_ID'
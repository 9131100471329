import * as types from "./types";

const initialState = {
	roomDetails: null,
	showAddProductModal: false,
	showEditProductModal: false,
	isRefreshStoreProducts: false,
	viewProductId: null,
	showAddMediaModal: false,
	isRefreshExhibitorRoomDetails: false,
	showLiveToggleModal: false,
	liveSessionId: null,
	showCreatePrivateChatModal: false,
	isRefreshChatListing: false,
	newPrivateChatId: null,
	newPrivateChatInvitedCount: 0,
	isViewNewPrivateChat: false,
	showCreatePrivateChatSuccessModal: false,
	showDeleteChatModal: false,
	deleteChatId: null,
	deleteChatName: "",
	showAddPrivateChatParticipantsModal: false,
	showRemovePrivateChatParticipantsModal: false,
	agentViewingOrderId: null,
};

const AgentReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_ROOM_DETAILS:
			return {
				...state,
				roomDetails: action.params.roomDetails,
			};

		// Add product modal
		case types.OPEN_ADD_PRODUCT_MODAL:
			return {
				...state,
				showAddProductModal: true,
			};
		case types.CLOSE_ADD_PRODUCT_MODAL:
			return {
				...state,
				showAddProductModal: false,
			};

		// Edit product modal
		case types.OPEN_EDIT_PRODUCT_MODAL:
			return {
				...state,
				showEditProductModal: true,
				viewProductId: parseInt(action.params.productId),
			};
		case types.CLOSE_EDIT_PRODUCT_MODAL:
			return {
				...state,
				showEditProductModal: false,
				viewProductId: null,
			};

		// Refresh store Products
		case types.REFRESH_STORE_PRODUCTS:
			return {
				...state,
				isRefreshStoreProducts: action.params.refresh,
			};

		// Add media modal
		case types.OPEN_ADD_MEDIA_MODAL:
			return {
				...state,
				showAddMediaModal: true,
			};
		case types.CLOSE_ADD_MEDIA_MODAL:
			return {
				...state,
				showAddMediaModal: false,
			};

		// Refresh store Products
		case types.REFRESH_EXHIBITOR_ROOM_DETAILS:
			return {
				...state,
				isRefreshExhibitorRoomDetails: action.params.refresh,
			};

		// Live toggle modal
		case types.OPEN_LIVE_TOGGLE_MODAL:
			return {
				...state,
				showLiveToggleModal: true,
			};
		case types.CLOSE_LIVE_TOGGLE_MODAL:
			return {
				...state,
				showLiveToggleModal: false,
			};

		case types.SET_LIVE_SESSION_ID:
			return {
				...state,
				liveSessionId: action.params.liveSessionId,
			};

		// Create private chat modal
		case types.OPEN_CREATE_PRIVATE_CHAT_MODAL:
			return {
				...state,
				showCreatePrivateChatModal: true,
			};
		case types.CLOSE_CREATE_PRIVATE_CHAT_MODAL:
			return {
				...state,
				showCreatePrivateChatModal: false,
			};
		case types.REFRESH_CHAT_LISTING:
			return {
				...state,
				isRefreshChatListing: action.params.refresh,
			};

		// New private chat
		case types.SET_NEW_CHAT_ID:
			return {
				...state,
				newPrivateChatId: action.params.chatId,
			};
		case types.SET_NEW_CHAT_INVITED_COUNT:
			return {
				...state,
				newPrivateChatInvitedCount: action.params.newPrivateChatInvitedCount,
			};
		case types.VIEW_NEW_PRIVATE_CHAT:
			return {
				...state,
				isViewNewPrivateChat: action.params.view,
			};

		// Create private chat success modal
		case types.OPEN_CREATE_PRIVATE_CHAT_SUCCESS_MODAL:
			return {
				...state,
				showCreatePrivateChatSuccessModal: true,
			};
		case types.CLOSE_CREATE_PRIVATE_CHAT_SUCCESS_MODAL:
			return {
				...state,
				showCreatePrivateChatSuccessModal: false,
			};

		// Delete chat modal
		case types.OPEN_DELETE_CHAT_MODAL:
			return {
				...state,
				showDeleteChatModal: true,
			};
		case types.CLOSE_DELETE_CHAT_MODAL:
			return {
				...state,
				showDeleteChatModal: false,
			};
		case types.SET_DELETE_CHAT_ID:
			return {
				...state,
				deleteChatId: action.params.chatId,
			};
		case types.SET_DELETE_CHAT_NAME:
			return {
				...state,
				deleteChatName: action.params.name,
			};

		// Add private chat participants modal
		case types.OPEN_ADD_PRIVATE_CHAT_PARTICIPANTS:
			return {
				...state,
				showAddPrivateChatParticipantsModal: true,
			};
		case types.CLOSE_ADD_PRIVATE_CHAT_PARTICIPANTS:
			return {
				...state,
				showAddPrivateChatParticipantsModal: false,
			};

		// Remove private chat participants modal
		case types.OPEN_REMOVE_PRIVATE_CHAT_PARTICIPANTS:
			return {
				...state,
				showRemovePrivateChatParticipantsModal: true,
			};
		case types.CLOSE_REMOVE_PRIVATE_CHAT_PARTICIPANTS:
			return {
				...state,
				showRemovePrivateChatParticipantsModal: false,
			};

		// set agent viewing order id
		case types.SET_AGENT_VIEWING_ORDER_ID:
			return {
				...state,
				agentViewingOrderId: action.params.viewingOrderId,
			};
		default:
			return state;
	}
};

export default AgentReducer;

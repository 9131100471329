/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { convertYoutubeToEmbedLink, convertFacebookToEmbedLink } from "app/helper/convertEmbedLink";
import ReactPlayer from "react-player";
import IconBriefcase from "app/assets/icon-briefcase.svg";
import IconWebsite from "app/assets/Channels_Exhibitors/icon_website.png";
import IconLinkedin from "app/assets/Channels_Exhibitors/icon_linkedin.png";
import IconInstagram from "app/assets/Channels_Exhibitors/icon_instagram.png";
import IconFacebook from "app/assets/Channels_Exhibitors/icon_fb.png";
import IconYoutube from "app/assets/Channels_Exhibitors/icon_youtube.png";
import IconPrevious from "app/assets/Channels_Exhibitors/btn_previous.png";
import IconNext from "app/assets/Channels_Exhibitors/btn_next.png";
import { Icon } from "@iconify/react";
import live24Regular from "@iconify-icons/fluent/live-24-regular";
import Slider from "react-slick";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { exhibitorActions } from "app/states/exhibitor";
import ExhibitorDownloadFilesModal from "../ExhibitorDownloadFilesModal/ExhibitorDownloadFilesModal";
import { ACTIVITY_CODE, MEDIA_TYPES } from "app/config/settings";
import moment from "moment";
import EventAPI from "app/apis/event";
import { exhibitorSocket } from "app/services/socketService";
import IconPlayVideo from "app/assets/Channels_Exhibitors/play.png";
import ImageNoContentBackground from "app/assets/Channels_Exhibitors/no-content-background.jpg";
import { urlify } from "app/helper/common";
import LocalStorageService from "app/services/localStorageService";

const ExhibitorDetail = ({ exhibitorRoomDetail, isNotFound }) => {
	const { roomId } = useParams();
	const eventSetting = LocalStorageService.getEventSetting();

	const dispatch = useDispatch();
	const openExhibitorDownloadFilesModal = () => dispatch(exhibitorActions.openExhibitorDownloadFilesModal());

	const [contents, setContents] = useState([]);
	const [selectedContentUrl, setSelectedContentUrl] = useState("");
	const [selectedContentType, setSelectedContentType] = useState(null);
	const [selectedContentIndex, setSelectContentIndex] = useState(0);

	const [liveStreamStatusText, setLiveStreamStatusText] = useState("");
	const [liveStreamStatusClass, setLiveStreamStatusClass] = useState("");
	const [liveSession, setLiveSession] = useState(null);
	const [isLive, setIsLive] = useState(false);

	const [filesDownload, setFilesDownload] = useState([]);

	useEffect(() => {
		exhibitorSocket.on("room-update", (message) => {
			// do something with incoming message from backend
			// console.log("exhibitorSocket: ", message);

			const { action_code, data } = message;

			// Live streaming toggled
			if (action_code === 500) {
				const { is_live, live_ended, thumbnail_url, video_url, start_at, end_at } = data;

				// Live stream started
				if (is_live && !live_ended) {
					setIsLive(true);
					setSelectedContentUrl(video_url);
					setSelectedContentType(MEDIA_TYPES["video"]);
					setLiveStreamStatusText("");
					setLiveStreamStatusClass("");
				}
				// Live stream ended
				else {
					setIsLive(false);
					setSelectedContentUrl(thumbnail_url);
					setSelectedContentType(MEDIA_TYPES["image"]);
					setLiveStreamStatusText("STREAMING HAS ENDED. STAY TUNED FOR THE NEXT ONE.");
					setLiveStreamStatusClass("text-live-stream-ended");
				}
			}
		});

		// return () => {
		// 	// turning of socket listner on unmount
		// 	exhibitorSocket.off("room-update");
		// };
	}, []);

	useEffect(() => {
		if (!exhibitorRoomDetail) return;

		// Reset states
		setSelectedContentUrl(null);
		setSelectedContentType(null);
		setSelectContentIndex(0);
		setLiveStreamStatusText("");
		setLiveStreamStatusClass("");
		setLiveSession(null);
		setIsLive(false);

		const { live_sessions, medias } = exhibitorRoomDetail;

		// Merge medias and live videos into one array
		let liveSessionVideos = live_sessions.map((liveSession) => {
			return {
				type: MEDIA_TYPES["video"],
				thumbnail_url: liveSession.thumbnail_url,
				content_url: liveSession.video_url,
				isLiveSession: true,
			};
		});

		let mediaVideos = medias.map((media) => {
			return {
				type: media.type,
				thumbnail_url: media.thumbnail_url,
				content_url: media.content_url,
				isLiveSession: false,
			};
		});

		let carouselContents = liveSessionVideos.concat(mediaVideos);
		setContents(carouselContents);

		// Live streaming logic handling
		if (live_sessions.length > 0) {
			setLiveSession(live_sessions[0]);
		}

		// Display first video/media link
		if (carouselContents.length > 0) {
			const { type, thumbnail_url, content_url } = carouselContents[0];

			// If no content_url (video), show thumbnail
			if (!content_url) {
				setSelectedContentUrl(thumbnail_url);
			} else {
				setSelectedContentUrl(content_url);
			}

			setSelectedContentType(type);
		}

		// Set files download
		const { files } = exhibitorRoomDetail;
		setFilesDownload(files);
	}, [exhibitorRoomDetail]);

	useEffect(() => {
		handleLiveStreamConditions();
	}, [liveSession]);

	const handleLiveStreamConditions = () => {
		if (!liveSession) {
			setLiveStreamStatusText("NO LIVE STREAM SCHEDULED");
			setLiveStreamStatusClass("text-no-live-stream");
			return;
		}

		const { is_live, live_ended, thumbnail_url, video_url, start_at, end_at } = liveSession;

		// Format start_at/end_at
		const startAt = moment(start_at).format("Do MMM YYYY, h:mmA");
		const starAtTimeOnly = moment(start_at).format("h:mmA");
		const endAtTimeOnly = moment(end_at).format("h:mmA");

		// Check if live stream starting soon within 15mins
		const diffMin = moment(start_at).diff(moment(), "minutes");
		if (diffMin <= 15 && !is_live && !live_ended) {
			setSelectedContentUrl(thumbnail_url);
			setSelectedContentType(MEDIA_TYPES["image"]);
			setLiveStreamStatusText(
				`<strong>LIVE STREAM WILL BEGIN SHORTLY</strong> | ${starAtTimeOnly} to ${endAtTimeOnly}`
			);
			setLiveStreamStatusClass("text-live-stream-soon");
		}
		// Live Stream ongoing
		else if (is_live && !live_ended) {
			setSelectedContentUrl(video_url);
			setSelectedContentType(MEDIA_TYPES["video"]);
			setLiveStreamStatusText("");
			setLiveStreamStatusClass("");
			setIsLive(true);
		}
		// Before Live Stream
		else if (!is_live && !live_ended) {
			setSelectedContentUrl(thumbnail_url);
			setSelectedContentType(MEDIA_TYPES["image"]);
			setLiveStreamStatusText(`<strong>NEXT LIVE STREAM</strong>: ${startAt} to ${endAtTimeOnly}`);
			setLiveStreamStatusClass("text-live-stream-soon");
		}
		// Post Live Stream
		else if (!is_live && live_ended) {
			setIsLive(false);
			setSelectedContentUrl(thumbnail_url);
			setSelectedContentType(MEDIA_TYPES["image"]);
			setLiveStreamStatusText("STREAMING HAS ENDED. STAY TUNED FOR THE NEXT ONE.");
			setLiveStreamStatusClass("text-live-stream-ended");
		}
	};

	const logActivity = (code, targetId) => {
		EventAPI.postLogActivity(code, targetId)
			.then((response) => {
				// success
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const renderVideoPlayer = () => {
		if (!exhibitorRoomDetail) return;

		if (parseInt(selectedContentType) === MEDIA_TYPES["image"]) {
			return <img src={selectedContentUrl} alt="Content" className="video-thumbnail" />;
		} else {
			// If is live, log activity
			if (isLive) {
				logActivity(ACTIVITY_CODE["watch_exhibitor_live"], roomId);
			}

			return (
				<div className="video-wrapper">
					<ReactPlayer
						className="exhibitor-video-player"
						url={selectedContentUrl}
						controls
						height={380}
						playing={isLive ? true : false}
					/>
				</div>
			);
		}
	};

	const handleChangeMedia = (index) => {
		const { type, thumbnail_url, content_url, isLiveSession } = contents[index];

		setSelectContentIndex(index);

		// If is live session, set the live session
		if (isLiveSession) {
			handleLiveStreamConditions();
		} else {
			setSelectedContentType(type);

			if (parseInt(type) === MEDIA_TYPES["image"]) {
				setSelectedContentUrl(thumbnail_url);
			} else {
				setSelectedContentUrl(content_url);
			}
		}
	};

	if (isNotFound) {
		return (
			<div className="exhibitor-detail-container flex justify-center">
				<div className="not-found-wrapper">
					<div className="not-found-title">Ops, exhibitor room not found!</div>
					<div className="not-found-subtitle">
						The exhibitor room that you've attempted to access does not exist.
					</div>
				</div>
			</div>
		);
	}

	if (!exhibitorRoomDetail) {
		return (
			<div className="event-cover-wrapper flex justify-center items-center">
				{/* <ReactLoading type={"spinningBubbles"} color="lightgrey" /> */}
				<img src={eventSetting.image_url} alt="event cover" className="event-cover" />
			</div>
		);
	}

	return (
		<div className="exhibitor-detail-container">
			<section className="exhibitor-header">
				<div className="grid grid-cols-12 gap-3 px-3 lg:px-5 py-2 w-full">
					<div className="col-span-4 lg:col-span-6 flex items-center pr-3">
						<img src={exhibitorRoomDetail.logo_url} alt="logo" className="exhibitor-logo mr-1 lg:mr-3" />
						<div className="exhibitor-title">{exhibitorRoomDetail.title}</div>
					</div>
					<div className="col-span-8 lg:col-span-6 pr-2 lg:pr-0">
						{contents.length > 0 && (
							<Slider
								className="content-carousel"
								dots={false}
								infinite={false}
								slidesToShow={3}
								slidesToScroll={1}
								nextArrow={<img src={IconNext} alt="next" className="btn-carousel" />}
								prevArrow={<img src={IconPrevious} alt="previous" className="btn-carousel" />}>
								{contents.map((content, index) => {
									const { type, thumbnail_url } = content;
									return (
										<div
											key={index}
											data-type={type}
											onClick={() => handleChangeMedia(index)}
											className={`carousel-item ${
												index === selectedContentIndex ? "active" : ""
											}`}>
											<img src={thumbnail_url} alt="video" className="carousel-thumbnail" />

											{parseInt(type) === MEDIA_TYPES["video"] && (
												<img src={IconPlayVideo} alt="play" className="carousel-play-icon" />
											)}
										</div>
									);
								})}
							</Slider>
						)}
					</div>
				</div>
			</section>

			{selectedContentUrl ? (
				<section className="exhibitor-video-container">{renderVideoPlayer()}</section>
			) : (
				<section className="exhibitor-no-content-container flex justify-center items-center">
					<img src={ImageNoContentBackground} alt="no content" className="img-no-content" />
					<p className="text-no-content">Great things are coming, stay tuned</p>
				</section>
			)}

			<section className="exhibitor-meta p-3">
				<div className="flex justify-between items-start lg:items-center mb-1">
					<div className="mr-3">
						{exhibitorRoomDetail.account.website && (
							<a href={exhibitorRoomDetail.account.website} target="_blank" rel="noreferrer">
								<img src={IconWebsite} alt="website" className="meta-links" />
							</a>
						)}
						{exhibitorRoomDetail.account.linkedin_url && (
							<a href={exhibitorRoomDetail.account.linkedin_url} target="_blank" rel="noreferrer">
								<img src={IconLinkedin} alt="linkedin" className="meta-links" />
							</a>
						)}
						{exhibitorRoomDetail.account.instagram_url && (
							<a href={exhibitorRoomDetail.account.instagram_url} target="_blank" rel="noreferrer">
								<img src={IconInstagram} alt="instagram" className="meta-links" />
							</a>
						)}
						{exhibitorRoomDetail.account.facebook_url && (
							<a href={exhibitorRoomDetail.account.facebook_url} target="_blank" rel="noreferrer">
								<img src={IconFacebook} alt="facebook" className="meta-links" />
							</a>
						)}
						{exhibitorRoomDetail.account.youtube_url && (
							<a href={exhibitorRoomDetail.account.youtube_url} target="_blank" rel="noreferrer">
								<img src={IconYoutube} alt="youtube" className="meta-links" />
							</a>
						)}

						{filesDownload.length > 0 && (
							<span
								className="meta-download px-2 py-1 lg:px-2 lg:py-1"
								onClick={openExhibitorDownloadFilesModal}>
								Downloads
							</span>
						)}
					</div>
					<div className="flex flex-end items-center">
						{/* <div className="meta-briefcase px-2 py-1 lg:px-2 lg:py-1 flex items-center mr-2">
							<img src={IconBriefcase} alt="briefcase" className="icon-briefcase inline pr-1" />
							Briefcase
						</div> */}

						{isLive && (
							<span className="badge-live">
								LIVE <Icon icon={live24Regular} className="icon-live" />
							</span>
						)}

						{liveStreamStatusText !== "" && (
							<div
								className={liveStreamStatusClass}
								dangerouslySetInnerHTML={{ __html: liveStreamStatusText }}
							/>
						)}
					</div>
				</div>
			</section>

			<section className="exhibitor-description lg:py-2 lg:px-4">
				<div className="hidden lg:block">
					<h3 className="title">{exhibitorRoomDetail.tagline || exhibitorRoomDetail.title}</h3>
					<div
						className="description"
						dangerouslySetInnerHTML={{ __html: urlify(exhibitorRoomDetail.description) }}
					/>
				</div>

				<div className="block lg:hidden">
					<Accordion elevation={0} className="accordion-wrapper">
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="exhibitor-description">
							<h3 className="title">{exhibitorRoomDetail.title || "Title"}</h3>
						</AccordionSummary>
						<AccordionDetails className="accordion-body">
							<div
								className="description"
								dangerouslySetInnerHTML={{ __html: urlify(exhibitorRoomDetail.description) }}
							/>
						</AccordionDetails>
					</Accordion>
				</div>
			</section>

			<ExhibitorDownloadFilesModal files={filesDownload} />
		</div>
	);
};

export default withRouter(ExhibitorDetail);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import GoogleLogin from "react-google-login";
import { refreshGoogleTokenSetup } from "app/services/socialLoginService";
import { getAllDaysOfMonth, getAllMonths, getAllYears } from "app/helper/datetime";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Authentication from "app/apis/authentication";
import moment from "moment";
import FacebookIcon from "app/assets/Authentication/facebook.png";
import GoogleIcon from "app/assets/Authentication/google.png";
import "./style.scss";
import LocalStorageService from "app/services/localStorageService";
import COUNTRY_CALLING_CODES from "app/config/country_by_calling_code.json";
import { authenticationActions } from "app/states/authentication";
import { useMediaQuery } from "react-responsive";
import PrivacyPolicyModal from "../../Modal/PrivacyPolicyModal/PrivacyPolicyModal";
import TermsConditionModal from "../../Modal/TermsConditionModal/TermsConditionModal";
import EventAPI from "app/apis/event";

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`auth-tabpanel-${index}`}
			aria-labelledby={`auth-tab-${index}`}
			{...other}>
			{value === index && <Box py={3}>{children}</Box>}
		</div>
	);
};

function switchTab(index) {
	return {
		id: `auth-tab-${index}`,
		"aria-controls": `auth-tabpanel-${index}`,
	};
}

const LoginRegisterContainer = ({ history, location }) => {
	const { eventCode } = useParams();

	// create a variable to store the component instance
	let recaptchaInstance;

	const dispatch = useDispatch();
	const openResetPasswordModal = () => dispatch(commonActions.openResetPasswordModal());
	const openTermsConditionModal = () => dispatch(commonActions.openTermsConditionModal());
	const openPrivacyPolicyModal = () => dispatch(commonActions.openPrivacyPolicyModal());
	const setUserProfile = (userProfile) => dispatch(authenticationActions.setUserProfile(userProfile));
	const setIsLoggedIn = (isLogin) => dispatch(authenticationActions.setIsLoggedIn(isLogin));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const closeLoginRegisterModal = () => dispatch(authenticationActions.closeLoginRegisterModal());
	const openEventRegistrationModal = () => dispatch(authenticationActions.openEventRegistrationModal());
	const openShippingAddressModal = () => dispatch(authenticationActions.openShippingAddressModal());
	const openTicketSummaryModal = () => dispatch(authenticationActions.openTicketSummaryModal());

	const loginRegisterTabIndex = useSelector(({ auth }) => auth.loginRegisterTabIndex);
	const eventSettings = useSelector(({ event }) => event.eventSettings);
	const isFromRegisterButton = useSelector(({ auth }) => auth.isFromRegisterButton);

	const [tabIndex, setTabIndex] = useState(loginRegisterTabIndex);

	const [registerInput, setRegisterInput] = useState({
		email: "",
		password: "",
		confirm_password: "",
		first_name: "",
		last_name: "",
		calling_code: 60,
		phone_no: "",
		agree_privacy: false,
		agree_tnc: false,
		captcha_token: "",
	});

	const [loginInput, setLoginInput] = useState({
		email: "",
		password: "",
	});

	useEffect(() => {
		getEmailFromUrl();
	}, []);

	const getEmailFromUrl = () => {
		const email = new URLSearchParams(location.search).get("email");

		if (email) {
			setRegisterInput({ ...registerInput, email: email });
			setLoginInput({ ...loginInput, email: email });
		}
	};

	const handleTabChange = (event, newTabIndex) => {
		setTabIndex(newTabIndex);
	};

	const onGoogleLoginSuccess = (res) => {
		refreshGoogleTokenSetup(res);

		const { profileObj, tokenId } = res;
		const { email, googleId } = profileObj;

		const loginData = {
			method: 2, // For google login
			email: email,
			external_id: googleId,
			external_token: tokenId,
		};

		postLogin(loginData);
	};

	const onGoogleLoginFailure = (res) => {
		console.log("google login fail", res);
	};

	const onFacebookLogin = (res) => {
		const { id, accessToken } = res;
		localStorage.setItem("socialAuthToken", accessToken);

		const loginData = {
			method: 3, // For facebook login
			external_id: id,
			external_token: accessToken,
		};

		postLogin(loginData);
	};

	const onFacebookLoginFailure = (res) => {
		console.log("facebook login fail", res);
		openAlertSnackbar("Facebook Login Fail", "error");
	};

	const handleRegisterInputChange = (event) => {
		if (event.target.name === "agree_privacy" || event.target.name === "agree_tnc") {
			setRegisterInput({ ...registerInput, [event.target.name]: event.target.checked });
		} else {
			setRegisterInput({ ...registerInput, [event.target.name]: event.target.value });
		}
	};

	const handleRegister = (event) => {
		event.preventDefault();

		// Ensure password and confirm password is same
		if (registerInput.password !== registerInput.confirm_password) {
			openAlertSnackbar("Password and confirm password must be same", "error");
			return;
		}

		// let formattedDob = moment(
		// 	`${registerInput.dob_year}-${registerInput.dob_month}-${registerInput.dob_day}`,
		// 	"YYYY-MMMM-DD"
		// );
		// formattedDob = formattedDob.format("YYYY-MM-DD");

		const userData = {
			email: registerInput.email,
			password: registerInput.password,
			first_name: registerInput.first_name,
			last_name: registerInput.last_name,
			// dob: formattedDob,
			// phone_no: `${registerInput.calling_code}${registerInput.phone_no}`,
		};

		Authentication.postRegister(eventSettings.id, userData, registerInput.captcha_token)
			.then((response) => {
				const { verification_required, user } = response;

				setIsLoggedIn(true);
				setUserProfile(user);

				// Check if user requires verification
				if (verification_required) {
					history.push(`/events/${eventCode}/verify-email`, {
						email: registerInput.email,
					});
				}

				if (isFromRegisterButton) {
					// If event is paid event, show ticket summary
					if (eventSettings && eventSettings.entry_tickets && eventSettings.entry_tickets.length > 0) {
						if (parseInt(eventSettings.entry_tickets[0].price) > 0) {
							openTicketSummaryModal();
							return;
						}
					}

					// If event is free event, show event registration modal after register
					openEventRegistrationModal();

					// // If ecommerce enabled, show shipping address modal
					// if (eventSettings.ecommerce_enabled === 1) {
					// 	closeLoginRegisterModal();
					// 	openShippingAddressModal();
					// 	return;
					// }
				}

				/** No longer required for code drop 2.5 onwards */
				// // Check if event started yet
				// const diffDays = moment().diff(moment(start_at), "days");
				// if (diffDays >= 0) {
				// 	history.push(`/${eventCode}/channel`);
				// } else {
				// 	history.push(`/${eventCode}/countdown`);
				// }

				// Close login register modal
				closeLoginRegisterModal();
			})
			.catch((error) => {
				resetRecaptcha();
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleLoginInputChange = (event) => {
		setLoginInput({ ...loginInput, [event.target.name]: event.target.value });
	};

	const handleLogin = (event) => {
		event.preventDefault();

		const loginData = {
			method: 1, // For standard email & password login
			email: loginInput.email,
			password: loginInput.password,
		};

		postLogin(loginData);
	};

	const postLogin = async (loginData = {}) => {
		Authentication.postLogin(loginData)
			.then(async (response) => {
				const { user } = response;
				// const { profile_completed, phone_input_required } = user;

				setIsLoggedIn(true);
				setUserProfile(user);

				// // If login using social media and require phone input
				// if (phone_input_required === 1) {
				// 	history.push(`/events/${eventCode}/register/social/add-mobile`);
				// 	return;
				// }

				if (isFromRegisterButton) {
					// Check if user has joined event
					EventAPI.getUserInfoByEventId()
						.then((response) => {
							// If ecommerce enabled, show shipping address modal
							if (eventSettings.ecommerce_enabled === 1) {
								openShippingAddressModal();
								return;
							}
						})
						.catch((error) => {
							// If user has not joined the event, prompt ticket summary modal
							// If event is paid event, show ticket summary
							if (eventSettings && eventSettings.entry_tickets && eventSettings.entry_tickets.length > 0) {
								if (parseInt(eventSettings.entry_tickets[0].price) > 0) {
									openTicketSummaryModal();
									return;
								}
							}

							// If event is free event, show event registration modal after register
							openEventRegistrationModal();

							// // If ecommerce enabled, redirect to shipping address page
							// if (eventSettings.ecommerce_enabled === 1) {
							// 	closeLoginRegisterModal();
							// 	openShippingAddressModal();
							// 	return;
							// }
						});
				}

				/** No longer required for code drop 2.5 onwards */
				// // Check if event started yet
				// const diffDays = moment().diff(moment(start_at), "days");
				// if (diffDays >= 0) {
				// 	history.push(`/${eventCode}/channel`);
				// } else {
				// 	history.push(`/${eventCode}/countdown`);
				// }

				// Close login register modal
				closeLoginRegisterModal();
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.message, "error");

				// Account not verified
				if (error && error.status === 403) {
					history.push(`/events/${eventCode}/verify-email`, {
						email: loginInput.email,
					});
				}
			});
	};

	const onChangeRecaptcha = (value) => {
		setRegisterInput({ ...registerInput, captcha_token: value });
	};

	const resetRecaptcha = () => {
		if (recaptchaInstance) {
			recaptchaInstance.reset();
		}
		setRegisterInput({ ...registerInput, captcha_token: "" });
	};

	const SocialLoginButtons = () => {
		return (
			<section className="flex justify-center lg:justify-start">
				<GoogleLogin
					clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
					render={(renderProps) => (
						<button
							type="button"
							className="btn-google-login flex justify-center items-center rounded px-3 mr-4"
							onClick={renderProps.onClick}
							disabled={renderProps.disabled}>
							<img src={GoogleIcon} alt="Google login" className="google-icon" />
						</button>
					)}
					buttonText="Login"
					onSuccess={(response) => onGoogleLoginSuccess(response)}
					onFailure={(error) => onGoogleLoginFailure(error)}
					cookiePolicy={"single_host_origin"}
					// isSignedIn={true}
				/>
				<FacebookLogin
					appId={process.env.REACT_APP_FB_APP_ID}
					callback={(response) => onFacebookLogin(response)}
					onFailure={(error) => onFacebookLoginFailure(error)}
					isMobile={false}
					render={(renderProps) => (
						<button
							type="button"
							className="btn-fb-login rounded"
							onClick={renderProps.onClick}
							disabled={renderProps.isDisabled}>
							<img src={FacebookIcon} alt="facebook login" />
						</button>
					)}
				/>
			</section>
		);
	};

	return (
		<div id="login-register-container" className="flex flex-col pt-10 lg:pt-6 lg:justify-center h-full">
			<section className="mb-4 px-14 lg:px-0">
				<div className="title mb-2">{tabIndex === 0 ? "Welcome back to boom!" : "Welcome to boom!"}</div>
				<div className="subtitle">
					{tabIndex === 0
						? "Please login with your boom account to access to this event."
						: "Please sign up for a boom account to access to this event."}
				</div>
			</section>

			<section className="form-section">
				<Tabs
					value={tabIndex}
					onChange={handleTabChange}
					aria-label="Login Register Tabs"
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth">
					<Tab label="Login" {...switchTab(0)} className="tab" />
					<Tab label="Sign Up" {...switchTab(1)} className="tab" />
				</Tabs>

				<TabPanel value={tabIndex} index={0}>
					<form id="form-login" method="post" onSubmit={handleLogin} className="px-10 lg:px-0 pt-4 lg:pt-0">
						<div className="form-input">
							<label htmlFor="login-email">Email address</label>
							<input
								id="login-email"
								type="email"
								name="email"
								required
								value={loginInput.email}
								onChange={handleLoginInputChange}
							/>
						</div>
						<div className="form-input form-password-input">
							<label htmlFor="login-password">Password</label>
							<input
								id="login-password"
								type="password"
								name="password"
								required
								value={loginInput.password}
								onChange={handleLoginInputChange}
							/>
						</div>
						<div className="mb-3">
							<span className="forget-password-link" onClick={openResetPasswordModal}>
								Forgotten your password?
							</span>
						</div>

						{tabIndex === 0 && (
							<div className="my-6">
								<SocialLoginButtons />
							</div>
						)}
						<div className="mb-3 text-center lg:text-left">
							<button type="submit" className="btn-primary btn-login">
								Sign In
							</button>
						</div>
					</form>
				</TabPanel>

				<TabPanel value={tabIndex} index={1}>
					<form
						id="form-register"
						method="post"
						onSubmit={handleRegister}
						className="px-10 lg:px-0 pt-4 lg:pt-0">
						<div className="form-input">
							<label htmlFor="register-first-name">First name</label>
							<input
								id="register-first-name"
								type="text"
								name="first_name"
								required
								value={registerInput.first_name}
								onChange={handleRegisterInputChange}
							/>
						</div>
						<div className="form-input">
							<label htmlFor="register-last-name">Last name</label>
							<input
								id="register-last-name"
								type="text"
								name="last_name"
								required
								value={registerInput.last_name}
								onChange={handleRegisterInputChange}
							/>
						</div>
						<div className="form-input">
							<label htmlFor="register-email">Email address</label>
							<input
								id="register-email"
								type="email"
								name="email"
								required
								value={registerInput.email}
								onChange={handleRegisterInputChange}
							/>
						</div>
						<div className="form-input">
							<label htmlFor="register-password">Password</label>
							<input
								id="register-password"
								type="password"
								name="password"
								required
								value={registerInput.password}
								onChange={handleRegisterInputChange}
							/>
						</div>
						<div className="form-input">
							<label htmlFor="register-confirm-password">Confirm password</label>
							<input
								id="register-confirm-password"
								type="password"
								name="confirm_password"
								required
								value={registerInput.confirm_password}
								onChange={handleRegisterInputChange}
							/>
						</div>
						<div className="flex justify-between mb-6">
							<div className="pr-3">
								<div className="form-input flex items-center">
									{/* <input
										id="agree_privacy"
										type="checkbox"
										className="app-custom-checkbox"
										required
										name="agree_privacy"
										checked={registerInput.agree_privacy}
										onChange={handleRegisterInputChange}
									/> */}
									<div className="app__checkbox">
										<input
											id="agree_privacy"
											type="checkbox"
											name="agree_privacy"
											required
											checked={registerInput.agree_privacy}
											onChange={handleRegisterInputChange}
										/>
										<div className="app__checkbox-visible"></div>
									</div>
									<label htmlFor="agree_privacy">
										I have read and agree to the{" "}
										<span className="text-anchor-link" onClick={openPrivacyPolicyModal}>
											Privacy Policy
										</span>
									</label>
								</div>
								<div className="form-input flex items-center" style={{ marginBottom: 0 }}>
									{/* <input
										id="agree_tnc"
										type="checkbox"
										className="app-custom-checkbox"
										required
										name="agree_tnc"
										checked={registerInput.agree_tnc}
										onChange={handleRegisterInputChange}
									/> */}
									<div className="app__checkbox">
										<input
											id="agree_tnc"
											type="checkbox"
											name="agree_tnc"
											required
											checked={registerInput.agree_tnc}
											onChange={handleRegisterInputChange}
										/>
										<div className="app__checkbox-visible"></div>
									</div>
									<label htmlFor="agree_tnc">
										I have read and agree to the{" "}
										<span className="text-anchor-link" onClick={openTermsConditionModal}>
											Terms of Use
										</span>
									</label>
								</div>
							</div>
						</div>

						<div className="mb-6">
							<ReCAPTCHA
								ref={(e) => (recaptchaInstance = e)}
								sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
								onChange={onChangeRecaptcha}
								size="normal"
							/>
						</div>

						{tabIndex === 1 && (
							<div className="mb-6">
								<SocialLoginButtons />
							</div>
						)}

						<div className="text-center lg:text-left">
							<button type="submit" className="btn-primary btn-sign-up">
								Sign Up
							</button>
						</div>
					</form>
				</TabPanel>
			</section>

			<PrivacyPolicyModal />
			<TermsConditionModal />
		</div>
	);
};

export default withRouter(LoginRegisterContainer);

import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconExhibitor from "app/assets/Agent/exhibitor_dark.png";
import IconAdd from "app/assets/Authentication/icon-add.svg";
import IconEdit from "app/assets/Agent/icon_edit.png";
import GalleryMedia from "app/views/components/GalleryMedia/GalleryMedia";
import { useSelector, useDispatch } from "react-redux";
import AgentAPI from "app/apis/agent";
import ExhibitorFile from "app/views/components/ExhibitorFile/ExhibitorFile";
import { commonActions } from "app/states/common";
import SessionStorageService from "app/services/sessionStorageService";

const MAX_MEDIA_COUNT = 10;
const MAX_FILE_COUNT = 5;

const ExhibitorDetailsContainer = () => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const roomDetails = useSelector(({ agent }) => agent.roomDetails);

	const [inputData, setInputData] = useState({
		company_name: "",
		company_logo: "",
		title: "",
		short_description: "",
		full_description: "",
	});

	const [gallery, setGallery] = useState([]);
	const [materialFiles, setMaterialFiles] = useState([]);

	const companyLogoRef = useRef();
	const [companyLogo, setCompanyLogo] = useState();
	const [companyLogoFile, setCompanyLogoFile] = useState();

	// Ensure the flag is cleared on refresh
	useEffect(() => {
		SessionStorageService.removeDisableUpdateExhibitorDetailsInAgentPage();
	}, []);

	useEffect(() => {
		if (!roomDetails) return;

		const isUpdateDisabled = SessionStorageService.getDisableUpdateExhibitorDetailsInAgentPage();
		if (!isUpdateDisabled) {
			setInputData({
				company_name: roomDetails.title ? roomDetails.title : inputData.company_name,
				company_logo: roomDetails.logo_url ? roomDetails.logo_url : inputData.company_logo,
				title: roomDetails.tagline ? roomDetails.tagline : inputData.title,
				short_description: roomDetails.short_description
					? roomDetails.short_description
					: inputData.short_description,
				full_description: roomDetails.description ? roomDetails.description : inputData.full_description,
			});

			SessionStorageService.removeDisableUpdateExhibitorDetailsInAgentPage();
		}

		setGallery(roomDetails.medias);
		setMaterialFiles(roomDetails.files);
	}, [roomDetails]);

	const handleChangeImage = (event) => {
		setCompanyLogo(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : "");
		setCompanyLogoFile(event.target.files[0]);
	};

	const browseImage = () => {
		companyLogoRef.current.click();
	};

	const handleInputDataChange = (event) => {
		setInputData({
			...inputData,
			[event.target.name]: event.target.value,
		});
	};

	const submitUpdateExhibitorDetails = () => {
		let formData = new FormData();

		if (companyLogo) {
			formData.append("logo", companyLogoFile);
		}

		formData.append("title", inputData.company_name);
		formData.append("tagline", inputData.title);
		formData.append("short_description", inputData.short_description);
		formData.append("description", inputData.full_description);

		AgentAPI.postUpdateRoomDetail(formData)
			.then((response) => {
				openAlertSnackbar("Updated Exhibitor Details", "success");
				SessionStorageService.removeDisableUpdateExhibitorDetailsInAgentPage();
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleDeleteMediaFile = (media_id) => {
		const data = {
			media_ids: [media_id],
		};

		AgentAPI.deleteExhibitorRoomMediaFiles(data)
			.then((response) => {
				// Find the index of the removed file
				let galleryCopy = [...gallery];
				let index = galleryCopy
					.map((media) => {
						return media.id;
					})
					.indexOf(media_id);

				// Perform removal from array
				if (index !== -1) {
					galleryCopy.splice(index, 1);
					setGallery(galleryCopy);
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleDeleteFile = (file_id) => {
		const data = {
			file_ids: [file_id],
		};

		AgentAPI.deleteExhibitorRoomFiles(data)
			.then((response) => {
				// Find the index of the removed file
				let materialFilesCopy = [...materialFiles];
				let index = materialFilesCopy
					.map((file) => {
						return file.id;
					})
					.indexOf(file_id);

				// Perform removal from array
				if (index !== -1) {
					materialFilesCopy.splice(index, 1);
					setMaterialFiles(materialFilesCopy);
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleUploadFile = (event) => {
		if (event.target.files[0]) {
			let formData = new FormData();
			formData.append("file", event.target.files[0]);

			AgentAPI.postAddExhibitorRoomFiles(formData)
				.then((response) => {
					const newFile = response;
					setMaterialFiles([...materialFiles, newFile]);
				})
				.catch((error) => {
					openAlertSnackbar(error.data.message, "error");
				});
		}
	};

	return (
		<div id="exhibitor-details" className="exhibitor-details-container mb-3">
			<div className="grid grid-cols-12 gap-3">
				<div className="col-span-2">
					<h3>
						<img src={IconExhibitor} alt="Exhibitor Details" /> Exhibitor Details
					</h3>
				</div>
				<div className="col-span-8">
					<form className="form-container mb-3">
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Company Name</label> <span className="required">*</span>
								<div className="remark">(max 50 characters)</div>
							</div>
							<div className="col-span-7">
								<input
									type="text"
									name="company_name"
									value={inputData.company_name}
									onChange={handleInputDataChange}
									maxLength={50}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-4">
							<div className="col-span-2">
								<label>Company Logo</label> <span className="required">*</span>
							</div>
							<div className="col-span-7 flex">
								<div className="logo-image mr-3" onClick={browseImage}>
									{companyLogo || inputData.company_logo ? (
										<>
											<img
												src={companyLogo || inputData.company_logo}
												alt="Cover"
												className="logo"
											/>
											<img src={IconEdit} alt="edit" className="logo-edit" />
										</>
									) : (
										<img src={IconAdd} alt="Add Logo" className="icon-add" />
									)}
								</div>
								<input
									type="file"
									name="image"
									onChange={handleChangeImage}
									ref={companyLogoRef}
									className="hidden"
									accept="image/*"
								/>
								<div className="remark self-end">
									(square image of at least 100px x 100px is recommended)
								</div>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Title / Tagline</label> <span className="required">*</span>
								<div className="remark">(max 60 characters)</div>
							</div>
							<div className="col-span-7">
								<input
									type="text"
									name="title"
									value={inputData.title}
									onChange={handleInputDataChange}
									maxLength={60}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Short Description</label> <span className="required">*</span>
								<div className="remark">(max 80 characters)</div>
							</div>
							<div className="col-span-7">
								<input
									type="text"
									name="short_description"
									value={inputData.short_description}
									onChange={handleInputDataChange}
									maxLength={80}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Full Description</label> <span className="required">*</span>
								<div className="remark">(max 1000 characters)</div>
							</div>
							<div className="col-span-7">
								<textarea
									name="full_description"
									onChange={handleInputDataChange}
									maxLength={1000}
									rows={5}
									value={inputData.full_description}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Gallery</label>
								<div className="remark">(max 10 media files)</div>
							</div>
							<div className="col-span-7">
								<div className="grid 2xl:grid-cols-10 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 gap-3 mb-2">
									{gallery.map((media, index) => {
										const { id } = media;

										return (
											<div
												key={index}
												className="file-input-wrapper 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4">
												<GalleryMedia
													thumbnail={media.thumbnail_url || media.content_url}
													onDelete={() => handleDeleteMediaFile(id)}
												/>
											</div>
										);
									})}

									{gallery.length < MAX_MEDIA_COUNT && (
										<div className="file-input-wrapper 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4">
											<GalleryMedia />
										</div>
									)}
								</div>
								<div className="remark">
									Improve the viewing experience for your exhibition by uploading high resolution{" "}
									<strong>images</strong> and <strong>videos</strong>. <br />
									Media are recommended to be in <strong>landscape orientation</strong>.
								</div>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Materials / Files</label>
							</div>
							<div className="col-span-7">
								<div className="grid 2xl:grid-cols-10 xl:grid-cols-12 md:grid-cols-12 gap-3 mb-2">
									{materialFiles.map((materialFile, index) => {
										const { id, name, content_url } = materialFile;

										return (
											<div
												key={index}
												className="file-input-wrapper 2xl:col-span-2 xl:col-span-3 md:col-span-4 mb-3">
												<ExhibitorFile
													id={id}
													content_url={content_url}
													fileName={name}
													onDelete={() => handleDeleteFile(id)}
												/>
											</div>
										);
									})}

									{materialFiles.length < MAX_FILE_COUNT && (
										<div className="file-input-wrapper 2xl:col-span-2 xl:col-span-3 md:col-span-4 mb-3">
											<ExhibitorFile onUpload={handleUploadFile} />
										</div>
									)}
								</div>
							</div>
						</div>
					</form>

					<div className="flex items-center justify-end">
						<button type="button" className="btn-save ml-5" onClick={submitUpdateExhibitorDetails}>
							Save Changes
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ExhibitorDetailsContainer);

import apiAction from "../services/apiAction";
import LocalStorageService from "../services/localStorageService";

const AgentAPI = {
	/**
	 * Get room detail
	 */
	getRoomDetail: () => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update room detail
	 */
	postUpdateRoomDetail: (formData) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Create new private chat
	 * { dialog_id, title, user_ids }
	 */
	postCreatNewPrivateChat: (data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Invite user to existing private chat
	 * { chat_id, user_ids }
	 */
	postInviteUserToPrivatechat: (data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats/invite`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update chat settings
	 * { appear_offline, allow_offline_chat, chat_start_at, chat_end_at }
	 */
	postUpdateChatSettings: (data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats/settings`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update live stream detail
	 */
	postUpdateLivestreamDetail: (formData) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/streamings`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Toggle live stream
	 * { live_session_id, is_live }
	 */
	postToggleLiveStream: (data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/streamings/live`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Toggle live stream
	 * { live_session_id, is_live }
	 */
	deleteLiveStreamDetail: (data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/streamings`;

			apiAction
				.delete(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get Exhibitor Room Store Products
	 * queryParams { limit, offset }
	 */
	getExhibitorRoomStoreProducts: (storeId, queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/stores/${storeId}/products`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Post update store settings
	 */
	postUpdateStoreDetails: (storeId, data) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/stores/${storeId}/settings`;

			apiAction
				.post(url, data)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get Exhibitor Room Store Product detail
	 */
	getExhibitorRoomStoreProductDetail: (storeId, productId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/stores/${storeId}/products/${productId}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Add Exhibitor room Store Products
	 */
	postAddStoreProduct: (storeId, formData) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/stores/${storeId}/products`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update Exhibitor room Store Products
	 */
	putUpdateStoreProduct: (storeId, formData) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/stores/${storeId}/products`;

			apiAction
				.put(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete Exhibitor room Store Products
	 */
	deleteStoreProduct: (storeId, productId) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/stores/${storeId}/products`;

			const data = {
				product_id: productId,
			};

			apiAction
				.delete(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Add exhibitor room files
	 */
	postAddExhibitorRoomFiles: (formData) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/files`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete Exhibitor room files
	 * @param {object} data
	 * { file_ids }
	 */
	deleteExhibitorRoomFiles: (data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/files`;

			apiAction
				.delete(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Add exhibitor room media files
	 */
	postAddExhibitorRoomMediaFiles: (formData) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/medias`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete Exhibitor room media files
	 * @param {object} data
	 * { media_ids }
	 */
	deleteExhibitorRoomMediaFiles: (data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/medias`;

			apiAction
				.delete(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Invite agent
	 */
	postInviteAgent: (email) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/agents/invite`;

			const data = { email };

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get agent listing (including invited)
	 */
	getAgentListing: () => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/agents`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update agent (Can be use for combine action of update, delete agent)
	 */
	postUpdateAgents: (data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/agents`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get room chat listing
	 */
	getRoomChatListing: () => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get exhibitor room visitor listing
	 */
	getExhibitorRoomVisitorListing: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/audiences`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get exhibitor room visitor detail
	 */
	getExhibitorRoomVisitorDetail: (userId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/audiences/${userId}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Create new private chat
	 */
	postCreateNewPrivateChat: (data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete private chat
	 */
	postDeletePrivateChat: (chat_id) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats`;

			const data = {
				chat_id: chat_id,
			};

			apiAction
				.delete(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get chat detail
	 */
	getChatDetail: (chat_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats/${chat_id}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * invite and delete user from existing private chat
	 */
	postUpdatePrivateChatUsers: (data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats/users`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Join private chat (for agent)
	 */
	postAgentJoinPrivateChat: (chat_id) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats/join`;

			const data = {
				chat_id,
			};

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get chat messages
	 * @param {int} chatId
	 * @param {object} queryParams
	 * {limit, last_message_id}
	 */
	getChatMessages: (chatId, queryParams = {}) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats/${chatId}/messages`;

			apiAction
				.get(url, queryParams)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Post submit chat message
	 * @param {int} chatId
	 * @param {string} message
	 */
	postSubmitChatMessage: (chatId, message) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/chats/${chatId}/messages`;

			const data = {
				message,
			};

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get order listing
	 */
	getOrderListing: () => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/stores/orders`;

			apiAction
				.get(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get order detail
	 * @param {int} orderId
	 */
	getOrderDetail: (orderId) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/stores/orders/${orderId}`;

			apiAction
				.get(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * update order delivery status
	 * @param {int} orderId
	 */
	postUpdateOrderDeliveryStatus: (orderId, data) => {
		return new Promise((resolve, reject) => {
			const eventId = LocalStorageService.getEventIdByEventCode();
			const userEventRole = LocalStorageService.getUserEventRole();
			let roomId = null;

			if (userEventRole.agent.exhibitor_rooms.length > 0) {
				roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			}

			const url = `/events/${eventId}/agents/rooms/${roomId}/stores/orders/${orderId}/delivery`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},
};

export default AgentAPI;

import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { agentActions } from "app/states/agent";
import { useDispatch, useSelector } from "react-redux";
import IconDelete from "app/assets/iconfinder_Tick_Mark_Dark_1398912 1.png";
import "./style.scss";
import AgentAPI from "app/apis/agent";
import { commonActions } from "app/states/common";

const DeleteChatModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeDeleteChatModal = () => dispatch(agentActions.closeDeleteChatModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const refreshChatListing = (refresh) => dispatch(agentActions.refreshChatListing(refresh));
	const setDeleteChatName = (name) => dispatch(agentActions.setDeleteChatName(name));
	const setDeleteChatId = (chatId) => dispatch(agentActions.setDeleteChatId(chatId));

	const showModal = useSelector(({ agent }) => agent.showDeleteChatModal);
	const deleteChatId = useSelector(({ agent }) => agent.deleteChatId);
	const deleteChatName = useSelector(({ agent }) => agent.deleteChatName);

	const handleDeleteChat = () => {
		// Call delete chat API
		AgentAPI.postDeletePrivateChat(deleteChatId)
			.then((response) => {
				refreshChatListing(true);
				setDeleteChatId(null);
				setDeleteChatName(null);
				closeDeleteChatModal();
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<Modal open={showModal} onClose={closeDeleteChatModal} aria-labelledby="agent-delete-chat-modal">
			<div className="app-modal agent-delete-chat-modal">
				<div className="flex flex-col justify-center items-center mb-5 py-5 md:py-0 px-8 md:px-28">
					<img src={IconDelete} alt="Delete" className="icon-delete" />
					<p className="mb-3">You are about the delete a private chat named</p>
					<p>
						<strong>{deleteChatName}</strong>
					</p>
				</div>

				<div className="action-bar">
					<button type="button" className="btn-cancel" onClick={closeDeleteChatModal}>
						Dismiss
					</button>
					<button type="button" className="btn-delete-chat" onClick={handleDeleteChat}>
						Delete Chat
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(DeleteChatModal);

import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@iconify/react";
import baselineSearch from "@iconify-icons/ic/baseline-search";
import ChannelAccordion from "app/views/components/ChannelAccordion/ChannelAccordion";
import "./style.scss";
import Channel from "app/apis/channel";
import { useDispatch, useSelector } from "react-redux";
import { channelActions } from "app/states/channel";
import IconChannel from "app/assets/Channels_Exhibitors/icon_channel.png";
import IconChevronDown from "app/assets/Channels_Exhibitors/chevron_down.png";
import IconLiveSession from "app/assets/Channels_Exhibitors/icon_live_channel.png";
import ChannelListMobileModal from "../ChannelListMobileModal/ChannelListMobileModal";
import DateFilter from "app/views/components/DateFilter/DateFilter";
import moment from "moment";
import SessionStorageService from "app/services/sessionStorageService";
import LocalStorageService from "app/services/localStorageService";

const REFRESH_INTERVAL = 30000; // 30s

const ChannelList = ({ history }) => {
	const { sessionId } = useParams();

	const cachedSelectedDateFilter = SessionStorageService.getChannelListSelectedDateFilter();

	// Check if now is before/after event
	const eventSetting = LocalStorageService.getEventSetting();
	const eventStartAt = moment(eventSetting.start_at);
	const eventEndAt = moment(eventSetting.end_at);
	let isBeforeOrAfterEvent = false;
	if (moment().isBefore(moment(eventStartAt)) || moment().isAfter(moment(eventEndAt))) {
		isBeforeOrAfterEvent = true;
	}

	let defaultSelectedDate = new Date();
	if (isBeforeOrAfterEvent) {
		defaultSelectedDate = moment(eventStartAt).toDate();
	}

	const dispatch = useDispatch();
	const setChannelSessionId = (channelSessionId) => dispatch(channelActions.setChannelSessionId(channelSessionId));
	const openMobileChannelListModal = () => dispatch(channelActions.openMobileChannelListModal());
	const setExpandChannelCategory = (category) => dispatch(channelActions.setExpandChannelCategory(category));
	const setRefreshChannelList = (isRefresh) => dispatch(channelActions.setRefreshChannelList(isRefresh));

	const channelSessionId = useSelector(({ channel }) => channel.channelSessionId);
	const isRefreshChannelList = useSelector(({ channel }) => channel.isRefreshChannelList);

	const [liveSessions, setLiveSessions] = useState([]);
	const [channels, setChannels] = useState([]);
	const [searchChannelText, setSearchChannelText] = useState("");
	const [selectedDate, setSelectedDate] = useState(
		cachedSelectedDateFilter ? new Date(cachedSelectedDateFilter) : defaultSelectedDate
	);
	const [refresh, setRefresh] = useState(false);
	const [expandAllOnSearch, setExpandAllOnSearch] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const refreshInterval = setInterval(() => {
			setRefresh(true);
		}, REFRESH_INTERVAL);

		return () => {
			clearInterval(refreshInterval);
		}
	}, []);

	// Handle refresh every 30s interval
	useEffect(() => {
		if (refresh || isRefreshChannelList) {
			// Default channel list params
			let channelListParams = {};

			channelListParams["start_at"] = moment(selectedDate).startOf("day").valueOf();
			channelListParams["end_at"] = moment(selectedDate).endOf("day").valueOf();

			if (searchChannelText) {
				channelListParams["keyword"] = searchChannelText;
			}

			getEventChannels(channelListParams);
			setRefresh(false);
			setRefreshChannelList(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh, isRefreshChannelList]);

	// Handle date filter change
	useEffect(() => {
		// Default channel list params
		let channelListParams = {};

		channelListParams["start_at"] = moment(selectedDate).startOf("day").valueOf();
		channelListParams["end_at"] = moment(selectedDate).endOf("day").valueOf();

		if (searchChannelText) {
			channelListParams["keyword"] = searchChannelText;
		}

		getEventChannels(channelListParams);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate]);

	const handleChannelSearch = () => {
		let channelListParams = {
			start_at: moment(selectedDate).startOf("day").valueOf(),
			end_at: moment(selectedDate).endOf("day").valueOf(),
		};

		if (searchChannelText) {
			channelListParams["keyword"] = searchChannelText;
			setExpandAllOnSearch(true);
		} else {
			setExpandAllOnSearch(false);
		}

		getEventChannels(channelListParams);
	};

	const getEventChannels = (params) => {
		Channel.getChannels(params)
			.then((response) => {
				const { live_sessions, channels } = response;

				setLiveSessions(live_sessions);
				setChannels(channels);

				// Default set first channel as selected if there is no session id in route url
				if (channels.length > 0 && !channelSessionId && !sessionId) {
					setChannelSessionId(channels[0].sessions[0].id);
				}

				// if (live_sessions.length === 0 && channels.length === 0) {
				// 	setChannelSessionId(null);
				// }
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleMobileViewSelectChannelCategory = (category) => {
		setExpandChannelCategory(category);
		openMobileChannelListModal();
	};

	const onEnterSearch = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
			handleChannelSearch();
		}
	};

	const handleOnChangeDate = (date) => {
		SessionStorageService.setChannelListSelectedDateFilter(date);
		setSelectedDate(date);
	};

	return (
		<>
			{/* Desktop View */}
			<div
				id="channel-list"
				className="flex flex-col w-full h-full rounded-t border-2 border-gray-100 p-2 hidden lg:flex">
				<div className="mb-5 px-2">
					<div className="mb-3">
						<FormControl className="w-full">
							<InputLabel htmlFor="channel-search">Channel</InputLabel>
							<Input
								id="channel-search"
								type="text"
								value={searchChannelText}
								onChange={(event) => setSearchChannelText(event.target.value)}
								onKeyDown={onEnterSearch}
								name="channel_search"
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											className="p-0 icon-search"
											aria-label="Search Channel"
											onClick={handleChannelSearch}>
											<Icon icon={baselineSearch} />
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>

					<div>
						<div className="channel-filter-label mb-1">Filter by</div>
						<DateFilter selectedDate={selectedDate} onChange={handleOnChangeDate} />
					</div>
				</div>

				<div className="channel-list-items">
					{liveSessions.length > 0 && (
						<ChannelAccordion category="live" channelSessions={liveSessions} isExpand={expandAllOnSearch} />
					)}

					{channels.length > 0 &&
						channels.map((channel) => {
							const { id, title, thumbnail_url, sessions } = channel;

							const channelDetail = {
								id,
								title,
								thumbnail_url,
							};

							return (
								<ChannelAccordion
									key={`channel-${id}`}
									channelDetail={channelDetail}
									channelSessions={sessions}
									isExpand={expandAllOnSearch}
								/>
							);
						})}

					{isLoading && <div className="text-no-result">Loading...</div>}

					{!isLoading && liveSessions.length <= 0 && channels.length <= 0 && (
						<div className="text-no-result">No sessions found on the selected date.</div>
					)}
				</div>
			</div>

			{/* Mobile View */}
			<div id="channel-list" className="flex items-center justify-between p-2 lg:hidden">
				<section className="m__header flex items-center" onClick={openMobileChannelListModal}>
					<img src={IconChannel} alt="Channel" className="icon-channel" />
					<span>Channels</span>
					<img src={IconChevronDown} alt="Channel" className="icon-chevron-down" />
				</section>

				<section className="m__channels flex items-center">
					{liveSessions.length > 0 && (
						<img
							src={IconLiveSession}
							alt="channel thumbnail"
							className="rounded-full mx-1 mobile-channel-thumbnail"
							onClick={() => handleMobileViewSelectChannelCategory("live")}
						/>
					)}

					{channels.length > 0 &&
						channels.map((channel) => {
							const { id, title, thumbnail_url } = channel;

							return (
								<img
									key={id}
									src={thumbnail_url}
									alt={title}
									className="rounded-full mx-1 mobile-channel-thumbnail"
									onClick={() => handleMobileViewSelectChannelCategory(title)}
								/>
							);
						})}
				</section>
			</div>

			<ChannelListMobileModal
				liveSessions={liveSessions}
				channels={channels}
				getEventChannels={getEventChannels}
				selectedDate={selectedDate}
				onChangeDate={handleOnChangeDate}
			/>
		</>
	);
};

export default withRouter(ChannelList);

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconCoin from "app/assets/coin.png";
import IconPerson from "app/assets/icon-person.png";
import IconEmail from "app/assets/icon-email.png";
import IconPhone from "app/assets/icon-phone.png";
import IconLocation from "app/assets/icon-location.png";
import LocalStorageService from "app/services/localStorageService";
import { PAYMENT_SETTINGS } from "app/config/settings";
import { numberWithCommas } from "app/helper/common";
import { useDispatch, useSelector } from "react-redux";
import AgentAPI from "app/apis/agent";
import { DELIVERY_METHODS, PAYMENT_METHODS } from "app/config/apiEnums";
import { commonActions } from "app/states/common";

const AgentOrderDetailsContainer = () => {
	const eventSetting = LocalStorageService.getEventSetting();

	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const agentViewingOrderId = useSelector(({ agent }) => agent.agentViewingOrderId);

	const [orderDetail, setOrderDetail] = useState(null);
	const [orderStatus, setOrderStatus] = useState(0);

	const handleChangeOrderStatus = (event) => {
		setOrderStatus(event.target.value);
	};

	const handleUpdateOrderStatus = () => {
		const data = {
			status: orderStatus,
		};
		AgentAPI.postUpdateOrderDeliveryStatus(agentViewingOrderId, data)
			.then((response) => {
				openAlertSnackbar("Updated successfully.");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	useEffect(() => {
		if (agentViewingOrderId) {
			AgentAPI.getOrderDetail(agentViewingOrderId)
				.then((response) => {
					setOrderDetail(response);
					setOrderStatus(response.delivery_status);
				})
				.catch((error) => {
					//
				});
		}
	}, [agentViewingOrderId]);

	if (!orderDetail) {
		return <></>;
	}

	return (
		<div className="agent-order-details-container h-full">
			<section className="order-details-section">
				<div className="details-wrapper grid grid-cols-12 gap-5">
					<div className="col-span-12 lg:col-span-4">
						<div className="order-main-label">Order Number: #{orderDetail.order_no}</div>
						<div className="grid grid-cols-12 gap-3 lg:gap-0">
							<div className="col-span-6 lg:col-span-12 mb-2 flex items-center">
								<span className="order-text-label">Payment Method: </span>
								<span className="order-bubble">{PAYMENT_METHODS[orderDetail.payment_method]}</span>
							</div>
							<div className="col-span-6 lg:col-span-12 mb-2 flex items-center">
								<span className="order-text-label">Delivery Method: </span>
								<span className="order-bubble">{DELIVERY_METHODS[orderDetail.delivery_method]}</span>
							</div>
						</div>
					</div>
					<div className="col-span-12 lg:col-span-4">
						<div className="order-main-label">Order from</div>
						<div className="grid grid-cols-12 gap-3">
							<div className="col-span-12 flex items-center">
								<div className="icon-wrapper">
									<img src={IconPerson} alt="name" />
								</div>
								<div className="order-text-label">{orderDetail?.delivery_address?.name ?? '-'}</div>
							</div>
							<div className="col-span-12 flex items-start">
								<div className="icon-wrapper">
									<img src={IconLocation} alt="location" />
								</div>
								<div className="order-text-label">{orderDetail?.delivery_address?.address ?? '-'}</div>
							</div>
							<div className="col-span-6 flex items-center">
								<div className="icon-wrapper">
									<img src={IconPhone} alt="phone" />
								</div>
								<div className="order-text-label">{orderDetail?.delivery_address?.phone_no ?? '-'}</div>
							</div>
							<div className="col-span-6 flex items-center">
								<div className="icon-wrapper">
									<img src={IconEmail} alt="email" />
								</div>
								<div className="order-text-label">{orderDetail?.delivery_address?.email ?? '-'}</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 lg:col-span-4">
						<div className="order-main-label">Order status</div>
						<div className="flex items-center">
							<select
								className="order-status-select"
								value={orderStatus}
								onChange={handleChangeOrderStatus}>
								<option value={0}>Processing</option>
								<option value={1}>Delivery in Progress</option>
								<option value={2}>Completed</option>
							</select>
							<button type="button" className="btn-update-order-status" onClick={handleUpdateOrderStatus}>
								Update
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className="order-details-list-section">
				<div className="order-details-list-wrapper">
					{orderDetail.products.map((product) => {
						const { name, unit_price, currency, quantity, sub_total_price, image_url, sku } = product;

						return (
							<OrderDetailListItem
								thumbnail={image_url}
								name={name}
								currency={currency}
								quantity={quantity}
								price={unit_price}
								subtotalPrice={sub_total_price}
								sku={sku}
							/>
						);
					})}

					<div className="order-total-wrapper mt-2 lg:mt-5">
						<span className="mr-4">Order total</span>
						{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
							<span className="flex-inline items-center">
								<img src={IconCoin} alt="coin" className="icon-coin-total" />
								<span className="text-credit-amount-total">{orderDetail.total_price}</span>
							</span>
						) : (
							<span>
								{orderDetail.currency}
								{numberWithCommas(orderDetail.total_price)}
							</span>
						)}
					</div>
				</div>
			</section>
		</div>
	);
};

const OrderDetailListItem = ({ thumbnail, name, currency, price, subtotalPrice, quantity, sku }) => {
	const eventSetting = LocalStorageService.getEventSetting();

	return (
		<div className="order-detail-list-item flex">
			<img src={thumbnail} alt="product" className="img-product" />
			<div className="flex flex-col justify-between items-stretch w-full">
				<div>
					<div className="flex justify-between">
						<div className="product-name mb-2">{name}</div>
						<div className="product-price mb-2">
							{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
								<span className="flex items-center">
									<img src={IconCoin} alt="coin" className="icon-coin" />
									<span className="text-credit-amount">{numberWithCommas(price)}</span>
								</span>
							) : (
								<span>
									{currency}
									{numberWithCommas(price)}
								</span>
							)}
						</div>
					</div>
					<div className="flex items-center product-variant flex-wrap">
						{sku && sku.size && (
							<div className="variant-option">
								Size: <span className="size-value">{sku.size}</span>
							</div>
						)}
						{sku && sku.color && (
							<div className="variant-option">
								Color: <span className="color-value">{sku.color}</span>
							</div>
						)}
						{sku && sku.option && (
							<div className="variant-option">
								Option: <span className="option-value">{sku.option}</span>
							</div>
						)}
					</div>
				</div>
				<div className="flex justify-between items-center">
					<div>
						<label className="label-quantity">Quantity</label>
						<div className="text-count">{quantity || 0}</div>
					</div>
					<div className="subtotal flex flex-col lg:flex-row items-end lg:items-center">
						<span className="lg:mr-1">Subtotal: </span>
						{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
							<span className="flex items-center mt-1">
								<img src={IconCoin} alt="coin" className="icon-coin icon-coin-subtotal" />
								<span className="text-credit-amount">{numberWithCommas(subtotalPrice)}</span>
							</span>
						) : (
							<strong>
								{currency}
								{numberWithCommas(subtotalPrice)}
							</strong>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(AgentOrderDetailsContainer);

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconCoin from "app/assets/coin.png";
import IconLocation from "app/assets/icon-location.png";
import IconPhone from "app/assets/icon-phone.png";
import IconPerson from "app/assets/icon-person.png";
import IconEmail from "app/assets/icon-email.png";
import { useSelector } from "react-redux";
import LocalStorageService from "app/services/localStorageService";
import { PAYMENT_SETTINGS } from "app/config/settings";
import { numberWithCommas } from "app/helper/common";
import OrderAPI from "app/apis/order";
import { PAYMENT_METHODS, DELIVERY_METHODS, ORDER_STATUSES } from "app/config/apiEnums";
import ReactLoading from "react-loading";

const OrderDetailsContainer = () => {
	const eventSetting = LocalStorageService.getEventSetting();

	const viewingOrderId = useSelector(({ common }) => common.viewingOrderId);

	const [loading, setLoading] = useState(true);
	const [orderDetails, setOrderDetails] = useState(null);

	useEffect(() => {
		if (!viewingOrderId) return;

		setLoading(true);
		OrderAPI.getUserOrderDetails(viewingOrderId)
			.then((response) => {
				setOrderDetails(response);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [viewingOrderId]);

	if (!orderDetails) {
		return <></>;
	}

	return (
		<div className="order-details-container h-full">
			<section className="order-details-section">
				{(eventSetting.payment_settings.includes(PAYMENT_SETTINGS["place_booking"]) ||
					eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"])) && (
					<div className="details-wrapper details-place-booking grid grid-cols-12 gap-3">
						<div className="col-span-12 lg:col-span-5">
							<div className="hidden lg:block order-num">Order Number: #{orderDetails.order_no}</div>
							<div className="grid grid-cols-12 gap-3 lg:gap-0">
								{/* {eventSetting.payment_settings.includes(PAYMENT_SETTINGS["place_booking"]) && ( */}
								<div className="col-span-6 lg:col-span-12 payment-method flex items-center">
									<span className="text-order-label">Payment Method: </span>
									<span className="order-bubble">{PAYMENT_METHODS[orderDetails.payment_method]}</span>
								</div>
								{/* )} */}
								<div className="col-span-6 lg:col-span-12 delivery-method flex items-center">
									<span className="text-order-label">Delivery Method: </span>
									<span className="order-bubble">
										{DELIVERY_METHODS[orderDetails.delivery_method]}
									</span>
								</div>
							</div>
						</div>
						<div className="col-span-12 lg:col-span-7">
							<div className="merchant-name">Uniqlo Store</div>
							<div className="merchant-location flex items-start">
								<div className="icon-wrapper">
									<img src={IconLocation} alt="location" className="icon-location" />
								</div>
								<div>{orderDetails.address}</div>
							</div>
							<div className="merchant-phone flex items-center">
								<div className="icon-wrapper">
									<img src={IconPhone} alt="phone" className="icon-phone" />
								</div>
								<div>{orderDetails.phone_no}</div>
							</div>
						</div>
					</div>
				)}

				{/* {eventSetting.payment_settings.includes(PAYMENT_SETTINGS["payment_gateway"]) && ( */}
				<div className="details-payment-gateway">
					<section className="details-wrapper details-payment-gateway-order grid grid-cols-12">
						<div className="hidden lg:block col-span-12">
							<div className="order-num">Order Number: #{orderDetails.order_no}</div>
						</div>
						<div className="col-span-12">
							<div className="grid grid-cols-12 gap-3">
								<div className="col-span-6 lg:col-span-3 payment-method">
									<div className="text-order-label mb-1">Payment Method: </div>
									<span className="order-bubble">{PAYMENT_METHODS[orderDetails.payment_method]}</span>
								</div>
								<div className="col-span-6 lg:col-span-3 delivery-method">
									<div className="text-order-label mb-1">Delivery Method: </div>
									<span className="order-bubble">
										{DELIVERY_METHODS[orderDetails.delivery_method]}
									</span>
								</div>
								<div className="col-span-6 lg:col-span-3 order-status">
									<div className="text-order-label lg:mb-1">Order Status: </div>
									<span className="current-order-status">{ORDER_STATUSES[orderDetails.status]}</span>
								</div>
								<div className="col-span-6 lg:col-span-3">
									<div className="text-order-label lg:mb-1">Tracking URL: </div>
									{orderDetails.tracking_url ? (
										<a
											className="tracking-url"
											href={orderDetails.tracking_url}
											target="_blank"
											rel="noreferrer">
											{orderDetails.tracking_url}
										</a>
									) : (
										<span>-</span>
									)}
								</div>
							</div>
						</div>
					</section>
					<section className="details-wrapper grid grid-cols-12 gap-5 lg:gap-0">
						<div className="col-span-12 lg:col-span-6 divider">
							<div className="merchant-name">Uniqlo Store</div>
							{orderDetails.address && (
								<div className="merchant-location flex items-start">
									<div className="icon-wrapper">
										<img src={IconLocation} alt="location" className="icon-location" />
									</div>
									<div>{orderDetails.address}</div>
								</div>
							)}
							<div className="merchant-phone flex items-center">
								<div className="icon-wrapper">
									<img src={IconPhone} alt="phone" className="icon-phone" />
								</div>
								<div>
									{orderDetails.phone_no.map((phone, index) => {
										if (orderDetails.phone_no.length !== index + 1) {
											return `${phone} - `;
										}

										return phone;
									})}
								</div>
							</div>
						</div>
						{orderDetails.delivery_address && (
							<div className="col-span-12 lg:col-span-6 lg:px-3">
								<div className="merchant-name">Delivery Address</div>
								<div className="merchant-location flex items-start">
									<div className="icon-wrapper">
										<img src={IconPerson} alt="name" />
									</div>
									<div>{orderDetails.delivery_address.name}</div>
								</div>
								<div className="merchant-location flex items-start">
									<div className="icon-wrapper">
										<img src={IconLocation} alt="location" />
									</div>
									<div>{orderDetails.delivery_address.address}</div>
								</div>
								<div className="flex items-center">
									<div className="merchant-phone flex items-center mr-3">
										<div className="icon-wrapper">
											<img src={IconPhone} alt="phone" />
										</div>
										<div>{orderDetails.delivery_address.phone_no}</div>
									</div>
									<div className="merchant-phone flex items-center">
										<div className="icon-wrapper">
											<img src={IconEmail} alt="email" />
										</div>
										<div>{orderDetails.delivery_address.email}</div>
									</div>
								</div>
							</div>
						)}
					</section>
				</div>
				{/* )} */}
			</section>
			<section className="order-details-list-section">
				<div className="order-details-list-wrapper">
					{orderDetails.products.map((product, index) => {
						const { name, currency, image_url, quantity, sub_total_price, unit_price, sku } = product;

						return (
							<OrderDetailListItem
								key={index}
								thumbnail={image_url}
								name={name}
								currency={currency}
								quantity={quantity}
								unitPrice={unit_price}
								subtotal={sub_total_price}
								sku={sku}
							/>
						);
					})}

					<div className="order-total-wrapper mt-2 lg:mt-4">
						<div className="grid grid-cols-12 gap-3">
							<div className="col-span-12 lg:col-span-6"></div>
							<div className="col-span-12 lg:col-span-6">
								<div className="grid grid-cols-12 gap-3 w-full mb-2">
									<div className="col-span-6 text-left">
										Subtotal ({orderDetails.products.length} items):{" "}
									</div>
									<div className="col-span-6 text-right">
										{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
											<span className="flex-inline items-center">
												<img src={IconCoin} alt="coin" className="icon-coin-total" />
												<span className="text-credit-amount-total">
													{orderDetails.sub_total_price}
												</span>
											</span>
										) : (
											<span>
												{orderDetails.currency}
												{numberWithCommas(orderDetails.sub_total_price)}
											</span>
										)}
									</div>
								</div>
								<div className="grid grid-cols-12 gap-3 w-full mb-2">
									<div className="col-span-6 text-left">Delivery Fees: </div>
									<div className="col-span-6 text-right">
										{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
											<span className="flex-inline items-center">
												<img src={IconCoin} alt="coin" className="icon-coin-total" />
												<span className="text-credit-amount-total">
													{orderDetails.delivery_fees}
												</span>
											</span>
										) : (
											<span>
												{orderDetails.currency}
												{numberWithCommas(orderDetails.delivery_fees)}
											</span>
										)}
									</div>
								</div>
								<hr className="my-4" />
								<div className="grid grid-cols-12 gap-3 w-full">
									<div className="col-span-6 text-left">Total: </div>
									<div className="col-span-6 text-right">
										{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
											<span className="flex-inline items-center">
												<img src={IconCoin} alt="coin" className="icon-coin-total" />
												<span className="text-credit-amount-total">
													{orderDetails.total_price}
												</span>
											</span>
										) : (
											<span>
												{orderDetails.currency}
												{numberWithCommas(orderDetails.total_price)}
											</span>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{loading && (
				<div className="app-loading-overlay">
					<ReactLoading type={"spinningBubbles"} color="grey" />
					<p className="mt-3">Loading</p>
				</div>
			)}
		</div>
	);
};

const OrderDetailListItem = ({ thumbnail, name, currency, quantity, unitPrice, subtotal, sku }) => {
	const eventSetting = LocalStorageService.getEventSetting();

	const handleProductItemOnClick = () => {
		//
	};

	return (
		<div className="order-detail-list-item flex" onClick={handleProductItemOnClick}>
			<img src={thumbnail} alt="product" className="img-product" />
			<div className="flex flex-col justify-between items-stretch w-full">
				<div className="grid grid-cols-12 gap-3">
					<div className="col-span-12 lg:col-span-7">
						<div className="product-name">{name}</div>
						{sku && (
							<div className="flex items-center product-variant flex-wrap">
								{sku.size && (
									<div className="variant-option">
										Size: <span className="size-value">{sku.size}</span>
									</div>
								)}
								{sku.color && (
									<div className="variant-option">
										Color: <span className="color-value">{sku.color}</span>
									</div>
								)}
								{sku.option && (
									<div className="variant-option">
										Option: <span className="option-value">{sku.option}</span>
									</div>
								)}
							</div>
						)}
					</div>
					<div className="col-span-12 lg:col-span-5 lg:text-right">
						<div className="product-price mb-2">
							{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
								<span className="flex items-center">
									<img src={IconCoin} alt="coin" className="icon-coin" />
									<span className="text-credit-amount">{unitPrice}</span>
								</span>
							) : (
								<span>
									{currency}
									{numberWithCommas(unitPrice)}
								</span>
							)}
						</div>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<div className="product-quantity">
						<div className="label-quantity">Quantity</div>
						<div className="quantity-count">{quantity}</div>
					</div>
					<div className="subtotal flex flex-col lg:flex-row items-end lg:items-center">
						<span className="lg:mr-1 mb-1 lg:mb-0">Subtotal: </span>
						{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
							<span className="flex items-center mt-1">
								<img src={IconCoin} alt="coin" className="icon-coin icon-coin-subtotal" />
								<span className="text-credit-amount">{subtotal}</span>
							</span>
						) : (
							<strong>
								{currency}
								{numberWithCommas(subtotal)}
							</strong>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(OrderDetailsContainer);

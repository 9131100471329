import React from "react";
import "./style.scss";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import { agentActions } from "app/states/agent";
import IconLiveBlue from "app/assets/Agent/live_blue.png";
import moment from "moment";

const LiveToggleModal = ({
	isLive,
	onBehalfLogo,
	streamingUrl,
	streamingStartAt,
	streamingEndAt,
	streamingCover,
	onConfirm,
	onCancel,
}) => {
	const dispatch = useDispatch();
	const closeLiveToggleModal = () => dispatch(agentActions.closeLiveToggleModal());

	const showLiveToggleModal = useSelector(({ agent }) => agent.showLiveToggleModal);

	// Format streaming schedule
	let streamingSchedule = "";

	if (streamingStartAt && streamingEndAt) {
		let date = moment(streamingStartAt).format("ddd, DD MMM");
		let startTime = moment(streamingStartAt).format("hh:mmA");
		let endTime = moment(streamingEndAt).format("hh:mmA");

		streamingSchedule = `${date} from ${startTime}-${endTime}`;
	}

	const handleConfirm = () => {
		if (typeof onConfirm === "function") {
			onConfirm();
		}

		closeLiveToggleModal();
	};

	const handleCancel = () => {
		if (typeof onCancel === "function") {
			onCancel();
		}

		closeLiveToggleModal();
	};

	return (
		<Modal open={showLiveToggleModal} onClose={closeLiveToggleModal} aria-labelledby="live-toggle-modal">
			<div className="app-modal live-toggle-modal">
				<div className="live-toggle-modal-body">
					<div className="d-flex justify-content-center align-items-center mb-8">
						{onBehalfLogo && <img src={onBehalfLogo} alt="icon" className="modal-behalf-logo mr-3" />}

						{isLive ? (
							<img src={IconLiveBlue} alt="icon" className="live-off-logo" />
						) : (
							<img src={IconLiveBlue} alt="icon" className="live-on-logo" />
						)}
					</div>

					<h4 className="modal-title mb-4">{isLive ? "Turn Off LIVE!" : "Confirm Go LIVE!"}</h4>

					{streamingUrl && (
						<div className="text-center mb-4">
							<label className="text-label">Streaming URL</label>
							<p className="text-value">{streamingUrl}</p>
						</div>
					)}

					{streamingSchedule && (
						<div className="text-center mb-4">
							<label className="text-label">Streaming Schedule</label>
							<p className="text-value">{streamingSchedule}</p>
						</div>
					)}

					{streamingCover && (
						<div className="text-center mb-8">
							<label className="text-label mb-2">Cover Image</label>
							<img src={streamingCover} alt="cover" className="streaming-cover" />
						</div>
					)}

					{onConfirm && (
						<button
							type="button"
							className={`btn btn-primary btn-confirm mb-3 px-5`}
							onClick={handleConfirm}>
							{isLive ? "Turn Off" : "Go LIVE"}
						</button>
					)}

					<button type="button" className="btn btn-cancel px-5" onClick={handleCancel}>
						{isLive ? "No, Stay In" : "Dismiss"}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default LiveToggleModal;

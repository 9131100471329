import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconStaffs from "app/assets/Agent/staff_dark.png";
import IconEmail from "app/assets/Agent/icon-email-input.png";
import IconRemove from "app/assets/Agent/icon_remove.png";
import IconChevronDownWhite from "app/assets/Agent/chevron_down_white.png";
import AgentAPI from "app/apis/agent";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";
import IconDefaultProfile from "app/assets/icon-default-user.png";

const AgentStaffContainer = () => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [email, setEmail] = useState("");
	const [agents, setAgents] = useState([]);
	const [invitedAgents, setInvitedAgents] = useState([]);

	const [updatedAgents, setUpdatedAgents] = useState([]);
	const [deletedAgents, setDeletedAgents] = useState([]);
	const [deletedInvitedAgents, setDeletedInvitedAgents] = useState([]);
	const [currentTotalAgent, setCurrentTotalAgent] = useState(0);
	const [maxTotalAgent, setMaxTotalAgent] = useState(99);

	useEffect(() => {
		getAgentListing();
	}, []);

	const getAgentListing = () => {
		AgentAPI.getAgentListing()
			.then((response) => {
				const { agents, invited, max_agents } = response;

				setAgents(agents);
				setInvitedAgents(invited);
				setCurrentTotalAgent(agents.length + invited.length);
				setMaxTotalAgent(max_agents);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleSendInvite = () => {
		if (currentTotalAgent >= maxTotalAgent) {
			openAlertSnackbar("You have reached the maximum agent slots", "error");
			return;
		}

		AgentAPI.postInviteAgent(email)
			.then((response) => {
				setEmail("");
				getAgentListing();
				openAlertSnackbar("Invitation sent.", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleChangeRole = (agent_id, role) => {
		let updated_is_admin = 0;

		if (role === "Room Admin") {
			updated_is_admin = 1;
		}

		let isExistsInUpdatedList = false;
		let updatedAgentsCopy = [...updatedAgents];
		for (let i = 0; i < updatedAgentsCopy.length; i++) {
			if (updatedAgentsCopy[i].agent_id === agent_id) {
				isExistsInUpdatedList = true;
				updatedAgentsCopy[i].is_admin = updated_is_admin;
				break;
			}
		}

		if (!isExistsInUpdatedList) {
			setUpdatedAgents([...updatedAgents, { agent_id: agent_id, is_admin: updated_is_admin }]);
		} else {
			setUpdatedAgents(updatedAgentsCopy);
		}
	};

	const handleDeleteAgent = (agent_id) => {
		// Append agent id to the deleted agent array
		setDeletedAgents([...deletedAgents, agent_id]);

		// Remove deleted agent from the agent array
		let remainingAgents = agents.filter((agent) => {
			return agent.agent_id !== agent_id;
		});

		setAgents(remainingAgents);

		// Remove from updated agent array list
		let remainingUpdatedAgents = updatedAgents.filter((agent) => {
			return agent.agent_id !== agent_id;
		});

		setUpdatedAgents(remainingUpdatedAgents);
	};

	const handleDeleteInvitedAgent = (email) => {
		// Append agent id to the deleted agent array
		setDeletedInvitedAgents([...deletedInvitedAgents, email]);

		// Remove deleted invited agent from the invited agent array
		let remainingAgents = invitedAgents.filter((agent) => {
			return agent.email !== email;
		});

		setInvitedAgents(remainingAgents);
	};

	const handleSaveChanges = () => {
		const data = {
			delete_agents: deletedAgents,
			update_agents: updatedAgents,
			delete_invitations: deletedInvitedAgents,
		};

		AgentAPI.postUpdateAgents(data)
			.then((response) => {
				getAgentListing();

				// Reset state
				setUpdatedAgents([]);
				setDeletedAgents([]);
				setDeletedInvitedAgents([]);

				openAlertSnackbar("Changes saved", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<div id="agent-staff" className="agent-staff-container mb-3">
			<div className="grid grid-cols-12 gap-3">
				<div className="col-span-2 flex items-start">
					<img src={IconStaffs} alt="Agent/Staff Management" className="container-title-icon" />
					<h3>Agent/Staff Management</h3>
				</div>
				<div className="col-span-8">
					<form className="form-container mb-3">
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-5">
								<div className="icon-input-wrapper">
									<img src={IconEmail} alt="email" className="icon-email" />
									<input
										type="email"
										placeholder="Not listed below? Invite by email address… "
										name="email"
										value={email}
										onChange={(event) => setEmail(event.target.value)}
										required
									/>
								</div>
							</div>
							<div className="col-span-2">
								<button type="button" className="btn-send-invite" onClick={handleSendInvite}>
									Send invite
								</button>
							</div>
						</div>

						<div className="grid grid-cols-12 mb-3">
							{invitedAgents.map((agent) => {
								const { email } = agent;

								return (
									<div key={email} className="col-span-12">
										<InvitedAgent email={email} onDelete={() => handleDeleteInvitedAgent(email)} />
									</div>
								);
							})}
							{agents.map((agent) => {
								const { agent_id, profile_image_url, name, is_admin, job_title, company_name } = agent;

								return (
									<div key={agent_id} className="col-span-12">
										<Agent
											agentId={agent_id}
											image={profile_image_url}
											name={name}
											description={`${job_title} at ${company_name}`}
											role={is_admin ? "Room Admin" : "Room Agent"}
											onChangeRole={handleChangeRole}
											onDelete={() => handleDeleteAgent(agent_id)}
										/>
									</div>
								);
							})}
						</div>

						<div className="remark">
							You have used <strong>{currentTotalAgent}</strong> of your{" "}
							<strong>{maxTotalAgent} available agent slots.</strong>
						</div>
					</form>

					<div className="flex items-center justify-end">
						<button type="button" className="btn-save ml-5" onClick={handleSaveChanges}>
							Save Changes
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

// Agent Component
const Agent = ({ agentId, image, name, description, role, onChangeRole, onDelete }) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const [currentRole, setCurrentRole] = useState(role);
	const [showBorder, setShowBorder] = useState(!image ? false : true);

	let selectedClasss = role === "Room Admin" ? "admin-role-selected" : "agent-role-selected";

	const handleChangeRole = (changedRole) => {
		setCurrentRole(changedRole);
		onChangeRole(agentId, changedRole);
	};

	const handlImageOnError = (event) => {
		event.target.onerror = null;
		event.target.src = IconDefaultProfile;
		setShowBorder(false);
	};

	return (
		<div className="agent-row grid grid-cols-12 gap-3">
			<div className="col-span-7 flex items-center">
				<span className={`agent-thumbnail-wrapper ${!showBorder ? "no-border" : ""}`}>
					<img
						src={image || IconDefaultProfile}
						alt="thumbnail"
						className="agent-thumbnail"
						onError={handlImageOnError}
					/>
				</span>
				<div>
					<div className="agent-name">{name}</div>
					<div className="agent-desc">{description}</div>
				</div>
			</div>
			<div className="col-span-5 flex items-center justify-end">
				<div className="role-selection-wrapper">
					<div className="role-selection" onClick={() => setShowDropdown(!showDropdown)}>
						<div className={`role-selected-option ${selectedClasss}`}>
							{currentRole} <img src={IconChevronDownWhite} alt="dropdown" className="icon-dropdown" />
						</div>
						{showDropdown && (
							<div className="selection-dropdown">
								<div className="option admin-option" onClick={() => handleChangeRole("Room Admin")}>
									Room Admin
								</div>
								<div className="option agent-option" onClick={() => handleChangeRole("Room Agent")}>
									Room Agent
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="btn-remove" onClick={onDelete}>
					Remove <img src={IconRemove} alt="remove" className="icon-remove" />
				</div>
			</div>
			{/* <div className="col-span-2 flex items-center justify-end">
				
			</div> */}
		</div>
	);
};

const InvitedAgent = ({ email, onDelete }) => {
	return (
		<div className="agent-row grid grid-cols-12 gap-3">
			<div className="col-span-9">
				<span className="agent-name">{email}</span>
			</div>
			<div className="col-span-2 flex items-center justify-end">
				<div className="invited-option w-full">Invited</div>
			</div>
			<div className="col-span-1 flex items-center justify-end">
				<span className="btn-remove" onClick={onDelete}>
					Remove <img src={IconRemove} alt="remove" className="icon-remove" />
				</span>
			</div>
		</div>
	);
};

export default withRouter(AgentStaffContainer);

import * as types from "./types";

const initialState = {
	cart: [],
	showMyOrderProductDetailModal: false,
	viewingOrderRoomId: null,
	viewingOrderStoreId: null,
	viewingOrderProductId: null,
	refreshMyCartCount: 0,
};

const OrderReducer = (state = initialState, action) => {
	switch (action.type) {
		// CART
		case types.SET_CART:
			return {
				...state,
				cart: action.params.cartProducts,
			};
		case types.ADD_TO_CART:
			return {
				...state,
				cart: [...state.cart, action.params.product],
			};
		case types.REMOVE_FROM_CART:
			let cartCopy = [...state.cart];
			let index = cartCopy.findIndex((product) => product.id === action.params.productId);
			cartCopy.splice(index, 1);

			return {
				...state,
				cart: cartCopy,
			};
		case types.CLEAR_CART:
			return {
				...state,
				cart: [],
			};

		// States for viewing my order product details
		case types.OPEN_MY_ORDER_PRODUCT_DETAIL_MODAL:
			return {
				...state,
				showMyOrderProductDetailModal: true,
			};
		case types.CLOSE_MY_ORDER_PRODUCT_DETAIL_MODAL:
			return {
				...state,
				showMyOrderProductDetailModal: false,
			};
		case types.SET_VIEWING_ORDER_ROOM_ID:
			return {
				...state,
				viewingOrderRoomId: action.params.roomId,
			};
		case types.SET_VIEWING_ORDER_STORE_ID:
			return {
				...state,
				viewingOrderStoreId: action.params.storeId,
			};
		case types.SET_VIEWING_ORDER_PRODUCT_ID:
			return {
				...state,
				viewingOrderProductId: action.params.productId,
			};

		// States for my cart container
		case types.REFRESH_MY_CART:
			return {
				...state,
				refreshMyCartCount: state.refreshMyCartCount + 1,
			};
		default:
			return state;
	}
};

export default OrderReducer;

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { exhibitorActions } from "app/states/exhibitor";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import IconStore from "app/assets/Channels_Exhibitors/store_active.png";
import IconClose from "app/assets/icon_close.svg";
import IconSuccess from "app/assets/icon_success.png";
import IconCoin from "app/assets/coin.png";
import LocalStorageService from "app/services/localStorageService";
import { PAYMENT_SETTINGS } from "app/config/settings";
import LoadingGIF from "app/assets/loading-wave-3.gif";
import { numberWithCommas } from "app/helper/common";
import sessionStorageService from "app/services/sessionStorageService";

const ProductOrderedSuccessModal = ({ history }) => {
	const eventSetting = LocalStorageService.getEventSetting();
	const cartProductsOrdered = sessionStorageService.getCartProductsOrdered();

	const dispatch = useDispatch();
	const closeProductOrderedSuccessModal = () => dispatch(exhibitorActions.closeProductOrderedSuccessModal());
	const openJoinPrivateChatModal = () => dispatch(commonActions.openJoinPrivateChatModal());
	const setProfileModalTabIndex = (tabIndex) => dispatch(commonActions.setProfileModalTabIndex(tabIndex));
	const openProfileModal = () => dispatch(commonActions.openProfileModal());

	const showModal = useSelector(({ exhibitor }) => exhibitor.showProductOrderedSuccessModal);
	const exhibitorRoomDetails = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomDetails);
	const hasPendingPrivateChatInvitation = useSelector(({ exhibitor }) => exhibitor.hasPendingPrivateChatInvitation);

	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		sessionStorageService.removeCartProductsOrdered();

		if (hasPendingPrivateChatInvitation) {
			openJoinPrivateChatModal();
		}

		closeProductOrderedSuccessModal();
	};

	const handleViewMyOrder = () => {
		closeProductOrderedSuccessModal();

		// Open profile modal
		setProfileModalTabIndex(1);
		openProfileModal();
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="product-ordered-modal">
			<div id="product-ordered-modal" className="app-modal mobile-app-modal">
				<section className="modal-header flex justify-between lg:p-5">
					<h1 className="modal-title">
						<img src={IconStore} alt="store" className="icon-store" />
						{exhibitorRoomDetails && exhibitorRoomDetails.title}
					</h1>
					<span className="btn-close">
						<img src={IconClose} alt="Close" onClick={handleClose} />
					</span>
				</section>

				{loading ? (
					<section className="modal-body flex flex-col justify-center items-center text-center">
						<img src={LoadingGIF} alt="loading" />
						<p className="mt-3">Loading order information. Please wait for a moment...</p>
					</section>
				) : (
					<>
						<section className="modal-body lg:px-5">
							<div className="flex flex-col justify-center items-center mb-5 lg:mb-8">
								<img src={IconSuccess} alt="success" />

								{(eventSetting.payment_settings.includes(PAYMENT_SETTINGS["place_booking"]) ||
									eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"])) && (
									<p className="text-success-desc">
										Your order has been successfully placed. The seller will get in touch with you
										soon.
									</p>
								)}

								{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["payment_gateway"]) && (
									<p className="text-success-desc">
										Your order has been successfully placed. Order status can be tracked from{" "}
										<strong>My Orders</strong>.
									</p>
								)}
							</div>
							<div className="grid grid-cols-12 gap-5">
								<div className="col-span-12 lg:col-start-3 lg:col-end-11">
									<div className="section-container products-ordered-container">
										{cartProductsOrdered &&
											cartProductsOrdered.products.length > 0 &&
											cartProductsOrdered.products.map((product, index) => {
												const {
													id,
													name,
													unit_price,
													currency,
													quantity,
													sub_total_price,
													image_url,
													sku,
												} = product;

												return (
													<CartProduct
														key={`product-${id}`}
														thumbnail={image_url}
														name={name}
														currency={currency}
														unitPrice={unit_price}
														quantity={quantity}
														subtotal={sub_total_price}
														sku={sku}
													/>
												);
											})}
									</div>
								</div>
							</div>
						</section>

						<section className="modal-footer">
							<div className="credit-balance">
								{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) && (
									<>
										<div className="text-my-balance">My Balance</div>
										<div className="flex items-center">
											<img src={IconCoin} alt="coin" className="icon-coin" />
											<span className="text-credit-amount">950</span>
										</div>
									</>
								)}
							</div>
							<div className="flex justify-end items-center w-full">
								<button type="button" className="btn-dismiss" onClick={handleClose}>
									Dismiss
								</button>
								<button type="button" className="btn-view-order" onClick={handleViewMyOrder}>
									View My Orders
								</button>
							</div>
						</section>
					</>
				)}
			</div>
		</Modal>
	);
};

const CartProduct = ({ thumbnail, name, currency, unitPrice, quantity, subtotal, sku }) => {
	const eventSettings = useSelector(({ event }) => event.eventSettings);

	return (
		<div className="cart-product flex">
			<img src={thumbnail} alt="product" className="img-product" />
			<div className="flex flex-col justify-between items-stretch w-full">
				<div className="grid grid-cols-12 gap-2 lg:gap-3">
					<div className="col-span-12 lg:col-span-8">
						<div className="product-name">{name}</div>
						{sku && (
							<div className="flex items-center product-variant flex-wrap">
								{sku.size && (
									<div className="variant-option mb-1">
										Size: <span className="size-value">{sku.size}</span>
									</div>
								)}
								{sku.color && (
									<div className="variant-option mb-1">
										Color: <span className="color-value">{sku.color}</span>
									</div>
								)}
								{sku.option && (
									<div className="variant-option mb-1">
										Option: <span className="option-value">{sku.option}</span>
									</div>
								)}
							</div>
						)}
					</div>
					<div className="col-span-12 lg:col-span-4">
						<div className="product-price lg:text-right">
							{eventSettings.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
								<span className="flex items-center">
									<img src={IconCoin} alt="coin" className="icon-coin" />
									<span className="text-credit-amount">{unitPrice}</span>
								</span>
							) : (
								<span>
									{currency}
									{numberWithCommas(unitPrice)}
								</span>
							)}
						</div>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<div className="product-quantity">
						<div className="label-quantity">Quantity</div>
						<div className="quantity-count">{quantity}</div>
					</div>
					<div className="product-subtotal flex flex-col lg:flex-row items-end lg:items-center">
						<span className="mb-1 lg:mb-0 lg:mr-1">Subtotal: </span>
						{eventSettings.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
							<span className="flex items-center subtotal-price">
								<img src={IconCoin} alt="coin" className="icon-coin" />
								<span className="text-credit-amount">{numberWithCommas(subtotal)}</span>
							</span>
						) : (
							<span className="subtotal-price">
								{currency}
								{numberWithCommas(subtotal)}
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ProductOrderedSuccessModal);

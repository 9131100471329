export const SET_CHANNEL_SESSION_ID = 'channel/SET_CHANNEL_SESSION_ID'
export const SET_CHANNEL_SESSION = 'channel/SET_CHANNEL_SESSION'

export const OPEN_MOBILE_CHANNEL_LIST_MODAL = 'channel/OPEN_MOBILE_CHANNEL_LIST_MODAL'
export const CLOSE_MOBILE_CHANNEL_LIST_MODAL = 'channel/CLOSE_MOBILE_CHANNEL_LIST_MODAL'

export const SET_EXPAND_CHANNEL_CATEGORY = 'channel/SET_EXPAND_CHANNEL_CATEGORY'

export const SET_CHANNEL_CHAT_ID = 'channel/SET_CHANNEL_PUBLIC_DIALOG_ID'

export const SET_REFRESH_CHANNEL_LIST = 'channel/SET_REFRESH_CHANNEL_LIST'
import React from "react";
import { withRouter, useParams } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import IconSwitch from "app/assets/icon_switch.png";
import { exhibitorActions } from "app/states/exhibitor";

const SwitchExhibitorRoomModal = ({ history }) => {
	const { eventCode } = useParams();

	const dispatch = useDispatch();
	const closeSwitchExhibitorRoomModal = () => dispatch(commonActions.closeSwitchExhibitorRoomModal());
	const closeProfileModal = () => dispatch(commonActions.closeProfileModal());
	const setExhibitorRoomId = (roomId) => dispatch(exhibitorActions.setExhibitorRoomId(roomId));
	const setSwitchExhibitorRoom = (isSwitching) => dispatch(exhibitorActions.setSwitchExhibitorRoom(isSwitching));

	const showModal = useSelector(({ common }) => common.showSwitchExhibitorRoomModal);
	const exhibitorRoomDetails = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomDetails);
	const viewingSavedOrderRoomId = useSelector(({ common }) => common.viewingSavedOrderRoomId);
	const viewingSavedOrderRoomName = useSelector(({ common }) => common.viewingSavedOrderRoomName);

	const currentExhibitorStoreName = exhibitorRoomDetails ? exhibitorRoomDetails.title : "the current exhibitor store";

	const handleClose = () => {
		closeSwitchExhibitorRoomModal();
	};

	const handleNo = () => {
		handleClose();
	};

	const handleYes = () => {
		closeSwitchExhibitorRoomModal();
		closeProfileModal();
		setSwitchExhibitorRoom(true);

		// Set exhibitor room id and the details will be handled in the pages/Exhibitor/Exhibitor.js
		setExhibitorRoomId(viewingSavedOrderRoomId);
		history.push(`/events/${eventCode}/exhibitor`);
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="switch-exhibitor-room-modal">
			<div className="app-modal switch-exhibitor-room-modal">
				<div className="icon-container flex justify-center items-center">
					<img src={IconSwitch} alt="Delete" />
				</div>
				<div className="modal-content">
					<h1 className="modal-title mb-3 mt-12">Switch Exhibitor Room?</h1>
					<p className="mb-10">
						You have selected a saved order that was made with a different exhibitor. Any unsaved activities
						with <strong className="room-name">{currentExhibitorStoreName}</strong> will be lost. Would you
						like to switch to <strong className="room-name">{viewingSavedOrderRoomName}</strong>’s room now?
					</p>

					<div className="flex justify-center items-center">
						<button className="btn btn-primary-outline" onClick={handleNo}>
							No
						</button>
						<button className="btn btn-primary" onClick={handleYes}>
							Yes
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(SwitchExhibitorRoomModal);

import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { profileActions } from "app/states/profile";
import ProfileContainer from "../../ProfileContainer/ProfileContainer";

const MobileProfileModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeMobileProfileModal = () => dispatch(profileActions.closeMobileProfileModal());

	const showModal = useSelector(({ profile }) => profile.showMobileProfileModal);

	const handleClose = () => {
		closeMobileProfileModal();
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="mobile-profile-modal">
			<div className="app-modal mobile-app-modal mobile-profile-modal">
				<div className="modal-content">
					<div className="col-span-12 h-full">
						<ProfileContainer />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(MobileProfileModal);

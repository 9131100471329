/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import ProfileAPI from "app/apis/profile";
import { profileActions } from "app/states/profile";
import { addressActions } from "app/states/address";

const AddressManagementModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeAddressManagementModal = () => dispatch(commonActions.closeAddressManagementModal());
	const openAddAddressModal = () => dispatch(commonActions.openAddAddressModal());
	const refreshAddressList = () => dispatch(profileActions.refreshAddressList());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setAddressList = (addressList) => dispatch(commonActions.setAddressList(addressList));
	const setAddressModalOpenFrom = (from) => dispatch(addressActions.setAddressModalOpenFrom(from));
	const setViewingAddress = (address) => dispatch(addressActions.setViewingAddress(address));
	const openEditAddressModal = () => dispatch(addressActions.openEditAddressModal());

	const showModal = useSelector(({ common }) => common.showAddressManagementModal);
	const refreshAddressListCount = useSelector(({ profile }) => profile.refreshAddressListCount);

	const [addresses, setAddresses] = useState([]);
	const [defaultAddressId, setDefaultAddressId] = useState(null);

	// Get address list
	useEffect(() => {
		ProfileAPI.getAddressList()
			.then((response) => {
				setAddresses(response.addresses);
				setAddressList(response.addresses);

				// Set the default address id
				response.addresses.forEach((address) => {
					if (address.default === 1) {
						setDefaultAddressId(address.id);
					}
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshAddressListCount]);

	const handleClose = () => {
		closeAddressManagementModal();
	};

	// Update default address
	const handleCtaClick = () => {
		// Update default address
		let newDefaultAddress = addresses.find((address) => address.id === defaultAddressId);
		newDefaultAddress.default = 1;

		ProfileAPI.postUpdateAddress(newDefaultAddress)
			.then((response) => {
				refreshAddressList();
				openAlertSnackbar("Updated default address.", "success");
				closeAddressManagementModal();
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleCancel = () => {
		// Back to product ordered modal
		closeAddressManagementModal();
	};

	const handleAddNewAddress = () => {
		setAddressModalOpenFrom("exhibitor_store_add_address")
		openAddAddressModal();
		closeAddressManagementModal();
	};

	const handleOnChangeDefault = (addressId) => {
		setDefaultAddressId(addressId);
	};

	const handleViewAddress = (address) => {
		setViewingAddress(address);
		openEditAddressModal();
	}

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="address-management-modal">
			<div id="address-management-modal" className="app-modal mobile-app-modal">
				<section className="modal-header flex justify-between">
					<h1 className="modal-title">Address Management</h1>
					<div className="btn-add-new" onClick={handleAddNewAddress}>
						Add New Address
					</div>
				</section>

				<section className="modal-body">
					<table>
						<thead>
							<tr>
								<th>Name</th>
								<th>Recipient Name</th>
								<th>Contact Number</th>
								<th>Address</th>
								<th>Default?</th>
							</tr>
						</thead>
						<tbody>
							{addresses.map((address) => {
								const { id, label, name, phone_no, address: fullAddress } = address;

								return (
									<tr key={id} className="cursor-pointer">
										<td onClick={() => handleViewAddress(address)}>{label}</td>
										<td onClick={() => handleViewAddress(address)}>{name}</td>
										<td onClick={() => handleViewAddress(address)}>{phone_no}</td>
										<td onClick={() => handleViewAddress(address)}>{fullAddress}</td>
										<td className="text-center">
											<div className="app__checkbox">
												<input
													type="checkbox"
													name="default"
													checked={defaultAddressId === id}
													onChange={() => handleOnChangeDefault(id)}
												/>
												<div className="app__checkbox-visible"></div>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</section>

				<section className="modal-footer">
					<div className="flex justify-end items-center w-full">
						<button type="button" className="btn-secondary-action" onClick={handleCancel}>
							Cancel
						</button>
						<button type="button" className="btn-cta" onClick={handleCtaClick}>
							Update
						</button>
					</div>
				</section>
			</div>
		</Modal>
	);
};

export default withRouter(AddressManagementModal);

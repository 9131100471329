import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Icon } from "@iconify/react";
import live24Regular from "@iconify-icons/fluent/live-24-regular";
import "./style.scss";
import IconLiveSession from "app/assets/Channels_Exhibitors/icon_live_channel.png";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { exhibitorActions } from "app/states/exhibitor";
import IconDefault from "app/assets/icon-group-users.png";

const ExhibitorAccordion = ({ history, category, summary, listing, isExpand }) => {
	const dispatch = useDispatch();
	const setExhibitorRoomId = (roomId) => dispatch(exhibitorActions.setExhibitorRoomId(roomId));
	const closeMobileExhibitorRoomsModal = () => dispatch(exhibitorActions.closeMobileExhibitorRoomsModal());

	const [expand, setExpand] = useState(isExpand || false);

	useEffect(() => {
		setExpand(isExpand);
	}, [isExpand]);

	const handleExpand = (event, expanded) => {
		setExpand(expanded);
	};

	const viewRoom = (roomId) => {
		setExhibitorRoomId(roomId);
		closeMobileExhibitorRoomsModal();
	};

	return (
		<Accordion
			elevation={0}
			expanded={expand}
			onChange={handleExpand}
			className={`list-accordion ${!expand && "no-expand"}`}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="exhibitor-video-list"
				className="list-accordion-header">
				{category === "live" ? (
					<div className="flex items-center">
						<img src={IconLiveSession} alt="exhibitor thumbnail" className="mr-2 header-thumbnail" />
						<span className="badge-live">
							LIVE <Icon icon={live24Regular} className="icon-live" />
						</span>
					</div>
				) : (
					<div className="flex items-center">
						<img
							src={summary ? summary.thumbnail_url : IconDefault}
							alt="exhibitor thumbnail"
							className="mr-2 header-thumbnail"
						/>
						<span className="header-title font-bold">{summary ? summary.name : "Exhibitor"}</span>
					</div>
				)}
			</AccordionSummary>
			<AccordionDetails className="flex flex-col mx-5">
				{listing &&
					listing.map((session, index) => {
						const { id, title, short_description, logo_url, is_live } = session;

						const isLast = listing.length - 1 === index;

						if (is_live) {
							return (
								<div
									key={`session-${id}`}
									className="flex items-start exhibitor-video-list-item live-video"
									onClick={() => viewRoom(id)}>
									<img
										src={logo_url || IconDefault}
										alt="exhibitor thumbnail"
										className="list-item-thumbnail thumbnail-live"
									/>
									<div className={`list-item-content ${!isLast && "connector"}`}>
										<div className="mb-1">
											<span className="badge-live">
												LIVE <Icon icon={live24Regular} className="icon-live" />
											</span>
										</div>
										<div className="list-item-title title-live">{title}</div>
										<span className="datetime datetime-live">{short_description}</span>
									</div>
								</div>
							);
						}

						return (
							<div
								key={`session-${id}`}
								className={`flex items-start exhibitor-video-list-item`}
								onClick={() => viewRoom(id)}>
								<img src={logo_url || IconDefault} alt="exhibitor thumbnail" className={`list-item-thumbnail`} />
								<div className={`list-item-content ${!isLast && "connector"}`}>
									<div className="list-item-title">{title}</div>
									<span className="datetime">{short_description}</span>
								</div>
							</div>
						);
					})}
			</AccordionDetails>
		</Accordion>
	);
};

export default withRouter(ExhibitorAccordion);

ExhibitorAccordion.propTypes = {
	summary: PropTypes.object,
	listing: PropTypes.array,
};

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import Authentication from "app/apis/authentication";
import "./style.scss";
import IconForgotPassword from "app/assets/Authentication/icon_reset_password.png";

const ResetPasswordModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeResetPasswordModal = () => dispatch(commonActions.closeResetPasswordModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showModal = useSelector(({ common }) => common.showResetPasswordModal);

	const [isSent, setIsSent] = useState(false);
	const [email, setEmail] = useState("");

	const handleClose = () => {
        setIsSent(false);
        setEmail("");
		closeResetPasswordModal();
	};

	const requestResetPassword = (event) => {
		event.preventDefault();
		sendResetPasswordEmail();
	};

	const resendRequestResetPassword = (event) => {
		event.preventDefault();
		sendResetPasswordEmail();
	};

	const sendResetPasswordEmail = () => {
		Authentication.postForgotPassword(email)
			.then((response) => {
				if (!isSent) {
					setIsSent(true);
				} else {
					openAlertSnackbar("Password reset link has been resent.", "success");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="modal-reset-password">
			<div className="app-modal reset-password-modal">
				<div className="icon-reset-password-wrapper flex items-center">
					<img src={IconForgotPassword} alt="reset password" className="icon-reset-password" />
				</div>

				<div className="mt-10">
					{!isSent && (
						<div id="reset-password-request">
							<h2 id="modal-reset-password" className="modal-title mb-3">
								Reset Your Password
							</h2>
							<div className="modal-body">
								<p className="text-desc mb-4">Enter the email you used to sign up </p>

								<form id="reset-password-form" method="post" onSubmit={requestResetPassword}>
									<div className="form-input">
										<label htmlFor="reset-pw-email">Email address</label>
										<input
											id="reset-pw-email"
											type="email"
											name="reset-pw-email"
											required
											onChange={(event) => setEmail(event.target.value)}
										/>
									</div>

									<div className="mt-8 text-center lg:text-right">
										<button type="submit" className="btn-primary btn-send">
											Send
										</button>
									</div>
								</form>
							</div>
						</div>
					)}

					{isSent && (
						<div id="reset-password-request-complete">
							<p className="mb-8 text-desc">
								We have sent a password reset link to your email,{" "}
								<span className="email_underline">{email}</span>
							</p>

							<p className="text-desc">
								Haven't got an email yet?{" "}
								<span className="btn-resend" onClick={resendRequestResetPassword}>
									Resend
								</span>
							</p>

                            <div className="mt-8 text-center">
                                <button type="button" className="btn-primary btn-close" onClick={handleClose}>
                                    Close
                                </button>
                            </div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(ResetPasswordModal);

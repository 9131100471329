import React from "react";
import { withRouter, useParams } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { authenticationActions } from "app/states/authentication";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import IconSuccess from "app/assets/icon_success.png";
import TokenService from "app/services/tokenService";

const ResetPasswordSuccessModal = ({ history }) => {
	const { eventCode } = useParams();

	const dispatch = useDispatch();
	const closeResetPasswordSuccessModal = () => dispatch(authenticationActions.closeResetPasswordSuccessModal());

	const showModal = useSelector(({ auth }) => auth.showResetPasswordSuccessModal);

	const handleClose = () => {
		closeResetPasswordSuccessModal();
		TokenService.clearTokens();
		history.push(`/events/${eventCode}`);
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="modal-reset-password-success">
			<div className="app-modal reset-password-success-modal">
				<img src={IconSuccess} alt="success" className="icon-success" />

				<div className="mb-8">
					<p className="text-desc">
						Your password has been successfully reset!
					</p>
					<p className="text-desc">
						Please login again.
					</p>
				</div>

				<div className="mt-8 text-center">
					<button type="button" className="btn-primary btn-close" onClick={handleClose}>
						OK
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(ResetPasswordSuccessModal);

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconStreamingInactive from "app/assets/Agent/icon_streaming_inactive.png";
import IconStreaming from "app/assets/Agent/icon_streaming_active.png";
import IconChatInactive from "app/assets/Agent/chat_inactive.png";
import IconChat from "app/assets/Agent/chat_active.png";
import IconExhibitorInactive from "app/assets/Agent/icon_exhibitor_inactive.png";
import IconExhibitor from "app/assets/Agent/icon_exhibitor_active.png";
import IconStoreInactive from "app/assets/Agent/icon_store_inactive.png";
import IconStore from "app/assets/Agent/icon_store_active.png";
import IconInfoInactive from "app/assets/Agent/icon_info_inactive.png";
import IconInfo from "app/assets/Agent/icon_info_active.png";
import IconStaffInactive from "app/assets/Agent/visitors_inactive.png";
import IconStaff from "app/assets/Agent/visitors_active.png";
import { HashLink } from "react-router-hash-link";
import LocalStorageService from "app/services/localStorageService";

const NavigationBar = () => {
	const userEventRole = LocalStorageService.getUserEventRole();
	const eventSetting = LocalStorageService.getEventSetting();

	const [activeMenu, setActiveMenu] = useState("#streaming-details");

	return (
		<div id="agent-navbar" className="flex items-center">
			<NavMenu
				activeIcon={IconStreaming}
				inactiveIcon={IconStreamingInactive}
				title="Streaming Details"
				active={activeMenu === "#streaming-details"}
				linkTo="#streaming-details"
				onClick={(toLink) => setActiveMenu(toLink)}
			/>
			<NavMenu
				activeIcon={IconChat}
				inactiveIcon={IconChatInactive}
				title="Chat Settings"
				active={activeMenu === "#chat-settings"}
				linkTo="#chat-settings"
				onClick={(toLink) => setActiveMenu(toLink)}
			/>
			<NavMenu
				activeIcon={IconExhibitor}
				inactiveIcon={IconExhibitorInactive}
				title="Exhibitor Details"
				active={activeMenu === "#exhibitor-details"}
				linkTo="#exhibitor-details"
				onClick={(toLink) => setActiveMenu(toLink)}
			/>
			{eventSetting.ecommerce_enabled === 1 && (
				<NavMenu
					activeIcon={IconStore}
					inactiveIcon={IconStoreInactive}
					title="Store Details"
					active={activeMenu === "#store-details"}
					linkTo="#store-details"
					onClick={(toLink) => setActiveMenu(toLink)}
				/>
			)}
			<NavMenu
				activeIcon={IconInfo}
				inactiveIcon={IconInfoInactive}
				title="Company Info"
				active={activeMenu === "#company-info"}
				linkTo="#company-info"
				onClick={(toLink) => setActiveMenu(toLink)}
			/>

			{userEventRole.agent.exhibitor_rooms.length > 0 &&
				userEventRole.agent.exhibitor_rooms[0].acls.includes("agent_managements") && (
					<NavMenu
						activeIcon={IconStaff}
						inactiveIcon={IconStaffInactive}
						title="Agent/Staff Management"
						active={activeMenu === "#agent-staff"}
						linkTo="#agent-staff"
						onClick={(toLink) => setActiveMenu(toLink)}
					/>
				)}
		</div>
	);
};

const NavMenu = ({ activeIcon, inactiveIcon, title, active, onClick, linkTo }) => {
	return (
		<HashLink
			to={linkTo}
			className={`nav-menu ${active ? "nav-menu-active" : ""} flex items-center`}
			onClick={() => onClick(linkTo)}>
			<img src={active ? activeIcon : inactiveIcon} alt="nav-icon" className="nav-icon" />
			<div className={`nav-title ${active ? "nav-title-active" : ""} `}>{title}</div>
		</HashLink>
	);
};

export default withRouter(NavigationBar);

import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { agentActions } from "app/states/agent";
import { useDispatch, useSelector } from "react-redux";
import IconSuccess from "app/assets/icon_success.png";
import "./style.scss";

const CreatePrivateChatSuccessModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeCreatePrivateChatSuccessModal = () => dispatch(agentActions.closeCreatePrivateChatSuccessModal());
	const viewNewPrivateChat = (isViewNewPrivateChat) =>
		dispatch(agentActions.viewNewPrivateChat(isViewNewPrivateChat));

	const showModal = useSelector(({ agent }) => agent.showCreatePrivateChatSuccessModal);

	const handleViewChat = () => {
		viewNewPrivateChat(true);
		closeCreatePrivateChatSuccessModal();
	};

	return (
		<Modal
			open={showModal}
			onClose={closeCreatePrivateChatSuccessModal}
			aria-labelledby="agent-create-pvc-success-modal">
			<div className="app-modal agent-create-pvc-success-modal">
				<div className="modal-content flex flex-col justify-center items-center mb-5 py-5 md:py-0 px-8 md:px-28">
					<img src={IconSuccess} alt="Success" className="icon-success" />
					<p>
						Chat request successfully sent. Participants will be added automatically into the group once
						they accept the invitation.
					</p>
				</div>

				<div className="action-bar">
					<button type="button" className="btn-cancel" onClick={closeCreatePrivateChatSuccessModal}>
						Dismiss
					</button>
					<button type="button" className="btn-view-chat" onClick={handleViewChat}>
						View Chat
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(CreatePrivateChatSuccessModal);

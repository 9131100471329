import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { InitAxiosInterceptor } from "./app/services/axiosInterceptor";

import Routes from "./app/routes";
import history from "./app/routes/history";
import reduxStore from "./app/services/redux/reduxStore";
import LocalStorageService from "./app/services/localStorageService";
import AlertSnackbar from "app/views/components/Snackbar/AlertSnackbar";
import {Helmet} from "react-helmet";
import Logo from "app/assets/boom-favicon.png";

InitAxiosInterceptor();

function App() {
	const cachedWebConfig = LocalStorageService.getWebConfig();

	return (
		<Provider store={reduxStore}>
			<Router history={history}>
				<Routes />
			</Router>

			<AlertSnackbar />

			<Helmet>
				<meta property="og:image" content={cachedWebConfig?.logo_url ?? Logo} />
            </Helmet>
		</Provider>
	);
}

export default App;

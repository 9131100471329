import * as types from "./types";

// Reset password success modal
export const openResetPasswordSuccessModal = () => ({
	type: types.OPEN_RESET_PW_SUCCESS_MODAL,
});

export const closeResetPasswordSuccessModal = () => ({
	type: types.CLOSE_RESET_PW_SUCCESS_MODAL,
});

// Login register modal
export const openLoginRegisterModal = () => ({
	type: types.OPEN_LOGIN_REGISTER_MODAL,
});

export const closeLoginRegisterModal = () => ({
	type: types.CLOSE_LOGIN_REGISTER_MODAL,
});

// Event registration modal
export const openEventRegistrationModal = () => ({
	type: types.OPEN_EVENT_REGISTRATION_MODAL,
});

export const closeEventRegistrationModal = () => ({
	type: types.CLOSE_EVENT_REGISTRATION_MODAL,
});

// Shipping address modal
export const openShippingAddressModal = () => ({
	type: types.OPEN_SHIPPING_ADDRESS_MODAL,
});

export const closeShippingAddressModal = () => ({
	type: types.CLOSE_SHIPPING_ADDRESS_MODAL,
});

// Set login register tab index
export const setLoginRegisterTabIndex = (tabIndex) => ({
	type: types.SET_LOGIN_REGISTER_TAB_INDEX,
    params: {
        tabIndex
    }
});

// Set user profile
export const setIsLoggedIn = (isLogin) => ({
	type: types.SET_IS_LOGGED_IN,
    params: {
        isLogin
    }
});

// Set user profile
export const setUserProfile = (userProfile) => ({
	type: types.SET_USER_PROFILE,
    params: {
        userProfile
    }
});

// ticket summary modal
export const openTicketSummaryModal = () => ({
	type: types.OPEN_TICKET_SUMMARY_MODAL,
});

export const closeTicketSummaryModal = () => ({
	type: types.CLOSE_TICKET_SUMMARY_MODAL,
});

// ticket checkout modal
export const openTicketCheckoutStatusModal = () => ({
	type: types.OPEN_TICKET_CHECKOUT_STATUS_MODAL,
});

export const closeTicketCheckoutStatusModal = () => ({
	type: types.CLOSE_TICKET_CHECKOUT_STATUS_MODAL,
});

// refresh user event status
export const refreshUserEventStatus = () => ({
	type: types.REFRESH_USER_EVENT_STATUS,
});

// is from register button
export const setIsFromRegisterButton = (isFromRegisterButton) => ({
	type: types.SET_IS_FROM_REGISTER_BUTTON,
	params: {
		isFromRegisterButton
	}
});

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import ProfileAPI from "app/apis/profile";
import IconClose from "app/assets/icon_close.svg";
import "./style.scss";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import { GENDER } from "app/config/settings";

const VisitorDetailModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeVisitorDetailModal = () => dispatch(commonActions.closeVisitorDetailModal());

	const showModal = useSelector(({ common }) => common.showVisitorDetailModal);
	const viewingVisitorUserId = useSelector(({ common }) => common.viewingVisitorUserId);

	const [visitorDetail, setVisitorDetail] = useState({
		email: "",
		name: "",
		about: "",
		profile_image_url: "",
		gender: "",
		country: "",
		city: "",
		job_title: "",
		company_name: "",
		industry: "",
	});
	const [interests, setInterests] = useState([]);

	useEffect(() => {
		if (viewingVisitorUserId) {
			getUserDetails();
		}
	}, [viewingVisitorUserId]);

	// view visitor API
	const getUserDetails = () => {
		ProfileAPI.getUserDetailsByUserId(viewingVisitorUserId).then((response) => {
			const {
				email,
				name,
				about,
				profile_image_url,
				gender,
				country,
				city,
				interest,
				job_title,
				company_name,
				industry,
			} = response;

			setVisitorDetail({
				email: email,
				name: name,
				about: about,
				profile_image_url: profile_image_url,
				gender: gender,
				country: country,
				city: city,
				job_title: job_title,
				company_name: company_name,
				industry: industry,
			});

			if (interest) {
				// parse interest json if is json string
				let interestArray = [];
				try {
					interestArray = JSON.parse(interest);
				} catch (e) {
					// If is not json string, split the string by comma
					interestArray = interest.split(",");
				}

				setInterests(interestArray);
			}
		});
	};

	const handleClose = () => {
		closeVisitorDetailModal();
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="visitor-detail-modal">
			<div className="app-modal visitor-detail-modal">
				<span className="btn-close">
					<img src={IconClose} alt="Close" onClick={handleClose} />
				</span>

				<section className="detail-header grid grid-cols-10 lg:grid-cols-12 gap-1 gap-3">
					<div className="col-span-3 xl:col-span-3 lg:col-span-4">
						<img
							src={visitorDetail.profile_image_url || IconDefaultProfile}
							alt="profile"
							className="profile-img"
						/>
					</div>
					<div className="col-span-7 xl:col-span-3 lg:col-span-8 flex flex-col justify-end">
						<h1 className="name">{visitorDetail.name}</h1>
						<h6 className="designation">
							{visitorDetail.job_title} at {visitorDetail.company_name}
						</h6>
						<h6 className="email">{visitorDetail.email}</h6>
					</div>
				</section>

				<section className="detail-body grid grid-cols-10 lg:grid-cols-12 gap-3">
					<div className="col-span-12 xl:col-span-3 lg:col-span-4 pl-4 pr-4 lg:pl-8 lg:pr-0 flex flex-row lg:flex-col justify-between">
						<div className="mb-5">
							<div className="detail-label">Gender</div>
							<div className="detail-value">{GENDER[visitorDetail.gender]}</div>
						</div>

						<div className="mb-5">
							<div className="detail-label">Location</div>
							<div className="detail-value">
								{visitorDetail.city}, {visitorDetail.country}
							</div>
						</div>

						<div className="mb-5">
							<div className="detail-label">Industry</div>
							<div className="detail-value">{visitorDetail.industry}</div>
						</div>
					</div>
					<div className="col-span-12 xl:col-span-9 lg:col-span-8 px-4 lg:pl-0 lg:px-0">
						<div className="mb-5">
							<div className="detail-label">About</div>
							<div className="detail-value">{visitorDetail.about}</div>
						</div>
						<div className="mb-5">
							<div className="detail-label">Interest</div>
							{interests.map((interest) => {
								return <span className="interest-pill">{interest}</span>;
							})}
						</div>
					</div>
				</section>
			</div>
		</Modal>
	);
};

export default withRouter(VisitorDetailModal);

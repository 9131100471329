import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { agentActions } from "app/states/agent";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import AgentAPI from "app/apis/agent";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import { commonActions } from "app/states/common";

const CreatePrivateChatModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeCreatePrivateChatModal = () => dispatch(agentActions.closeCreatePrivateChatModal());
	const openCreatePrivateChatSuccessModal = () => dispatch(agentActions.openCreatePrivateChatSuccessModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setNewChatId = (chatId) => dispatch(agentActions.setNewChatId(chatId));
	const refreshChatListing = (refresh) => dispatch(agentActions.refreshChatListing(refresh));
	const setNewChatInvitedCount = (count) => dispatch(agentActions.setNewChatInvitedCount(count));

	const showCreatePrivateChatModal = useSelector(({ agent }) => agent.showCreatePrivateChatModal);

	const [currentPage, setCurrentPage] = useState(1);
	const [isSelectAll, setIsSelectAll] = useState(false);
	const [visitors, setVisitors] = useState([]);
	const [selectedVisitorsUserId, setSelectedVisitorsUserId] = useState([]);
	const [selectedVisitors, setSelectedVisitors] = useState([]);
	const [chatName, setChatName] = useState("");

	useEffect(() => {
		if (showCreatePrivateChatModal) {
			getExhibitorRoomVisitorListing();
		}
	}, [showCreatePrivateChatModal]);

	const getExhibitorRoomVisitorListing = () => {
		let queryParams = {
			limit: 650,
			include_histories: 0,
		};

		AgentAPI.getExhibitorRoomVisitorListing(queryParams)
			.then((response) => {
				const { audiences } = response;

				setVisitors(audiences);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleOnNext = () => {
		if (selectedVisitorsUserId.length <= 0) {
			openAlertSnackbar("At least 1 visitor is required", "error");
			return;
		}
		setCurrentPage(2);
	};

	const handleOnBack = () => {
		setCurrentPage(1);
	};

	const resetStates = () => {
		setCurrentPage(1);
		setIsSelectAll(false);
		setSelectedVisitorsUserId([]);
		setSelectedVisitors([]);
		setChatName("");
	};

	const handleSendRequest = async () => {
		if (!chatName) {
			openAlertSnackbar("Chat name is required.", "error");
			return;
		}

		try {
			// API create private chat
			const data = {
				title: chatName,
				user_ids: selectedVisitorsUserId,
			};

			AgentAPI.postCreateNewPrivateChat(data)
				.then((response) => {
					const { chat_id } = response;

					// After success create private chat
					// refresh chat list
					refreshChatListing(true);

					// set new private chat details
					setNewChatId(chat_id);
					setNewChatInvitedCount(selectedVisitorsUserId.length);

					// Before show success modal, clear states
					resetStates();

					// show success modal
					openCreatePrivateChatSuccessModal();
					closeCreatePrivateChatModal();
				})
				.catch((error) => {
					console.error(error);
					openAlertSnackbar(error.data.message, "error");
				});
		} catch (error) {
			console.error(error);
		}
	};

	const handleSelectAll = () => {
		// When turn to not select all, clear all selected
		if (isSelectAll) {
			setSelectedVisitorsUserId([]);
			setSelectedVisitors([]);
		}
		// When turn to select all, add all to selected
		else {
			let availableVisitorsUserId = visitors.map((visitor) => {
				return visitor.user_id;
			});

			setSelectedVisitorsUserId(availableVisitorsUserId);
			setSelectedVisitors(visitors);
		}

		setIsSelectAll(!isSelectAll);
	};

	const handleSelectVisitor = (visitor) => {
		const { user_id } = visitor;

		// If already in selected list, remove from it
		if (selectedVisitorsUserId.includes(user_id)) {
			let selectedVisitorsUserIdCopy = [...selectedVisitorsUserId];
			let index = selectedVisitorsUserIdCopy.indexOf(user_id);
			selectedVisitorsUserIdCopy.splice(index, 1);
			setSelectedVisitorsUserId(selectedVisitorsUserIdCopy);

			let selectedVisitorsCopy = [...selectedVisitors];
			let remainingSelectedVisitors = selectedVisitorsCopy.filter((visitor) => {
				return visitor.user_id !== user_id;
			});
			setSelectedVisitors(remainingSelectedVisitors);

			// Auto untick select all
			if (selectedVisitorsUserIdCopy.length !== visitors.length) {
				setIsSelectAll(false);
			}
		}
		// If not in the selected list, add into it
		else {
			setSelectedVisitorsUserId([...selectedVisitorsUserId, user_id]);
			setSelectedVisitors([...selectedVisitors, visitor]);
		}
	};

	return (
		<Modal
			open={showCreatePrivateChatModal}
			onClose={closeCreatePrivateChatModal}
			aria-labelledby="agent-create-pvc-modal">
			<div className="app-modal agent-create-pvc-modal">
				{currentPage === 1 && (
					<>
						<div className="modal-content">
							<div className="flex justify-between items-center mb-3">
								<span className="text-to">To</span>
								<div>
									<div className="flex items-center">
										<label htmlFor="select_all" className="mr-3">
											Select all
										</label>
										<input
											id="select_all"
											type="checkbox"
											className="app-custom-checkbox"
											name="select_all"
											checked={isSelectAll}
											onChange={handleSelectAll}
										/>
									</div>
								</div>
							</div>
							<div className="visitors-available">
								{visitors.map((visitor) => {
									const { user_id, name, company, job_title, image_url } = visitor;

									return (
										<div key={user_id} className="visitor-select">
											<div className="flex items-center">
												<img
													src={image_url || IconDefaultProfile}
													alt="profile"
													className="visitor-image"
												/>
												<div>
													<div className="visitor-name">{name}</div>
													<div className="visitor-desc">
														{job_title} at {company}
													</div>
												</div>
											</div>
											<input
												type="checkbox"
												className="app-custom-checkbox"
												checked={selectedVisitorsUserId.includes(user_id)}
												onChange={() => handleSelectVisitor(visitor)}
											/>
										</div>
									);
								})}
							</div>
						</div>

						<div className="action-bar">
							<div className="grid grid-cols-10 gap-3">
								<div className="col-span-4 md:col-span-6">
									<div className="text-selected">Selected:</div>
									<div className="text-selected-count">{selectedVisitorsUserId.length} people</div>
								</div>
								<div className="col-span-3 md:col-span-2 flex justify-center items-center">
									<button type="button" className="btn-dismiss" onClick={closeCreatePrivateChatModal}>
										Dismiss
									</button>
								</div>
								<div className="col-span-3 md:col-span-2 flex justify-center items-center">
									<button type="button" className="btn-next" onClick={handleOnNext}>
										Next
									</button>
								</div>
							</div>
						</div>
					</>
				)}

				{currentPage === 2 && (
					<>
						<div className="modal-content">
							<div className="flex items-center mb-5">
								<label className="chat-name-label">Chat Name</label>
								<input
									className="chat-name-input"
									type="text"
									name="chat_name"
									required
									onChange={(event) => setChatName(event.target.value)}
									value={chatName}
								/>
							</div>

							<div className="text-send-invite">
								You will send an invite to {selectedVisitorsUserId.length} visitors
							</div>
							<div className="visitors-confirmation">
								{selectedVisitors.map((visitor) => {
									const { user_id, name, company, job_title, image_url } = visitor;

									return (
										<div key={`confirm-${user_id}`} className="visitor-select">
											<div className="flex items-center">
												<img
													src={image_url || IconDefaultProfile}
													alt="profile"
													className="visitor-image"
												/>
												<div>
													<div className="visitor-name">{name}</div>
													<div className="visitor-desc">
														{job_title} at {company}
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>

						<div className="action-bar">
							<div className="grid grid-cols-10 gap-3">
								<div className="col-span-3 md:col-span-5">
									<div className="text-selected">Selected:</div>
									<div className="text-selected-count">{selectedVisitorsUserId.length} people</div>
								</div>
								<div className="col-span-2 flex justify-center items-center">
									<button type="button" className="btn-back" onClick={handleOnBack}>
										Back
									</button>
								</div>
								<div className="col-span-5 md:col-span-3 flex justify-center items-center">
									<button type="button" className="btn-send" onClick={handleSendRequest}>
										Send Request
									</button>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
};

export default withRouter(CreatePrivateChatModal);

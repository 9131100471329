import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@iconify/react";
import baselineSearch from "@iconify-icons/ic/baseline-search";
import "./style.scss";
import ExhibitorAPI from "app/apis/exhibitor";
import { useDispatch } from "react-redux";
import { exhibitorActions } from "app/states/exhibitor";
import { useParams } from "react-router-dom";
import ExhibitorAccordion from "app/views/components/ExhibitorAccordion/ExhibitorAccordion";
import IconChannel from "app/assets/Channels_Exhibitors/icon_channel.png";
import IconChevronDown from "app/assets/Channels_Exhibitors/chevron_down.png";
import IconLive from "app/assets/Channels_Exhibitors/icon_live_exhibitor.png";
import ExhibitorRoomsMobileModal from "../ExhibitorRoomsMobileModal/ExhibitorRoomsMobileModal";
import LocalStorageService from "app/services/localStorageService";

const ExhibitorRooms = ({ history }) => {
	const { roomId } = useParams();

	const userEventRole = LocalStorageService.getUserEventRole();

	const dispatch = useDispatch();
	const setExhibitorRoomId = (roomId) => dispatch(exhibitorActions.setExhibitorRoomId(roomId));
	const openMobileExhibitorRoomsModal = () => dispatch(exhibitorActions.openMobileExhibitorRoomsModal());
	const setExpandExhibitorCategory = (category) => dispatch(exhibitorActions.setExpandExhibitorCategory(category));

	const [liveSessions, setLiveSessions] = useState([]);
	const [exhibitors, setExhibitors] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [expandAllOnSearch, setExpandAllOnSearch] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getExhibitorRooms();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearchChange = (event) => {
		setSearchText(event.target.value);
	};

	const handleExhibitorSearch = () => {
		let params = {};

		if (searchText) {
			params["keyword"] = searchText;
			setExpandAllOnSearch(true);
		} else {
			setExpandAllOnSearch(false);
		}

		getExhibitorRooms(params);
	};

	const getExhibitorRooms = (params) => {
		ExhibitorAPI.getExhibitorRooms(params)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					const { live_sessions, categories, initial_room_id } = response.data;

					setLiveSessions(live_sessions);
					setExhibitors(categories);

					// If current user is agent, set to the agent's exhibitor room
					if (userEventRole && userEventRole.agent && !roomId) {
						setExhibitorRoomId(userEventRole.agent.exhibitor_rooms[0].room_id);
					}
					// Default set randomised initial_room_id as selected if there is no session id in route url
					else if (initial_room_id && !roomId) {
						setExhibitorRoomId(initial_room_id);
					}
				} else {
					const { errors } = response;
					console.error(errors);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleMobileViewSelectExhibitorCategory = (category) => {
		setExpandExhibitorCategory(category);
		openMobileExhibitorRoomsModal();
	};

	const onEnterSearch = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
			handleExhibitorSearch();
		}
	};

	return (
		<>
			{/* Desktop View */}
			<div
				id="exhibitor-rooms"
				className="flex flex-col w-full rounded-t border-2 border-gray-100 p-2 hidden lg:flex">
				<div className="mb-5 px-2">
					<FormControl>
						<InputLabel htmlFor="search">Exhibitor Rooms</InputLabel>
						<Input
							id="search"
							type="text"
							value={searchText}
							onChange={handleSearchChange}
							onKeyDown={onEnterSearch}
							name="search"
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										className="p-0 icon-search"
										aria-label="Search Exhibitor Rooms"
										onClick={handleExhibitorSearch}>
										<Icon icon={baselineSearch} />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>

				<div className="exhibitor-rooms-items">
					{liveSessions.length > 0 && (
						<ExhibitorAccordion category="live" listing={liveSessions} isExpand={expandAllOnSearch} />
					)}

					{exhibitors.length > 0 &&
						exhibitors.map((exhibitor) => {
							const { name, thumbnail_url, rooms } = exhibitor;

							const summary = {
								name,
								thumbnail_url,
							};

							return (
								<ExhibitorAccordion
									key={`exhibitor-${name}`}
									summary={summary}
									listing={rooms}
									isExpand={expandAllOnSearch}
								/>
							);
						})}

					{isLoading && <div className="text-no-result">Loading...</div>}

					{/* Client request to remove. Temporary comment in case client u-turn */}
					{/* {!isLoading && liveSessions.length <= 0 && exhibitors.length <= 0 && (
						<div className="text-no-result">No Results Found</div>
					)} */}
				</div>
			</div>

			{/* Mobile View */}
			<div id="exhibitor-rooms" className="flex items-center justify-between p-2 lg:hidden">
				<section className="m__header flex items-center" onClick={openMobileExhibitorRoomsModal}>
					<img src={IconChannel} alt="Channel" className="icon-block" />
					<span>Exhibitor</span>
					<img src={IconChevronDown} alt="Channel" className="icon-chevron-down" />
				</section>

				<section className="m__rooms flex-inline justify-end items-center">
					{liveSessions.length > 0 && (
						<img
							src={IconLive}
							alt="live thumbnail"
							className="rounded-full mx-1 mobile-exhibitor-thumbnail"
							onClick={() => handleMobileViewSelectExhibitorCategory("live")}
						/>
					)}

					{exhibitors.length > 0 &&
						exhibitors.map((exhibitor) => {
							const { name, thumbnail_url } = exhibitor;

							return (
								<img
									key={name}
									src={thumbnail_url}
									alt={name}
									className="rounded-full mx-1 mobile-exhibitor-thumbnail"
									onClick={() => handleMobileViewSelectExhibitorCategory(name)}
								/>
							);
						})}
				</section>
			</div>

			<ExhibitorRoomsMobileModal
				liveSessions={liveSessions}
				exhibitors={exhibitors}
				getExhibitorRooms={getExhibitorRooms}
			/>
		</>
	);
};

export default withRouter(ExhibitorRooms);

import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import './style.scss'

const AgentMobile = ({ history }) => {

    return (
        <div id="agent-mobile-page" className="">
            Agent mobile page
        </div>
    )
}

export default withRouter(AgentMobile);
export const SET_EXHIBITOR_ROOM_ID = "exhibitor/SET_EXHIBITOR_ROOM_ID";
export const SET_EXHIBITOR_STORE_ID = "exhibitor/SET_EXHIBITOR_STORE_ID";
export const SET_EXHIBITOR_ROOM_DETAILS = "exhibitor/SET_EXHIBITOR_ROOM_DETAILS";

export const OPEN_MOBILE_EXHIBITOR_ROOMS_MODAL = "exhibitor/OPEN_MOBILE_EXHIBITOR_ROOMS_MODAL";
export const CLOSE_MOBILE_EXHIBITOR_ROOMS_MODAL = "exhibitor/CLOSE_MOBILE_EXHIBITOR_ROOMS_MODAL";

export const SET_EXPAND_EXHIBITOR_CATEGORY = "exhibitor/SET_EXPAND_EXHIBITOR_CATEGORY";

export const SET_STORE_PRODUCTS = "exhibitor/SET_STORE_PRODUCTS";

export const SET_EXHIBITOR_CHAT_ID = "exhibitor/SET_EXHIBITOR_CHAT_ID";
export const SET_EXHIBITOR_DIALOG_ID = "exhibitor/SET_EXHIBITOR_DIALOG_ID";

export const OPEN_EXHIBITOR_DOWNLOAD_FILES_MODAL = "exhibitor/OPEN_EXHIBITOR_DOWNLOAD_FILES_MODAL";
export const CLOSE_EXHIBITOR_DOWNLOAD_FILES_MODAL = "exhibitor/CLOSE_EXHIBITOR_DOWNLOAD_FILES_MODAL";

export const SET_PRIVATE_CHAT_JOINED_TIMESTAMP = "exhibitor/SET_PRIVATE_CHAT_JOINED_TIMESTAMP";
export const SET_PRIVATE_CHAT_VISITORS = "exhibitor/SET_PRIVATE_CHAT_VISITORS";

export const REFRESH_EXHIBITOR_ROOM_DETAILS = "exhibitor/REFRESH_EXHIBITOR_ROOM_DETAILS";

export const OPEN_EXHIBITOR_STORE_MODAL = "common/OPEN_EXHIBITOR_STORE_MODAL";
export const CLOSE_EXHIBITOR_STORE_MODAL = "common/CLOSE_EXHIBITOR_STORE_MODAL";

export const OPEN_PRODUCT_DETAIL_MODAL = "common/OPEN_PRODUCT_IMAGE_MODAL";
export const CLOSE_PRODUCT_DETAIL_MODAL = "common/CLOSE_PRODUCT_IMAGE_MODAL";

export const OPEN_PRODUCT_ORDER_SUMMARY_MODAL = "exhibitor/OPEN_PRODUCT_ORDER_SUMMARY_MODAL";
export const CLOSE_PRODUCT_ORDER_SUMMARY_MODAL = "exhibitor/CLOSE_PRODUCT_ORDER_SUMMARY_MODAL";

export const OPEN_PRODUCT_ORDERED_SUCCESS_MODAL = "exhibitor/OPEN_PRODUCT_ORDERED_SUCCESS_MODAL";
export const CLOSE_PRODUCT_ORDERED_SUCCESS_MODAL = "exhibitor/CLOSE_PRODUCT_ORDERED_SUCCESS_MODAL";

export const OPEN_PRODUCT_ORDERED_PROCESSING_MODAL = "exhibitor/OPEN_PRODUCT_ORDERED_PROCESSING_MODAL";
export const CLOSE_PRODUCT_ORDERED_PROCESSING_MODAL = "exhibitor/CLOSE_PRODUCT_ORDERED_PROCESSING_MODAL";

export const OPEN_PRODUCT_ORDERED_FAILED_MODAL = "exhibitor/OPEN_PRODUCT_ORDERED_FAILED_MODAL";
export const CLOSE_PRODUCT_ORDERED_FAILED_MODAL = "exhibitor/CLOSE_PRODUCT_ORDERED_FAILED_MODAL";

export const SET_HAS_PENDING_PRIVATE_CHAT_INVITATION = "exhibitor/SET_HAS_PENDING_PRIVATE_CHAT_INVITATION";

export const SET_EXHIBITOR_STORE_SETTINGS = "exhibitor/SET_EXHIBITOR_STORE_SETTINGS";

export const SET_SWITCH_EXHIBITOR_ROOM = "exhibitor/SET_SWITCH_EXHIBITOR_ROOM";
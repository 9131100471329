import React from "react";
import "./style.scss";
import IconChecked from "app/assets/Agent/icon_checked.png";

const CustomCheckbox = ({ value, name, checked, onChange }) => {
	return (
		<div
			className={`custom-checkbox-wrapper ${checked ? "checked" : ""} mb-2`}
			data-key={value}
			onClick={() => onChange(value)}>
			{checked && <img src={IconChecked} alt="checked" />} {name}
		</div>
	);
};

export default CustomCheckbox;

import React, { useEffect, useState } from "react";
import MenuBar from "app/views/containers/MenuBar/MenuBar";
import BrandLogoMobile from "app/views/components/BrandLogoMobile/BrandLogoMobile";
import IconBack from "app/assets/icon-back.png";
import "./style.scss";
import OrderDetailsContainer from "app/views/containers/Order/OrderDetailsContainer/OrderDetailsContainer";
import { withRouter } from 'react-router-dom';
import { useSelector } from "react-redux";
import OrderAPI from "app/apis/order";

const MobileProfileOrderPage = ({ history }) => {
	const viewingOrderId = useSelector(({ common }) => common.viewingOrderId);

	const [orderNo, setOrderNo] = useState(null);

	useEffect(() => {
		if (!viewingOrderId) return;

		OrderAPI.getUserOrderDetails(viewingOrderId)
			.then((response) => {
				setOrderNo(response.order_no);
			})
			.catch((error) => {
				console.error(error);
			})
	}, [viewingOrderId]);

	const handleGoBack = () => {
		history.goBack();
	}

	return (
		<div className="grid grid-cols-12 w-screen mobile-profile-order-page">
			<div className="col-span-12">
				<BrandLogoMobile />
			</div>
				
			<div className="col-span-12">
				<section className="my-order-header flex justify-center items-center">
					<span onClick={handleGoBack}>
						<img src={IconBack} alt="back" className="icon-back" />
					</span>
					<span>
						Order number: #{orderNo}
					</span>
				</section>
				<section>
					<OrderDetailsContainer />
				</section>
			</div>

			{/* Mobile View Menu Bar */}
			<div className="col-span-12 block">
				<MenuBar />
			</div>
		</div>
	);
};

export default withRouter(MobileProfileOrderPage);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import COUNTRIES from "app/config/country_region.json";
import ProfileAPI from "app/apis/profile";
import { useDispatch, useSelector } from "react-redux";
import { authenticationActions } from "app/states/authentication";
import { commonActions } from "app/states/common";
import LocalStorageService from "app/services/localStorageService";
import EventAPI from "app/apis/event";

const ShippingAddressContainer = ({ history }) => {
	const { eventCode } = useParams();

	const dispatch = useDispatch();
	const closeShippingAddressModal = () => dispatch(authenticationActions.closeShippingAddressModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setUserProfile = (userProfile) => dispatch(authenticationActions.setUserProfile(userProfile));

	const isEnteringEvent = useSelector(({ common }) => common.isEnteringEvent);
	const userProfile = useSelector(({ auth }) => auth.userProfile);

	// Get user's default address
	const defaultAddress = userProfile.addresses.find((address) => address.default === 1);

	// Get "Malaysia" regions
	const MsiaRegions = COUNTRIES.filter((countries) => {
		return countries.countryShortCode === defaultAddress ? defaultAddress.country : "MY";
	});

	const [regions, setRegions] = useState(MsiaRegions[0].regions);

	const [inputData, setInputData] = useState({
		name: "",
		recipient_name: defaultAddress ? defaultAddress.name : userProfile.first_name + " " + userProfile.last_name,
		contact_number: "",
		address: "",
		postcode: "",
		country: "",
		state: "",
		city: "",
	});
	const [defaultAddressId, setDefaultAddressId] = useState(null);

	useEffect(() => {
		// If got default address, prefill the form
		if (userProfile.addresses && userProfile.addresses.length > 0) {
			setDefaultAddressId(defaultAddress.id);
			setInputData({
				name: defaultAddress.label || "",
				contact_number: defaultAddress.phone_no || "",
				address: defaultAddress.address || "",
				postcode: defaultAddress.postcode || "",
				country: defaultAddress.country || "",
				state: defaultAddress.state || "",
				city: defaultAddress.city || "",
			});

			const selectedCountryRegions = COUNTRIES.filter((countries) => {
				return countries.countryShortCode === defaultAddress.country;
			});

			setRegions(selectedCountryRegions[0].regions);
		}
	}, [userProfile]);

	const handleInputDataChange = (event) => {
		setInputData({
			...inputData,
			[event.target.name]: event.target.value,
		});

		if (event.target.name === "country") {
			const selectedCountryRegions = COUNTRIES.filter((countries) => {
				return countries.countryShortCode === event.target.value;
			});

			setRegions(selectedCountryRegions[0].regions);
		}
	};

	const getLatestUserProfile = () => {
		EventAPI.getUserInfoByEventId()
			.then(response => {
				const { addresses } = response.user;
				const updatedUserProfile = {
					...userProfile,
					addresses
				};

				setUserProfile(updatedUserProfile);
				LocalStorageService.setUserProfile(JSON.stringify(updatedUserProfile));
			})
			.catch(error => {
				console.error(error);
			})
	}

	const handleSaveAddress = (event) => {
		event.preventDefault();

		let data = {
			label: inputData.name,
			name: inputData.recipient_name,
			email: userProfile.email,
			phone_no: inputData.contact_number,
			address: inputData.address,
			postcode: inputData.postcode,
			country: inputData.country,
			state: inputData.state,
			city: inputData.city,
			default: 1,
		};

		// If have default address, save it
		if (defaultAddressId) {
			data["id"] = defaultAddressId;

			ProfileAPI.postUpdateAddress(data)
				.then((response) => {
					getLatestUserProfile();
					openAlertSnackbar("Shipping Address Saved!", "success");
					closeShippingAddressModal();

					if (isEnteringEvent) {
						history.push(`/events/${eventCode}/channel`);
					}
				})
				.catch((error) => {
					openAlertSnackbar(`${error.data.message}`, "error");
				});
		}
		// If do not have default address, add a new address
		else {
			ProfileAPI.postAddAddress(data)
				.then((response) => {
					getLatestUserProfile();
					openAlertSnackbar("Shipping Address Saved!", "success");
					closeShippingAddressModal();

					if (isEnteringEvent) {
						history.push(`/events/${eventCode}/channel`);
					}
				})
				.catch((error) => {
					openAlertSnackbar(`${error.data.message}`, "error");
				});
		}
	};

	const handleSkipForNow = () => {
		closeShippingAddressModal();

		if (isEnteringEvent) {
			history.push(`/events/${eventCode}/channel`);
		}
	};

	return (
		<div id="shipping-address-container" className="flex flex-col pt-10 lg:pt-6 lg:justify-center h-full">
			<section className="mb-6 px-20 lg:px-0 lg:pr-40">
				<div className="text-register-complete mb-2">Registration complete!</div>
				<div className="title mb-2">You're almost there</div>
				<div className="subtitle">
					In this event, you can purchase products from exhibitors. Would you like to update your{" "}
					<strong>default shipping address</strong> now?
				</div>
			</section>

			<form
				id="form-shipping-address"
				method="post"
				onSubmit={handleSaveAddress}
				className="px-10 lg:px-0 pt-4 lg:pt-0">
				<div className="form-input">
					<label htmlFor="name">Name *</label>
					<input
						id="name"
						type="text"
						name="name"
						required
						value={inputData.name}
						onChange={handleInputDataChange}
						placeholder="e.g. Home, Office"
					/>
				</div>
				<div className="form-input">
					<label htmlFor="recipient_name">Recipient Name *</label>
					<input
						id="recipient_name"
						type="text"
						name="recipient_name"
						required
						value={inputData.recipient_name}
						onChange={handleInputDataChange}
					/>
				</div>
				<div className="form-input">
					<label htmlFor="contact_number">Contact Number *</label>
					<input
						id="contact_number"
						type="text"
						name="contact_number"
						required
						value={inputData.contact_number}
						onChange={handleInputDataChange}
						placeholder="e.g. +60123456789"
					/>
				</div>
				<div className="form-input">
					<label htmlFor="address">Address *</label>
					<input
						id="address"
						type="text"
						name="address"
						required
						value={inputData.address}
						onChange={handleInputDataChange}
					/>
				</div>
				<div className="form-input">
					<label htmlFor="postcode">Postcode *</label>
					<input
						id="postcode"
						type="text"
						name="postcode"
						required
						value={inputData.postcode}
						onChange={handleInputDataChange}
					/>
				</div>
				<div className="form-input">
					<label htmlFor="country">Country *</label>
					<div className="select-wrapper">
						<select
							id="country"
							name="country"
							className="col-span-4"
							required
							value={inputData.country}
							onChange={handleInputDataChange}>
							<option value="" disabled></option>
							{COUNTRIES.map((item) => (
								<option key={item.countryShortCode} value={item.countryShortCode}>
									{item.countryName}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="form-input">
					<label htmlFor="state">State *</label>
					<div className="select-wrapper">
						<select
							id="state"
							name="state"
							className="col-span-4"
							required
							value={inputData.state}
							onChange={handleInputDataChange}>
							<option value="" disabled></option>
							{regions.map((item) => (
								<option key={item.name} value={item.name}>
									{item.name}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="form-input">
					<label htmlFor="city">City *</label>
					<input
						id="city"
						type="text"
						name="city"
						className="col-span-4"
						required
						value={inputData.city}
						onChange={handleInputDataChange}></input>
				</div>

				<div className="mt-16 lg:mt-8 mb-3 flex justify-center lg:justify-end items-center">
					<button
						type="button"
						className="hidden lg:block btn-primary-outline lg:mr-3"
						onClick={handleSkipForNow}>
						Skip for now
					</button>
					<button type="submit" className="btn-primary btn-save">
						Save
					</button>
				</div>

				{/* For mobile view */}
				<div className="btn-skip-now py-2 mb-6" onClick={handleSkipForNow}>
					Skip for now
				</div>
			</form>
		</div>
	);
};

export default withRouter(ShippingAddressContainer);

import React from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import MenuBar from "app/views/containers/MenuBar/MenuBar";
import BrandLogoMobile from "app/views/components/BrandLogoMobile/BrandLogoMobile";
import AgentOrdersContainer from "app/views/containers/Agent/AgentOrdersContainer/AgentOrdersContainer";

const AgentOrders = ({ history }) => {
	return (
		<div id="agent-orders-page" className="flex flex-col md:flex-row w-screen min-h-screen lg:pt-1">
			<section className="hidden lg:block mr-2">
				<MenuBar />
			</section>

			<div className="block lg:hidden">
				<BrandLogoMobile />
			</div>

			<div className="content-container">
				<AgentOrdersContainer />
			</div>

			{/* Mobile View Menu Bar */}
			<div className="block md:hidden w-full">
				<MenuBar />
			</div>
		</div>
	);
};

export default withRouter(AgentOrders);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import AgentAPI from "app/apis/agent";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { agentSocket } from "app/services/socketService";

import VisitorDetailModal from "../../Modal/VisitorDetailModal/VisitorDetailModal";

const AgentVisitorsContainer = () => {
	const dispatch = useDispatch();
	const openVisitorDetailModal = (userId) => dispatch(commonActions.openVisitorDetailModal(userId));

	const roomDetails = useSelector(({ agent }) => agent.roomDetails);

	const [totalVisitors, setTotalVisitors] = useState(0);
	const [visitors, setVisitors] = useState([]);
	const [viewType, setViewType] = useState("Active");

	useEffect(() => {
		getExhibitorRoomVisitorListing();
	}, [viewType]);

	// Handle socket response
	useEffect(() => {
		agentSocket.on("room-update", (message) => {
			const { action_code, data } = message;
			// console.log("agentSocket", message);

			// Don't do any update if is viewing all visitor list
			if (viewType === "All") {
				return;
			}

			// Attendance update (new joiner)
			if (action_code === 100) {
				const { total, audience } = data;

				setTotalVisitors(total);
				setVisitors((prevStates) => {
					let updatedVisitors = [...prevStates, audience];
					return updatedVisitors;
				});
			}

			// Attendance update (user leave)
			if (action_code === 101) {
				const { total, audience } = data;
				const { user_id } = audience;

				setTotalVisitors(total);

				// Find the user_id from visitors
				setVisitors((prevStates) => {
					let visitorsCopy = [...prevStates];
					let remainingVisitors = visitorsCopy.filter((visitor) => {
						return visitor.user_id !== user_id;
					});

					return remainingVisitors;
				});
			}
		});

		return () => {
			// turning of socket listner on unmount
			agentSocket.off("room-update");
		};
	}, []);

	const getExhibitorRoomVisitorListing = () => {
		let queryParams = {
			limit: 200,
			include_histories: viewType === "Active" ? 0 : 1,
		};

		AgentAPI.getExhibitorRoomVisitorListing(queryParams)
			.then((response) => {
				const { total, audiences } = response;

				setTotalVisitors(total);
				setVisitors(audiences);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleViewVisitorDetail = (userId) => {
		openVisitorDetailModal(userId);
	};

	return (
		<div className="agent-visitors-container">
			<section className="flex items-center company-info-wrapper">
				<img src={roomDetails.logo_url || IconDefaultProfile} alt="Logo" className="company-logo" />
				<span className="company-name">{roomDetails.account.name} (Visitors)</span>
			</section>

			<section className="visitors-container">
				<div className="flex justify-between items-center mb-8">
					<h1>
						{viewType} Visitors ({totalVisitors})
					</h1>
					<ButtonGroup
						color="primary"
						aria-label="outlined primary button group"
						disableElevation
						disableFocusRipple
						className="btn-group-type">
						<Button
							onClick={() => setViewType("Active")}
							className={`${viewType === "Active" ? "active" : ""}`}>
							Active Visitors
						</Button>
						<Button onClick={() => setViewType("All")} className={`${viewType === "All" ? "active" : ""}`}>
							All Visitors
						</Button>
					</ButtonGroup>
				</div>

				<div className="visitor-list grid grid-cols-10 gap-3 w-full">
					{[...new Set(visitors)].map((visitor) => {
						return <VisitorListItem visitor={visitor} handleViewVisitorDetail={handleViewVisitorDetail} />;
					})}
				</div>
			</section>

			<VisitorDetailModal />
		</div>
	);
};

const VisitorListItem = ({ visitor, handleViewVisitorDetail }) => {
	const { user_id, name, company, job_title, image_url } = visitor;

	const [showBorder, setShowBorder] = useState(false);

	const handlImageOnError = (event) => {
		event.target.onerror = null;
		event.target.src = IconDefaultProfile;
		setShowBorder(false);
	};

	return (
		<div
			key={`visitor-${user_id}`}
			className={`visitor-list-item col-span-2`}
			onClick={() => handleViewVisitorDetail(user_id)}>
			<img
				src={image_url || IconDefaultProfile}
				alt="profile"
				className={`${!showBorder ? "no-border" : ""}`}
				onError={handlImageOnError}
			/>
			<div>
				<div className="visitor-name">{name}</div>
				<div className="visitor-desc">
					{job_title} at {company}
				</div>
			</div>
		</div>
	);
};

export default withRouter(AgentVisitorsContainer);

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { exhibitorActions } from "app/states/exhibitor";
import ExhibitorAPI from "app/apis/exhibitor";
import { PAYMENT_SETTINGS } from "app/config/settings";
import LocalStorageService from "app/services/localStorageService";

const Product = ({ image, name, currency, price, external_product_url, storeUrl }) => {
	const eventSetting = LocalStorageService.getEventSetting();

	const dispatch = useDispatch();
	const openExhibitorStoreModal = () => dispatch(exhibitorActions.openExhibitorStoreModal());

	const handleProductClick = () => {
		if (eventSetting.payment_settings.includes(PAYMENT_SETTINGS["ecommerce"])) {
			if (external_product_url) {
				window.open(external_product_url, "_blank");
			}
			else if (storeUrl) {
				window.open(storeUrl, "_blank");
			}
			
			return;
		}

		openExhibitorStoreModal();
	};

	return (
		<div className="product col-span-6" onClick={handleProductClick}>
			<img src={image} alt={name} className="product-image mr-2" />
			<div className="p-2">
				<div className="product-name" title={name}>
					{name}
				</div>
				{price > 0 && (
					<div className="product-price">
						{currency}
						{price}
					</div>
				)}
			</div>
		</div>
	);
};

const ExhibitorStore = () => {
	const eventSetting = LocalStorageService.getEventSetting();

	const dispatch = useDispatch();
	const setStoreProducts = (products) => dispatch(exhibitorActions.setStoreProducts(products));
	const setExhibitorStoreSettings = (storeSettings) =>
		dispatch(exhibitorActions.setExhibitorStoreSettings(storeSettings));

	const exhibitorRoomId = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomId);
	const exhibitorStoreId = useSelector(({ exhibitor }) => exhibitor.exhibitorStoreId);
	const storeProducts = useSelector(({ exhibitor }) => exhibitor.storeProducts);

	const [storeUrl, setStoreUrl] = useState("");

	useEffect(() => {
		if (!exhibitorStoreId) {
			setStoreProducts([]);
			return;
		}

		ExhibitorAPI.getExhibitorRoomStoreProducts(exhibitorRoomId, exhibitorStoreId)
			.then((response) => {
				const { store_url, products } = response;

				setStoreUrl(store_url);
				setStoreProducts(products);
				setExhibitorStoreSettings(response);
			})
			.catch((error) => {
				console.error("Store products error: ", error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exhibitorStoreId]);

	const handleOrderNow = () => {
		if (storeUrl) {
			window.open(storeUrl, "_blank");
		}
	};

	return (
		<div className="exhibitor-store-container">
			<div className="products-container">
				<div className="grid grid-cols-12 gap-3 px-5 pt-3">
					{storeProducts.map((product) => {
						const { name, currency, price, image_url, external_product_url } = product;

						return (
							<Product
								key={name}
								image={image_url}
								name={name}
								currency={currency}
								price={price}
								external_product_url={external_product_url}
								storeUrl={storeUrl}
							/>
						);
					})}
				</div>
			</div>
			{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["ecommerce"]) && storeUrl && (
				<div className="px-5">
					<button className="btn-order" onClick={handleOrderNow}>
						Order Now
					</button>
				</div>
			)}
		</div>
	);
};

export default withRouter(ExhibitorStore);

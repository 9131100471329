import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import LocalStorageService from 'app/services/localStorageService';

import IconClose from "app/assets/icon_close.svg";
import './style.scss'


const PrivacyPolicyModal = ({ history }) => {

    const dispatch = useDispatch();
    const closePrivacyPolicyModal = () => dispatch(commonActions.closePrivacyPolicyModal());

    const showModal = useSelector(({ common }) => common.showPrivacyPolicyModal);

    const [content, setContent] = useState('');

    useEffect(() => {
        const webConfig = LocalStorageService.getWebConfig();

        if (webConfig) {
            const { privacy_policy_content } = webConfig;
            setContent(privacy_policy_content);
        }
        
    }, []);

    const handleClose = () => {
        closePrivacyPolicyModal();
    }

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="privacy-policy-modal"
        >
            <div className="app-modal privacy-policy-modal">
                <div className="flex justify-between mb-4">
                    <h1 className="modal-title">Privacy Policy</h1>
                    <span className="btn-close"><img src={IconClose} alt="Close" onClick={handleClose} /></span>
                </div>
                <div className="modal-content">
                    <iframe src={content} title="Privacy Policy" />
                </div>
            </div>
        </Modal>
    )
}

export default withRouter(PrivacyPolicyModal);
import React, { forwardRef } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import moment from "moment";
import IconDateFilter from "app/assets/Channels_Exhibitors/icon_date_filter.png";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import LocalStorageService from "app/services/localStorageService";

const DateFilter = ({ selectedDate, onChange }) => {
	const eventSetting = LocalStorageService.getEventSetting();
	
	const DateFilterInput = forwardRef(({ value, onClick }, ref) => {
		let displayDate = "TODAY";

		let isCurrentDate = moment(value).isSame(new Date(), "day");
		if (!isCurrentDate) {
			displayDate = value;
		}

		return (
			<div className="date-filter-input">
				<input ref={ref} className="input-filter" type="text" value={displayDate} onClick={onClick} readOnly />
				<img src={IconDateFilter} alt="filter date" className="icon-date-filter" onClick={onClick} />
			</div>
		);
	});

	return (
		<DatePicker
			selected={selectedDate}
			onChange={onChange}
			customInput={<DateFilterInput />}
			minDate={new Date(eventSetting.start_at)}
			maxDate={new Date(eventSetting.end_at)}
		/>
	);
};

export default (DateFilter);

DateFilter.propTypes = {
	selectedDate: PropTypes.any.isRequired,
	onChange: PropTypes.func,
};

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Authentication from "app/apis/authentication";
import "./style.scss";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";

const VerifyEmailContainer = ({ location }) => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const emailParam = location.state && location.state.email ? location.state.email : "your email";
	const [email] = useState(emailParam);

	const resendEmail = () => {
		Authentication.postResendConfirmation(email)
			.then((response) => {
				openAlertSnackbar("Resent verification email", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<div className="verify-email-container flex flex-col items-center lg:items-start h-full">
			<label className="title mb-2">Verify your Email</label>

			<p className="desc mb-6">You will need to verify your email to complete signing up </p>

			<p className="desc">
				An email has been sent to <span className="text-email">{email}</span> with a link to verify your
				account. If you have not received the email after a few minutes, please check your spam folder
			</p>

			<div className="mt-8 mb-3">
				<button type="button" className="btn-resend" onClick={resendEmail}>
					Resend Email
				</button>
			</div>
		</div>
	);
};

export default withRouter(VerifyEmailContainer);

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import COUNTRIES from "app/config/country_region.json";
import EventAPI from "app/apis/event";
import { useDispatch, useSelector } from "react-redux";
import { authenticationActions } from "app/states/authentication";
import { commonActions } from "app/states/common";
import { eventActions } from "app/states/event";
import { USER_EVENT_PAYMENT_STATUS } from "app/config/apiEnums";

const EventRegistrationContainer = ({ history }) => {
	const dispatch = useDispatch();
	const openShippingAddressModal = () => dispatch(authenticationActions.openShippingAddressModal());
	const closeEventRegistrationModal = () => dispatch(authenticationActions.closeEventRegistrationModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const refreshUserEventStatus = () => dispatch(authenticationActions.refreshUserEventStatus());
	const setTicketPaymentStatus = (ticketPaymentStatus) =>
		dispatch(eventActions.setTicketPaymentStatus(ticketPaymentStatus));
	const openTicketCheckoutStatusModal = () => dispatch(authenticationActions.openTicketCheckoutStatusModal());

	const isCheckoutEventTicket = useSelector(({ event }) => event.isCheckoutEventTicket);
	const eventSetting = useSelector(({ event }) => event.eventSettings);
	const userProfile = useSelector(({ auth }) => auth.userProfile);

	// // Get the user's default address country
	// const defaultAddress =
	// 	userProfile && userProfile.addresses ? userProfile.addresses.find((address) => address.default === 1) : null;

	const [inputData, setInputData] = useState({
		first_name: userProfile ? userProfile.first_name : "",
		last_name: userProfile ? userProfile.last_name : "",
		job_title: userProfile ? userProfile.job_title : "",
		company_name: userProfile ? userProfile.company_name : "",
		country: userProfile ? userProfile.country : "",
	});
	
	const handleprofileInputChange = (event) => {
		setInputData({
			...inputData,
			[event.target.name]: event.target.value,
		});
	};

	const handleSubmitEventRegistration = (event) => {
		event.preventDefault();

		let data = {
			event_id: eventSetting.id,
			first_name: inputData.first_name,
			last_name: inputData.last_name,
			country: inputData.country,
			job_title: inputData.job_title,
			company_name: inputData.company_name,
		};

		EventAPI.postJoinEvent(data)
			.then((response) => {
				const { user } = response;
				const { event } = user;
				const { ecommerce_enabled } = event;

				refreshUserEventStatus();
				if (ecommerce_enabled === 1) {
					openShippingAddressModal();
					closeEventRegistrationModal();
				} else {
					closeEventRegistrationModal();
				}
			})
			.catch((error) => {
				openAlertSnackbar(`${error.data.message}`, "error");
			});
	};

	const purchaseTicketAndJoinEvent = (event) => {
		event.preventDefault();

		// Show payment processing, do not close window notice
		setTicketPaymentStatus(USER_EVENT_PAYMENT_STATUS[1]);
		openTicketCheckoutStatusModal();

		let landingPageUrl = new URL(window.location.href);
		landingPageUrl.hash = "";

		let data = {
			event_id: eventSetting.id,
			ticket_id: eventSetting.entry_tickets[0].id,
			return_url: landingPageUrl,
			first_name: inputData.first_name,
			last_name: inputData.last_name,
			country: inputData.country,
			job_title: inputData.job_title,
			company_name: inputData.company_name,
		};

		EventAPI.postPurchaseTicketAndJoinEvent(data)
			.then((response) => {
				const { payment_url } = response;

				closeEventRegistrationModal();
				window.location.href = payment_url;
			})
			.catch((error) => {
				openAlertSnackbar(`${error.data.message}`, "error");
			});
	};

	return (
		<div id="event-registration-container" className="flex flex-col pt-10 lg:pt-6 lg:justify-center h-full">
			<section className="mb-6 px-20 lg:px-0">
				<div className="title mb-2">Event Registration</div>
				<div className="subtitle">Please provide the information below to register for this event</div>
			</section>

			<form
				id="form-event-registration"
				method="post"
				onSubmit={isCheckoutEventTicket ? purchaseTicketAndJoinEvent : handleSubmitEventRegistration}
				className="px-10 lg:px-0 pt-4 lg:pt-0">
				<div className="form-input">
					<label htmlFor="first_name">First Name *</label>
					<input
						id="first_name"
						type="text"
						name="first_name"
						required
						value={inputData.first_name}
						onChange={handleprofileInputChange}
					/>
				</div>
				<div className="form-input">
					<label htmlFor="last_name">Last Name *</label>
					<input
						id="last_name"
						type="text"
						name="last_name"
						required
						value={inputData.last_name}
						onChange={handleprofileInputChange}
					/>
				</div>
				<div className="form-input">
					<label htmlFor="job_title">Job Title *</label>
					<input
						id="job_title"
						type="text"
						name="job_title"
						required
						value={inputData.job_title}
						onChange={handleprofileInputChange}
					/>
				</div>
				<div className="form-input">
					<label htmlFor="company_name">Company Name *</label>
					<input
						id="company_name"
						type="text"
						name="company_name"
						required
						value={inputData.company_name}
						onChange={handleprofileInputChange}
					/>
				</div>
				<div className="form-input">
					<label htmlFor="country">Country *</label>
					<div className="select-wrapper">
						<select
							id="country"
							name="country"
							className="col-span-4"
							required
							value={inputData.country}
							onChange={handleprofileInputChange}>
							<option value="" disabled  selected={inputData.country === null || inputData.country === ""}></option>
							{COUNTRIES.map((item) => (
								<option key={item.countryShortCode} value={item.countryShortCode}>
									{item.countryName}
								</option>
							))}
						</select>
					</div>
				</div>

				<div className="mt-16 lg:mt-8 mb-3 text-center lg:text-right">
					<button type="submit" className="btn-primary btn-complete-registration">
						{isCheckoutEventTicket ? "Checkout" : "Complete Registration"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default withRouter(EventRegistrationContainer);

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { exhibitorActions } from "app/states/exhibitor";
import { useDispatch, useSelector } from "react-redux";
import IconClose from "app/assets/icon_close.svg";
import "./style.scss";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@iconify/react";
import baselineSearch from "@iconify-icons/ic/baseline-search";
import ExhibitorAccordion from "app/views/components/ExhibitorAccordion/ExhibitorAccordion";

const ExhibitorRoomsMobileModal = ({ history, getExhibitorRooms, liveSessions, exhibitors }) => {
	const [searchText, setSearchText] = useState("");

	const dispatch = useDispatch();
	const closeMobileExhibitorRoomsModal = () => dispatch(exhibitorActions.closeMobileExhibitorRoomsModal());

	const showModal = useSelector(({ exhibitor }) => exhibitor.showMobileExhibitorRoomsModal);
	const expandExhibitorCategory = useSelector(({ exhibitor }) => exhibitor.expandExhibitorCategory);

	const handleClose = () => {
		closeMobileExhibitorRoomsModal();
	};

	const handleExhibitorSearch = () => {
		if (!searchText) {
			getExhibitorRooms();
			return;
		}

		let params = {
			keyword: searchText,
		};

		getExhibitorRooms(params);
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="exhibitor-room-mobile-modal">
			<div className="mobile-app-modal exhibitor-room-mobile-modal">
				<div className="flex justify-between items-center mb-3">
					<h1 className="modal-title">Exhibitor</h1>
					<span className="btn-close">
						<img src={IconClose} alt="Close" onClick={handleClose} />
					</span>
				</div>
				<div className="modal-content pb-8">
					<section className="mb-3">
						<div className="mb-3">
							<FormControl style={{ width: "100%" }}>
								<InputLabel htmlFor="search">Exhibitor Rooms</InputLabel>
								<Input
									id="search"
									type="text"
									value={searchText}
									onChange={(event) => setSearchText(event.target.value)}
									name="search"
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												className="p-0"
												aria-label="Search Exhibitor Rooms"
												onClick={handleExhibitorSearch}>
												<Icon icon={baselineSearch} />
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</div>
					</section>

					<section className="exhibitor-rooms-items">
						{liveSessions.length > 0 && (
							<ExhibitorAccordion
								category="live"
								listing={liveSessions}
								isExpand={expandExhibitorCategory === "live"}
							/>
						)}

						{exhibitors.length > 0 &&
							exhibitors.map((exhibitor) => {
								const { name, thumbnail_url, rooms } = exhibitor;

								const summary = {
									name,
									thumbnail_url,
								};
	

								return (
									<ExhibitorAccordion
										key={`exhibitor-${name}`}
										summary={summary} 
										listing={rooms}
										isExpand={expandExhibitorCategory === name}
									/>
								);
							})}

					</section>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(ExhibitorRoomsMobileModal);

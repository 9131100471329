import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import IconAdd from "app/assets/Authentication/icon-add.svg";
import IconEdit from "app/assets/Agent/icon_edit.png";
import { useDispatch } from 'react-redux';
import { agentActions } from "app/states/agent";

const AgentProductListItem = ({ id, productName, image, priceTag, addNew }) => {
	const dispatch = useDispatch();
	const openAddProductModal = () => dispatch(agentActions.openAddProductModal());
	const openEditProductModal = (productId) => dispatch(agentActions.openEditProductModal(productId));

	if (addNew) {
		return (
			<div className="product-list-item" onClick={openAddProductModal}>
				<img src={IconAdd} alt="Add Logo" className="icon-add" />
			</div>
		);
	}

	return (
		<div className="product-list-item" onClick={() => openEditProductModal(id)}>
			<div className="product-card">
				<div className="product-thumbnail-wrapper">
					<img src={image} alt="product thumbnail" className="product-thumbnail" />
				</div>
				<div className="p-2">
					<div className="name">{productName}</div>
					<div className="price">{priceTag}</div>
				</div>
				<img src={IconEdit} alt="edit" className="icon-edit" />
			</div>
		</div>
	);
};

export default AgentProductListItem;

AgentProductListItem.propTypes = {
	productName: PropTypes.string,
	image: PropTypes.string,
	priceTag: PropTypes.string,
	addNew: PropTypes.bool,
};

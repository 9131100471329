export const OPEN_RESET_PW_SUCCESS_MODAL = "authentication/OPEN_RESET_PW_SUCCESS_MODAL";
export const CLOSE_RESET_PW_SUCCESS_MODAL = "authentication/CLOSE_RESET_PW_SUCCESS_MODAL";

export const OPEN_LOGIN_REGISTER_MODAL = "authentication/OPEN_LOGIN_REGISTER_MODAL";
export const CLOSE_LOGIN_REGISTER_MODAL = "authentication/CLOSE_LOGIN_REGISTER_MODAL";

export const OPEN_EVENT_REGISTRATION_MODAL = "authentication/OPEN_EVENT_REGISTRATION_MODAL";
export const CLOSE_EVENT_REGISTRATION_MODAL = "authentication/CLOSE_EVENT_REGISTRATION_MODAL";

export const OPEN_SHIPPING_ADDRESS_MODAL = "authentication/OPEN_SHIPPING_ADDRESS_MODAL";
export const CLOSE_SHIPPING_ADDRESS_MODAL = "authentication/CLOSE_SHIPPING_ADDRESS_MODAL";

export const SET_LOGIN_REGISTER_TAB_INDEX = "authentication/SET_LOGIN_REGISTER_TAB_INDEX";

export const SET_IS_LOGGED_IN = "authentication/SET_IS_LOGGED_IN";
export const SET_USER_PROFILE = "authentication/SET_USER_PROFILE";

export const OPEN_TICKET_SUMMARY_MODAL = "authentication/OPEN_TICKET_SUMMARY_MODAL";
export const CLOSE_TICKET_SUMMARY_MODAL = "authentication/CLOSE_TICKET_SUMMARY_MODAL";

export const OPEN_TICKET_CHECKOUT_STATUS_MODAL = "authentication/OPEN_TICKET_CHECKOUT_STATUS_MODAL";
export const CLOSE_TICKET_CHECKOUT_STATUS_MODAL = "authentication/CLOSE_TICKET_CHECKOUT_STATUS_MODAL";

export const REFRESH_USER_EVENT_STATUS = "authentication/REFRESH_USER_EVENT_STATUS";

export const SET_IS_FROM_REGISTER_BUTTON = "authentication/SET_IS_FROM_REGISTER_BUTTON";


const TokenService = {

    getAccessToken: () => {
        return localStorage.getItem("accessToken");
    },

    setAccessToken: (accessToken) => {
        localStorage.setItem('accessToken', accessToken);
    },

    clearTokens: () => {
        localStorage.removeItem("accessToken");
    },

};

export default TokenService;
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconChat from "app/assets/Agent/chat_dark.png";
import DateInput from "app/views/components/DateInput/DateInput";
import Switch from "react-switch";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import AgentAPI from "app/apis/agent";
import { commonActions } from "app/states/common";

const ChatSettingsContainer = () => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const roomDetails = useSelector(({ agent }) => agent.roomDetails);

	const [inputData, setInputData] = useState({
		start_time: "",
		end_time: "",
		allow_public_chat: true,
		appear_offline: true,
	});

	useEffect(() => {
		if (!roomDetails) return;

		let timezoneOffsetInMinutes = moment().utcOffset();

		let [startHour, startMinute] = roomDetails.chat_setting.chat_start_at.split(":");
		let [endHour, endMinute] = roomDetails.chat_setting.chat_end_at.split(":");

		let startTime = new Date();
		startTime.setHours(startHour);
		startTime.setMinutes(startMinute);
		startTime = moment(startTime).add(timezoneOffsetInMinutes, "minutes");

		let endTime = new Date();
		endTime.setHours(endHour);
		endTime.setMinutes(endMinute);
		endTime = moment(endTime).add(timezoneOffsetInMinutes, "minutes");

		setInputData({
			start_time: new Date(startTime),
			end_time: new Date(endTime),
			allow_offline_chat: roomDetails.chat_setting.allow_offline_chat === 1 ? true : false,
			appear_offline: roomDetails.chat_setting.appear_offline === 1 ? true : false,
		});
	}, [roomDetails]);

	const submitUpdateChatSettings = () => {
		// Convert to GMT+0 to store in backend
		let timezoneOffsetInMinutes = moment().utcOffset();

		const data = {
			appear_offline: inputData.appear_offline ? 1 : 0,
			allow_offline_chat: inputData.allow_offline_chat ? 1 : 0,
			chat_start_at: moment(inputData.start_time).subtract(timezoneOffsetInMinutes, "minutes").format("HH:mm"),
			chat_end_at: moment(inputData.end_time).subtract(timezoneOffsetInMinutes, "minutes").format("HH:mm"),
		};

		AgentAPI.postUpdateChatSettings(data)
			.then((response) => {
				openAlertSnackbar("Updated Chat Settings", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<div id="chat-settings" className="chat-settings-container mb-3">
			<div className="grid grid-cols-12 gap-3">
				<div className="col-span-2">
					<h3>
						<img src={IconChat} alt="Chat Settings" /> Chat Settings
					</h3>
				</div>
				<div className="col-span-8">
					<form className="form-container mb-3">
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Agent Availability</label>
							</div>
							<div className="col-span-7">
								<div className="flex items-center">
									<span className="mr-2 datetime-label">Start time</span>
									<div className="datetime-select mr-3">
										<DateInput
											dateFormat="hh:mma"
											selectedDate={inputData.start_time}
											showTimeSelectOnly
											showTimeInput
											onChange={(date) => setInputData({ ...inputData, start_time: date })}
										/>
									</div>
									<span className="mr-2 datetime-label">End time</span>
									<div className="datetime-select mr-3">
										<DateInput
											dateFormat="hh:mma"
											selectedDate={inputData.end_time}
											showTimeSelectOnly
											showTimeInput
											onChange={(date) => setInputData({ ...inputData, end_time: date })}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Appear Offline</label>
							</div>
							<div className="col-span-7">
								<Switch
									value={inputData.appear_offline}
									checked={inputData.appear_offline}
									onChange={(checked) => setInputData({ ...inputData, appear_offline: checked })}
									onColor="#00BF2C"
									handleDiameter={22}
									checkedIcon={false}
									uncheckedIcon={false}
								/>
								<div className="remark">
									Toggle this if you need to indicate to the audience that your agents are temporary
									away.
								</div>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label className="block">Allow Public Chat on Offline</label>
							</div>
							<div className="col-span-7">
								<Switch
									value={inputData.allow_offline_chat}
									checked={inputData.allow_offline_chat}
									onChange={(checked) => setInputData({ ...inputData, allow_offline_chat: checked })}
									onColor="#00BF2C"
									handleDiameter={22}
									checkedIcon={false}
									uncheckedIcon={false}
								/>
								<div className="remark">
									Determines if your visitors can continue to chat while your agents are offline.
								</div>
							</div>
						</div>
					</form>

					<div className="flex items-center justify-end">
						<button type="button" className="btn-save ml-5" onClick={submitUpdateChatSettings}>
							Save Changes
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ChatSettingsContainer);

import * as types from "./types";

const initialState = {
	showEditAddressModal: false,
	viewingAddress: null,
	showConfirmDeleteAddressModal: false,
	addressModalOpenFrom: null,
};

const AddressReducer = (state = initialState, action) => {
	switch (action.type) {
		// SHOW EDIT ADDRESS MODAL
		case types.OPEN_EDIT_ADDRESS_MODAL:
			return {
				...state,
				showEditAddressModal: true,
			};
		case types.CLOSE_EDIT_ADDRESS_MODAL:
			return {
				...state,
				showEditAddressModal: false,
			};

		// Viewing address id
		case types.SET_VIEWING_ADDRESS:
			return {
				...state,
				viewingAddress: action.params.address,
			};

		// Show confirm delete address modal
		case types.OPEN_CONFIRM_DELETE_ADDRESS_MODAL:
			return {
				...state,
				showConfirmDeleteAddressModal: true,
			};
		case types.CLOSE_CONFIRM_DELETE_ADDRESS_MODAL:
			return {
				...state,
				showConfirmDeleteAddressModal: false,
			};

		// set address modal open from 
		case types.SET_ADDRESS_MODAL_OPEN_FROM:
			return {
				...state,
				addressModalOpenFrom: action.params.from,
			};
		default:
			return state;
	}
};

export default AddressReducer;

import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import SendIcon from "@material-ui/icons/Send";
import IconUpvote from "app/assets/icon-upvote.svg";
import IconUpvoteNone from "app/assets/icon-upvote-none.svg";
import AssetQnAAnswerArrow from "app/assets/qna-answer-arrow.svg";
import { useSelector, useDispatch } from "react-redux";
import ChannelApi from "app/apis/channel";
import LocalStorageService from "app/services/localStorageService";
import { channelSocket } from "app/services/socketService";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import { commonActions } from "app/states/common";
import TextareaAutosize from 'react-textarea-autosize';
import { urlify } from "app/helper/common";

const QnaSenderComponent = ({ questionId, avatar, name, message, upvote, hasUpvoted }) => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const channelSessionId = useSelector(({ channel }) => channel.channelSessionId);

	const [upvoteCount, setUpvoteCount] = useState(upvote);
	const [voteCasted, setVoteCasted] = useState(hasUpvoted || false);

	// Check if user has upvoted the question,
	// If has upvoted, set next action to unlike (0)
	// If has not upvoted, set next action to like (1)
	let nextAction = voteCasted ? 0 : 1;

	const handleUpvote = () => {
		if (voteCasted) {
			postLikeQuestion(nextAction);
			setVoteCasted(false);
			return;
		}

		postLikeQuestion(nextAction);
		setVoteCasted(true);
	};

	const postLikeQuestion = (like) => {
		ChannelApi.postLikeChannelSessionQuestion(channelSessionId, questionId, like)
			.then((res) => {
				const { total_like } = res;
				setUpvoteCount(total_like);
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<div id={`question-${questionId}`} className="sender grid grid-cols-10 gap-1 mt-3 mb-3">
			<div className="col-span-1 avatar">
				<img src={avatar || IconDefaultProfile} alt={name} className="avatar-image" />
			</div>
			<div className="col-span-9 message-body">
				<div className="person-name mb-1">{name}</div>
				<div className="message-text" dangerouslySetInnerHTML={{ __html: urlify(message) }} />
				<span className="upvote" onClick={handleUpvote}>
					<img src={upvoteCount > 0 ? IconUpvote : IconUpvoteNone} alt="upvote count" className="inline" />
					<span className="text-count">{upvoteCount}</span>
				</span>
			</div>
		</div>
	);
};

const AnswerComponent = ({ name, message }) => {
	return (
		<div className="answer grid grid-cols-12 mt-2">
			<div className="col-span-2"></div>
			<div className="col-span-1">
				<img src={AssetQnAAnswerArrow} alt={"Answer arrow"} />
			</div>
			<div className="col-span-9 message-body">
				<div className="person-name mb-1">{name}</div>
				<div className="message-text" dangerouslySetInnerHTML={{ __html: urlify(message) }} />
			</div>
		</div>
	);
};

const QnAContainer = () => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	
	const channelSessionId = useSelector(({ channel }) => channel.channelSessionId);
	const channelSession = useSelector(({ channel }) => channel.channelSession);

	const [inputMessage, setInputMessage] = useState("");
	const [qnaContents, setQnaContents] = useState([]);
	const [disableInputFlag, setDisableInputFlag] = useState(channelSession ? false : true);
	
	const messageScrollViewRef = useRef();
	const questionsContainerRef = useRef();
	const replyContainerRef = useRef();

	const userProfile = LocalStorageService.getUserProfile();

	// Handle qna socket
	useEffect(() => {
		channelSocket.on("session-update", (message) => {
			// do something with incoming message from backend
			// console.log("channelSocket: ", message);

			const { action_code, data } = message;

			// New question on Q&A
			if (action_code === 200) {
				const { question } = data;

				const newQnaContent = {
					...question,
					social: {
						liked: 0,
						total_like: 0,
					},
					answers: [],
				};
				
				setQnaContents(prevStates => [...prevStates, newQnaContent]);
			}

			// New answer on Q&A
			if (action_code === 201) {
				const { answer } = data;
				const { question_id } = answer;

				setQnaContents((prevStates) => {
					let qnaContentsCopy = prevStates;
					const updatedQnaContents = qnaContentsCopy.map((qnaContent) => {
						const { id } = qnaContent;
	
						if (question_id === id) {
							qnaContent.answers = [...qnaContent.answers, answer];
						}
	
						return qnaContent;
					});

					return updatedQnaContents;
				});
			}

			// Q&A Answer updated by admin
			if (action_code === 202) {
				const { answer } = data;
				const { id: answerId, question_id, text } = answer;

				setQnaContents((prevStates) => {
					let qnaContentsCopy = prevStates;
					const updatedQnaContents = qnaContentsCopy.map((qnaContent) => {
						const { id } = qnaContent;
	
						if (question_id === id) {
							qnaContent.answers.forEach((answer) => {
								if (answer.id === answerId) {
									answer.text = text;
								}
							})
						}
	
						return qnaContent;
					});

					return updatedQnaContents;
				});
			}

			// Q&A Answer deleted by admin
			if (action_code === 203) {
				const { answer } = data;
				const { id: answerId } = answer;

				setQnaContents((prevStates) => {
					let qnaContentsCopy = prevStates;
					const updatedQnaContents = qnaContentsCopy.map((qnaContent) => {
						// Find the answer id and filter out
						const answersLeft = qnaContent.answers.filter((answer) => {
							return answer.id !== answerId;
						});
	
						qnaContent.answers = answersLeft;
	
						return qnaContent;
					});

					return updatedQnaContents;
				});
			}

			// Q&A Question updated by admin
			if (action_code === 204) {
				const { question } = data;
				const { id: questionId, text } = question;

				setQnaContents((prevStates) => {
					let qnaContentsCopy = prevStates;
					const updatedQnaContents = qnaContentsCopy.map((qnaContent) => {
						const { id } = qnaContent;
	
						if (questionId === id) {
							qnaContent.text = text;
						}
	
						return qnaContent;
					});

					return updatedQnaContents;
				});
			}

			// Q&A Question deleted
			if (action_code === 205) {
				const { question } = data;
				const { id: questionId } = question;

				setQnaContents((prevStates) => {
					let qnaContentsCopy = prevStates;
					const questionsLeft = qnaContentsCopy.filter((qnaContent) => {
						return questionId !== qnaContent.id;
					});

					return questionsLeft;
				});
			}
		});

		// return () => {
		// 	// turning of socket listner on unmount
		// 	channelSocket.off("session-update");
		// };
	}, []);

	// Scroll to bottom after loaded qna listing
	useEffect(() => {
		scrollToBottom();
	}, [qnaContents]);

	// Get channel session's qna
	useEffect(() => {
		if (!channelSessionId) return;

		setQnaContents([]);

		ChannelApi.getChannelSessionQnA(channelSessionId)
			.then((res) => {
				const { qnas } = res;
				setQnaContents(qnas);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [channelSessionId]);

	// Disable input based on channel session
	useEffect(() => {
		if (channelSession) {
			setDisableInputFlag(false);
		}
		else {
			setDisableInputFlag(true);
		}
	}, [channelSession]);

	const onChangeMessage = (event) => {
		setInputMessage(event.target.value);
	};

	const onMessageKeyDown = (event) => {
		if (event.keyCode === 13 && event.shiftKey === false) {
			event.preventDefault();
			handleSendMessage();
		}
	};

	const handleSendMessage = () => {
		// Prevent user send empty message
		if (inputMessage === "" || !channelSessionId) {
			return;
		}

		let formattedInputMessage = inputMessage.replace(/(?:\r\n|\r|\n)/g, '<br>');

		ChannelApi.postChannelSessionQuestion(channelSessionId, formattedInputMessage)
			.then((res) => {
				setInputMessage("");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const scrollToBottom = () => {
		messageScrollViewRef.current.scrollTo(0, messageScrollViewRef.current.scrollHeight);
	};

	const onTextAreaHeightChange = (rowHeight) => {
		if (!questionsContainerRef || !replyContainerRef.current) return;

		let textareaHeight = rowHeight;
		let replyContainerHeight = textareaHeight + 12;
		let offset = 4;
		
		questionsContainerRef.current.style.height = `calc(100% - ${replyContainerHeight + offset}px)`;
		replyContainerRef.current.style.height = `calc(${replyContainerHeight}px)`;
	}

	return (
		<div className="qna-container">
			<div className={`questions`} ref={questionsContainerRef}>
				<div className="qna-contents px-5 pt-3 overflow-y-auto" ref={messageScrollViewRef}>

					{qnaContents.map((qnaContent) => {
						const { answers } = qnaContent;
						const {
							id,
							user_name,
							user_thumbnail_url,
							text,
							social: { liked, total_like },
						} = qnaContent;

						return (
							<div key={`question-${id}`} className="qna-wrapper">
								<QnaSenderComponent
									questionId={id}
									avatar={user_thumbnail_url}
									name={user_name}
									message={`Q: ${text}`}
									upvote={total_like}
									hasUpvoted={liked}
								/>

								{answers.map((answer) => {
									const { id, user_name, text } = answer;

									return <AnswerComponent key={`answer-${id}`} name={user_name} message={text} />;
								})}
							</div>
						);
					})}
				</div>
			</div>

			<div className="reply-container px-5 py-2" ref={replyContainerRef}>
				<div className="reply">
					<div className="avatar">
						<img src={userProfile.profile_image_url || IconDefaultProfile} alt={"John Press"} className="avatar-image mr-2" />
					</div>
					<div className="reply-main">
						<TextareaAutosize
							maxRows={3}
							minRows={1}
							onHeightChange={(rowHeight) => onTextAreaHeightChange(rowHeight)}
							value={inputMessage}
							placeholder="Type your message here..."
							onKeyDown={onMessageKeyDown}
							onChange={onChangeMessage}
							disabled={disableInputFlag}
							/>
						<div className="reply-send" onClick={handleSendMessage}>
							<SendIcon />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(QnAContainer);

import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import { commonActions } from "app/states/common";
import { exhibitorActions } from "app/states/exhibitor";
import { useDispatch, useSelector } from "react-redux";
import './style.scss';
import IconJoinPrivateChat from "app/assets/Channels_Exhibitors/join_private_chat.png";
import ExhibitorAPI from 'app/apis/exhibitor';
import sessionStorageService from 'app/services/sessionStorageService';

const JoinPrivateChatModal = ({ history }) => {

    const dispatch = useDispatch();
    const closeJoinPrivateChatModal = () => dispatch(commonActions.closeJoinPrivateChatModal());
    const setIsPrivateChat = (isPrivateChat) => dispatch(commonActions.setIsPrivateChat(isPrivateChat));
    const setExhibitorDialogId = (dialogId) => dispatch(exhibitorActions.setExhibitorDialogId(dialogId));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setPrivateChatJoinedTimestamp = (timestamp) => dispatch(exhibitorActions.setPrivateChatJoinedTimestamp(timestamp));

    const showModal = useSelector(({ common }) => common.showJoinPrivateChatModal);
    const exhibitorRoomId = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomId);
    const exhibitorChatId = useSelector(({ exhibitor }) => exhibitor.exhibitorChatId);
    const exhibitorRoomDetails = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomDetails);

    const [roomName, setRoomName] = useState("");

    useEffect(() => {
        if (exhibitorRoomDetails) {
            setRoomName(exhibitorRoomDetails.account.name);
        }
    }, [exhibitorRoomDetails]);

    const handleClose = () => {
        // If user click outside to dismiss modal, we assume user reject the chat invitation
        handleNo();
        closeJoinPrivateChatModal();
    }

    const handleNo = () => {
        // To allow private chat invitation again
        sessionStorageService.removeHasPcInvitation();

        ExhibitorAPI.postRejectChatInvitation(exhibitorRoomId, exhibitorChatId)
            .then(response => {
                closeJoinPrivateChatModal();
            })
            .catch(error => {
                console.error("Reject chat error: ", error);
                openAlertSnackbar(error.data.message, "error");
            })
    }

    const handleYes =() => {
        // Accept to join private chat
        ExhibitorAPI.postAcceptChatInvitation(exhibitorRoomId, exhibitorChatId)
            .then(response => {
                const { dialog_id, joined_at } = response;

                setIsPrivateChat(true);
                setPrivateChatJoinedTimestamp(joined_at);
                setExhibitorDialogId(dialog_id);
                
                closeJoinPrivateChatModal();
            })
            .catch(error => {
                console.error("Join chat error: ", error);
                openAlertSnackbar(error.data.message, "error");
            })
    }

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="join-private-chat-modal"
        >
            <div className="app-modal join-private-chat-modal">
                <div className="icon-private-chat-container flex items-center">
                    <img src={IconJoinPrivateChat} alt="private chat" className="icon-private-chat" />
                </div>
                <div className="modal-content">
                    <h1 className="modal-title mb-3 mt-12">Join our private chat</h1>
                    <p className="mb-10">You've been invited to join a private chat with <strong>{roomName}</strong></p>

                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-6">
                            <button className="btn btn-primary-outline" onClick={handleNo}>Decline</button>
                        </div>
                        <div className="col-span-6">
                            <button className="btn btn-primary" onClick={handleYes}>Accept</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default withRouter(JoinPrivateChatModal);
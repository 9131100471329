import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { authenticationActions } from "app/states/authentication";
import EventInfoContainer from "../EventInfoContainer/EventInfoContainer";
import { eventActions } from "app/states/event";
import { CURRENCY_SIGN } from "app/config/settings";

const TicketSummaryModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeTicketSummaryModal = () => dispatch(authenticationActions.closeTicketSummaryModal());
	const openEventRegistrationModal = () => dispatch(authenticationActions.openEventRegistrationModal());
	const setIsCheckoutEventTicket = (isCheckoutEventTicket) =>
		dispatch(eventActions.setIsCheckoutEventTicket(isCheckoutEventTicket));

	const showModal = useSelector(({ auth }) => auth.showTicketSummaryModal);
	const eventSettings = useSelector(({ event }) => event.eventSettings);

	const [ticketName, setTicketName] = useState(null);
	const [ticketDesc, setTicketDesc] = useState(null);
	const [ticketPrice, setTicketPrice] = useState(0);
	const [currency, setCurrency] = useState("USD");

	// Get event ticket price
	useEffect(() => {
		if (eventSettings && eventSettings.entry_tickets && eventSettings.entry_tickets.length > 0) {
			setTicketName(eventSettings.entry_tickets[0].name);
			setTicketDesc(eventSettings.entry_tickets[0].description);
			setTicketPrice(eventSettings.entry_tickets[0].price);
			setCurrency(eventSettings.entry_tickets[0].currency);
		}
	}, [eventSettings]);

	const handleClose = () => {
		setIsCheckoutEventTicket(false);
		closeTicketSummaryModal();
	};

	const handleNext = () => {
		setIsCheckoutEventTicket(true);
		closeTicketSummaryModal();
		openEventRegistrationModal();
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="ticket-summary-modal">
			<div className="app-modal mobile-app-modal ticket-summary-modal">
				<div className="modal-content">
					<div className="grid grid-cols-12 gap-5 h-full">
						<div className="hidden lg:block col-span-6 px-10 h-full">
							<EventInfoContainer />
						</div>
						<div className="col-span-12 lg:col-span-6 h-full">
							<div className="ticket-summary-container flex flex-col pt-10 lg:pt-6 lg:justify-center h-full">
								<div className="title mb-1">Ticket Summary</div>
								<div className="subtitle mb-10 lg:mb-6">A ticket is required to join this event.</div>

								<div className="digital-ticket mb-6">
									<div className="top-section grid grid-cols-12 gap-3">
										<div className="col-span-7 lg:col-span-8">
											<label className="ticket-label mb-2">{ticketName}</label>
											<div className="ticket-desc">{ticketDesc}</div>
										</div>
										<div className="col-span-5 lg:col-span-4 text-right ticket-price-wrapper">
											<span className="ticket-price">
												{CURRENCY_SIGN[currency]} {ticketPrice}
											</span>{" "}
											/ ticket
										</div>
									</div>
									<hr />
									<div className="bottom-section grid grid-cols-12 gap-3">
										<div className="col-span-7 lg:col-span-9">
											<label className="ticket-label">Payable Amount</label>
										</div>
										<div className="col-span-5 lg:col-span-3 text-right">
											<span className="ticket-price">
												{CURRENCY_SIGN[currency]} {ticketPrice}
											</span>
										</div>
									</div>
								</div>

								<div className="button-wrapper lg:flex lg:justify-end lg:items-center">
									<button type="button" className="btn-primary btn-next" onClick={handleNext}>
										Next
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(TicketSummaryModal);

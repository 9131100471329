/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withRouter, useLocation } from "react-router";
import EventAPI from "app/apis/event";
import TokenService from "app/services/tokenService";
import axios from "axios";
import useQueryParams from "app/views/hooks/useQueryParams";
import PublicRoutes from "./public";
import PrivateRoutes from "./private";
import LocalStorageService from "app/services/localStorageService";
import { useDispatch, useSelector } from "react-redux";
import { authenticationActions } from "app/states/authentication";
import ReactLoading from "react-loading";
import { eventActions } from "app/states/event";
import ReactPixel from "react-facebook-pixel";
import { FB_PIXEL_ID } from "app/config/config";

// Initialize Facebook Pixel
const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	debug: false, // enable logs
};
ReactPixel.init(FB_PIXEL_ID, advancedMatching, options);

const Routes = ({ history, location }) => {
	const { pathname } = useLocation();
	const queryParams = useQueryParams();

	const dispatch = useDispatch();
	const setUserProfile = (userProfile) => dispatch(authenticationActions.setUserProfile(userProfile));
	const setIsLoggedIn = (isLogin) => dispatch(authenticationActions.setIsLoggedIn(isLogin));
	const setEventSettings = (eventSettings) => dispatch(eventActions.setEventSettings(eventSettings));

	const isLoggedin = useSelector(({ auth }) => auth.isLoggedin);
	const showLoading = useSelector(({ common }) => common.showLoading);

	const [publicRoutes, setPublicRoutes] = useState([]);
	const [privateRoutes, setPrivateRoutes] = useState([]);

	const [currentEventCode, setCurrentEventCode] = useState("ebizstart");
	const [doneCheckEventCode, setDoneCheckEventCode] = useState(false);
	const [userEventRole, setUserEventRole] = useState(LocalStorageService.getUserEventRole());

	// Check event code
	useEffect(() => {
		checkEventCode();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Render public and private routes
	useEffect(() => {
		if (doneCheckEventCode) {
			renderPublicRoutes();
			renderPrivateRoutes();
		}
	}, [doneCheckEventCode]);

	// Scroll to top on change route
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	// Get user info by event id, to check whether user has joined the event
	// If not join, redirect to landing page
	useEffect(() => {
		// Get event code
		let pathNamePieces = location.pathname.split("/");
		let eventCode = pathNamePieces[2]; // take the third piece

		if (isLoggedin && eventCode) {
			// Add access token to axios after logged in
			const accessToken = TokenService.getAccessToken();
			axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

			EventAPI.getUserInfoByEventId()
				.then((response) => {
					// do nothing if joined
					const { user } = response;

					// Cache user event role data
					const userEventRoleData = {
						roles: user.roles,
						agent: user.agent,
					};
					LocalStorageService.setUserEventRole(eventCode, userEventRoleData);
					setUserEventRole(userEventRoleData);
				})
				.catch((error) => {
					console.error(error);
					history.replace(`/events/${eventCode}`);
				});
		}
	}, [isLoggedin, pathname]);

	// Redirect visitor to landing page if event is unpublished/upcoming/ended/paused
	useEffect(() => {
		// Get event code
		let pathNamePieces = location.pathname.split("/");
		let eventCode = pathNamePieces[2]; // take the third piece

		// If is visitor, check event status to ensure event is ongoing to allow visitor to access the event
		if (userEventRole && userEventRole.roles.includes("visitor")) {
			EventAPI.getEventSetting(eventCode)
				.then((response) => {
					const { status } = response.event;

					if (status === 0 || status === 2 || status === 3 || status === 4) {
						history.replace(`/events/${eventCode}`);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [userEventRole, pathname]);

	// FB Pixel track page view for event "amalaysiancoffeeproject"
	useEffect(() => {
		// Get event code
		let pathNamePieces = location.pathname.split("/");
		let eventCode = pathNamePieces[2]; // take the third piece

		// Track page view
		if (eventCode === "amalaysiancoffeeproject") {
			ReactPixel.pageView();
		}
	}, [pathname]);

	const checkEventCode = () => {
		// Sample pathname:  /events/ebizstart/login
		let pathNamePieces = location.pathname.split("/");
		let eventCode = pathNamePieces[2]; // take the third piece

		getEventSettings(eventCode);
	};

	const getEventSettings = (event_code = "ebizstart") => {
		const previewToken = queryParams.get("preview_token");
		let requestHeaders = {};
		if (previewToken) {
			requestHeaders["preview-token"] = previewToken;
		}

		EventAPI.getEventSetting(event_code, requestHeaders)
			.then((response) => {
				setCurrentEventCode(event_code);
				setDoneCheckEventCode(true);

				const { event, web_config } = response;
				const { theme } = event;

				LocalStorageService.setEventIdByEventCode(event_code, event.id);
				LocalStorageService.setEventSetting(event_code, JSON.stringify(event));
				LocalStorageService.setWebConfig(event_code, JSON.stringify(web_config));
				setEventSettings(event);

				changeThemeColor(theme);
			})
			.catch((error) => {
				// If no such event code, use default ebizstart
				window.location.href = "https://boom.rocks/";
			});
	};

	const changeThemeColor = (theme) => {
		if (!theme) return;

		const root = document.documentElement;

		if (theme.background_color) {
			root.style.setProperty("--bg-landing-page", `#${theme.background_color}`);
		}
		if (theme.font_color1) {
			root.style.setProperty("--font-color1", `#${theme.font_color1}`);
		}
		if (theme.font_color2) {
			root.style.setProperty("--font-color2", `#${theme.font_color2}`);
		}
		if (theme.font_color3) {
			root.style.setProperty("--font-color3", `#${theme.font_color3}`);
		}
	};

	const checkIsAuthenticated = (RouteComponent) => {
		const accessToken = TokenService.getAccessToken();

		// Add authorization bearer token to axios
		axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

		if (!accessToken) {
			setUserProfile(null);
			setIsLoggedIn(false);
			TokenService.clearTokens();
			LocalStorageService.clearAll();
			return <Redirect to={`/events/${currentEventCode}`} />;
		}

		return <RouteComponent />;
	};

	const renderPublicRoutes = () => {
		let rootRoutes = Array.prototype.concat(PublicRoutes);

		// Add authorization bearer token to axios
		const accessToken = TokenService.getAccessToken();
		if (accessToken) {
			axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
		}

		const publicRoutes = rootRoutes.map((route, index) => (
			<Route key={index} exact={route.exact} path={route.path} component={route.component} />
		));

		setPublicRoutes(publicRoutes);
	};

	const renderPrivateRoutes = () => {
		let rootRoutes = Array.prototype.concat(PrivateRoutes);

		const privateRoutes = rootRoutes.map((route, index) => (
			<Route
				key={index}
				exact={route.exact}
				path={route.path}
				render={() => checkIsAuthenticated(route.component)}
			/>
		));

		setPrivateRoutes(privateRoutes);
	};

	return (
		<Fragment>
			<Switch>
				{publicRoutes}
				{privateRoutes}
			</Switch>

			{showLoading && (
				<div className="app-loading-overlay">
					<ReactLoading type={"spinningBubbles"} color="grey" />
					<p className="mt-3">Loading</p>
				</div>
			)}
		</Fragment>
	);
};

export default withRouter(Routes);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { exhibitorActions } from "app/states/exhibitor";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import IconStore from "app/assets/Channels_Exhibitors/store_active.png";
import IconAddProduct from "app/assets/Channels_Exhibitors/icon_add_product.png";
import IconClose from "app/assets/icon_close.svg";
import IconPlus from "app/assets/Channels_Exhibitors/plus.png";
import IconMinus from "app/assets/Channels_Exhibitors/minus.png";
import "./style.scss";
import Slider from "react-slick";
import ImageNext from "app/assets/Channels_Exhibitors/image_next.png";
import ImagePrevious from "app/assets/Channels_Exhibitors/image_previous.png";
import { PAYMENT_SETTINGS } from "app/config/settings";
import IconCoin from "app/assets/coin.png";
import OrderAPI from "app/apis/order";
import ExhibitorAPI from "app/apis/exhibitor";

const StoreProductContainer = ({ history }) => {
	const { roomId } = useParams();

	const dispatch = useDispatch();
	const setStoreProducts = (products) => dispatch(exhibitorActions.setStoreProducts(products));
	const setExhibitorStoreSettings = (storeSettings) =>
		dispatch(exhibitorActions.setExhibitorStoreSettings(storeSettings));
	const closeExhibitorStoreModal = () => dispatch(exhibitorActions.closeExhibitorStoreModal());
	const openJoinPrivateChatModal = () => dispatch(commonActions.openJoinPrivateChatModal());
	const openProductOrderSummaryModal = () => dispatch(exhibitorActions.openProductOrderSummaryModal());

	const exhibitorRoomId = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomId);
	const exhibitorStoreId = useSelector(({ exhibitor }) => exhibitor.exhibitorStoreId);

	const storeProducts = useSelector(({ exhibitor }) => exhibitor.storeProducts);
	const exhibitorRoomDetails = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomDetails);
	const hasPendingPrivateChatInvitation = useSelector(({ exhibitor }) => exhibitor.hasPendingPrivateChatInvitation);
	const eventSettings = useSelector(({ event }) => event.eventSettings);
	const refreshMyCartCount = useSelector(({ order }) => order.refreshMyCartCount);

	const [shoppingCart, setShoppingCart] = useState(null);

	const handleClose = () => {
		if (hasPendingPrivateChatInvitation) {
			openJoinPrivateChatModal();
		}

		closeExhibitorStoreModal();
	};

	const handlePlaceOrder = () => {
		openProductOrderSummaryModal();
		closeExhibitorStoreModal();
	};

	// Get store products if does not have
	useEffect(() => {
		if (!exhibitorStoreId) {
			return;
		}

		if (storeProducts.length === 0) {
			ExhibitorAPI.getExhibitorRoomStoreProducts(exhibitorRoomId, exhibitorStoreId)
				.then((response) => {
					const { products } = response;

					setStoreProducts(products);
					setExhibitorStoreSettings(response);
				})
				.catch((error) => {
					console.error("Store products error: ", error);
				});
		}
	}, [exhibitorStoreId]);

	// Get the user's saved order
	useEffect(() => {
		OrderAPI.getSavedOrderByExhibitorRoom(roomId)
			.then((response) => {
				setShoppingCart(response);
			})
			.catch((error) => {
				if (error.status === 404) {
					// Clear shopping cart when there is no saved order
					setShoppingCart(null);
				}

				console.error(error);
			});
	}, [refreshMyCartCount]);

	return (
		<div className="store-product-container">
			<section className="store-body">
				<div className="flex justify-between md:p-5">
					<h1 className="modal-title">
						<img src={IconStore} alt="store" className="icon-store" />
						{exhibitorRoomDetails && exhibitorRoomDetails.title}
					</h1>
					<span className="btn-close">
						<img src={IconClose} alt="Close" onClick={handleClose} />
					</span>
				</div>
				<div className="modal-content md:px-5">
					<div className="grid grid-cols-12 gap-3">
						{storeProducts.map((product, index) => {
							const { id, name, currency, price, image_url, variants, external_product_url } = product;
							let quantity = 0;

							if (shoppingCart && shoppingCart.products.length > 0) {
								const cartProduct = shoppingCart.products.find((item) => {
									return item.id === id;
								});
								quantity = cartProduct ? cartProduct.quantity : 0;
							}

							return (
								<Product
									key={`${name}-${index}`}
									productId={id}
									title={name}
									price={`${currency} ${price}`}
									description={"Description"}
									images={[image_url]}
									variants={variants}
									quantity={quantity}
									external_product_url={external_product_url}
								/>
							);
						})}
					</div>
				</div>
			</section>

			<section className="store-footer">
				<div>
					{eventSettings.payment_settings.includes(PAYMENT_SETTINGS["credit"]) && (
						<>
							<div className="text-my-balance">My balance</div>
							<span className="flex items-center">
								<img src={IconCoin} alt="coin" className="icon-coin" />
								<span className="text-credit-amount">950</span>
							</span>
						</>
					)}
				</div>

				{!eventSettings.payment_settings.includes(PAYMENT_SETTINGS["ecommerce"]) && (
					<div className="flex items-center">
						{shoppingCart && (
							<div className="order-summary">
								<span className="mr-2">Order Total ({shoppingCart.products.length} items):</span>
								{eventSettings.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
									<span className="flex items-center">
										<img src={IconCoin} alt="coin" className="icon-coin" />
										<span className="text-credit-amount">{shoppingCart.total_price}</span>
									</span>
								) : (
									<strong className="total-price">
										{shoppingCart.currency} {shoppingCart.total_price}
									</strong>
								)}
							</div>
						)}
						<button
							type="button"
							className={`btn-place-order ${
								!(shoppingCart && shoppingCart.products.length > 0) ? "btn-disabled" : ""
							}`}
							onClick={handlePlaceOrder}
							disabled={!(shoppingCart && shoppingCart.products.length > 0)}>
							Place Order
						</button>
					</div>
				)}
			</section>
		</div>
	);
};

/**
 * Product component
 */
const Product = ({ productId, images, title, description, price, variants, quantity, external_product_url }) => {
	const dispatch = useDispatch();
	const openProductImageModal = (productId) => dispatch(exhibitorActions.openProductDetailModal(productId));

	const eventSettings = useSelector(({ event }) => event.eventSettings);

	const handleGoToProductDetails = () => {
		if (eventSettings.payment_settings.includes(PAYMENT_SETTINGS["ecommerce"]) && external_product_url) {
			window.open(external_product_url, "_blank");
		} else {
			openProductImageModal(productId);
		}
	};

	const PrevArrow = (props) => {
		const { className, style, onClick } = props;
		return (
			<div className={`${className} btn-next-previous`} style={{ ...style }} onClick={onClick}>
				<img src={ImagePrevious} alt="previous" />
			</div>
		);
	};

	const NextArrow = (props) => {
		const { className, style, onClick } = props;
		return (
			<div className={`${className} btn-next-previous`} style={{ ...style }} onClick={onClick}>
				<img src={ImageNext} alt="next" />
			</div>
		);
	};

	return (
		<div className="store-product col-span-12 md:col-span-6">
			<div className="grid grid-cols-12 gap-3">
				<div className="col-span-3">
					<Slider
						className="product-image-carousel"
						dots={false}
						slidesToShow={1}
						slidesToScroll={1}
						nextArrow={<NextArrow />}
						prevArrow={<PrevArrow />}>
						{images.map((image, index) => {
							return (
								<div key={index}>
									<img
										src={image}
										alt="product"
										className="cursor-pointer"
										onClick={handleGoToProductDetails}
									/>
								</div>
							);
						})}
					</Slider>
				</div>
				<div className="col-span-7">
					<div className="product-title">{title}</div>
					<div className="product-price">
						{eventSettings.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
							<span className="flex items-center">
								<img src={IconCoin} alt="coin" className="icon-coin" />
								<span className="text-credit-amount">{price}</span>
							</span>
						) : (
							price
						)}
					</div>
					<div className="product-desc">{description}</div>
					<div className="flex items-center product-variant flex-wrap">
						{variants.sizes.length > 0 && (
							<div className="variant-option mb-1">
								Size: <span className="size-value">{variants.sizes.join(", ")}</span>
							</div>
						)}
						{variants.colors.length > 0 && (
							<div className="variant-option mb-1">
								Color: <span className="color-value">{variants.colors.join(", ")}</span>
							</div>
						)}
						{variants.options.length > 0 && (
							<div className="variant-option mb-1">
								Option: <span className="option-value">{variants.options.join(", ")}</span>
							</div>
						)}
					</div>
				</div>

				{/* Only show quantity control if not ecommerce mode */}
				{!eventSettings.payment_settings.includes(PAYMENT_SETTINGS["ecommerce"]) && (
					<div className="col-span-2 flex justify-center items-center">
						{quantity > 0 ? (
							<div className="product-count-control flex items-center">
								<img
									src={IconMinus}
									alt="minus"
									className="inline cursor-pointer"
									onClick={handleGoToProductDetails}
								/>
								<span className="text-count">{quantity}</span>
								<img
									src={IconPlus}
									alt="plus"
									className="inline cursor-pointer"
									onClick={handleGoToProductDetails}
								/>
							</div>
						) : (
							<img
								src={IconAddProduct}
								alt="add product"
								className="btn-add-product"
								onClick={handleGoToProductDetails}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default withRouter(StoreProductContainer);

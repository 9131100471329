/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import IconBriefcase from "app/assets/icon-briefcase.svg";
import IconFavourite from "app/assets/Channels_Exhibitors/icon-favourite.svg";
import IconFavouriteFilled from "app/assets/Channels_Exhibitors/icon-favourite-filled.svg";
import "./style.scss";
import ReactLoading from "react-loading";
import ChannelApi from "app/apis/channel";
import { convertYoutubeToEmbedLink, convertFacebookToEmbedLink } from "app/helper/convertEmbedLink";
import ReactPlayer from "react-player";
import moment from "moment";
import { ACTIVITY_CODE, MEDIA_TYPES } from "app/config/settings";
import { Icon } from "@iconify/react";
import live24Regular from "@iconify-icons/fluent/live-24-regular";
import EventAPI from "app/apis/event";
import { channelSocket } from "app/services/socketService";
import { useDispatch, useSelector } from "react-redux";
import { channelActions } from "app/states/channel";
import LocalStorageService from "app/services/localStorageService";
import { commonActions } from "app/states/common";

const ROUTE_NEXT_SESSION_TIMEOUT = 15 * 60 * 1000; // 15 minutes

const ChannelVideo = () => {
	const eventSetting = LocalStorageService.getEventSetting();

	const dispatch = useDispatch();
	const setChannelSessionId = (channelSessionId) => dispatch(channelActions.setChannelSessionId(channelSessionId));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setRefreshChannelList = (isRefresh) => dispatch(channelActions.setRefreshChannelList(isRefresh));

	const channelSessionId = useSelector(({ channel }) => channel.channelSessionId);
	const channelSession = useSelector(({ channel }) => channel.channelSession);

	const [contentUrl, setContentUrl] = useState("");
	const [mediaType, setMediaType] = useState(MEDIA_TYPES["video"]);
	const [liveStreamStatusText, setLiveStreamStatusText] = useState("");
	const [isRecorded, setIsRecorded] = useState(false);
	const [isLive, setIsLive] = useState(false);
	const [liveEnded, setLiveEnded] = useState(false);
	const [isNext, setIsNext] = useState(false);

	const [liked, setLiked] = useState(0);
	const [totalLikes, setTotalLikes] = useState(0);
	const [date, setDate] = useState("");
	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");

	const [nextSession, setNextSession] = useState(null);

	useEffect(() => {
		channelSocket.on("session-update", (message) => {
			// do something with incoming message from backend
			// console.log("channelSocket: ", message);

			const { action_code, data } = message;

			// Live stream toggled
			if (action_code === 500) {
				const { session_id, is_live, live_ended, live_video_url, live_type, thumbnail_url, next_session } =
					data;

				setRefreshChannelList(true);

				// Live stream started
				if (is_live && !live_ended) {
					let contentUrl = getEmbeddedLink(live_type, live_video_url);

					setIsLive(true);
					setMediaType(MEDIA_TYPES["video"]);
					setContentUrl(contentUrl);
					setLiveStreamStatusText("");
					setLiveEnded(false);
					setNextSession(null);
					setIsNext(false);
					setIsRecorded(false);
				}
				// Live stream ended
				else {
					setIsLive(false);
					setMediaType(MEDIA_TYPES["image"]);
					setContentUrl(thumbnail_url);
					setLiveEnded(true);
					setNextSession(next_session);
				}
			}
		});
	}, []);

	useEffect(() => {
		let nextSessionTimeout;

		// When live stream ended, auto route user to next session in 15mins
		if (nextSession) {
			nextSessionTimeout = setTimeout(() => {
				const { id } = nextSession;
				setChannelSessionId(id);
			}, ROUTE_NEXT_SESSION_TIMEOUT);
		}
		else {
			clearTimeout(nextSessionTimeout);
		}

		return () => {
			clearTimeout(nextSessionTimeout);
		}
	}, [nextSession]);

	useEffect(() => {
		if (!channelSession) return;

		// Reset state when change channel session
		setContentUrl("");
		setMediaType(MEDIA_TYPES["video"]);
		setLiveStreamStatusText("");
		setIsRecorded(false);
		setIsLive(false);
		setLiveEnded(false);

		// Process video link
		const { video } = channelSession;
		const {
			live_type,
			live_video_url,
			thumbnail_url,
			is_live,
			live_ended,
			start_at,
			end_at,
			recorded_type,
			recorded_video_url,
			pre_live_video_url,
		} = video;

		// Set is next
		const { is_next } = channelSession;
		setIsNext(is_next);

		// Default
		let channelSessionMediaType = MEDIA_TYPES["video"];
		let channelSessionVideoType = live_type;
		let channelSessionContentUrl = live_video_url;

		// Format start_at/end_at
		const starAtTimeOnly = moment(start_at).format("h:mmA");
		const endAtTimeOnly = moment(end_at).format("h:mmA");

		// Before Live Stream, Check if live stream starting soon within 15mins
		const diffMin = moment(start_at).diff(moment(), "minutes");

		if (is_next) {
			if (pre_live_video_url) {
				channelSessionContentUrl = pre_live_video_url;
			} else {
				channelSessionMediaType = MEDIA_TYPES["image"];
				channelSessionContentUrl = thumbnail_url;
			}
		} else if (diffMin <= 15 && !is_live && !live_ended) {
			channelSessionMediaType = MEDIA_TYPES["image"];
			channelSessionContentUrl = thumbnail_url;
			setLiveStreamStatusText(
				`<strong>LIVE STREAM WILL BEGIN SHORTLY</strong> | ${starAtTimeOnly} to ${endAtTimeOnly}`
			);
		}
		// Before Live Stream
		else if (!is_live && !live_ended) {
			channelSessionMediaType = MEDIA_TYPES["image"];
			channelSessionContentUrl = thumbnail_url;
		}
		// Live stream ongoing
		else if (is_live && !live_ended) {
			channelSessionMediaType = MEDIA_TYPES["video"];
			channelSessionVideoType = live_type;
			channelSessionContentUrl = live_video_url;
			setIsLive(true);
		}
		// Post Live Stream
		else if (!is_live && live_ended) {
			// If live stream ended, and has recorded video url, show recorded
			if (recorded_video_url) {
				channelSessionMediaType = MEDIA_TYPES["video"];
				channelSessionVideoType = recorded_type;
				channelSessionContentUrl = recorded_video_url;
				setIsRecorded(true);
			} else {
				channelSessionMediaType = MEDIA_TYPES["image"];
				channelSessionContentUrl = thumbnail_url;
			}
		}

		// If media type is video, convert link to embedded link
		if (channelSessionMediaType === MEDIA_TYPES["video"]) {
			channelSessionContentUrl = getEmbeddedLink(channelSessionVideoType, channelSessionContentUrl);
		}

		setMediaType(channelSessionMediaType);
		setContentUrl(channelSessionContentUrl);

		// Set the datetime
		// const { start_at, end_at } = video;
		const sessionDate = moment(start_at).format("Do MMM YYYY");
		const sessionStartTime = moment(start_at).format("h:mmA");
		const sessionEndTime = moment(end_at).format("h:mmA");
		setDate(sessionDate);
		setStartTime(sessionStartTime);
		setEndTime(sessionEndTime);

		// Set the liked number
		const { social } = channelSession;
		const { liked, total_like } = social;

		setLiked(liked);
		setTotalLikes(total_like);
	}, [channelSession]);

	const getEmbeddedLink = (channelSessionVideoType, channelSessionContentUrl) => {
		switch (channelSessionVideoType) {
			// youtube video
			case 2:
				const youtubeEmbedLink = convertYoutubeToEmbedLink(channelSessionContentUrl);
				channelSessionContentUrl = youtubeEmbedLink;
				break;
			// facebook video
			case 3:
				const facebookEmbedLink = convertFacebookToEmbedLink(channelSessionContentUrl);
				channelSessionContentUrl = facebookEmbedLink;
				break;
			// local upload video
			case 1:
			default:
				break;
		}

		return channelSessionContentUrl;
	};

	const handleLikeChannel = () => {
		if (!channelSession) return;

		let updatedLike = liked === 0 ? 1 : 0;

		ChannelApi.postLikeChannelSession(channelSessionId, updatedLike)
			.then((response) => {
				const { total_like } = response;
				setTotalLikes(total_like);
				setLiked(updatedLike);
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const logActivity = (code, targetId) => {
		EventAPI.postLogActivity(code, targetId)
			.then((response) => {
				// success
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handlePlayVideo = () => {
		logActivity(ACTIVITY_CODE["watch_channel_recorded"], channelSessionId);
	};

	const renderVideoPlayer = () => {
		if (!channelSession) return;

		if (mediaType === MEDIA_TYPES["image"]) {
			return <img src={contentUrl} alt="Content" className="video-thumbnail" />;
		} else {
			// If is live, log activity
			if (isLive) {
				logActivity(ACTIVITY_CODE["watch_channel_live"], channelSessionId);
			}

			return (
				<div className="video-wrapper">
					<ReactPlayer
						className="channel-video-player"
						url={contentUrl}
						controls
						playing={isLive ? true : false}
						onStart={handlePlayVideo}
					/>
				</div>
			);
		}
	};

	const renderChannelSessionStatusText = () => {
		if (liveStreamStatusText !== "") {
			return (
				<div className={"text-live-stream-soon"} dangerouslySetInnerHTML={{ __html: liveStreamStatusText }} />
			);
		} else if (isRecorded) {
			return (
				<>
					<span className="text-recorded mr-4">( Recorded Playback )</span>
					<span className="mr-4">{date}</span>
					<span>
						{startTime} to {endTime}
					</span>
				</>
			);
		} else if (isNext) {
			return (
				<>
					<span className="badge-next-up mr-4">NEXT UP</span>
					<span className="mr-4">{date}</span>
					<span>
						{startTime} to {endTime}
					</span>
				</>
			);
		} else if (isLive) {
			return (
				<>
					<span className="badge-live">
						LIVE <Icon icon={live24Regular} className="icon-live" />
					</span>
					<span className="mr-4">{date}</span>
					<span>
						{startTime} to {endTime}
					</span>
				</>
			);
		} else {
			return (
				<>
					<span className="mr-4">{date}</span>
					<span>
						{startTime} to {endTime}
					</span>
				</>
			);
		}
	};

	if (!channelSession && !channelSessionId) {
		return (
			<div className="channel-video-container flex justify-center items-center">
				{/* <ReactLoading type={"spinningBubbles"} color="lightgrey" /> */}
				<img src={eventSetting.image_url} alt="event cover" className="event-cover" />;
			</div>
		);
	}

	return (
		<>
			{channelSession ? (
				<div className="channel-video-container">
					<div className="channel-video">
						{renderVideoPlayer()}

						{/* Show the live ended overlay */}
						{liveEnded && (
							<div className="live-ended-overlay">
								<div className="live-ended-text-container">
									<span className="text-live-ended-title">Live Stream has ended. </span>
									<span className="text-live-ended-subtitle">
										Please stay tuned for the recorded video.
									</span>
								</div>
							</div>
						)}
					</div>

					<div className="channel-video-meta p-3">
						<div className="flex justify-between items-start lg:items-center mb-1">
							<div className="meta-title mr-3">{channelSession.title}</div>
							<div className="flex flex-end items-center">
								{/* <div className="meta-briefcase px-2 py-1 lg:px-2 lg:py-1 flex items-center mr-2">
									<img src={IconBriefcase} alt="briefcase" className="icon-briefcase inline pr-1" />
									Briefcase
								</div> */}
								<div
									className="meta-likes py-1 lg:px-2 lg:py-1 flex items-center"
									onClick={handleLikeChannel}>
									<img
										src={liked ? IconFavouriteFilled : IconFavourite}
										alt="likes"
										className="icon-favourite inline pr-1"
									/>
									{totalLikes} Likes
								</div>
							</div>
						</div>
						<div className="meta-datetime flex items-center">{renderChannelSessionStatusText()}</div>
					</div>
				</div>
			) : (
				<div className="channel-video-container flex justify-center items-start">
					<div className="not-found-wrapper">
						<div className="not-found-title">Ops, channel room not found!</div>
						<div className="not-found-subtitle">The channel room that you've attempted to access does not exist.</div>
					</div>
				</div>
			)}
		</>
	);
};

export default withRouter(ChannelVideo);

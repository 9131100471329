import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { authenticationActions } from "app/states/authentication";
import EventInfoContainer from "../EventInfoContainer/EventInfoContainer";
import LoadingGIF from "app/assets/loading-wave-3.gif";
import { eventActions } from "app/states/event";
import { USER_EVENT_PAYMENT_STATUS } from "app/config/apiEnums";

const TicketCheckoutStatusModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeTicketCheckoutStatusModal = () => dispatch(authenticationActions.closeTicketCheckoutStatusModal());
	const openShippingAddressModal = () => dispatch(authenticationActions.openShippingAddressModal());
	const setTicketPaymentStatus = (ticketPaymentStatus) =>
		dispatch(eventActions.setTicketPaymentStatus(ticketPaymentStatus));

	const showModal = useSelector(({ auth }) => auth.showTicketCheckoutStatusModal);
	const ticketPaymentStatus = useSelector(({ event }) => event.ticketPaymentStatus);
	const eventSettings = useSelector(({ event }) => event.eventSettings);

	const handleClose = () => {
		closeTicketCheckoutStatusModal();

		// If status is fail/success, clear status on close
		if (ticketPaymentStatus === USER_EVENT_PAYMENT_STATUS[0] || ticketPaymentStatus === USER_EVENT_PAYMENT_STATUS[2]) {
			setTicketPaymentStatus(null);
		}

		// If ecommerce enabled, open shipping address modal
		if (eventSettings && eventSettings.ecommerce_enabled === 1 && ticketPaymentStatus === USER_EVENT_PAYMENT_STATUS[2]) {
			openShippingAddressModal();
		}
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="ticket-checkout-status-modal">
			<div className="app-modal mobile-app-modal ticket-checkout-status-modal">
				<div className="modal-content">
					<div className="grid grid-cols-12 lg:gap-5 h-full">
						<div className="hidden lg:block col-span-6 px-10 h-full">
							<EventInfoContainer />
						</div>
						<div className="col-span-12 lg:col-span-6 h-full">
							<div className="ticket-checkout-status-container flex flex-col pt-10 lg:pt-6 lg:justify-center h-full">
								{ticketPaymentStatus === USER_EVENT_PAYMENT_STATUS[1] && (
									<section className="processing-wrapper">
										<div className="title mb-3">We are processing your payment...</div>
										<div className="hidden lg:flex justify-center items-center mb-3">
											<img src={LoadingGIF} alt="loading" className="img-loading" />
										</div>
										<div className="desc mb-3">
											This may take awhile, please do not click <strong>back</strong>,{" "}
											<strong>refresh</strong> or <strong>close</strong> this window. You will be
											automatically redirected once your payment is processed.
										</div>
										<div className="flex justify-center items-center lg:hidden text-center mb-10">
											<img src={LoadingGIF} alt="loading" className="img-loading" />
										</div>
										<div className="lg:hidden px-10">
											<EventInfoContainer />
										</div>
									</section>
								)}

								{ticketPaymentStatus === USER_EVENT_PAYMENT_STATUS[2] && (
									<section className="success-wrapper">
										<div className="title mb-3">Registration Complete</div>
										<div className="desc mb-8 lg:mb-14">
											You are all set. See you on the actual event day!
										</div>
										<div className="lg:hidden px-6 lg:px-10 mb-10">
											<EventInfoContainer />
										</div>
										<div className="btn-close-wrapper">
											<button
												type="button"
												className="btn-primary btn-close"
												onClick={handleClose}>
												Close
											</button>
										</div>
									</section>
								)}

								{ticketPaymentStatus === USER_EVENT_PAYMENT_STATUS[0] && (
									<section className="fail-wrapper">
										<div className="title mb-3">Registration Failed!</div>
										<div className="desc mb-8 lg:mb-14">
											Oops! Something went wrong with your payment transaction.Please try again.
										</div>
										<div className="lg:hidden px-6 lg:px-10 mb-10">
											<EventInfoContainer />
										</div>
										<div className="btn-close-wrapper">
											<button
												type="button"
												className="btn-primary btn-close"
												onClick={handleClose}>
												Close
											</button>
										</div>
									</section>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(TicketCheckoutStatusModal);

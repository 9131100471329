import React from "react";
import EventInfoContainer from "app/views/containers/Authentication/EventInfoContainer/EventInfoContainer";
import EventRegistrationContainer from "app/views/containers/Authentication/EventRegistrationContainer/EventRegistrationContainer";

const EventRegistration = () => {
	return (
		<div id="event-registration-page" className="w-screen min-h-screen bg-gray-200 relative">
			<div className="grid grid-cols-12 gap-5 min-h-screen">
				<div className="col-span-6 px-10 h-full">
					<EventInfoContainer />
				</div>
				<div className="col-span-6 px-10 h-full">
					<EventRegistrationContainer />
				</div>
			</div>
		</div>
	);
};

export default EventRegistration;

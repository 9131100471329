import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import './style.scss'
import MenuBar from "app/views/containers/MenuBar/MenuBar";
import BrandLogoMobile from "app/views/components/BrandLogoMobile/BrandLogoMobile";
import EngagementContainer from 'app/views/containers/Agent/EngagementContainer/EngagementContainer';

const AgentEngagements = ({ history }) => {

    return (
        <div id="agent-engagements-page" className="flex flex-col md:flex-row w-screen min-h-screen md:pt-1">
            <section className="hidden md:block">
				<MenuBar />
			</section>

			<div className="block md:hidden">
				<BrandLogoMobile />
			</div>

			<div className="content-container w-full grid grid-cols-12 gap-1">
                <div className="col-span-12 md:col-span-12">
				    <EngagementContainer />
                </div>
                {/* <div className="hidden md:block md:col-span-3">
                    Quick shortcuts
                </div> */}
			</div>

			{/* Mobile View Menu Bar */}
			<div className="block md:hidden w-full">
				<MenuBar />
			</div>
        </div>
    )
}

export default withRouter(AgentEngagements);
export const OPEN_RESET_PASSWORD_MODAL = 'common/OPEN_RESET_PASSWORD_MODAL'
export const CLOSE_RESET_PASSWORD_MODAL = 'common/CLOSE_RESET_PASSWORD_MODAL'

export const OPEN_TERMS_CONDITION_MODAL = 'common/OPEN_TERMS_CONDITION_MODAL'
export const CLOSE_TERMS_CONDITION_MODAL = 'common/CLOSE_TERMS_CONDITION_MODAL'

export const OPEN_PRIVACY_POLICY_MODAL = 'common/OPEN_PRIVACY_POLICY_MODAL'
export const CLOSE_PRIVACY_POLICY_MODAL = 'common/CLOSE_PRIVACY_POLICY_MODAL'

export const OPEN_PROFILE_MODAL = 'common/OPEN_PROFILE_MODAL'
export const CLOSE_PROFILE_MODAL = 'common/CLOSE_PROFILE_MODAL'

export const OPEN_JOIN_PRIVATE_CHAT_MODAL = 'common/OPEN_JOIN_PRIVATE_CHAT_MODAL'
export const CLOSE_JOIN_PRIVATE_CHAT_MODAL = 'common/CLOSE_JOIN_PRIVATE_CHAT_MODAL'

export const OPEN_EXIT_PRIVATE_CHAT_MODAL = 'common/OPEN_EXIT_PRIVATE_CHAT_MODAL'
export const CLOSE_EXIT_PRIVATE_CHAT_MODAL = 'common/CLOSE_EXIT_PRIVATE_CHAT_MODAL'

export const OPEN_PRIVATE_CHAT_PEOPLE_MODAL = 'common/OPEN_PRIVATE_CHAT_PEOPLE_MODAL'
export const CLOSE_PRIVATE_CHAT_PEOPLE_MODAL = 'common/CLOSE_PRIVATE_CHAT_PEOPLE_MODAL'
export const SET_IS_PRIVATE_CHAT = 'common/SET_IS_PRIVATE_CHAT'

export const OPEN_ALERT_SNACKBAR = 'common/OPEN_ALERT_SNACKBAR'
export const CLOSE_ALERT_SNACKBAR = 'common/CLOSE_ALERT_SNACKBAR'

export const OPEN_VISITOR_DETAIL_MODAL = 'common/OPEN_VISITOR_DETAIL_MODAL'
export const CLOSE_VISITOR_DETAIL_MODAL = 'common/CLOSE_VISITOR_DETAIL_MODAL'

export const SET_CHAT_UNREAD_COUNT = 'common/SET_CHAT_UNREAD_COUNT'

export const SET_PROFILE_MODAL_TAB_INDEX = 'common/SET_PROFILE_MODAL_TAB_INDEX'

export const OPEN_DELETE_SAVED_ORDERS_MODAL = 'common/OPEN_DELETE_SAVED_ORDERS_MODAL'
export const CLOSE_DELETE_SAVED_ORDERS_MODAL = 'common/CLOSE_DELETE_SAVED_ORDERS_MODAL'

export const OPEN_SWITCH_EXHIBITOR_ROOM_MODAL = 'common/OPEN_SWITCH_EXHIBITOR_ROOM_MODAL'
export const CLOSE_SWITCH_EXHIBITOR_ROOM_MODAL = 'common/CLOSE_SWITCH_EXHIBITOR_ROOM_MODAL'

export const OPEN_ADDRESS_MANAGEMENT_MODAL = 'common/OPEN_ADDRESS_MANAGEMENT_MODAL'
export const CLOSE_ADDRESS_MANAGEMENT_MODAL = 'common/CLOSE_ADDRESS_MANAGEMENT_MODAL'

export const OPEN_ADD_ADDRESS_MODAL = 'common/OPEN_ADD_ADDRESS_MODAL'
export const CLOSE_ADD_ADDRESS_MODAL = 'common/CLOSE_ADD_ADDRESS_MODAL'

export const SET_ADDRESS_LIST = 'common/SET_ADDRESS_LIST'

export const SET_VIEWING_ORDER_ID = 'common/SET_VIEWING_ORDER_ID'

export const SET_VIEWING_SAVED_ORDER_ROOM_ID = 'common/SET_VIEWING_SAVED_ORDER_ROOM_ID'
export const SET_VIEWING_SAVED_ORDER_ROOM_NAME = 'common/SET_VIEWING_SAVED_ORDER_ROOM_NAME'

export const SET_IS_ENTERING_EVENT = 'common/SET_IS_ENTERING_EVENT'

export const OPEN_PRODUCT_DETAIL_VIEW_MODAL = 'common/OPEN_PRODUCT_DETAIL_VIEW_MODAL'
export const CLOSE_PRODUCT_DETAIL_VIEW_MODAL = 'common/CLOSE_PRODUCT_DETAIL_VIEW_MODAL'

export const SHOW_LOADING = 'common/SHOW_LOADING'
export const HIDE_LOADING = 'common/HIDE_LOADING'
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { channelActions } from "app/states/channel";
import { useDispatch, useSelector } from "react-redux";
import IconClose from "app/assets/icon_close.svg";
import "./style.scss";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@iconify/react";
import baselineSearch from "@iconify-icons/ic/baseline-search";
import ChannelAccordion from "app/views/components/ChannelAccordion/ChannelAccordion";
import DateFilter from "app/views/components/DateFilter/DateFilter";
import moment from "moment";

const ChannelListMobileModal = ({ history, getEventChannels, liveSessions, channels, selectedDate, onChangeDate }) => {
	const [searchChannelText, setSearchChannelText] = useState("");

	const dispatch = useDispatch();
	const closeMobileChannelListModal = () => dispatch(channelActions.closeMobileChannelListModal());

	const showModal = useSelector(({ channel }) => channel.showMobileChannelListModal);
	const expandChannelCategory = useSelector(({ channel }) => channel.expandChannelCategory);

	const handleClose = () => {
		closeMobileChannelListModal();
	};

	const handleChannelSearch = () => {
		let channelListParams = {
			start_at: moment(selectedDate).startOf("day").valueOf(),
			end_at: moment(selectedDate).endOf("day").valueOf(),
		};

		if (searchChannelText) {
			channelListParams["keyword"] = searchChannelText;
		}

		getEventChannels(channelListParams);
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="channel-list-mobile-modal">
			<div className="mobile-app-modal channel-list-mobile-modal">
				<div className="flex justify-between items-center mb-3">
					<h1 className="modal-title">Channels</h1>
					<span className="btn-close">
						<img src={IconClose} alt="Close" onClick={handleClose} />
					</span>
				</div>
				<div className="modal-content">
					<section className="mb-3">
						<div className="mb-3">
							<FormControl style={{ width: "100%" }}>
								<InputLabel htmlFor="channel-search">Channel</InputLabel>
								<Input
									id="channel-search"
									type="text"
									value={searchChannelText}
									onChange={(event) => setSearchChannelText(event.target.value)}
									name="channel_search"
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												className="p-0"
												aria-label="Search Channel"
												onClick={handleChannelSearch}>
												<Icon icon={baselineSearch} />
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</div>

						<div>
							<div className="channel-filter-label mb-1">Filter by</div>
							<DateFilter selectedDate={selectedDate} onChange={onChangeDate} />
						</div>
					</section>

					<section className="channel-list-items">
						{liveSessions.length > 0 && (
							<ChannelAccordion
								category="live"
								channelSessions={liveSessions}
								isExpand={expandChannelCategory === "live"}
							/>
						)}

						{channels.length > 0 &&
							channels.map((channel) => {
								const { id, title, thumbnail_url, sessions } = channel;

								const channelDetail = {
									id,
									title,
									thumbnail_url,
								};

								return (
									<ChannelAccordion
										key={`channel-${id}`}
										channelDetail={channelDetail}
										channelSessions={sessions}
										isExpand={expandChannelCategory === title}
									/>
								);
							})}
					</section>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(ChannelListMobileModal);

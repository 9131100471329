import * as types from "./types";

export const setRoomDetails = (roomDetails) => ({
	type: types.SET_ROOM_DETAILS,
	params: {
		roomDetails,
	},
});

// Add product modal
export const openAddProductModal = () => ({
	type: types.OPEN_ADD_PRODUCT_MODAL,
});

export const closeAddProductModal = () => ({
	type: types.CLOSE_ADD_PRODUCT_MODAL,
});

// Edit product modal
export const openEditProductModal = (productId) => ({
	type: types.OPEN_EDIT_PRODUCT_MODAL,
	params: {
		productId,
	},
});

export const closeEditProductModal = () => ({
	type: types.CLOSE_EDIT_PRODUCT_MODAL,
});

// Refresh product list
export const refreshStoreProducts = (refresh) => ({
	type: types.REFRESH_STORE_PRODUCTS,
	params: {
		refresh,
	},
});

// Add media modal
export const openAddMediaModal = () => ({
    type: types.OPEN_ADD_MEDIA_MODAL,
});

export const closeAddMediaModal = () => ({
    type: types.CLOSE_ADD_MEDIA_MODAL,
});

// Refresh exhibitor room details
export const refreshExhibitorRoomDetails = (refresh) => ({
	type: types.REFRESH_EXHIBITOR_ROOM_DETAILS,
	params: {
		refresh,
	},
});

// Live toggle modal
export const openLiveToggleModal = () => ({
    type: types.OPEN_LIVE_TOGGLE_MODAL,
});

export const closeLiveToggleModal = () => ({
    type: types.CLOSE_LIVE_TOGGLE_MODAL,
});

export const setLiveSessionId = (liveSessionId) => ({
	type: types.SET_LIVE_SESSION_ID,
	params: {
		liveSessionId,
	},
});

// Create private chat modal
export const openCreatePrivateChatModal = () => ({
    type: types.OPEN_CREATE_PRIVATE_CHAT_MODAL,
});

export const closeCreatePrivateChatModal = () => ({
    type: types.CLOSE_CREATE_PRIVATE_CHAT_MODAL,
});

// For new private chat handling
export const refreshChatListing = (refresh) => ({
    type: types.REFRESH_CHAT_LISTING,
	params: {
		refresh
	}
});

export const setNewChatId = (chatId) => ({
    type: types.SET_NEW_CHAT_ID,
	params: {
		chatId
	}
});

export const setNewChatInvitedCount = (count) => ({
    type: types.SET_NEW_CHAT_INVITED_COUNT,
	params: {
		count
	}
});

export const viewNewPrivateChat = (view) => ({
    type: types.VIEW_NEW_PRIVATE_CHAT,
	params: {
		view
	}
});

// Create private chat success modal
export const openCreatePrivateChatSuccessModal = () => ({
    type: types.OPEN_CREATE_PRIVATE_CHAT_SUCCESS_MODAL,
});

export const closeCreatePrivateChatSuccessModal = () => ({
    type: types.CLOSE_CREATE_PRIVATE_CHAT_SUCCESS_MODAL,
});

// Delete chat modal
export const openDeleteChatModal = () => ({
    type: types.OPEN_DELETE_CHAT_MODAL,
});

export const closeDeleteChatModal = () => ({
    type: types.CLOSE_DELETE_CHAT_MODAL,
});

export const setDeleteChatId = (chatId) => ({
    type: types.SET_DELETE_CHAT_ID,
	params: {
		chatId
	}
});

export const setDeleteChatName = (name) => ({
    type: types.SET_DELETE_CHAT_NAME,
	params: {
		name
	}
});

// Add private chat participants modal
export const openAddPrivateChatParticipants = () => ({
    type: types.OPEN_ADD_PRIVATE_CHAT_PARTICIPANTS,
});

export const closeAddPrivateChatParticipants = () => ({
    type: types.CLOSE_ADD_PRIVATE_CHAT_PARTICIPANTS,
});

// Remove private chat participants modal
export const openRemovePrivateChatParticipants = () => ({
    type: types.OPEN_REMOVE_PRIVATE_CHAT_PARTICIPANTS,
});

export const closeRemovePrivateChatParticipants = () => ({
    type: types.CLOSE_REMOVE_PRIVATE_CHAT_PARTICIPANTS,
});

// Set agent viewing order id
export const setAgentViewingOrderId = (viewingOrderId) => ({
    type: types.SET_AGENT_VIEWING_ORDER_ID,
	params: {
		viewingOrderId
	}
});
import { getEventCodeFromUrl } from "app/helper/common";

const LocalStorageService = {
	// Clear all
	clearAll: () => {
		localStorage.clear();
	},

	// User profile
	setUserProfile: (userProfile) => {
		localStorage.setItem(`userProfile`, userProfile);
	},

	getUserProfile: () => {
		const userProfile = localStorage.getItem(`userProfile`);
		return JSON.parse(userProfile);
	},

	// User event role
	setUserEventRole: (eventCode, userEventRole) => {
		localStorage.setItem(`${eventCode}_userEventRole`, JSON.stringify(userEventRole));
	},

	getUserEventRole: () => {
		const eventCode = getEventCodeFromUrl();
		const userEventRole = localStorage.getItem(`${eventCode}_userEventRole`);
		return JSON.parse(userEventRole);
	},

	// Event setting object
	setEventSetting: (eventCode, eventSetting) => {
		localStorage.setItem(`${eventCode}_eventSetting`, eventSetting);
	},

	getEventSetting: () => {
		const eventCode = getEventCodeFromUrl();
		return JSON.parse(localStorage.getItem(`${eventCode}_eventSetting`));
	},

	// Web config object
	setWebConfig: (eventCode, webConfig) => {
		localStorage.setItem(`${eventCode}_webConfig`, webConfig);
	},

	getWebConfig: () => {
		const eventCode = getEventCodeFromUrl();
		return JSON.parse(localStorage.getItem(`${eventCode}_webConfig`));
	},

	// QuickBlox session object
	setQbSession: (qbSession) => {
		localStorage.setItem("qbSession", qbSession);
	},

	getQbSession: () => {
		return JSON.parse(localStorage.getItem("qbSession"));
	},

	// User login secrets for Quickblox
	setQbUserSecret: (username, password) => {
		const userSecrets = {
			username, password
		}

		localStorage.setItem("qbUserSecrets", JSON.stringify(userSecrets));
	},

	getQbUserSecret: () => {
		return JSON.parse(localStorage.getItem("qbUserSecrets"));
	},

	// Cache event id by event code
	setEventIdByEventCode: (eventCode, eventId) => {
		localStorage.setItem(`${eventCode}_eid`, eventId);
	},

	getEventIdByEventCode: () => {
		const eventCode = getEventCodeFromUrl();
		return localStorage.getItem(`${eventCode}_eid`);
	},
};

export default LocalStorageService;

import apiAction from "../services/apiAction"

const ProfileAPI = {

    /**
     * Get user profile
     */
    getUserProfile: () => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/profile`;

            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Update user profile
     * @param {FormData} formData 
     */
    postUpdateUserProfile: (formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/profile`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get user detail by user_id
     */
    getUserDetailsByUserId: (user_id) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/${user_id}`;

            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * List user addresses
     */
    getAddressList: () => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/addresses`;

            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Add user address
     */
    postAddAddress: (data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/addresses`;

            apiAction.post(url, data)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Update user address
     */
    postUpdateAddress: (data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/addresses`;

            apiAction.put(url, data)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Delete user address
     */
    deleteAddress: (addressId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/addresses`;

            const data = {
                id: addressId
            }

            apiAction.delete(url, data)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },
};

export default ProfileAPI;

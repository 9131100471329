import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { exhibitorActions } from "app/states/exhibitor";
import { useDispatch, useSelector } from "react-redux";
import "app/styles/general.scss";
import "./style.scss";
import IconExitPrivateChat from "app/assets/Channels_Exhibitors/exit_private_chat.png";
import ExhibitorAPI from "app/apis/exhibitor";
import sessionStorageService from "app/services/sessionStorageService";

const ExitPrivateChatModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeExitPrivateChatModal = () => dispatch(commonActions.closeExitPrivateChatModal());
	const setIsPrivateChat = (isPrivateChat) => dispatch(commonActions.setIsPrivateChat(isPrivateChat));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setPrivateChatJoinedTimestamp = (timestamp) =>
		dispatch(exhibitorActions.setPrivateChatJoinedTimestamp(timestamp));
	const setPrivateChatVisitors = (visitors) => dispatch(exhibitorActions.setPrivateChatVisitors(visitors));
	const refreshExhibitorRoomDetails = (refresh) => dispatch(exhibitorActions.refreshExhibitorRoomDetails(refresh));

	const showModal = useSelector(({ common }) => common.showExitPrivateChatModal);
	const exhibitorRoomId = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomId);
	const exhibitorChatId = useSelector(({ exhibitor }) => exhibitor.exhibitorChatId);
	const exhibitorRoomDetails = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomDetails);

	const [roomName, setRoomName] = useState("");

	useEffect(() => {
		if (exhibitorRoomDetails) {
			setRoomName(exhibitorRoomDetails.account.name);
		}
	}, [exhibitorRoomDetails]);

	const handleClose = () => {
		closeExitPrivateChatModal();
	};

	const handleNo = () => {
		closeExitPrivateChatModal();
	};

	const handleYes = () => {
		// Exit private chat
		ExhibitorAPI.postLeavePrivateChat(exhibitorRoomId, exhibitorChatId)
			.then((response) => {
				// To allow private chat invitation again
				sessionStorageService.removeHasPcInvitation();

				refreshExhibitorRoomDetails(true);

				// Reset redux states
				setIsPrivateChat(false);
				setPrivateChatJoinedTimestamp(null);
				setPrivateChatVisitors([]);

				closeExitPrivateChatModal();
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="exit-private-chat-modal">
			<div className="app-modal exit-private-chat-modal">
				<div className="icon-private-chat-container flex items-center">
					<img src={IconExitPrivateChat} alt="private chat" className="icon-private-chat" />
				</div>
				<div className="modal-content">
					<h1 className="modal-title mb-3 mt-12">Exit private chat</h1>
					<p className="mb-10">
						Are you sure you want to end private chat section with <strong>{roomName}</strong>?
					</p>

					<div className="grid grid-cols-12 gap-4">
						<div className="col-span-6">
							<button className="btn btn-primary-outline" onClick={handleNo}>
								No, Stay In
							</button>
						</div>
						<div className="col-span-6">
							<button className="btn btn-primary" onClick={handleYes}>
								Yes
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(ExitPrivateChatModal);

import * as types from "./types";

/**
 * Cart
 */
export const setCart = (cartProducts) => ({
	type: types.SET_CART,
	params: {
		cartProducts,
	},
});

export const addToCart = (product) => ({
	type: types.ADD_TO_CART,
	params: {
		product,
	},
});

export const removeFromCart = (productId) => ({
	type: types.REMOVE_FROM_CART,
	params: {
		productId,
	},
});

export const clearCart = () => ({
	type: types.CLEAR_CART,
});

/**
 * States for viewing my order product details
 */
export const openMyOrderProductDetailModal = () => ({
	type: types.OPEN_MY_ORDER_PRODUCT_DETAIL_MODAL,
});

export const closeMyOrderProductDetailModal = () => ({
	type: types.CLOSE_MY_ORDER_PRODUCT_DETAIL_MODAL,
});

export const setViewingOrderRoomId = (roomId) => ({
	type: types.SET_VIEWING_ORDER_ROOM_ID,
	params: {
		roomId,
	},
});

export const setViewingOrderStoreId = (storeId) => ({
	type: types.SET_VIEWING_ORDER_STORE_ID,
	params: {
		storeId,
	},
});

export const setViewingOrderProductId = (productId) => ({
	type: types.SET_VIEWING_ORDER_PRODUCT_ID,
	params: {
		productId,
	},
});

/**
 * States for my cart container
 */
export const refreshMyCart = () => ({
	type: types.REFRESH_MY_CART,
});

import React from 'react'
import './style.scss'

const BrandCopyrightMobile = () => {

    return (
        <div className="brand-copyright-mobile">
            <span className="text-xs text-gray-500 lg:text-white"> © eBIZSTART 2021. All rights reserved.</span>
        </div>
    )
}

export default BrandCopyrightMobile;
import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import IconAdd from "app/assets/Authentication/icon-add.svg";
import IconRemove from "app/assets/Agent/icon_remove_media.png";
import { useDispatch } from 'react-redux';
import { agentActions } from 'app/states/agent';

const GalleryMedia = ({ mediaObj, thumbnail, onDelete }) => {

	const dispatch = useDispatch();
	const openAddMediaModal = () => dispatch(agentActions.openAddMediaModal());

	return (
		<div className="file-display mr-3" onClick={!thumbnail ? openAddMediaModal : () => {}}>
			{thumbnail ? (
				<>
					<img src={thumbnail} alt="thumbnail" className="file-thumbnail" />
					<img src={IconRemove} alt="edit" className="icon-edit" onClick={onDelete} />
				</>
			) : (
				<img src={IconAdd} alt="Add Logo" className="icon-add" />
			)}
		</div>
	);
};

export default GalleryMedia;

GalleryMedia.propTypes = {
	thumbnail: PropTypes.string,
};

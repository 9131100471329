/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconPollsSelected from "app/assets/icon-polls-selected.png";
import { useDispatch, useSelector } from "react-redux";
import ChannelApi from "app/apis/channel";
import { channelSocket } from "app/services/socketService";
import _ from "lodash";
import { commonActions } from "app/states/common";

const PollsContainer = () => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const channelSessionId = useSelector(({ channel }) => channel.channelSessionId);

	const [activePolls, setActivePolls] = useState([]);
	const [submittedPolls, setSubmittedPolls] = useState([]);

	useEffect(() => {
		setActivePolls([]);
		setSubmittedPolls([]);

		if (channelSessionId) {
			getActivePolls();
			getSubmittedPolls();
		}
	}, [channelSessionId]);

	useEffect(() => {
		channelSocket.on("session-update", (message) => {
			// do something with incoming message from backend
			// console.log("channelSocket: ", message);

			const { action_code, data } = message;

			// New poll created
			if (action_code === 300) {
				const { poll } = data;
				const { id, title, choices } = poll;

				const newPoll = {
					id,
					title,
					choices,
				};

				setActivePolls((prevStates) => [...prevStates, newPoll]);
			}

			// Poll result update
			if (action_code === 301) {
				const { poll } = data;
				const { id, choices, total_voters } = poll;

				// Update poll result if user has submitted poll
				// because after submitted poll only can see the poll's result
				setSubmittedPolls((prevStates) => {
					let submittedPollsCopy = prevStates;
					let updatedSubmittedPollsCopy = submittedPollsCopy.map((submittedPoll) => {
						// Find the submitted poll by id, to update the result
						if (submittedPoll.id === id) {
							submittedPoll.total_voters = total_voters;

							submittedPoll.choices = submittedPoll.choices.map((choice, index) => {
								if (choice.id === choices[index].id) {
									choice.total_voted = choices[index].total_voted;
								}
								return choice;
							});
						}

						return submittedPoll;
					});

					return updatedSubmittedPollsCopy;
				});
			}

			// Poll updated by admin
			if (action_code === 302) {
				const { poll } = data;
				const { id, title, choices } = poll;

				// Find the id in activePolls and update
				setActivePolls((prevStates) => {
					let activePollsCopy = prevStates;
					let updatedActivePolls = activePollsCopy.map((activePoll) => {
						if (activePoll.id === id) {
							return poll;
						}

						return activePoll;
					});

					return updatedActivePolls;
				});

				// If not updated in active polls,
				// Find the id in submittedPolls and update
				setSubmittedPolls((prevStates) => {
					let submittedPollsCopy = prevStates;
					let updatedSubmittedPolls = submittedPollsCopy.map((submittedPoll) => {
						if (submittedPoll.id === id) {
							submittedPoll.title = title;
							submittedPoll.choices = _.merge(submittedPoll.choices, choices);
						}

						return submittedPoll;
					});

					return updatedSubmittedPolls;
				});
			}

			// Poll deleted by admin
			if (action_code === 303) {
				const { poll } = data;
				const { id } = poll;

				// Filter out the deleted poll id from active polls array
				setActivePolls((prevStates) => {
					let activePollsCopy = prevStates;
					let activePollsLeft = activePollsCopy.filter((activePoll) => {
						return activePoll.id !== id;
					});

					return activePollsLeft;
				});

				// Filter out the deleted poll id from submitted polls array
				setSubmittedPolls((prevStates) => {
					let submittedPollsCopy = prevStates;
					let submittedPollsLeft = submittedPollsCopy.filter((submittedPoll) => {
						return submittedPoll.id !== id;
					});

					return submittedPollsLeft;
				});
			}
		});

		// return () => {
		// 	// turning of socket listner on unmount
		// 	channelSocket.off("session-update");
		// };
	}, []);

	const getActivePolls = () => {
		ChannelApi.getChannelSessionActivePolls(channelSessionId)
			.then((res) => {
				const { polls } = res;

				setActivePolls(polls);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getSubmittedPolls = () => {
		ChannelApi.getChannelSessionSubmittedPolls(channelSessionId)
			.then((res) => {
				const { polls } = res;

				setSubmittedPolls(polls);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleSelectOption = (pollId, choiceId) => {
		ChannelApi.postCastVoteOnPoll(channelSessionId, pollId, choiceId)
			.then((res) => {
				// Once voted, will turn from active poll to submitted poll
				// Remove poll from active polls array using pollId
				const updatedActivePolls = activePolls.filter((activePoll) => {
					return activePoll.id !== pollId;
				});
				setActivePolls(updatedActivePolls);

				// Add voted poll to submitted polls array
				const submittedPoll = res;
				setSubmittedPolls([...submittedPolls, submittedPoll]);
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const PollsOption = ({ pollId, choiceId, value }) => (
		<div className="polls-option mb-2" onClick={() => handleSelectOption(pollId, choiceId)}>
			{value}
		</div>
	);

	const PollsOptionRevealed = ({ value, selected, pollOptionCount, pollTotalCount }) => {
		// Calculate percentage
		let percentage = 0;

		if (pollOptionCount && pollTotalCount && pollTotalCount > 0) {
			percentage = (pollOptionCount / pollTotalCount) * 100;
		}

		const progressFillerStyles = {
			position: "absolute",
			top: 0,
			left: 0,
			height: "100%",
			width: `${percentage}%`,
			backgroundColor: "rgba(62, 81, 241, 0.1)",
			borderRadius: "inherit",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		};

		return (
			<div className="polls-progress-container mb-2">
				<div className="polls-progress" style={progressFillerStyles}></div>
				<div className="polls-option-revealed px-3">
					<div className={`option ${selected && "font-bold"}`}>
						{value} {selected && <img src={IconPollsSelected} alt="selected" className="inline" />}
					</div>
					<div className="percentage">{percentage.toFixed(1)}%</div>
				</div>
			</div>
		);
	};

	return (
		<div className="polls-container px-5 pt-3">
			{activePolls.map((activePoll) => {
				const { id: pollId, title, choices } = activePoll;

				return (
					<div key={`poll-${pollId}`} className="polls-wrapper mb-4">
						<label className="polls-section-title mb-3">New Polls (1-2)</label>
						<ChevronRightIcon className="chevron-right" />
						<div className="polls-box p-3">
							<div className="polls-title mb-3">{title}</div>
							{choices.map((choice) => {
								const { id: choiceId, text } = choice;
								return (
									<PollsOption
										key={`option-${choiceId}`}
										pollId={pollId}
										choiceId={choiceId}
										value={text}
									/>
								);
							})}
						</div>
					</div>
				);
			})}

			{submittedPolls.map((submittedPoll) => {
				const { id, title, total_voters, choices } = submittedPoll;

				return (
					<div key={`poll-${id}`} className="polls-wrapper mb-4">
						<label className="polls-section-title mb-3">Submitted Polls (1-2)</label>
						<ChevronRightIcon className="chevron-right" />
						<div className="polls-box p-3">
							<div className="polls-title mb-3">{title}</div>
							{choices.map((choice) => {
								const { id, text, is_voted, total_voted } = choice;
								return (
									<PollsOptionRevealed
										key={`choice-${id}`}
										value={text}
										selected={is_voted === 1 ? true : false}
										pollOptionCount={total_voted}
										pollTotalCount={total_voters}
									/>
								);
							})}

							<div className="total-voted">{total_voters} votes</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default withRouter(PollsContainer);

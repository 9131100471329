import * as types from "./types";

/**
 * Refresh address list
 */
export const refreshAddressList = () => ({
	type: types.REFRESH_ADDRESS_LIST,
});

/**
 * mobile profile modal
 */
export const openMobileProfileModal = () => ({
	type: types.OPEN_MOBILE_PROFILE_MODAL,
});

export const closeMobileProfileModal = () => ({
	type: types.CLOSE_MOBILE_PROFILE_MODAL,
});

// set selected profile tab
export const setSelectedProfileTabIndex = (tabIndex) => ({
	type: types.SET_SELECTED_PROFILE_TAB_INDEX,
	params: {
		tabIndex
	}
});
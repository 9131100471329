import React from "react";
import { withRouter, Link } from "react-router-dom";
import "./style.scss";
import { useDispatch } from "react-redux";
import { profileActions } from "app/states/profile";

const MobileMenuButton = ({ icon, name, link, active }) => {
	const dispatch = useDispatch();
	const setSelectedProfileTabIndex = (tabIndex) => dispatch(profileActions.setSelectedProfileTabIndex(tabIndex));

	const handleOnClick = () => {
		setSelectedProfileTabIndex(null);
	}

	return (
		<Link to={link} className="mobile-menu-button menu-button flex flex-col justify-center items-center lg:mb-5" onClick={handleOnClick}>
			<div className={`menu-icon-container`}>
				<img src={icon} alt={name} className="w-full h-auto" />
			</div>
			<div className={`menu-name ${active && "menu-name-active"}`}>{name}</div>
		</Link>
	);
};

export default withRouter(MobileMenuButton);

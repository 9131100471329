export const urlify = (textToCheck) => {
	if (!textToCheck) return textToCheck;

	const expression = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;

	return textToCheck.replace(expression, function (url) {
		return '<a class="text-anchor-link" href="' + url + '" target="_blank">' + url + "</a>";
	});
};

export function numberWithCommas(x) {
	if (!x) return 0;
	return x.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function cartesian(args) {
	if (!args || args.length <= 0) {
		return [];
	}

	let result = [],
		max = args.length - 1;

	function helper(arr, i) {
		for (let j = 0, l = args[i].length; j < l; j++) {
			let a = arr.slice(0); // clone arr
			a.push(args[i][j]);
			if (i === max) result.push(a);
			else helper(a, i + 1);
		}
	}

	helper([], 0);

	return result;
}

export const getEventCodeFromUrl = () => {
	let pathname = window.location.pathname;
	let pathNamePieces = pathname.split("/");
	let eventCode = pathNamePieces[2]; // take the third piece
	return eventCode;
}
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import VisitorDetailModal from "../Modal/VisitorDetailModal/VisitorDetailModal";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";

const Visitor = ({ image, name, desc, onClick }) => {
	const [showBorder, setShowBorder] = useState(false);

	const handlImageOnError = (event) => {
		event.target.onerror = null;
		event.target.src = IconDefaultProfile;
		setShowBorder(false);
	};

	return (
		<div className={`visitor-item flex items-center mb-3`} onClick={onClick}>
			<img
				src={image || IconDefaultProfile}
				alt={name}
				className={`visitor-image mr-2 ${!showBorder ? "no-border" : ""}`}
				onError={handlImageOnError}
			/>
			<div>
				<div className="visitor-name">{name}</div>
				<div className="visitor-desc">{desc}</div>
			</div>
		</div>
	);
};

const VisitorList = ({ visitors }) => {
	const dispatch = useDispatch();
	const openVisitorDetailModal = (userId) => dispatch(commonActions.openVisitorDetailModal(userId));

	const handleViewVisitorDetail = (userId) => {
		openVisitorDetailModal(userId);
	};

	// Get unique user_id only
	const uniqueVisitors = [...new Map(visitors.map((item) => [item["user_id"], item])).values()];
	
	// const uniqueVisitors = visitors.filter(
	// 	(visitor, index, self) => index === self.findIndex((t) => t.user_id === visitor.user_id)
	// );

	return (
		<div className="visitor-list-container px-5 pt-3">
			{uniqueVisitors.map((visitor) => {
				const { user_id, name, company, job_title, image_url } = visitor;

				let description = "";
				if (job_title && company) {
					description = `${job_title} at ${company}`;
				} else if (job_title) {
					description = job_title;
				} else if (company) {
					description = company;
				}

				return (
					<Visitor
						key={`${user_id}-${Math.random()}`}
						image={image_url}
						name={name}
						desc={description}
						onClick={() => handleViewVisitorDetail(user_id)}
					/>
				);
			})}

			<VisitorDetailModal />
		</div>
	);
};

export default withRouter(VisitorList);

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import IconDeleteRed from "app/assets/icon_delete_red.png";
import OrderAPI from "app/apis/order";
import { orderActions } from "app/states/order";

const DeleteSavedOrderModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeDeleteSavedOrdersModal = () => dispatch(commonActions.closeDeleteSavedOrdersModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setViewingSavedOrderRoomId = (roomId) => dispatch(commonActions.setViewingSavedOrderRoomId(roomId));
	const refreshMyCart = () => dispatch(orderActions.refreshMyCart());

	const showModal = useSelector(({ common }) => common.showDeleteSavedOrdersModal);
	const viewingSavedOrderRoomId = useSelector(({ common }) => common.viewingSavedOrderRoomId);

	const handleClose = () => {
		closeDeleteSavedOrdersModal();
	};

	const handleNo = () => {
		handleClose();
	};

	const handleYes = () => {
		OrderAPI.deleteSavedOrderByExhibitorRoom(viewingSavedOrderRoomId)
			.then((response) => {
				refreshMyCart();
				setViewingSavedOrderRoomId(null);
				closeDeleteSavedOrdersModal();
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="delete-saved-order-modal">
			<div className="app-modal delete-saved-order-modal">
				<div className="icon-container flex justify-center items-center">
					<img src={IconDeleteRed} alt="Delete" />
				</div>
				<div className="modal-content">
					<h1 className="modal-title mb-3 mt-12">Are you sure?</h1>
					<p className="mb-10">Are you sure you want to delete this saved order?</p>

					<div className="flex justify-center items-center">
						<button className="btn btn-primary-outline" onClick={handleNo}>
							Cancel
						</button>
						<button className="btn btn-delete" onClick={handleYes}>
							Delete
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(DeleteSavedOrderModal);

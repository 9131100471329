import React, { useEffect, useState } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import "./style.scss";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import AgentOrderDetailsContainer from "../AgentOrderDetailsContainer/AgentOrderDetailsContainer";
import AgentAPI from "app/apis/agent";
import { useDispatch, useSelector } from "react-redux";
import { agentActions } from "app/states/agent";

const AgentOrdersContainer = () => {
	const dispatch = useDispatch();
	const setAgentViewingOrderId = (viewingOrderId) => dispatch(agentActions.setAgentViewingOrderId(viewingOrderId));

	const agentViewingOrderId = useSelector(({ agent }) => agent.agentViewingOrderId);

	const [orderListing, setOrderListing] = useState([]);

	// Get order listing
	useEffect(() => {
		AgentAPI.getOrderListing()
			.then((response) => {
				const { orders } = response;
				setOrderListing(orders);

				if (orders.length > 0) {
					setAgentViewingOrderId(orders[0].id);
				}
			})
			.catch((error) => {
				//
			});
	}, []);

	return (
		<div className="agent-orders-container grid grid-cols-12 h-full">
			<div className="order-list-wrapper col-span-12 lg:col-span-3 h-full">
				<div className="order-list-header">All Orders</div>
				<div className="order-list-body">
					{orderListing.map((order) => {
						const { id, order_no, description, image_url, created_at } = order;

						return (
							<OrderListItem
								key={`order-list-item-${id}`}
								id={id}
								order_no={order_no}
								description={description}
								thumbnail={image_url}
								created_at={created_at}
								active={agentViewingOrderId === id}
							/>
						);
					})}
				</div>
			</div>
			<div className="order-details-wrapper lg:col-span-9">
				<AgentOrderDetailsContainer />
			</div>
		</div>
	);
};

const OrderListItem = ({ active, id, order_no, thumbnail, created_at, description }) => {
	const history = useHistory();
	const { eventCode } = useParams();

	const dispatch = useDispatch();
	const setAgentViewingOrderId = (viewingOrderId) => dispatch(agentActions.setAgentViewingOrderId(viewingOrderId));

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const handleViewOrderDetails = () => {
		setAgentViewingOrderId(id);

		// If tablet or mobile, redirect to detail page
		if (isTabletOrMobile) {
			//
		}
	};

	return (
		<div className={`order-list-item ${active ? "active" : ""}`} onClick={handleViewOrderDetails}>
			<div className="image-set-wrapper">
				<img src={thumbnail} alt="orders" className="order-image" />
				{/* <img src="https://dummyimage.com/100x100.png" alt="orders" className="order-image-1" />
				<img src="https://dummyimage.com/100x100.png" alt="orders" className="order-image-2" /> */}
			</div>
			<div className="order-item-summary">
				<div className="flex justify-between items-center mb-2">
					<span className="order-no">#{order_no}</span>
					<span className="order-date">{created_at ? moment(created_at).format("DD/M/yyyy") : ""}</span>
				</div>
				<div className="order-item-title">{description}</div>
			</div>
		</div>
	);
};

export default withRouter(AgentOrdersContainer);

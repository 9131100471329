/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import moment from "moment";
import SendIcon from "@material-ui/icons/Send";
import LocalStorageService from "app/services/localStorageService";
import IconVisitorsActive from "app/assets/Channels_Exhibitors/visitors_active.png";
import IconLeave from "app/assets/Channels_Exhibitors/icon_leave.png";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import { exhibitorActions } from "app/states/exhibitor";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import ReactLoading from "react-loading";
import ExhibitorAPI from "app/apis/exhibitor";
import { channelSocket, exhibitorSocket } from "app/services/socketService";
import { urlify } from "app/helper/common";
import sessionStorageService from "app/services/sessionStorageService";
import ChannelAPI from "app/apis/channel";
import useStateRef from "react-usestateref";
import TextareaAutosize from "react-textarea-autosize";

const CHAT_MESSAGES_LIMIT = 20;

const ReceiverMessageComponent = ({ image, name, message, timestamp }) => {
	const [showBorder, setShowBorder] = useState(false);

	const handlImageOnError = (event) => {
		event.target.onerror = null;
		event.target.src = IconDefaultProfile;
		setShowBorder(false);
	};

	const yesterday = moment().subtract(1, "days").startOf("day");
	const isYesterday = moment(timestamp).isSame(yesterday, "day");
	const isToday = moment(timestamp).isSame(moment(), "day");

	let timestampFromNow = moment(timestamp).format("DD MMM, HH:mm");
	if (isYesterday) {
		timestampFromNow = moment(timestamp).format("[Yesterday], HH:mm");
	} else if (isToday) {
		timestampFromNow = moment(timestamp).format("HH:mm");
	}

	return (
		<div className="receiver mb-3">
			<div className="avatar mr-2">
				<img
					src={image || IconDefaultProfile}
					alt={name}
					className={`avatar-image ${!showBorder ? "no-border" : ""}`}
					onError={handlImageOnError}
				/>
			</div>
			<div className="message-body">
				<div className="person-name mb-1">{name}</div>
				<div className="message-text" dangerouslySetInnerHTML={{ __html: urlify(message) }} />
				<span className="message-time float-right">{timestampFromNow}</span>
			</div>
		</div>
	);
};

const SenderMessageComponent = ({ name, message, timestamp }) => {
	const [showBorder, setShowBorder] = useState(false);

	const handlImageOnError = (event) => {
		event.target.onerror = null;
		event.target.src = IconDefaultProfile;
		setShowBorder(false);
	};

	const yesterday = moment().subtract(1, "days").startOf("day");
	const isYesterday = moment(timestamp).isSame(yesterday, "day");
	const isToday = moment(timestamp).isSame(moment(), "day");

	let timestampFromNow = moment(timestamp).format("DD MMM, HH:mm");
	if (isYesterday) {
		timestampFromNow = moment(timestamp).format("[Yesterday], HH:mm");
	} else if (isToday) {
		timestampFromNow = moment(timestamp).format("HH:mm");
	}

	const userProfile = LocalStorageService.getUserProfile();

	return (
		<div className="sender mb-3">
			<div className="message-body">
				<div className="person-name mb-1">{name}</div>
				<div className="message-text" dangerouslySetInnerHTML={{ __html: urlify(message) }} />
				<span className="message-time">{timestampFromNow}</span>
			</div>
			<div className="avatar ml-2">
				<img
					src={userProfile.profile_image_url || IconDefaultProfile}
					alt={name}
					className={`avatar-image ${!showBorder ? "no-border" : ""}`}
					onError={handlImageOnError}
				/>
			</div>
		</div>
	);
};

const ChatContainer = ({ type }) => {
	const { sessionId, roomId } = useParams();

	const userProfile = LocalStorageService.getUserProfile();

	const [profileImg, setProfileImg] = useState(null);
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);

	const [inputMessage, setInputMessage] = useState("");
	const [messages, setMessages] = useState([]);
	const [disableChat, setDisableChat] = useState(true);
	const [chatContainerHeight, setChatContainerHeight] = useState(null);
	const [currentChatId, setCurrentChatId, currentChatIdRef] = useStateRef(null);
	const [unreadCount, setUnreadCount] = useState(0);
	const [triggerUpdateUnreadCount, setTriggerUpdateUnreadCount] = useState(0);
	const [lastMessageId, setLastMessageId] = useState(null);
	const [hasMoreOldMessages, setHasMoreOldMessages] = useState(true);
	const [loadingOldMessages, setLoadingOldMessages] = useState(false);
	const [doneResetStates, setDoneResetStates] = useState(false);
	const [loadingMessages, setLoadingMessages] = useState(false);
	const [doneFirstLoadMessages, setDoneFirstLoadMessage] = useState(false);

	const [isOffline, setIsOffline] = useState(false);
	const [emailSubject, setEmailSubject] = useState("");
	const [emailMessage, setEmailMessage] = useState("");
	const [availableStartTime, setAvailableStartTime] = useState(null);
	const [availableEndTime, setAvailableEndTime] = useState(null);

	const dispatch = useDispatch();
	const openExitPrivateChatModal = () => dispatch(commonActions.openExitPrivateChatModal());
	const openPrivateChatPeopleModal = () => dispatch(commonActions.openPrivateChatPeopleModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setPrivateChatVisitors = (visitors) => dispatch(exhibitorActions.setPrivateChatVisitors(visitors));
	const setChatUnreadCount = (count) => dispatch(commonActions.setChatUnreadCount(count));

	const privateChatVisitors = useSelector(({ exhibitor }) => exhibitor.privateChatVisitors);
	const isPrivateChat = useSelector(({ common }) => common.isPrivateChat);
	const channelChatId = useSelector(({ channel }) => channel.channelChatId);
	const exhibitorChatId = useSelector(({ exhibitor }) => exhibitor.exhibitorChatId);
	const exhibitorRoomDetails = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomDetails);
	const privateChatJoinedTimestamp = useSelector(({ exhibitor }) => exhibitor.privateChatJoinedTimestamp);

	const conversationRef = useRef();
	const messageScrollViewRef = useRef();
	const replyContainerRef = useRef();

	// Handle channel socket
	useEffect(() => {
		channelSocket.on("session-update", (message) => {
			// console.log("channelSocket: ", message);

			const { action_code, data } = message;

			// Chat Message arrived
			if (action_code === 407) {
				const { chat_id } = data;

				// Ensure chat messages from same channel, only append message
				if (chat_id === currentChatIdRef.current) {
					setMessages((prevStates) => [...prevStates, data]);

					// Add unread count
					setUnreadCount((prevState) => prevState + 1);
					setTriggerUpdateUnreadCount((prevState) => prevState + 1);
				}
			}
		});
	}, []);

	// Handle exhibitor socket
	useEffect(() => {
		exhibitorSocket.on("room-update", (message) => {
			// console.log("exhibitorSocket: ", message);

			const { action_code, data } = message;

			// Chat setting updated
			if (action_code === 406) {
				const chat_setting = data;
				checkOffline(chat_setting);
			}

			// Chat Message arrived
			if (action_code === 407) {
				const { chat_id, message_id } = data;

				// Ensure the message from current chat id, only append the message
				// in order to differentiate between public and private chat
				if (chat_id === currentChatIdRef.current) {
					setMessages((prevStates) => {
						const messageList = [...prevStates];

						const duplicateMessage = messageList.filter((message) => {
							return message.message_id === message_id;
						});

						if (duplicateMessage.length === 0) {
							return [...prevStates, data];
						}

						return [...prevStates];
					});

					// Add unread count
					setUnreadCount((prevState) => prevState + 1);
					setTriggerUpdateUnreadCount((prevState) => prevState + 1);
				}
			}
		});

		// return () => {
		// 	// turning of socket listner on unmount
		// 	exhibitorSocket.off("room-update");
		// };
	}, []);

	// Get the private chat detail for exhibitor room
	useEffect(() => {
		if (isPrivateChat) {
			ExhibitorAPI.getPrivateChatDetail(roomId, exhibitorChatId)
				.then((response) => {
					const { visitors, agents } = response;

					setPrivateChatVisitors([...agents, ...visitors]);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [isPrivateChat, exhibitorChatId]);

	// Check if exhibitor offline
	useEffect(() => {
		if (exhibitorRoomDetails) {
			const { chat_setting } = exhibitorRoomDetails;

			checkOffline(chat_setting);
		}
	}, [exhibitorRoomDetails]);

	// Set the current chat id based on the current channel/exhibitor
	useEffect(() => {
		if (type === "channel") {
			setCurrentChatId(channelChatId);
		} else if (type === "exhibitor") {
			setCurrentChatId(exhibitorChatId);
		} else {
			setCurrentChatId(null);
		}
	}, [channelChatId, exhibitorChatId]);

	// Change the chat contain height when it is private chat
	// and return to normal if is not private chat
	useEffect(() => {
		if (isOffline) {
			setChatContainerHeight(null);
			return;
		}
		// Adjust the chat container height when turn to private chat
		else if (isPrivateChat) {
			let newChatContainerHeight = `calc(100% - 33px - 44px)`;
			setChatContainerHeight(newChatContainerHeight);
		}
		// Adjust the chat container height to default when leave private chat
		else if (chatContainerHeight && !isPrivateChat) {
			setChatContainerHeight(null);
		}
	}, [isPrivateChat]);

	// Scroll to bottom when there are new messages
	useEffect(() => {
		let timeout;

		// Do not scroll to bottom if loading old messages
		if (loadingOldMessages) {
			timeout = setTimeout(() => {
				setLoadingOldMessages(false);
			}, 500);
			return;
		} else {
			scrollToBottom();
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [messages]);

	// Listen for local storage changes
	useEffect(() => {
		// First load get user profile from local storage
		setProfileImg(userProfile.profile_image_url || IconDefaultProfile);
		setFirstName(userProfile.first_name);
		setLastName(userProfile.last_name);

		function checkUserData() {
			const updatedUserProfile = LocalStorageService.getUserProfile();

			if (updatedUserProfile) {
				setProfileImg(updatedUserProfile.profile_image_url || IconDefaultProfile);
				setFirstName(updatedUserProfile.first_name);
				setLastName(updatedUserProfile.last_name);
			}
		}

		window.addEventListener("storage", checkUserData);
	}, []);

	const retrieveChatHistory = () => {
		setLoadingMessages(true);

		let params = {
			limit: CHAT_MESSAGES_LIMIT,
		};

		if (lastMessageId) {
			params["last_message_id"] = lastMessageId;
		}

		if (type === "channel") {
			ChannelAPI.getChatMessages(sessionId, channelChatId, params)
				.then((response) => {
					const { messages: chatMessages } = response;

					// After success retrieve chat history only enable chat
					setDisableChat(false);

					if (chatMessages.length > 0) {
						setMessages([...chatMessages, ...messages]);
						setLastMessageId(chatMessages[0].message_id);

						// After 500ms, only allow scroll to top to retrieve old messages
						if (!doneFirstLoadMessages) {
							setTimeout(() => {
								setDoneFirstLoadMessage(true);
							}, 500);
						}
					} else {
						// To stop keep loading old messages if no more
						setHasMoreOldMessages(false);
					}
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setLoadingMessages(false);
				});
		} else if (type === "exhibitor") {
			ExhibitorAPI.getChatMessages(roomId, exhibitorChatId, params)
				.then((response) => {
					const { messages: chatMessages } = response;

					// After success retrieve chat history only enable chat
					setDisableChat(false);

					if (chatMessages.length > 0) {
						setMessages([...chatMessages, ...messages]);
						setLastMessageId(chatMessages[0].message_id);

						// After 500ms, only allow scroll to top to retrieve old messages
						if (!doneFirstLoadMessages) {
							setTimeout(() => {
								setDoneFirstLoadMessage(true);
							}, 500);
						}
					} else {
						// To stop keep loading old messages if no more
						setHasMoreOldMessages(false);
					}
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setLoadingMessages(false);
				});
		}
	};

	// Connect and join chat
	useEffect(() => {
		resetStates();
	}, [currentChatId, isPrivateChat]);

	useEffect(() => {
		if (doneResetStates && currentChatId) {
			setDoneResetStates(false);
			retrieveChatHistory();
		}
	}, [doneResetStates, currentChatId]);

	// Add the unread count when user is not in the chat tab
	useEffect(() => {
		if (triggerUpdateUnreadCount === 0) return;

		// Add unread count if user is not in chat tab
		if (type === "channel" && sessionStorageService.getChannelTabIndex() !== 0) {
			setChatUnreadCount(unreadCount);
		} else if (type === "exhibitor" && sessionStorageService.getExhibitorTabIndex() !== 0) {
			setChatUnreadCount(unreadCount);
		} else {
			// Set unread count to 0
			setUnreadCount(0);
			setChatUnreadCount(0);
		}
	}, [triggerUpdateUnreadCount]);

	const resetStates = () => {
		setMessages([]);
		setDisableChat(true);
		setLastMessageId(null);
		setHasMoreOldMessages(true);
		setLoadingOldMessages(false);
		setDoneFirstLoadMessage(false);
		setDoneResetStates(true);
	};

	const onChangeMessage = (event) => {
		setInputMessage(event.target.value);
	};

	const onMessageKeyDown = (event) => {
		if (event.keyCode === 13 && event.shiftKey === false) {
			event.preventDefault();
			handleSendMessage();
		}
	};

	const handleSendMessage = () => {
		// Prevent user send empty message
		if (inputMessage === "") {
			return;
		}

		let formattedInputMessage = inputMessage.replace(/(?:\r\n|\r|\n)/g, "<br>");

		if (type === "channel") {
			ChannelAPI.postSubmitChatMessage(sessionId, currentChatId, formattedInputMessage)
				.then((response) => {
					setInputMessage("");
				})
				.catch((error) => {
					console.error(error);
				});
		} else if (type === "exhibitor") {
			ExhibitorAPI.postSubmitChatMessage(roomId, currentChatId, formattedInputMessage)
				.then((response) => {
					setInputMessage("");
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const scrollToBottom = () => {
		if (messageScrollViewRef.current) {
			messageScrollViewRef.current.scrollTo(0, messageScrollViewRef.current.scrollHeight);
		}
	};

	const handleSubmitEnquiry = (event) => {
		event.preventDefault();

		ExhibitorAPI.postSubmitEnquiry(roomId, emailSubject, emailMessage)
			.then((response) => {
				setEmailSubject("");
				setEmailMessage("");
				openAlertSnackbar("Enquiry Sent.", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const checkOffline = (chatSettings) => {
		let timezoneOffsetInMinutes = moment().utcOffset();

		const { appear_offline, allow_offline_chat, chat_start_at, chat_end_at } = chatSettings;

		// All in UTC+0 for calculation
		let currentTime = moment().subtract(timezoneOffsetInMinutes, "minutes");
		let startTime = moment(chat_start_at, "HH:mm");
		let endTime = moment(chat_end_at, "HH:mm");

		if ((startTime.hour() >= 12 && endTime.hour() <= 12) || endTime.isBefore(startTime)) {
			endTime.add(1, "days"); // handle spanning days endTime

			if (currentTime.hour() <= 12) {
				currentTime.add(1, "days"); // handle spanning days currentTime
			}
		}

		let yesterdayStartTime = moment(startTime).subtract(1, "days");
		let yesterdayEndTime = moment(endTime).subtract(1, "days");

		if (allow_offline_chat === 1) {
			setIsOffline(false);
		} else if (appear_offline === 1) {
			setIsOffline(true);
		} else if (
			currentTime.isBetween(startTime, endTime) ||
			currentTime.isBetween(yesterdayStartTime, yesterdayEndTime)
		) {
			setIsOffline(false);
		} else {
			setIsOffline(true);
		}

		// Display in the local timezone
		setAvailableStartTime(startTime.add(timezoneOffsetInMinutes, "minutes"));
		setAvailableEndTime(endTime.add(timezoneOffsetInMinutes, "minutes"));
	};

	const handleChatMessageScroll = (event) => {
		const scrollPos = event.target.scrollTop;
		const scrollTopOffset = 200;

		if (scrollPos < scrollTopOffset && hasMoreOldMessages && doneFirstLoadMessages) {
			setLoadingOldMessages(true);
			retrieveChatHistory();
		}
	};

	const onTextAreaHeightChange = (rowHeight) => {
		if (!conversationRef.current || !replyContainerRef.current || !messageScrollViewRef.current) return;

		let replyContainerHeightBefore = replyContainerRef.current.clientHeight;

		let textareaHeight = rowHeight;
		let replyContainerHeight = textareaHeight + 12;
		let offset = 4;

		conversationRef.current.style.height = `calc(100% - ${replyContainerHeight + offset}px)`;
		replyContainerRef.current.style.height = `calc(${replyContainerHeight}px)`;

		// Auto scroll down or top by bit to prevent text area height changes block the message view
		if (replyContainerHeight > replyContainerHeightBefore) {
			messageScrollViewRef.current.scrollTo(0, messageScrollViewRef.current.scrollTop + 17);
		} else {
			messageScrollViewRef.current.scrollTo(0, messageScrollViewRef.current.scrollTop - 17);
		}
	};

	// If agent is offline, show offline
	if (isOffline && type === "exhibitor") {
		return (
			<div className="chat-container">
				<div className="chat-offline">
					<div className="offline-notice">
						<div className="notice-title">Agent Offline</div>
						<div className="notice-desc">
							Our agents are only available from {availableStartTime.format("h.mma")} to{" "}
							{availableEndTime.format("h.mma")}.
						</div>
					</div>

					<form id="form-contact-email" method="post" onSubmit={handleSubmitEnquiry}>
						<h3 className="my-3">Drop Us An Email Instead !</h3>
						<input
							value={emailSubject}
							onChange={(event) => setEmailSubject(event.target.value)}
							placeholder="Subject"
							required
							className="mb-3"
						/>
						<textarea
							value={emailMessage}
							onChange={(event) => setEmailMessage(event.target.value)}
							placeholder="Your message"
							rows={5}
							required
							className="mb-3"
						/>

						<button type="submit" className="btn btn-primary btn-send-email">
							Send Email
						</button>
					</form>
				</div>
			</div>
		);
	}

	return (
		<div className="chat-container">
			{isPrivateChat && (
				<div className="private-chat-bar">
					<div className="flex items-center">
						<img src={IconVisitorsActive} alt="visitor" className="icon-visitors" />
						<span>
							<strong onClick={openPrivateChatPeopleModal} className="cursor-pointer">
								You and {privateChatVisitors.length - 1} people
							</strong>{" "}
							are in this private chat.
						</span>
					</div>
					<button type="button" className="btn-leave" onClick={openExitPrivateChatModal}>
						<img src={IconLeave} alt="leave" className="icon-leave" /> Leave
					</button>
				</div>
			)}

			<div
				className={`conversation`}
				ref={conversationRef}
				style={chatContainerHeight && { height: chatContainerHeight }}>
				{currentChatId && (
					<div
						className="chat-contents px-5 pt-3 overflow-y-auto"
						ref={messageScrollViewRef}
						onScroll={handleChatMessageScroll}>
						{isPrivateChat && type === "exhibitor" && (
							<div className="notice-bar">
								You've joined a private chat at {moment(privateChatJoinedTimestamp).format("h:mma")}
							</div>
						)}

						{messages.map((chatMessage, index) => {
							const { message_id } = chatMessage || index;
							const { message } = chatMessage || "";
							const { sent_at } = chatMessage || moment();
							const { user_id } = chatMessage || "";
							const { name } = chatMessage || "Anonymous";
							const { profile_image_url } = chatMessage || IconDefaultProfile;
							const { is_agent } = chatMessage || null;

							let username = name;

							// For exhibitor page, show (Agent) if is agent
							if (type === "exhibitor") {
								username = `${name}${is_agent === 1 ? " (Agent)" : ""}`;
							}

							// If is current user id, then means it is sender
							if (parseInt(user_id) === userProfile.id) {
								return (
									<SenderMessageComponent
										key={message_id}
										name={username}
										message={message}
										timestamp={sent_at}
									/>
								);
							}

							// Otherwise, it is receiver
							return (
								<ReceiverMessageComponent
									key={message_id}
									name={username}
									message={message}
									timestamp={sent_at}
									image={profile_image_url}
								/>
							);
						})}
					</div>
				)}
			</div>

			<div className="reply-container px-5 py-2" ref={replyContainerRef}>
				<div className="reply">
					<div className="avatar">
						<img src={profileImg} alt={firstName + " " + lastName} className="avatar-image mr-2" />
					</div>
					<div className="reply-main">
						<TextareaAutosize
							maxRows={6}
							minRows={1}
							onHeightChange={(rowHeight) => onTextAreaHeightChange(rowHeight)}
							value={inputMessage}
							placeholder="Type your message here..."
							onKeyDown={onMessageKeyDown}
							onChange={onChangeMessage}
							disabled={disableChat}
						/>
						<div className="reply-send" onClick={handleSendMessage}>
							<SendIcon />
						</div>
					</div>
				</div>
			</div>

			{loadingMessages && currentChatId && (
				<div className="chat-loading-overlay">
					<ReactLoading type={"spinningBubbles"} color="grey" />
					<p className="mt-3">Loading</p>
				</div>
			)}
		</div>
	);
};

export default withRouter(ChatContainer);

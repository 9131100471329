import apiAction from "../services/apiAction";
import LocalStorageService from "../services/localStorageService";

const ExhibitorAPI = {
	/**
	 * Get list of exhibitor rooms
	 * @param {object} queryParams
	 * {limit, offset, keyword}
	 */
	getExhibitorRooms: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${eventId}/exhibitors/rooms`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get exhibitor room detail
	 * @param {int} roomId
	 */
	getExhibitorRoomDetail: (roomId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${eventId}/exhibitors/rooms/${roomId}`;

			apiAction
				.get(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get exhibitor room audiences
	 * @param {int} roomId
	 * @param {object} queryParams
	 * {limit, offset}
	 */
	getExhibitorRoomAudiences: (roomId, queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${eventId}/exhibitors/rooms/${roomId}/audiences`;

			apiAction
				.get(url, queryParams)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get exhibitor room store products
	 * @param {int} roomId
	 * @param {int} storeId
	 * @param {object} queryParams
	 * {limit, offset}
	 */
	getExhibitorRoomStoreProducts: (roomId, storeId, queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${eventId}/exhibitors/rooms/${roomId}/stores/${storeId}/products`;

			apiAction
				.get(url, queryParams)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get exhibitor room store products
	 * @param {int} roomId
	 * @param {int} storeId
	 * @param {int} productId
	 * {limit, offset}
	 */
	getExhibitorRoomStoreProductDetail: (roomId, storeId, productId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${eventId}/exhibitors/rooms/${roomId}/stores/${storeId}/products/${productId}`;

			apiAction
				.get(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Accept chat invitation
	 * @param {int} room_id
	 * @param {int} chat_id
	 */
	postAcceptChatInvitation: (room_id, chat_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const event_id = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${event_id}/exhibitors/rooms/${room_id}/chats/accept`;

			const data = {
				chat_id,
			};

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Leave private chat
	 * @param {int} room_id
	 * @param {int} chat_id
	 */
	postLeavePrivateChat: (room_id, chat_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const event_id = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${event_id}/exhibitors/rooms/${room_id}/chats/leave`;

			const data = {
				chat_id,
			};

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Reject chat invitation
	 * @param {int} room_id
	 * @param {int} chat_id
	 */
	postRejectChatInvitation: (room_id, chat_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const event_id = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${event_id}/exhibitors/rooms/${room_id}/chats/reject`;

			const data = {
				chat_id,
			};

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Submit enquiry
	 * @param {int} room_id
	 * @param {int} chat_id
	 */
	postSubmitEnquiry: (room_id, subject, message) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const event_id = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${event_id}/exhibitors/rooms/${room_id}/enquiries`;

			const data = {
				subject,
				message,
			};

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get private chat detail
	 * @param {int} roomId
	 * @param {int} chatId
	 */
	getPrivateChatDetail: (roomId, chatId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${eventId}/exhibitors/rooms/${roomId}/chats/${chatId}`;

			apiAction
				.get(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get chat messages
	 * @param {int} roomId
	 * @param {int} chatId
	 * @param {object} queryParams
	 * {limit, last_message_id}
	 */
	getChatMessages: (roomId, chatId, queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${eventId}/exhibitors/rooms/${roomId}/chats/${chatId}/messages`;

			apiAction
				.get(url, queryParams)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Post submit chat message
	 * @param {int} roomId
	 * @param {int} chatId
	 * @param {string} message
	 */
	postSubmitChatMessage: (roomId, chatId, message) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${eventId}/exhibitors/rooms/${roomId}/chats/${chatId}/messages`;

			const data = {
				message,
			};

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},
};

export default ExhibitorAPI;

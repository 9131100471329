import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import "./style.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import OrderDetailsContainer from "../OrderDetailsContainer/OrderDetailsContainer";
import { useMediaQuery } from "react-responsive";
import OrderAPI from "app/apis/order";

const MyOrdersContainer = () => {
	const dispatch = useDispatch();
	const setViewingOrderId = (orderId) => dispatch(commonActions.setViewingOrderId(orderId));

	const viewingOrderId = useSelector(({ common }) => common.viewingOrderId);

	const [orders, setOrders] = useState([]);

	useEffect(() => {
		OrderAPI.getUserOrders()
			.then((response) => {
				const { orders } = response;
				setOrders(orders);

				// Set the first order as selected
				if (orders.length > 0) {
					setViewingOrderId(orders[0].id);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	return (
		<div className="my-orders-container grid grid-cols-12 h-full">
			<div className="order-list-wrapper col-span-12 lg:col-span-4 h-full">
				{orders.map((order) => {
					const { id, order_no, description, image_url, created_at, room_id, store_id } = order;

					return (
						<OrderListItem
							key={id}
							id={id}
							thumbnail={image_url}
							orderNo={order_no}
							description={description}
							orderDate={created_at}
							active={viewingOrderId === id}
							roomId={room_id}
							storeId={store_id}
						/>
					);
				})}
			</div>
			<div className="order-details-wrapper lg:col-span-8">
				<OrderDetailsContainer />
			</div>
		</div>
	);
};

const OrderListItem = ({ id, thumbnail, orderNo, description, orderDate, active, roomId, storeId }) => {
	const history = useHistory();
	const { eventCode } = useParams();
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const dispatch = useDispatch();
	const setViewingOrderId = (orderId) => dispatch(commonActions.setViewingOrderId(orderId));

	const handleViewOrderDetails = () => {
		setViewingOrderId(id);

		if (isTabletOrMobile) {
			history.push(`/events/${eventCode}/profile/orders`);
		}
	};

	return (
		<div className={`order-list-item ${active ? "active" : ""}`} onClick={handleViewOrderDetails}>
			<div className="image-set-wrapper">
				<img src={thumbnail} alt="orders" className="order-image" />
				{/* <img src="https://dummyimage.com/100x100.png" alt="orders" className="order-image-1" />
				<img src="https://dummyimage.com/100x100.png" alt="orders" className="order-image-2" /> */}
			</div>
			<div className="order-item-summary">
				<div className="flex justify-between items-center mb-2">
					<span className="order-no">#{orderNo}</span>
					<span className="order-date">{moment(orderDate).format("DD/M/yyyy")}</span>
				</div>
				<div className="order-item-title">{description}</div>
			</div>
		</div>
	);
};

export default withRouter(MyOrdersContainer);

import React, { useRef } from "react";
import "./style.scss";
import IconAdd from "app/assets/Authentication/icon-add.svg";
import IconRemove from "app/assets/Agent/icon_remove_media.png";

const MediaUpload = ({ thumbnail, fileName, isUploaded, onUpload, onRemove, thumbnailClassName, uploadClassName }) => {
	const imageRef = useRef();

	const browsePicture = () => {
		imageRef.current.click();
	};

	const handleImageChange = (event) => {
		if (typeof onUpload === "function") {
			onUpload(event);
		}
	};

	const handleRemoveImage = (event) => {
		event.preventDefault();

		if (typeof onRemove === "function") {
			onRemove(isUploaded || false);
		}
	};

	// Show image thumbnail
	if (thumbnail) {
		return (
			<div className="media-upload-wrapper">
				<div className={`media-upload ${thumbnailClassName}`} onClick={browsePicture}>
					<img src={thumbnail} alt="thumbnail" className="img-fluid img-media" />
					{fileName && <div className="filename">{fileName}</div>}
					<input
						type="file"
						name="image"
						onChange={handleImageChange}
						value={""}
						ref={imageRef}
						className="hidden"
						// accept="image/*"
					/>
				</div>
				<img src={IconRemove} alt="remove" className="icon-remove" onClick={handleRemoveImage} />
			</div>
		);
	}

	// No image thumbnail, show add icon
	return (
		<div className={`media-upload-add ${uploadClassName}`} onClick={browsePicture}>
			<img src={IconAdd} alt="Add" className="icon-add" />
			<input
				type="file"
				name="image"
				onChange={handleImageChange}
				value={""}
				ref={imageRef}
				className="hidden"
				// accept="image/*"
			/>
		</div>
	);
};

export default MediaUpload;

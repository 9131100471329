import * as types from "./types";

/**
 * Set event settings
 * @param {object} eventSettings
 * @returns
 */
export const setEventSettings = (eventSettings) => ({
	type: types.SET_EVENT_SETTINGS,
	params: {
		eventSettings,
	},
});

/**
 * Set checkout event ticket
 * @param {boolean} isCheckoutEventTicket
 * @returns
 */
export const setIsCheckoutEventTicket = (isCheckoutEventTicket) => ({
	type: types.SET_IS_CHECKOUT_EVENT_TICKET,
	params: {
		isCheckoutEventTicket,
	},
});

/**
 * Set ticket payment status
 * @param {string} ticketPaymentStatus
 * @returns
 */
export const setTicketPaymentStatus = (ticketPaymentStatus) => ({
	type: types.SET_TICKET_PAYMENT_STATUS,
	params: {
		ticketPaymentStatus,
	},
});

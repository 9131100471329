import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import IconClose from "app/assets/icon_close.svg";
import "./style.scss";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import ProfileContainer from "../../ProfileContainer/ProfileContainer";
import IconProfile from "app/assets/Profile/Profile_active.png";
import IconProfileInactive from "app/assets/Profile/Profile_inactive.png";
import IconOrder from "app/assets/Profile/myorder_active.png";
import IconOrderInactive from "app/assets/Profile/myorder_inactive.png";
import IconCart from "app/assets/icon_cart_active.png";
import IconCartInactive from "app/assets/icon_cart_inactive.png";
import IconLocation from "app/assets/icon_location_active.png";
import IconLocationInactive from "app/assets/icon_location_active.png";
import LocalStorageService from "app/services/localStorageService";
import IconLogout from "app/assets/icon-logout.png";
import TokenService from "app/services/tokenService";
import { channelSocket, exhibitorSocket, agentSocket } from "app/services/socketService";
import AuthenticationAPI from "app/apis/authentication";
import MyOrdersContainer from "../../Order/MyOrdersContainer/MyOrdersContainer";
import MySavedOrdersContainer from "../../Order/MySavedOrdersContainer/MySavedOrdersContainer";
import { authenticationActions } from "app/states/authentication";
import AddressManagementContainer from "../../Address/AddressManagementContainer";

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`profile-tabpanel-${index}`}
			aria-labelledby={`profile-tabpanel-${index}`}
			{...other}>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

function tabIndexProps(index) {
	return {
		id: `profile-tabpanel-${index}`,
		"aria-controls": `profile-tabpanel-${index}`,
	};
}

const ProfileModal = ({ history }) => {
	const eventSetting = LocalStorageService.getEventSetting();
	const { eventCode } = useParams();

	const dispatch = useDispatch();
	const closeProfileModal = () => dispatch(commonActions.closeProfileModal());
	const openJoinPrivateChatModal = () => dispatch(commonActions.openJoinPrivateChatModal());
	const setProfileModalTabIndex = (tabIndex) => dispatch(commonActions.setProfileModalTabIndex(tabIndex));
	const setIsLoggedIn = (isLogin) => dispatch(authenticationActions.setIsLoggedIn(isLogin));
	const setUserProfile = (userProfile) => dispatch(authenticationActions.setUserProfile(userProfile));

	const showModal = useSelector(({ common }) => common.showProfileModal);
	const profileModalTabIndex = useSelector(({ common }) => common.profileModalTabIndex);
	const hasPendingPrivateChatInvitation = useSelector(({ exhibitor }) => exhibitor.hasPendingPrivateChatInvitation);

	const [tabIndex, setTabIndex] = useState(profileModalTabIndex || 0);

	useEffect(() => {
		setTabIndex(profileModalTabIndex);
	}, [profileModalTabIndex]);

	const handleClose = () => {
		if (hasPendingPrivateChatInvitation) {
			openJoinPrivateChatModal();
		}

		closeProfileModal();
	};

	const handleTabChange = (event, newTabIndex) => {
		setProfileModalTabIndex(newTabIndex);
	};

	const handleLogout = () => {
		// Disconnect socket
		channelSocket.disconnect();
		agentSocket.disconnect();
		exhibitorSocket.disconnect();

		setIsLoggedIn(false);
		setUserProfile(null);
		AuthenticationAPI.postLogout();
		TokenService.clearTokens();
		LocalStorageService.clearAll();
		closeProfileModal();
		history.push(`/events/${eventCode}`);
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="profile-modal">
			<div className="app-modal profile-modal">
				<div className="modal-header flex justify-between items-center w-full">
					<Tabs
						value={tabIndex}
						onChange={handleTabChange}
						aria-label="Profile Tabs"
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth">
						<Tab
							icon={
								<img
									src={tabIndex === 0 ? IconProfile : IconProfileInactive}
									alt="My Profile"
									className="tab-icon"
								/>
							}
							label={
								<span className={`tab-label ${tabIndex === 0 ? "tab-label-active" : ""}`}>
									My Profile
								</span>
							}
							{...tabIndexProps(0)}
							className="tab"
						/>
						{eventSetting && eventSetting.ecommerce_enabled === 1 && (
							<Tab
								icon={
									<img
										src={tabIndex === 1 ? IconOrder : IconOrderInactive}
										alt="My Orders"
										className="tab-icon"
									/>
								}
								label={
									<span className={`tab-label ${tabIndex === 1 ? "tab-label-active" : ""}`}>
										My Orders
									</span>
								}
								{...tabIndexProps(1)}
								className="tab"
							/>
						)}
						{eventSetting && eventSetting.ecommerce_enabled === 1 && (
							<Tab
								icon={
									<img
										src={tabIndex === 2 ? IconCart : IconCartInactive}
										alt="My Cart"
										className="tab-icon"
									/>
								}
								label={
									<span className={`tab-label ${tabIndex === 2 ? "tab-label-active" : ""}`}>
										My Cart
									</span>
								}
								{...tabIndexProps(2)}
								className="tab"
							/>
						)}
						<Tab
							icon={
								<img
									src={tabIndex === 3 ? IconLocation : IconLocationInactive}
									alt="My Addresses"
									className="tab-icon"
								/>
							}
							label={
								<span className={`tab-label ${tabIndex === 3 ? "tab-label-active" : ""}`}>
									My Addresses
								</span>
							}
							{...tabIndexProps(3)}
							className="tab"
						/>
					</Tabs>

					{/* <span className="btn-close">
						<img src={IconClose} alt="Close" onClick={handleClose} />
					</span> */}

					{tabIndex === 0 && (
						<span className="btn-logout" onClick={handleLogout}>
							<img src={IconLogout} alt="Close" className="icon-logout" /> Logout
						</span>
					)}
				</div>
				<div className="modal-content">
					<TabPanel value={tabIndex} index={0} className="tab-panel profile-tab-panel p-5">
						<ProfileContainer />
					</TabPanel>
					<TabPanel value={tabIndex} index={1} className="tab-panel order-tab-panel">
						<MyOrdersContainer />
					</TabPanel>
					<TabPanel value={tabIndex} index={2} className="tab-panel saved-order-tab-panel">
						<MySavedOrdersContainer />
					</TabPanel>
					<TabPanel value={tabIndex} index={3} className="tab-panel address-tab-panel">
						<AddressManagementContainer />
					</TabPanel>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(ProfileModal);

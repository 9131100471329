import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import IconDeleteRed from "app/assets/icon_delete_red.png";
import { addressActions } from "app/states/address";
import ProfileAPI from "app/apis/profile";
import { profileActions } from "app/states/profile";
import { commonActions } from "app/states/common";

const ConfirmDeleteAddressModal = ({ history }) => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const closeConfirmDeleteAddressModal = () => dispatch(addressActions.closeConfirmDeleteAddressModal());
	const closeEditAddressModal = () => dispatch(addressActions.closeEditAddressModal());
	const refreshAddressList = () => dispatch(profileActions.refreshAddressList());

	const showModal = useSelector(({ address }) => address.showConfirmDeleteAddressModal);
	const viewingAddress = useSelector(({ address }) => address.viewingAddress);

	const handleClose = () => {
		closeConfirmDeleteAddressModal();
	};

	const handleNo = () => {
		handleClose();
	};

	const handleYes = () => {
		const { id } = viewingAddress;

		ProfileAPI.deleteAddress(id)
			.then((response) => {
				refreshAddressList();
				closeConfirmDeleteAddressModal();
				closeEditAddressModal();

				openAlertSnackbar("Delete successfully", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="confirm-delete-address-modal">
			<div className="app-modal confirm-delete-address-modal">
				<div className="icon-container flex justify-center items-center">
					<img src={IconDeleteRed} alt="Delete" />
				</div>
				<div className="modal-content">
					<h1 className="modal-title mb-3 mt-12">Delete Confirmation</h1>
					<p className="mb-10">Are you sure you want to delete this address?</p>

					<div className="flex justify-center items-center">
						<button className="btn btn-primary-outline" onClick={handleNo}>
							Cancel
						</button>
						<button className="btn btn-danger" onClick={handleYes}>
							Delete
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(ConfirmDeleteAddressModal);

import Countdown from "app/views/pages/Countdown/Countdown";
import Channel from "app/views/pages/Channel/Channel";
import CompleteProfile from "app/views/pages/CompleteProfile";
import Exhibitor from "app/views/pages/Exhibitor/Exhibitor";
import AgentMobile from "app/views/pages/Agent_Mobile/AgentMobile";
import AgentEngagements from "app/views/pages/AgentEngagements/AgentEngagements";
import AgentContents from "app/views/pages/AgentContents/AgentContents";
import AgentOrders from "app/views/pages/AgentOrders/AgentOrders";
import MobileProfilePage from "app/views/pages/MobileProfilePage/MobileProfilePage";
import SocialSignUpAddMobile from "app/views/pages/SocialSignUpAddMobile";
import MobileProfileOrderPage from "app/views/pages/MobileProfileOrderPage/MobileProfileOrderPage";
import EventRegistration from "app/views/pages/Authentication/EventRegistration/EventRegistration";
import ShippingAddressForm from "app/views/pages/Authentication/ShippingAddressForm/ShippingAddressForm";

const PrivateRoutes = [
	{
		path: "/events/:eventCode/register/social/add-mobile",
		exact: true,
		label: "Register Using Social Media Add Mobile Number Page",
		component: SocialSignUpAddMobile,
	},
	{
		path: "/events/:eventCode/countdown",
		exact: true,
		label: "Countdown Page",
		component: Countdown,
	},
	{
		path: "/events/:eventCode/complete-profile",
		exact: true,
		label: "Complete Profile Page",
		component: EventRegistration,
	},
	{
		path: "/:eventCode/profile/address",
		exact: true,
		label: "Shipping Address Page",
		component: ShippingAddressForm,
	},
	{
		path: "/events/:eventCode/profile",
		exact: true,
		label: "Mobile Profile Page",
		component: MobileProfilePage,
	},
	{
		path: "/events/:eventCode/profile/orders",
		exact: true,
		label: "Mobile Profile Order Page",
		component: MobileProfileOrderPage,
	},
	{
		path: "/events/:eventCode/channel",
		exact: true,
		label: "Channel Page",
		component: Channel,
	},
	{
		path: "/events/:eventCode/channel/session/:sessionId",
		exact: true,
		label: "Channel Session Page",
		component: Channel,
	},
	{
		path: "/events/:eventCode/exhibitor",
		exact: true,
		label: "Exhibitor Page",
		component: Exhibitor,
	},
	{
		path: "/events/:eventCode/exhibitor/room/:roomId",
		exact: true,
		label: "Exhibitor Room Page",
		component: Exhibitor,
	},
	{
		path: "/events/:eventCode/agent",
		exact: true,
		label: "Agent Page",
		component: AgentMobile,
	},
	{
		path: "/events/:eventCode/agent/engagements",
		exact: true,
		label: "Agent Engagements Page",
		component: AgentEngagements,
	},
	{
		path: "/events/:eventCode/agent/contents",
		exact: true,
		label: "Agent Contents Page",
		component: AgentContents,
	},
	{
		path: "/events/:eventCode/agent/orders",
		exact: true,
		label: "Agent Orders Page",
		component: AgentOrders,
	},
];

export default PrivateRoutes;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { exhibitorActions } from "app/states/exhibitor";
import { orderActions } from "app/states/order";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import Slider from "react-slick";
import ImageNext from "app/assets/Channels_Exhibitors/image_next.png";
import ImagePrevious from "app/assets/Channels_Exhibitors/image_previous.png";
import IconPlus from "app/assets/Channels_Exhibitors/plus.png";
import IconMinus from "app/assets/Channels_Exhibitors/minus.png";
import ExhibitorAPI from "app/apis/exhibitor";
import { numberWithCommas } from "app/helper/common";
import OrderAPI from "app/apis/order";
import { commonActions } from "app/states/common";
import { PAYMENT_SETTINGS } from "app/config/settings";

const ProductDetailModal = ({ history }) => {
	const { roomId } = useParams();

	const dispatch = useDispatch();
	const closeProductDetailModal = () => dispatch(exhibitorActions.closeProductDetailModal());
	const addToCart = (product) => dispatch(orderActions.addToCart(product));
	const removeFromCart = (productId) => dispatch(orderActions.removeFromCart(productId));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const refreshMyCart = () => dispatch(orderActions.refreshMyCart());

	const showModal = useSelector(({ exhibitor }) => exhibitor.showProductImageModal);
	const exhibitorStoreId = useSelector(({ exhibitor }) => exhibitor.exhibitorStoreId);
	const viewingProductId = useSelector(({ exhibitor }) => exhibitor.viewingProductId);
	const cart = useSelector(({ order }) => order.cart);
	const eventSettings = useSelector(({ event }) => event.eventSettings);

	const sliderRef = useRef();

	const [qtyInCart, setQtyInCart] = useState(0);
	const [productCount, setProductCount] = useState(0);
	const [productDetails, setProductDetails] = useState({
		id: null,
		name: "",
		currency: "",
		price: "",
		description: "",
		variants: {},
	});
	const [productSkus, setProductSkus] = useState([]);
	const [selectedSkuId, setSelectedSkuId] = useState(null);
	const [selectedVariants, setSelectedVariants] = useState({});

	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [productImages, setProductImages] = useState([]);
	const [doneGetDetail, setDoneGetDetail] = useState(false);

	// Get product details
	useEffect(() => {
		if (!viewingProductId) return;

		ExhibitorAPI.getExhibitorRoomStoreProductDetail(roomId, exhibitorStoreId, viewingProductId)
			.then((response) => {
				const { id, name, currency, price, description, images, variants, skus } = response;
				let productPrice = price;

				setProductSkus(skus);
				setProductImages(images);

				// Set selected sku id
				if (skus.length > 0) {
					setSelectedSkuId(skus[0].id);

					// Set selected sku price
					productPrice = skus[0].price;
				}

				// Set default selected variants
				let defaultSelectedVariants = {};
				Object.keys(variants).forEach((variantKey) => {
					if (variants[variantKey].length > 0) {
						defaultSelectedVariants[variantKey] = variants[variantKey][0];
					}
				});
				setSelectedVariants(defaultSelectedVariants);

				// Set product details
				setProductDetails({
					id,
					name,
					currency,
					price: productPrice,
					description,
					variants,
				});

				setDoneGetDetail(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [viewingProductId]);

	// Get the user's saved order
	useEffect(() => {
		if (!viewingProductId && !doneGetDetail) return;

		OrderAPI.getSavedOrderByExhibitorRoom(roomId)
			.then((response) => {
				const { products } = response;

				// Find the product id in cart, to populate quantity
				let savedOrderProductDetail = products.find((product) => product.id === viewingProductId);
				if (savedOrderProductDetail) {
					const { color, option, size } = savedOrderProductDetail.sku;
					setSelectedVariants({
						colors: color,
						options: option,
						sizes: size,
					});
					setProductCount(savedOrderProductDetail.quantity);
					setQtyInCart(savedOrderProductDetail.quantity);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [viewingProductId, doneGetDetail]);

	const handleClose = () => {
		resetStates();
		closeProductDetailModal();
	};

	const handleChangeImage = (index) => {
		setCurrentImageIndex(index);
		sliderRef.current.slickGoTo(index);
	};

	const handleAddProductCount = () => {
		let newQuantityCount = productCount + 1;
		setProductCount(newQuantityCount);

		// Check if got same product added to cart
		let cartProduct = cart.find((product) => product.id === productDetails.id);
		if (cartProduct) {
			// If product is added to cart, update product quantity count
			cartProduct.quantity = newQuantityCount;
		} else {
			// Add product to cart
			let cartProductDetails = {
				...productDetails,
				quantity: newQuantityCount,
			};

			addToCart(cartProductDetails);
		}
	};

	const handleMinusProductCount = () => {
		if (productCount <= 0) return;

		let newQuantityCount = productCount - 1;
		setProductCount(newQuantityCount);

		let cartProduct = cart.find((product) => product.id === productDetails.id);
		if (cartProduct) {
			// If cart product quantity is larger than 1, deduct quantity
			if (cartProduct.quantity > 1) {
				cartProduct.quantity = newQuantityCount;
			}
			// Else, remove from cart
			else {
				removeFromCart(productDetails.id);
			}
		}
	};

	const resetStates = () => {
		setCurrentImageIndex(0);
		setProductCount(0);
		setProductDetails({
			id: null,
			name: "",
			currency: "",
			price: "",
			description: "",
			variants: {},
		});
		setDoneGetDetail(false);
	};

	const handleBack = () => {
		resetStates();
		closeProductDetailModal();
	};

	const handleUpdate = () => {
		if (productCount <= 0 && qtyInCart === 0) {
			openAlertSnackbar("Quantity is required.", "error");
			return;
		}

		const data = {};

		if (productCount === 0) {
			// Delete product from cart when quantity is 0
			const deleteProduct = {
				product_id: viewingProductId,
			};

			if (deleteProduct) {
				deleteProduct["sku_id"] = selectedSkuId;
			}

			data["delete_products"] = [deleteProduct];
		} else {
			// Construct update product data
			const product = {
				product_id: viewingProductId,
				quantity: productCount,
			};

			if (selectedSkuId) {
				product["sku_id"] = selectedSkuId;
			}

			data["update_products"] = [product];
		}

		OrderAPI.postSaveOrderByExhibitorRoom(roomId, data)
			.then((response) => {
				refreshMyCart();
				openAlertSnackbar("Updated successfully.", "success");
				handleBack();
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleSelectVariant = (key, value) => {
		// Update selected variants
		let updatedSelectedVariants = { ...selectedVariants };
		updatedSelectedVariants[key] = value;
		setSelectedVariants(updatedSelectedVariants);

		productSkus.forEach((productSku) => {
			const { id, color, option, size, price } = productSku;

			if (
				updatedSelectedVariants["sizes"] === size &&
				updatedSelectedVariants["colors"] === color &&
				updatedSelectedVariants["options"] === option
			) {
				// Update selected sku_id
				setSelectedSkuId(id);

				// Update product price when select different variant
				setProductDetails({
					...productDetails,
					price: price,
				});
			}
		});
	};

	const PrevArrow = (props) => {
		const { className, style, onClick } = props;
		return (
			<div className={`${className} btn-next-previous`} style={{ ...style }} onClick={onClick}>
				<img src={ImagePrevious} alt="previous" />
			</div>
		);
	};

	const NextArrow = (props) => {
		const { className, style, onClick } = props;
		return (
			<div className={`${className} btn-next-previous`} style={{ ...style }} onClick={onClick}>
				<img src={ImageNext} alt="next" />
			</div>
		);
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="product-image-modal">
			<div className="app-modal mobile-app-modal product-image-modal">
				<div className="product-detail-container">
					<section className="content-container">
						<div className="grid grid-cols-12 gap-3">
							<section className="col-span-12 lg:col-span-9">
								<section className="grid grid-cols-12 gap-3 lg:gap-8">
									<div className="col-span-12 md:col-span-7">
										<Slider
											className="product-image-carousel"
											ref={sliderRef}
											dots={false}
											slidesToShow={1}
											slidesToScroll={1}
											nextArrow={<NextArrow />}
											prevArrow={<PrevArrow />}>
											{productImages.map((productImage) => {
												const { id, image_url } = productImage;
												return (
													<div key={id}>
														<img src={image_url} alt="thumbnail" />
													</div>
												);
											})}
										</Slider>
									</div>
									<div className="col-span-12 md:col-span-5 product-images">
										<div className="grid grid-cols-12 gap-3">
											{productImages.map((productImage, index) => {
												const { id, image_url } = productImage;
												return (
													<div key={id} className="col-span-3 md:col-span-4 m-auto">
														<img
															src={image_url}
															alt="thumbnail"
															className={currentImageIndex === index ? "img-active" : ""}
															onClick={() => handleChangeImage(index)}
														/>
													</div>
												);
											})}
										</div>
									</div>
								</section>

								<section className="product-details-section">
									<div className="flex justify-between items-end mb-2">
										<h1 className="product-name">{productDetails.name}</h1>
									</div>
									<div className="product-price">{`${productDetails.currency}${numberWithCommas(
										productDetails.price
									)}`}</div>
									<div className="product-description">{productDetails.description}</div>
								</section>
							</section>

							{/* Only show quantity control if not ecommerce mode */}
							{!eventSettings.payment_settings.includes(PAYMENT_SETTINGS["ecommerce"]) && (
								<section className="col-span-12 lg:col-span-3">
									<div className="variant-container">
										{Object.keys(productDetails.variants).map((variantKey) => {
											return (
												<div key={variantKey} className="variant-option-wrapper">
													<div className="text-variant-label">{variantKey.toUpperCase()}</div>
													<div className="flex items-center flex-wrap">
														{productDetails.variants[variantKey].map((variantValue) => {
															return (
																<div
																	key={variantValue}
																	className={`variant-selection ${
																		Object.keys(selectedVariants).length > 0 &&
																		selectedVariants[variantKey] === variantValue
																			? "variant-selected"
																			: ""
																	}`}
																	onClick={() =>
																		handleSelectVariant(variantKey, variantValue)
																	}>
																	<span>{variantValue}</span>
																</div>
															);
														})}
													</div>
												</div>
											);
										})}

										<div className="variant-option-wrapper">
											<div className="text-variant-label">Quantity</div>
											<div className="product-count-control flex items-center">
												<img
													src={IconMinus}
													alt="minus"
													className="inline cursor-pointer"
													onClick={handleMinusProductCount}
												/>
												<input
													className="product-count-input"
													value={productCount}
													readOnly></input>
												<img
													src={IconPlus}
													alt="plus"
													className="inline cursor-pointer"
													onClick={handleAddProductCount}
												/>
											</div>
										</div>
									</div>
								</section>
							)}
						</div>
					</section>

					<section className="store-footer">
						<button type="button" className="btn-back mr-4" onClick={handleBack}>
							Back
						</button>
						{/* Only show quantity control if not ecommerce mode */}
						{!eventSettings.payment_settings.includes(PAYMENT_SETTINGS["ecommerce"]) && (
							<button type="button" className="btn-update" onClick={handleUpdate}>
								Update
							</button>
						)}
					</section>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(ProductDetailModal);

import LocalStorageService from 'app/services/localStorageService'
import React from 'react'
import './style.scss'

const BrandLogoMobile = () => {
    const webConfig = LocalStorageService.getWebConfig();

    return (
        <div className="brand-logo-mobile-wrapper">
            <img src={webConfig.logo_url} alt="logo" />
        </div>
    )
}

export default BrandLogoMobile;
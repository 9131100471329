import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconDefaultProfile from "app/assets/icon-default-user.png";

const Speaker = ({ image, name, organization }) => {
	const [showBorder, setShowBorder] = useState(false);

	const handlImageOnError = (event) => {
		event.target.onerror = null;
		event.target.src = IconDefaultProfile;
		setShowBorder(false);
	};

	return (
		<div className="speaker-item flex items-center">
			<object className={`speaker-image-object mr-1 lg:mr-2`}>
				<img
					src={image || IconDefaultProfile}
					alt={name}
					className={`speaker-image mr-1 lg:mr-2 ${!showBorder ? "no-border" : ""}`}
					onError={handlImageOnError}
				/>
			</object>
			<div>
				<div className="speaker-name">{name}</div>
				<div className="speaker-org">{organization}</div>
			</div>
		</div>
	);
};

const SpeakersContainer = ({ speakers }) => {
	return (
		<div className="speaker-container lg:p-3">
			{speakers.length > 0 && (
				<>
					{/* Desktop View */}
					<div className="hidden lg:block">
						<div className="title mb-3">Speakers</div>
						<div className="speakers-list grid grid-cols-12 gap-3">
							{speakers.map((speaker) => {
								const { name, company, image_url } = speaker;

								return (
									<div className="col-span-4" key={name}>
										<Speaker image={image_url} name={name} organization={company} />
									</div>
								);
							})}
						</div>
					</div>

					{/* Mobile View */}
					<div className="block lg:hidden">
						<Accordion elevation={0} className="accordion-wrapper">
							<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="accordion-wrapper">
								<div className="title">Speakers</div>
							</AccordionSummary>
							<AccordionDetails className="accordion-body">
								<div className="speakers-list grid grid-cols-12 gap-3">
									{speakers.map((speaker) => {
										const { name, company, image_url } = speaker;

										return (
											<div className="col-span-4" key={name}>
												<Speaker image={image_url} name={name} organization={company} />
											</div>
										);
									})}
								</div>
							</AccordionDetails>
						</Accordion>
					</div>
				</>
			)}
		</div>
	);
};

export default withRouter(SpeakersContainer);

const SessionStorageService = {
	// Determine whether there is private chat invitation
	setHasPcInvitation: (hasInvitation) => {
		sessionStorage.setItem("hasPcInvitation", hasInvitation);
	},

	getHasPcInvitation: () => {
		return sessionStorage.getItem("hasPcInvitation");
	},

	removeHasPcInvitation: () => {
		// To allow private chat invitation again
		sessionStorage.removeItem("hasPcInvitation");
	},

	// Set the current channel tab index
	setChannelTabIndex: (index) => {
		sessionStorage.setItem("channelTabIndex", index);
	},

	getChannelTabIndex: () => {
		return parseInt(sessionStorage.getItem("channelTabIndex"));
	},

	removeChannelTabIndex: () => {
		sessionStorage.removeItem("channelTabIndex");
	},

	// Set the current exhibitor tab index
	setExhibitorTabIndex: (index) => {
		sessionStorage.setItem("exhibitorTabIndex", index);
	},

	getExhibitorTabIndex: () => {
		return parseInt(sessionStorage.getItem("exhibitorTabIndex"));
	},

	removeExhibitorTabIndex: () => {
		sessionStorage.removeItem("exhibitorTabIndex");
	},

	// Set the selected date filter in channel
	setChannelListSelectedDateFilter: (selectedDate) => {
		sessionStorage.setItem("channelDateFilter", selectedDate);
	},

	getChannelListSelectedDateFilter: () => {
		return sessionStorage.getItem("channelDateFilter");
	},

	removeChannelListSelectedDateFilter: () => {
		sessionStorage.removeItem("channelDateFilter");
	},

	// Disable update exhibitor details in agent content page
	disableUpdateExhibitorDetailsInAgentPage: () => {
		sessionStorage.setItem("disableUpdateExhibitorDetailsInAgentPage", true);
	},

	getDisableUpdateExhibitorDetailsInAgentPage: () => {
		return sessionStorage.getItem("disableUpdateExhibitorDetailsInAgentPage");
	},

	removeDisableUpdateExhibitorDetailsInAgentPage: () => {
		sessionStorage.removeItem("disableUpdateExhibitorDetailsInAgentPage");
	},

	// Set cart products for ordered status viewing (after place order)
	setCartProductsOrdered: (cartProductsOrdered) => {
		sessionStorage.setItem("cartProductsOrdered", JSON.stringify(cartProductsOrdered));
	},

	getCartProductsOrdered: () => {
		return JSON.parse(sessionStorage.getItem("cartProductsOrdered"));
	},

	removeCartProductsOrdered: () => {
		sessionStorage.removeItem("cartProductsOrdered");
	},
};

export default SessionStorageService;

import LoginRegister from "../views/pages/Authentication/LoginRegister/LoginRegister";
import ResetPassword from "../views/pages/ResetPassword";
import VerifyEmailSuccess from "../views/pages/VerifyEmailSuccess/VerifyEmailSuccess";
import VerifyEmail from "./../views/pages/VerifyEmail";
import RootPage from "./../views/pages/RootPage";
import LandingPage from "app/views/pages/LandingPage/LandingPage";

const PublicRoutes = [
	{
		path: "/",
		exact: true,
		label: "Root Page",
		component: RootPage,
	},
	{
		path: "/events",
		exact: true,
		label: "Root Page",
		component: RootPage,
	},
	{
		path: "/events/:eventCode",
		exact: true,
		label: "Landing Page",
		component: LandingPage,
	},
	{
		path: "/events/:eventCode/login",
		exact: true,
		label: "Login Register Page",
		component: LoginRegister,
	},
	{
		path: "/events/:eventCode/reset-password",
		exact: true,
		label: "Reset Password Page",
		component: ResetPassword,
	},
	{
		path: "/events/:eventCode/verify-email",
		exact: true,
		label: "Verify Email Page",
		component: VerifyEmail,
	},
	{
		path: "/events/:eventCode/verify-email/success",
		exact: true,
		label: "Verify Email Success Page",
		component: VerifyEmailSuccess,
	},
];

export default PublicRoutes;

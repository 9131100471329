import AdvertisementAPI from "app/apis/advertisement";
import { ACTIVITY_CODE } from "app/config/settings";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import EventAPI from "app/apis/event";

const ADS_TYPE = {
	leaderboard_banner: 1, // Long
	customized_leaderboard_banner: 2, // Short
};

const Advertisement = ({ type }) => {
	const [advertisements, setAdvertisements] = useState([]);
	const [rotateInterval, setRotateInterval] = useState(5);

	const [adsId, setAdsId] = useState(null);
	const [adsThumbnailUrl, setAdsThumbnailUrl] = useState("");
	const [adsUrl, setAdsUrl] = useState("");

	useEffect(() => {
		let params = {};

		if (type) {
			params = {
				type: ADS_TYPE[type],
			};
		}

		AdvertisementAPI.getAdvertisements(params)
			.then((response) => {
				const { rotation_interval, ads } = response;
				setRotateInterval(rotation_interval);
				setAdvertisements(ads);
				
				// Set first ads as default
				if (ads.length > 0) {
					const { id, thumbnail_url, content_url } = ads[0];
                    
					setAdsThumbnailUrl(thumbnail_url);
					setAdsUrl(content_url);
					setAdsId(id);

					// Consider view ads one time when view page
					logActivity(ACTIVITY_CODE["view_ads"], id);
				}
			})
			.catch((error) => {
				console.log("Ads error: ", error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Do not run if no advertisement
		if (advertisements.length === 0) {
			return;
		}

		// Right after get ads API, next index should be 1 in order to rotate to next
		let index = advertisements.length > 1 ? 1 : 0;

		const interval = setInterval(() => {
			// If advertisement only got 1, then run once only and stop rotating
			if (advertisements.length === 1) {
				clearInterval(interval);
			}

			// If the index reach the last advertisement, reset to first one and continue
			index = advertisements.length - 1 === index ? 0 : index + 1;

			setAdsThumbnailUrl(thumbnailUrl => advertisements[index].thumbnail_url);
			setAdsUrl(adsUrl => advertisements[index].content_url);
			setAdsId(adsId => advertisements[index].id);

			// Update ads view count on every ads rotation
			logActivity(ACTIVITY_CODE["view_ads"], advertisements[index].id);
		}, rotateInterval);

		return () => {
			clearInterval(interval);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advertisements, rotateInterval]);

	const logActivity = (code, targetId) => {
		if (targetId <= 0) return;
		
		EventAPI.postLogActivity(code, targetId)
			.then(response => {
				// success
			})
			.catch(error => {
				console.error(error);
			});
	}

	const handleAdsClick = (event) => {
		event.preventDefault();

		logActivity(ACTIVITY_CODE["click_ads"], adsId);
		window.open(adsUrl);
	}

    if (!adsThumbnailUrl) {
        return <></>;
    }

	return (
		<a href={adsUrl} target="_blank" rel="noreferrer" className="advertisement" onClick={handleAdsClick}>
			<img src={adsThumbnailUrl} alt="advertisement" className="lg:rounded" />
		</a>
	);
};

export default withRouter(Advertisement);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import IconClose from "app/assets/icon_close.svg";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import ExhibitorAPI from "app/apis/exhibitor";
import LocalStorageService from "app/services/localStorageService";

const Person = ({ image, name, desc }) => {
	const [showBorder, setShowBorder] = useState(false);

	const handlImageOnError = (event) => {
		event.target.onerror = null;
		event.target.src = IconDefaultProfile;
		setShowBorder(false);
	};

	return (
		<div className="person-item flex items-center mb-3">
			<img
				src={image || IconDefaultProfile}
				alt={name}
				className={`person-image mr-2 ${!showBorder ? "no-border" : ""}`}
				onError={handlImageOnError}
			/>
			<div>
				<div className="person-name">{name}</div>
				<div className="person-desc">{desc}</div>
			</div>
		</div>
	);
};

const PrivateChatPeopleModal = ({ history }) => {
	const { roomId } = useParams();

	const userProfile = LocalStorageService.getUserProfile();

	const dispatch = useDispatch();
	const closePrivateChatPeopleModal = () => dispatch(commonActions.closePrivateChatPeopleModal());

	const showModal = useSelector(({ common }) => common.showPrivateChatPeopleModal);
	const exhibitorChatId = useSelector(({ exhibitor }) => exhibitor.exhibitorChatId);
	const isPrivateChat = useSelector(({ common }) => common.isPrivateChat);

	const [people, setPeople] = useState([]);

	useEffect(() => {
		if (isPrivateChat) {
			ExhibitorAPI.getPrivateChatDetail(roomId, exhibitorChatId)
				.then((response) => {
					const { visitors, agents } = response;

					setPeople([...agents, ...visitors]);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [showModal]);

	const handleClose = () => {
		closePrivateChatPeopleModal();
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="private-chat-people-modal">
			<div className="app-modal private-chat-people-modal">
				<div className="flex justify-end items-center">
					<span className="btn-close">
						<img src={IconClose} alt="Close" onClick={handleClose} />
					</span>
				</div>
				<div className="modal-content">
					<h1 className="modal-title mb-3">{people.length - 1} people in the chat with you</h1>
					<div className="people-list-container">
						{people.map((person) => {
							const { user_id, name, company, job_title, image_url, agent_id } = person;

							let formattedName = name;
							if (agent_id) {
								formattedName = `${name} (Agent)`;
							} else if (user_id === userProfile.id) {
								formattedName = `${name} (You)`;
							}

							return (
								<Person
									key={user_id}
									image={image_url}
									name={formattedName}
									desc={`${job_title} at ${company}`}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(PrivateChatPeopleModal);

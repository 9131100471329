import * as types from "./types";

/**
 * Edit address modal
 */
export const openEditAddressModal = () => ({
	type: types.OPEN_EDIT_ADDRESS_MODAL,
});

export const closeEditAddressModal = () => ({
	type: types.CLOSE_EDIT_ADDRESS_MODAL,
});

// Viewing address
export const setViewingAddress = (address) => ({
	type: types.SET_VIEWING_ADDRESS,
	params: {
		address,
	},
});

/**
 * Confirm delete address modal
 */
export const openConfirmDeleteAddressModal = () => ({
	type: types.OPEN_CONFIRM_DELETE_ADDRESS_MODAL,
});

export const closeConfirmDeleteAddressModal = () => ({
	type: types.CLOSE_CONFIRM_DELETE_ADDRESS_MODAL,
});

// set address modal open from
export const setAddressModalOpenFrom = (from) => ({
	type: types.SET_ADDRESS_MODAL_OPEN_FROM,
	params: {
		from,
	},
});
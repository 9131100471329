import moment from "moment"

export const getAllDaysOfMonth = (month = null) => {
    let currYear = moment().format('YYYY');
    let defaultMonth = month || moment().format('MMMM');

    let daysInMonth = moment(`${currYear}-${defaultMonth}`, "YYYY-MMMM").daysInMonth();
    let days = Array.from({length: daysInMonth}, (_, i) => i + 1)
    
    return days;
}

export const getAllMonths = () => {
    return moment.months();
}

export const getAllYears = () => {
    let years = []
    let dateStart = moment().add(-100, 'y')
    let dateEnd = moment();

    while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'))
        dateStart.add(1, 'year')
    }

    return years;
}
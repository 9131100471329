import SessionStorageService from "app/services/sessionStorageService";
import React from "react";
import { withRouter, Link } from "react-router-dom";
import "./style.scss";

const MenuButton = ({ icon, name, link, active, disabled }) => {
	
	const handleLinkOnClick = () => {
		SessionStorageService.removeChannelListSelectedDateFilter();
	}

	return (
		<Link to={link} className={`menu-button mb-5 flex flex-col justify-center items-center ${disabled ? "menu-disabled" : ""}`}
			onClick={() => handleLinkOnClick()}>
			<div className={`menu-icon-container ${active && "menu-active"} p-3 mb-1`}>
				<img src={icon} alt={name} className="w-full h-auto" />
			</div>
			<div className={`menu-name ${active && "menu-name-active"}`}>{name}</div>
		</Link>
	);
};

export default withRouter(MenuButton);

import React, { forwardRef } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import IconChevronDown from "app/assets/Agent/chevron_down.png";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const DateInput = ({ dateFormat, selectedDate, onChange, showTimeInput, showTimeSelectOnly }) => {
	
	const DateFilterInput = forwardRef(({ value, onClick }, ref) => {
		value = showTimeInput && showTimeSelectOnly ? value.toLowerCase() : value;

		return (
			<div className="date-filter-input">
				<input ref={ref} className="input-filter" type="text" value={value} onClick={onClick} readOnly />
				<img src={IconChevronDown} alt="filter date" className="icon-date-filter" onClick={onClick} />
			</div>
		);
	});

	return (
		<DatePicker
			dateFormat={dateFormat || "yyyy/MM/dd"}
			selected={selectedDate}
			onChange={onChange}
			customInput={<DateFilterInput />}
			timeInputLabel="Time:"
			showTimeInput={showTimeInput || false}
			showTimeSelectOnly={showTimeSelectOnly || false}
		/>
	);
};

export default (DateInput);

DateInput.propTypes = {
	selectedDate: PropTypes.any.isRequired,
	onChange: PropTypes.func,
};

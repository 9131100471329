import * as types from "./types";
import LocalStorageService from 'app/services/localStorageService';

const initialState = {
	eventSettings: LocalStorageService.getEventSetting() ?? null,
	isCheckoutEventTicket: false,
	ticketPaymentStatus: null,
};

const EventReducer = (state = initialState, action) => {
	switch (action.type) {
		// set event settings
		case types.SET_EVENT_SETTINGS:
			return {
				...state,
				eventSettings: action.params.eventSettings,
			};

		// set checkout event settings
		case types.SET_IS_CHECKOUT_EVENT_TICKET:
			return {
				...state,
				isCheckoutEventTicket: action.params.isCheckoutEventTicket,
			};

		// set ticket payment status
		case types.SET_TICKET_PAYMENT_STATUS:
			return {
				...state,
				ticketPaymentStatus: action.params.ticketPaymentStatus,
			};
		default:
			return state;
	}
};

export default EventReducer;

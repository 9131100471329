export const PAYMENT_METHODS = {
	1: "COD",
	2: "Bank Transfer",
	3: "Online Payment",
};

export const DELIVERY_METHODS = {
	1: "Self Pickup",
	2: "Meetup",
	3: "Delivery",
};

export const ORDER_STATUSES = {
	0: "Unpaid",
	1: "Paid",
};

export const EVENT_STATUS = {
	0: "Not Started",
	1: "Ongoing",
	2: "Paused",
	3: "Ended",
	4: "Inactive",
};

export const USER_EVENT_PAYMENT_STATUS = {
	0: "fail",
	1: "processing",
	2: "success",
	3: "fail",
};

export const ORDER_PAYMENT_STATUS = {
	UNPAID: 0,
	PROCESSING: 1,
	PAID: 2,
	FAILED: 3,
};

export const DELIVERY_METHODS_NAME = {
	self_pickup: {
		value: 1,
		name: "Self Pickup",
	},
	meetup: {
		value: 2,
		name: "Meetup",
	},
	delivery: {
		value: 3,
		name: "Delivery",
	},
};

export const PAYMENT_METHODS_NAME = {
	cod: {
		value: 1,
		name: "COD",
	},
	bank_transfer: {
		value: 2,
		name: "Bank Transfer",
	},
	online_payment: {
		value: 3,
		name: "Online Payment",
	},
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import moment from "moment";
import SendIcon from "@material-ui/icons/Send";
import LocalStorageService from "app/services/localStorageService";
import { useDispatch, useSelector } from "react-redux";
import { agentActions } from "app/states/agent";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import IconDefaultGroup from "app/assets/icon-group-users.png";
import IconSearch from "app/assets/Agent/icon_search.png";
import AgentAPI from "app/apis/agent";
import ReactLoading from "react-loading";
import IconAdd from "app/assets/Agent/icon_plus.png";
import CreatePrivateChatSuccessModal from "../CreatePrivateChatSuccessModal/CreatePrivateChatSuccessModal";
import CreatePrivateChatModal from "../CreatePrivateChatModal/CreatePrivateChatModal";
import { commonActions } from "app/states/common";
import DeleteChatModal from "../DeleteChatModal/DeleteChatModal";
import AddPrivateChatParticipants from "../AddPrivateChatParticipants/AddPrivateChatParticipants";
import RemovePrivateChatParticipants from "../RemovePrivateChatParticipants/RemovePrivateChatParticipants";
import { agentSocket } from "app/services/socketService";
import { urlify } from "app/helper/common";
import { CHAT_ACTIVITIES } from "app/config/settings";
import useStateRef from "react-usestateref";
import TextareaAutosize from "react-textarea-autosize";

const CHAT_MESSAGES_LIMIT = 20;

const ReceiverMessageComponent = ({ image, name, message, timestamp }) => {
	const [showBorder, setShowBorder] = useState(false);

	const handlImageOnError = (event) => {
		event.target.onerror = null;
		event.target.src = IconDefaultProfile;
		setShowBorder(false);
	};

	const yesterday = moment().subtract(1, "days").startOf("day");
	const isYesterday = moment(timestamp).isSame(yesterday, "day");
	const isToday = moment(timestamp).isSame(moment(), "day");

	let timestampFromNow = moment(timestamp).format("DD MMM, HH:mm");
	if (isYesterday) {
		timestampFromNow = moment(timestamp).format("[Yesterday], HH:mm");
	} else if (isToday) {
		timestampFromNow = moment(timestamp).format("HH:mm");
	}

	return (
		<div className="receiver mb-3">
			<div className="avatar mr-2">
				<img
					src={image || IconDefaultProfile}
					alt={name}
					className={`avatar-image ${!showBorder ? "no-border" : ""}`}
					onError={handlImageOnError}
				/>
			</div>
			<div className="message-body">
				<div className="person-name mb-1">{name}</div>
				<div className="message-text" dangerouslySetInnerHTML={{ __html: urlify(message) }} />
				<span className="message-time float-right">{timestampFromNow}</span>
			</div>
		</div>
	);
};

const SenderMessageComponent = ({ name, message, timestamp }) => {
	const [showBorder, setShowBorder] = useState(false);

	const handlImageOnError = (event) => {
		event.target.onerror = null;
		event.target.src = IconDefaultProfile;
		setShowBorder(false);
	};

	const yesterday = moment().subtract(1, "days").startOf("day");
	const isYesterday = moment(timestamp).isSame(yesterday, "day");
	const isToday = moment(timestamp).isSame(moment(), "day");

	let timestampFromNow = moment(timestamp).format("DD MMM, HH:mm");
	if (isYesterday) {
		timestampFromNow = moment(timestamp).format("[Yesterday], HH:mm");
	} else if (isToday) {
		timestampFromNow = moment(timestamp).format("HH:mm");
	}

	const userProfile = LocalStorageService.getUserProfile();

	return (
		<div className="sender mb-3">
			<div className="message-body">
				<div className="person-name mb-1">{name}</div>
				<div className="message-text" dangerouslySetInnerHTML={{ __html: urlify(message) }} />
				<span className="message-time">{timestampFromNow}</span>
			</div>
			<div className="avatar ml-2">
				<img
					src={userProfile.profile_image_url || IconDefaultProfile}
					alt={name}
					className={`avatar-image ${!showBorder ? "no-border" : ""}`}
					onError={handlImageOnError}
				/>
			</div>
		</div>
	);
};

const AgentChatContainer = ({ history, active }) => {
	const { eventCode } = useParams();
	const userProfile = LocalStorageService.getUserProfile();
	const userEventRole = LocalStorageService.getUserEventRole();

	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [maxPrivateChat, setMaxPrivateChat] = useState(0);
	const [publicChat, setPublicChat] = useState(null);
	const [privateChats, setPrivateChats] = useState([]);
	const [unreadMessageCount, setUnreadMessageCount] = useState({});

	const [currentChatId, setCurrentChatId, currentChatIdRef] = useStateRef(null);
	const [currentChatIndex, setCurrentChatIndex] = useState(0);
	const [currentChatTitle, setCurrentChatTitle] = useState("");
	const [currentChatLogo, setCurrentChatLogo] = useState(null);
	const [hasActiveVisitors, setHasActiveVisitors] = useState(false);

	const [currentChatEventHistory, setCurrentChatEventHistory] = useState([]);
	const [currentChatCreatedTime, setCurrentChatCreatedTime] = useState(null);

	const [socketData, setSocketData] = useState(null);
	const [triggerJoinChatEventCount, setTriggerJoinChatEventCount] = useState(0);
	const [triggerLeaveChatEventCount, setTriggerLeaveChatEventCount] = useState(0);
	const [newMessage, setNewMessage] = useState(null);
	const [lastMessageId, setLastMessageId] = useState(null);
	const [hasMoreOldMessages, setHasMoreOldMessages] = useState(true);
	const [loadingOldMessages, setLoadingOldMessages] = useState(false);
	const [doneResetStates, setDoneResetStates] = useState(false);
	const [doneFirstLoadMessages, setDoneFirstLoadMessage] = useState(false);

	const [inputMessage, setInputMessage] = useState("");
	const [messages, setMessages] = useState([]);
	const [joinedChat, setJoinedChat] = useState(false);
	const [showMoreOption, setShowMoreOption] = useState(false);
	const [isPrivateChat, setIsPrivateChat] = useState(false);

	const dispatch = useDispatch();
	const openCreatePrivateChatModal = () => dispatch(agentActions.openCreatePrivateChatModal());
	const refreshChatListing = (refresh) => dispatch(agentActions.refreshChatListing(refresh));
	const viewNewPrivateChat = (isView) => dispatch(agentActions.viewNewPrivateChat(isView));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const openDeleteChatModal = () => dispatch(agentActions.openDeleteChatModal());
	const setDeleteChatName = (name) => dispatch(agentActions.setDeleteChatName(name));
	const setDeleteChatId = (chatId) => dispatch(agentActions.setDeleteChatId(chatId));
	const openAddPrivateChatParticipants = () => dispatch(agentActions.openAddPrivateChatParticipants());
	const openRemovePrivateChatParticipants = () => dispatch(agentActions.openRemovePrivateChatParticipants());

	const isRefreshChatListing = useSelector(({ agent }) => agent.isRefreshChatListing);
	const isViewNewPrivateChat = useSelector(({ agent }) => agent.isViewNewPrivateChat);
	const newPrivateChatId = useSelector(({ agent }) => agent.newPrivateChatId);
	const deleteChatId = useSelector(({ agent }) => agent.deleteChatId);

	const messageScrollViewRef = useRef();
	const conversationRef = useRef();
	const replyContainerRef = useRef();

	useEffect(() => {
		if (userEventRole && userEventRole.agent) {
			getRoomChatListing();
		} else {
			history.replace(`/events/${eventCode}/channel`);
		}
	}, []);

	// Handle socket response
	useEffect(() => {
		agentSocket.on("room-update", (message) => {
			const { action_code, data } = message;
			// console.log("agentSocket", message);

			// Attendance update (new joiner)
			if (action_code === 100) {
				const { audience, time } = data;

				// Append to the chat event history
				const adjustedSocketData = {
					user: audience,
					time: time,
					dialog_id: null,
				};

				setSocketData(adjustedSocketData);
				setTriggerJoinChatEventCount((prevState) => prevState + 1);
			}

			// Attendance update (user leave)
			if (action_code === 101) {
				const { audience, time } = data;

				// Append to the chat event history
				const adjustedSocketData = {
					user: audience,
					time: time,
					dialog_id: null,
				};

				setSocketData(adjustedSocketData);
				setTriggerLeaveChatEventCount((prevState) => prevState + 1);
			}

			// New joiner in private chat, enable chat
			if (action_code === 401) {
				// Enable chat
				setHasActiveVisitors(true);

				// Append to the chat event history
				setSocketData(data);
				setTriggerJoinChatEventCount((prevState) => prevState + 1);
			}

			// User leave in private chat
			if (action_code === 402) {
				const { total } = data;

				// Disable chat if no more visitor
				if (total <= 0) {
					setHasActiveVisitors((prevState) => false);
				}

				// Append to the chat event history
				setSocketData(data);
				setTriggerLeaveChatEventCount((prevState) => prevState + 1);
			}

			// Private chat closed/disbanded
			if (action_code === 404) {
				const { chat_id } = data;

				// Remove from the chat listing
				setPrivateChats((prevStates) => {
					let privateChatsCopy = [...prevStates];
					let remainingPrivateChats = privateChatsCopy.filter((privateChatObject) => {
						return privateChatObject.chat_id !== chat_id;
					});

					return remainingPrivateChats;
				});
			}

			// New private chat created
			if (action_code === 405) {
				const { id, title, image_url, dialog_id, created_at } = data;

				// Add to the chat listing
				let newPrivateChat = {
					chat_id: id,
					dialog_id: dialog_id,
					title: title,
					image_url: image_url,
					created_at: created_at,
				};
				setPrivateChats((prevStates) => [...prevStates, newPrivateChat]);

				// Make all agents join the new private chat
				AgentAPI.postAgentJoinPrivateChat(newPrivateChat.chat_id)
					.then((response) => {
						//
					})
					.catch((error) => {
						console.error("Agent join chat error: ", error);
					});
			}

			// Chat Message arrived
			if (action_code === 407) {
				const { chat_id, user_id, message, name, sent_at } = data;

				// To detect the chat unread count
				// If userId not same as current logged in user id, add unread count,
				// So that it doesn't add unread count when current user send message
				if (user_id !== userProfile.id) {
					// Add unread count
					setUnreadMessageCount((prevStates) => {
						let unreadMessageCountCopy = { ...prevStates };
						let unreadCount = unreadMessageCountCopy[chat_id] || 0;

						// If currentChatId not same as the message chat id, add unread count
						if (chat_id !== currentChatIdRef.current) {
							return {
								...unreadMessageCountCopy,
								[chat_id]: unreadCount + 1,
							};
						}

						return unreadMessageCountCopy;
					});
				}

				// To detect the last message
				// Find the dialog id in the public/private chat list,
				// then change the last message to latest
				let isLastMessageSet = false;

				setPublicChat((prevState) => {
					let publicChatCopy = { ...prevState };

					if (publicChatCopy.chat_id === chat_id) {
						publicChatCopy.last_message = {
							message,
							name,
							sent_at,
							user_id,
						};
						isLastMessageSet = true;
					}

					return publicChatCopy;
				});

				// If not set yet, find the private chat
				if (!isLastMessageSet) {
					setPrivateChats((prevStates) => {
						let privateChatsCopy = [...prevStates];

						privateChatsCopy.forEach((privateChat) => {
							if (privateChat.chat_id === chat_id) {
								privateChat.last_message = {
									message,
									name,
									sent_at,
									user_id,
								};
							}
						});

						return privateChatsCopy;
					});
				}

				// Ensure agent in the current chat id, only append the message to the array
				setNewMessage(data);
			}
		});

		// return () => {
		// 	// turning of socket listner on unmount
		// 	agentSocket.off("room-update");
		// };
	}, []);

	// Handle add new message to message array list
	useEffect(() => {
		if (newMessage && newMessage.chat_id === currentChatId) {
			setMessages((messageArray) => [...messageArray, newMessage]);
		}
	}, [newMessage]);

	// Handle join chat event
	useEffect(() => {
		if (triggerJoinChatEventCount === 0) return;

		// Append to the chat event history
		const { user, time, chat_id } = socketData;

		// Only append chat event in current dialog
		if (currentChatId === chat_id) {
			let newChatEvent = {
				user_id: user.user_id,
				name: user.name,
				type: CHAT_ACTIVITIES["join_chat"],
				time: time,
			};

			setCurrentChatEventHistory((prevStates) => [newChatEvent, ...prevStates]);
		}
		// If current dialog is public chat
		else if (currentChatIndex === 0 && !chat_id) {
			let newChatEvent = {
				user_id: user.user_id,
				name: user.name,
				type: CHAT_ACTIVITIES["join_chat"],
				time: time,
			};

			setCurrentChatEventHistory((prevStates) => [newChatEvent, ...prevStates]);
		}
	}, [triggerJoinChatEventCount]);

	// Handle leave chat event
	useEffect(() => {
		if (triggerLeaveChatEventCount === 0) return;

		// Append to the chat event history
		const { user, time, chat_id } = socketData;

		// Only append chat event in current dialog
		if (currentChatId === chat_id) {
			let newChatEvent = {
				user_id: user.user_id,
				name: user.name,
				type: CHAT_ACTIVITIES["leave_chat"],
				time: time,
			};

			setCurrentChatEventHistory((prevStates) => [newChatEvent, ...prevStates]);
		}
		// If current dialog is public chat
		else if (currentChatIndex === 0 && !chat_id) {
			let newChatEvent = {
				user_id: user.user_id,
				name: user.name,
				type: CHAT_ACTIVITIES["leave_chat"],
				time: time,
			};

			setCurrentChatEventHistory((prevStates) => [newChatEvent, ...prevStates]);
		}
	}, [triggerLeaveChatEventCount]);

	useEffect(() => {
		if (isRefreshChatListing) {
			getRoomChatListing();
			refreshChatListing(false);
		}
	}, [isRefreshChatListing]);

	useEffect(() => {
		if (isViewNewPrivateChat) {
			getChatDetail(newPrivateChatId);

			// Reset state
			viewNewPrivateChat(false);
		}
	}, [isViewNewPrivateChat]);

	// To handle after delete a private chat
	useEffect(() => {
		if (!isFirstLoad && !deleteChatId) {
			// Set the current dialogId to the public chat as default
			getChatDetail(publicChat.chat_id);
		}
	}, [deleteChatId]);

	useEffect(() => {
		let timeout;

		if (loadingOldMessages) {
			timeout = setTimeout(() => {
				setLoadingOldMessages(false);
			}, 1000);

			return;
		} else {
			scrollToBottom();
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [messages]);

	const retrieveChatHistory = () => {
		let params = {
			limit: CHAT_MESSAGES_LIMIT,
		};

		if (lastMessageId) {
			params["last_message_id"] = lastMessageId;
		}

		AgentAPI.getChatMessages(currentChatId, params)
			.then((response) => {
				const { messages: chatMessages } = response;

				if (chatMessages.length > 0) {
					setMessages([...chatMessages, ...messages]);
					setLastMessageId(chatMessages[0].message_id);
					setHasMoreOldMessages(true);

					// After 500ms, only allow scroll to top to retrieve old messages
					if (!doneFirstLoadMessages) {
						setTimeout(() => {
							setDoneFirstLoadMessage(true);
						}, 500);
					}
				} else {
					// To stop keep loading old messages if no more
					setHasMoreOldMessages(false);
				}

				setJoinedChat(true);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	// Connect and join chat
	useEffect(() => {
		resetStates();
	}, [currentChatId]);

	useEffect(() => {
		// If connected to chat, and has dialog id, only join dialog
		if (doneResetStates && currentChatId) {
			setDoneResetStates(false);
			retrieveChatHistory();
		}
	}, [doneResetStates, currentChatId]);

	const resetStates = () => {
		setMessages([]);
		setLastMessageId(null);
		setLoadingOldMessages(false);
		setDoneFirstLoadMessage(false);
		setDoneResetStates(true);
	};

	const handleChatMessageScroll = (event) => {
		// If scroll height and client height is same, do nothing
		// To prevent clearing messages trigger retrieveChatHistory
		const scrollHeight = event.target.scrollHeight;
		const clientHeight = event.target.clientHeight;
		if (scrollHeight <= clientHeight) return;

		// When scroll to top, load old messages
		const scrollPos = event.target.scrollTop;
		const scrollTopOffset = 200;
		if (scrollPos < scrollTopOffset && hasMoreOldMessages && doneFirstLoadMessages) {
			setLoadingOldMessages(true);
			retrieveChatHistory();
		}
	};

	const getRoomChatListing = () => {
		AgentAPI.getRoomChatListing().then((response) => {
			const { max_private_chat, public_chat, private_chats } = response;

			setMaxPrivateChat(max_private_chat);
			setPublicChat(public_chat);
			setPrivateChats(private_chats);

			// Set the public chat as selected chat on first load
			if (isFirstLoad) {
				setIsFirstLoad(false);
				getChatDetail(public_chat.chat_id);
			}

			// Check if the current agent has joined all private chats
			private_chats.forEach((privateChat) => {
				const { chat_id, is_joined } = privateChat;

				if (is_joined === 0) {
					AgentAPI.postAgentJoinPrivateChat(chat_id)
						.then((response) => {
							//
						})
						.catch((error) => {
							console.error("Agent join chat error: ", error);
						});
				}
			});

			// Map all the dialog IDs to unread count state
			let unreadMessageTracker = {};
			unreadMessageTracker[public_chat.chat_id] = 0;

			private_chats.forEach((privateChat) => {
				unreadMessageTracker[privateChat.chat_id] = 0;
			});

			setUnreadMessageCount(unreadMessageTracker);
		});
	};

	const onChangeMessage = (event) => {
		setInputMessage(event.target.value);
	};

	const onMessageKeyDown = (event) => {
		if (event.keyCode === 13 && event.shiftKey === false) {
			event.preventDefault();
			handleSendMessage();
		}
	};

	const onTextAreaHeightChange = (rowHeight) => {
		if (!conversationRef.current || !replyContainerRef.current) return;

		let replyContainerHeightBefore = replyContainerRef.current.clientHeight;

		let textareaHeight = rowHeight;
		let replyContainerHeight = textareaHeight + 12;
		let offset = 8;

		conversationRef.current.style.height = `calc(100% - 48px - ${replyContainerHeight + offset}px)`;
		replyContainerRef.current.style.height = `calc(${replyContainerHeight}px)`;

		// Auto scroll down or top by bit to prevent text area height changes block the message view
		if (replyContainerHeight > replyContainerHeightBefore) {
			messageScrollViewRef.current.scrollTo(0, messageScrollViewRef.current.scrollTop + 17);
		} else {
			messageScrollViewRef.current.scrollTo(0, messageScrollViewRef.current.scrollTop - 17);
		}
	};

	const handleSendMessage = () => {
		// Prevent user send empty message
		if (inputMessage === "") {
			return;
		}

		let formattedInputMessage = inputMessage.replace(/(?:\r\n|\r|\n)/g, "<br>");

		AgentAPI.postSubmitChatMessage(currentChatId, formattedInputMessage)
			.then((response) => {
				setInputMessage("");
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const scrollToBottom = () => {
		messageScrollViewRef.current.scrollTo(0, messageScrollViewRef.current.scrollHeight);
	};

	const handleSelectChat = (chatId) => {
		// If user select the same chat id, ignore the action
		if (currentChatId === chatId) {
			return;
		}

		// Clear the unread count if any
		setUnreadMessageCount({
			...unreadMessageCount,
			[chatId]: 0,
		});

		// Clear messages
		setMessages([]);

		// Get the selected chat detail
		getChatDetail(chatId);
	};

	const getChatDetail = (chatId) => {
		AgentAPI.getChatDetail(chatId)
			.then((response) => {
				const { id, type, title, image_url, visitors, chat_activities, created_at } = response;

				// Set to false when changing to another chat
				setJoinedChat(false);

				// Set the current chat detail
				setCurrentChatId(id);
				setCurrentChatTitle(title);
				setCurrentChatLogo(image_url);
				setIsPrivateChat(type === 2 ? true : false);
				setShowMoreOption(false);

				// Chat event history
				setCurrentChatEventHistory(chat_activities);

				let isCurrentDate = moment(created_at).isSame(new Date(), "day");
				let formattedCreatedTime = moment(created_at).format("h:mma, D/M/YY");

				if (isCurrentDate) {
					formattedCreatedTime = moment(created_at).format("h:mma, [Today]");
				}
				setCurrentChatCreatedTime(formattedCreatedTime);

				// Set the chat index
				// Find the private chat index
				// If don't have, set the chat index to public chat
				let privateChatIndex = privateChats.findIndex((privateChat) => {
					return privateChat.chat_id === chatId;
				});

				let newChatIndex = 0;
				if (privateChatIndex !== -1) {
					// Found the private chat index
					newChatIndex = privateChatIndex + 1;
				}

				setCurrentChatIndex(newChatIndex);

				// Check if there is any active visitors in the private chat
				// If don't have any, will disable the agent chat
				let hasVisitorAccepted = false;

				visitors.forEach((visitor) => {
					if (visitor.accepted === 1) {
						hasVisitorAccepted = true;
					}
				});

				setHasActiveVisitors(hasVisitorAccepted);
				setDoneResetStates(true);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleCreateNewPrivateChat = () => {
		// If reach maximum private chat room, don't allow to create
		if (privateChats.length >= maxPrivateChat) {
			openAlertSnackbar("You have used all breakout room slots", "error");
			return;
		}

		openCreatePrivateChatModal();
	};

	const handleDeleteChat = () => {
		setShowMoreOption(false);
		setDeleteChatId(currentChatId);
		setDeleteChatName(currentChatTitle);
		openDeleteChatModal();
	};

	const handleAddParticipantsToPrivateChat = () => {
		setShowMoreOption(false);
		openAddPrivateChatParticipants();
	};

	const handleRemoveParticipantsToPrivateChat = () => {
		setShowMoreOption(false);
		openRemovePrivateChatParticipants();
	};

	return (
		<div
			className="agent-chat-wrapper grid grid-cols-12"
			style={active ? { display: "grid" } : { display: "none" }}>
			<div className="agent-chat-list col-span-3">
				<div className="chat-list-search-wrapper">
					<div className="chat-list-name">All chats</div>
					<div className="">
						<img src={IconSearch} alt="search" className="icon-search" />
					</div>
				</div>
				<div className="available-chat-status-wrapper">
					You have used <strong>{privateChats.length}</strong> of your{" "}
					<strong>{maxPrivateChat} available breakout room slots.</strong>
				</div>
				<div className="chat-list-wrapper">
					{publicChat && (
						<div
							className={`chat-list-item ${currentChatIndex === 0 ? "chat-active" : ""}`}
							onClick={() => handleSelectChat(publicChat.chat_id)}>
							<img src={publicChat.image_url || IconDefaultGroup} alt="logo" className="chat-logo" />
							<div className="chat-info">
								<div className="chat-name">{publicChat.title}</div>
								{publicChat.last_message && (
									<div className="chat-last-message">
										{userProfile.id !== publicChat.last_message.user_id && (
											<span>{publicChat.last_message.name}: </span>
										)}
										{publicChat.last_message.message.replaceAll("<br>", " ")}
									</div>
								)}
							</div>
							<span className="chat-last-timestamp">
								{publicChat.last_message
									? moment(publicChat.last_message.sent_at).format("h:mma")
									: moment(publicChat.created_at).format("h:mma")}
							</span>
							{unreadMessageCount[publicChat.chat_id] && unreadMessageCount[publicChat.chat_id] > 0 ? (
								<span className="chat-unread-message-bubble">
									{unreadMessageCount[publicChat.chat_id]}
								</span>
							) : (
								<></>
							)}
						</div>
					)}

					{privateChats.map((privateChat, index) => {
						const { chat_id, title, image_url, created_at, last_message } = privateChat;

						return (
							<div
								key={`private-chat-${index}`}
								className={`chat-list-item ${currentChatIndex === index + 1 ? "chat-active" : ""}`}
								onClick={() => handleSelectChat(chat_id)}>
								<img src={image_url || IconDefaultGroup} alt="logo" className="chat-logo" />
								<div className="chat-info">
									<div className="chat-name">{title}</div>
									{last_message && (
										<div className="chat-last-message">
											{userProfile.id !== last_message.user_id && (
												<span>{last_message.name}: </span>
											)}
											{last_message.message.replaceAll("<br>", " ")}
										</div>
									)}
								</div>
								<span className="chat-last-timestamp">
									{last_message
										? moment(last_message.sent_at).format("h:mma")
										: moment(created_at).format("h:mma")}
								</span>
								{unreadMessageCount[privateChat.chat_id] &&
								unreadMessageCount[privateChat.chat_id] > 0 ? (
									<span className="chat-unread-message-bubble">
										{unreadMessageCount[privateChat.chat_id]}
									</span>
								) : (
									<></>
								)}
							</div>
						);
					})}
				</div>
				<button className="btn-new-chat btn-primary" onClick={handleCreateNewPrivateChat}>
					<img src={IconAdd} alt="add" className="icon-add" /> New Chat
				</button>
			</div>

			<div className="agent-chat-container col-span-7">
				<div className="conversation-name-wrapper">
					<div className="flex items-center">
						<img src={currentChatLogo || IconDefaultGroup} alt="Chat Logo" className="conversation-logo" />
						<span className="conversation-name">
							{currentChatTitle} ({currentChatIndex > 0 ? "Private" : "Public"} chat)
						</span>
					</div>
					{isPrivateChat && (
						<div className="more-option-wrapper">
							<span className="btn-more-option" onClick={() => setShowMoreOption(!showMoreOption)}>
								...
							</span>
							{showMoreOption && (
								<>
									<div className="app-overlay" onClick={() => setShowMoreOption(false)}></div>
									<div className="more-option-dropdown">
										<div className="option" onClick={handleAddParticipantsToPrivateChat}>
											Add Participants
										</div>
										<div className="option" onClick={handleRemoveParticipantsToPrivateChat}>
											Remove Participants
										</div>
										<div className="option" onClick={handleDeleteChat}>
											Delete Chat
										</div>
									</div>
								</>
							)}
						</div>
					)}
				</div>

				<div className={`conversation`} ref={conversationRef}>
					<div
						className="chat-contents px-5 pt-3 overflow-y-auto"
						ref={messageScrollViewRef}
						onScroll={handleChatMessageScroll}>
						{messages.map((chatMessage, index) => {
							const { message_id } = chatMessage || index;
							const { message } = chatMessage || "";
							const { sent_at } = chatMessage || moment();
							const { user_id } = chatMessage || "";
							const { name } = chatMessage || "Anonymous";
							const { profile_image_url } = chatMessage || IconDefaultProfile;

							// If is current user id, then means it is sender
							if (parseInt(user_id) === userProfile.id) {
								return (
									<SenderMessageComponent
										key={message_id}
										name={name}
										message={message}
										timestamp={sent_at}
									/>
								);
							}

							// Otherwise, it is receiver
							return (
								<ReceiverMessageComponent
									key={message_id}
									name={name}
									message={message}
									timestamp={sent_at}
									image={profile_image_url}
								/>
							);
						})}
					</div>
				</div>

				<div className="reply-container px-5" ref={replyContainerRef}>
					<div className="reply">
						<div className="avatar">
							<img
								src={userProfile.profile_image_url || IconDefaultProfile}
								alt={userProfile.first_name + " " + userProfile.last_name}
								className="avatar-image mr-2"
							/>
						</div>
						<div className="reply-main">
							<TextareaAutosize
								maxRows={6}
								minRows={1}
								onHeightChange={(rowHeight) => onTextAreaHeightChange(rowHeight)}
								value={inputMessage}
								placeholder="Type your message here..."
								onKeyDown={onMessageKeyDown}
								onChange={onChangeMessage}
								disabled={currentChatIndex > 0 && !hasActiveVisitors}
							/>
							<div className="reply-send" onClick={handleSendMessage}>
								<SendIcon />
							</div>
						</div>
					</div>
				</div>

				{!joinedChat && (
					<div className="chat-loading-overlay">
						<ReactLoading type={"spinningBubbles"} color="grey" />
						<p className="mt-3">Loading</p>
					</div>
				)}
			</div>

			<div className="agent-chat-event-history-container col-span-2">
				<div className="chat-event-history-header">
					<h3>Chat Event History</h3>
				</div>
				<div className="chat-event-history-body">
					{currentChatEventHistory.map((chatEvent, index) => {
						const { name, type, time } = chatEvent;

						let actionDescription = "";
						let indicatorClassName = "";

						if (type === CHAT_ACTIVITIES["join_chat"]) {
							indicatorClassName = "join-event-indicator";
							actionDescription = `<strong>${name}</strong> joined room`;
						} else if (type === CHAT_ACTIVITIES["leave_chat"]) {
							indicatorClassName = "leave-event-indicator";
							actionDescription = `<strong>${name}</strong> left room`;
						} else if (type === CHAT_ACTIVITIES["invitation"]) {
							actionDescription = `Invited <strong>${name}</strong>`;
						}

						let isCurrentDate = moment(time).isSame(new Date(), "day");
						let formattedTime = moment(time).format("h:mma, D/M/YY");

						if (isCurrentDate) {
							formattedTime = moment(time).format("h:mma, [Today]");
						}

						return (
							<div key={`chat-event-${index}`} className="chat-event-item">
								<div className={`chat-event-indicator ${indicatorClassName}`}></div>
								<div>
									<div
										className="chat-event-desc"
										dangerouslySetInnerHTML={{ __html: actionDescription }}
									/>
									<div className="chat-event-time">at {formattedTime}</div>
								</div>
							</div>
						);
					})}

					{currentChatCreatedTime && (
						<div className="chat-event-item">
							<div className={`chat-event-indicator`}></div>
							<div>
								<div className="chat-event-desc">Room created</div>
								<div className="chat-event-time">at {currentChatCreatedTime}</div>
							</div>
						</div>
					)}
				</div>
			</div>

			<CreatePrivateChatModal />
			<CreatePrivateChatSuccessModal />
			<DeleteChatModal />

			{currentChatId && <AddPrivateChatParticipants chatId={currentChatId} />}
			{currentChatId && <RemovePrivateChatParticipants chatId={currentChatId} />}
		</div>
	);
};

export default withRouter(AgentChatContainer);

import { getEventCodeFromUrl } from "app/helper/common";
import apiAction from "../services/apiAction"
import TokenService from "../services/tokenService"
import LocalStorageService from './../services/localStorageService';

const Authentication = {

    postLogin: (loginData = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/login`;
            // const eventId = LocalStorageService.getEventIdByEventCode();

            const data = {
                // 'event_id': eventId,
                ...loginData
            }

            apiAction.post(url, data)
                .then(response => {
                    const { token, user } = response.data;

                    TokenService.setAccessToken(token);
                    LocalStorageService.setUserProfile(JSON.stringify(user));

                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    postRegister: (eventId, user = {}, captchaToken) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = '/users';
            const event_id = eventId || LocalStorageService.getEventIdByEventCode();

            const data = {
                "event_id": parseInt(event_id),
                "captcha_token": captchaToken,
                "user": user
            }

            apiAction.post(url, data)
                .then(response => {
                    const { token, user } = response.data;

                    TokenService.setAccessToken(token);
                    LocalStorageService.setUserProfile(JSON.stringify(user));

                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    postConfirmVerification: (token) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/confirm`;

            const data = {
                'token': token
            }

            apiAction.post(url, data)
                .then(response => {
                    const { event } = response.data;

                    let pathname = window.location.pathname;
                    let pathNamePieces = pathname.split("/");
                    let eventCode = pathNamePieces[2]; // take the third piece
                    LocalStorageService.setEventIdByEventCode(eventCode, event.id);
                    LocalStorageService.setEventSetting(JSON.stringify(event));

                    resolve(response.data)
                })
                .catch(err => reject(err))
        })
    },

    postForgotPassword: (email) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/resetpwd`;
            const eventId = LocalStorageService.getEventIdByEventCode();

            const data = {
                'email': email,
                'event_id': eventId
            }

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    postUpdatePassword: (newPassword, token) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/updatepwd`;

            const data = {
                'password': newPassword,
                'token': token
            }

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    postResendConfirmation: (email) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/send-confirm`;
            const eventId = LocalStorageService.getEventIdByEventCode();

            const data = {
                'email': email,
                'event_id': eventId
            }

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    postLogout: () => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = '/users/logout';

            apiAction.post(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    postCompleteProfile: (formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/complete-profile`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },
};

export default Authentication;

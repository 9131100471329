/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import MenuButton from "app/views/components/MenuButton/MenuButton";
import LocalStorageService from "app/services/localStorageService";
import "./style.scss";
import { useLocation } from "react-router-dom";
import IconChannel from "app/assets/Menu/Channels_active.png";
import IconChannelInactive from "app/assets/Menu/Channels_inactive.png";
import IconExhibitor from "app/assets/Menu/Exhibitors_active.png";
import IconExhibitorInactive from "app/assets/Menu/Exhibitors_inactive.png";
import IconEngagement from "app/assets/Menu/engagements_active.png";
import IconEngagementInactive from "app/assets/Menu/engagements_inactive.png";
import IconContent from "app/assets/Menu/Content_active.png";
import IconContentInactive from "app/assets/Menu/Content_inactive.png";
import IconOrders from "app/assets/Menu/Orders_active.png";
import IconOrdersInactive from "app/assets/Menu/Orders_inactive.png";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import ProfileModal from "../Modal/ProfileModal/ProfileModal";
import MobileMenuButton from "app/views/components/MenuButton/MobileMenuButton";
import IconMobileChannelActive from "app/assets/Menu/mobile_channel_active.png";
import IconMobileChannelInactive from "app/assets/Menu/mobile_channel_inactive.png";
import IconMobileExhibitorActive from "app/assets/Menu/mobile_exhibitor_active.png";
import IconMobileExhibitorInactive from "app/assets/Menu/mobile_exhibitor_inactive.png";
import IconMobileProfileActive from "app/assets/Menu/mobile_profile_active.png";
import IconMobileProfileInactive from "app/assets/Menu/mobile_profile_inactive.png";
import IconMobileAgentActive from "app/assets/Menu/mobile_agent_active.png";
import IconMobileAgentInactive from "app/assets/Menu/mobile_agent_inactive.png";
import LiveToggleModal from "../Modal/LiveToggleModal/LiveToggleModal";
import { agentActions } from "app/states/agent";
import AgentAPI from "app/apis/agent";
import IconLiveRed from "app/assets/Menu/icon_live_red.png";
import Switch from "react-switch";
import IconDefaultProfile from "app/assets/icon-default-user.png";
import DeleteSavedOrderModal from "../Modal/DeleteSavedOrderModal/DeleteSavedOrderModal";
import SwitchExhibitorRoomModal from "../Modal/SwitchExhibitorRoomModal/SwitchExhibitorRoomModal";
import Badge from "@material-ui/core/Badge";
import IconCart from "app/assets/icon_cart_inactive.png";
import IconNotification from "app/assets/icon_notification.png";
import AddressManagementModal from "../Modal/Address/AddressManagementModal/AddressManagementModal";
import AddAddressModal from "../Modal/Address/AddAddressModal/AddAddressModal";
import ProfileAPI from "app/apis/profile";
import { authenticationActions } from "app/states/authentication";
import OrderAPI from "app/apis/order";
import EditAddressModal from "../Modal/Address/EditAddressModal/EditAddressModal";
import ConfirmDeleteAddressModal from "../Modal/Address/ConfirmDeleteAddressModal/ConfirmDeleteAddressModal";

const MenuBar = ({ history }) => {
	const dispatch = useDispatch();
	const openProfileModal = () => dispatch(commonActions.openProfileModal());
	const openLiveToggleModal = () => dispatch(agentActions.openLiveToggleModal());
	const setRoomDetails = (data) => dispatch(agentActions.setRoomDetails(data));
	const setLiveSessionId = (liveSessionId) => dispatch(agentActions.setLiveSessionId(liveSessionId));
	const refreshExhibitorRoomDetails = (refresh) => dispatch(agentActions.refreshExhibitorRoomDetails(refresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setProfileModalTabIndex = (tabIndex) => dispatch(commonActions.setProfileModalTabIndex(tabIndex));
	const setUserProfile = (userProfile) => dispatch(authenticationActions.setUserProfile(userProfile));

	const roomDetails = useSelector(({ agent }) => agent.roomDetails);
	const liveSessionId = useSelector(({ agent }) => agent.liveSessionId);
	const isRefreshExhibitorRoomDetails = useSelector(({ agent }) => agent.isRefreshExhibitorRoomDetails);
	const userProfile = useSelector(({ auth }) => auth.userProfile);
	const refreshMyCartCount = useSelector(({ order }) => order.refreshMyCartCount);

	const [liveSession, setLiveSession] = useState(null);
	const [isLive, setIsLive] = useState(false);

	const [cartItemCount, setCartItemCount] = useState(2);
	const [notificationCount, setNotificationCount] = useState(3);

	const webConfig = LocalStorageService.getWebConfig();
	const eventSetting = LocalStorageService.getEventSetting();
	const userEventRole = LocalStorageService.getUserEventRole();
	const isExhibitorOrAgent =
		userEventRole &&
		userEventRole.roles &&
		userEventRole.roles.every((role) => ["exhibitor", "admin", "agent"].includes(role));

	const location = useLocation();
	const { eventCode } = useParams();

	// Handle refresh exhibitor room details
	useEffect(() => {
		if (isRefreshExhibitorRoomDetails) {
			refreshExhibitorRoomDetails(false);
			getRoomDetails();
		}
	}, [isRefreshExhibitorRoomDetails]);

	// Get user profile
	useEffect(() => {
		ProfileAPI.getUserProfile()
			.then((response) => {
				const { profile_image_url, first_name, last_name } = response.user;

				// Update local storage profile data
				let userProfileCopy = { ...userProfile };

				userProfileCopy = {
					...userProfileCopy,
					profile_image_url: profile_image_url,
					first_name: first_name,
					last_name: last_name,
				};

				setUserProfile(userProfileCopy);
				LocalStorageService.setUserProfile(JSON.stringify(userProfileCopy));
				window.dispatchEvent(new Event("storage"));
			})
			.catch((error) => {
				console.error("Profile error: ", error);
			});
	}, []);

	// Get exhibitor room details for agent/admin
	useEffect(() => {
		if (isExhibitorOrAgent) {
			getRoomDetails();
		}
	}, []);

	// Handle live stream toggling
	useEffect(() => {
		if (!roomDetails) return;

		const { live_sessions } = roomDetails;

		if (live_sessions.length > 0) {
			const liveSessionDetails = live_sessions[0];

			const { id, type, video_url, thumbnail_url, start_at, end_at, is_live } = liveSessionDetails;

			// Check if live session is expired
			if (liveSessionDetails.id) {
				setLiveSessionId(liveSessionDetails.id);
			}

			// Ensure live session id is set
			if (liveSessionId === null) {
				setLiveSessionId(id);
			}

			setLiveSession({
				id: id || null,
				streaming_url: video_url,
				date: new Date(start_at),
				start_time: new Date(start_at),
				end_time: new Date(end_at),
				streaming_platform: type,
				cover_image: thumbnail_url,
				is_live: is_live,
			});
			setIsLive(is_live ? true : false);
		} else {
			setLiveSession(null);
		}
	}, [roomDetails]);

	// Get list of saved order rooms for the cart numbers
	useEffect(() => {
		OrderAPI.getSavedOrderRooms()
			.then((response) => {
				const { rooms } = response;
				setCartItemCount(rooms.length);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshMyCartCount]);

	const isCurrentRoute = (link) => {
		if (location.pathname.includes(link)) {
			return true;
		}

		return false;
	};

	const getRoomDetails = () => {
		AgentAPI.getRoomDetail()
			.then((response) => {
				setRoomDetails(response);
			})
			.catch((error) => {
				console.error("Get room details: ", error);
			});
	};

	const handleToggleLive = () => {
		if (!liveSessionId) {
			openAlertSnackbar("Please update streaming details first", "error");

			// If user not in agent content page, redirect to agent content page
			if (!location.pathname.includes("/agent/contents")) {
				history.push(`/events/${eventCode}/agent/contents`);
			}

			return;
		}

		const data = {
			live_session_id: liveSessionId,
			is_live: !liveSession.is_live ? 1 : 0,
		};

		AgentAPI.postToggleLiveStream(data)
			.then((response) => {
				refreshExhibitorRoomDetails(true);

				if (!isLive) {
					openAlertSnackbar("Live streaming has started!", "success");
				} else {
					openAlertSnackbar("Live streaming has stopped!", "success");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleSwitchOnChange = () => {
		if (liveSessionId) {
			openLiveToggleModal();
		} else {
			openAlertSnackbar("Please update streaming details first", "error");

			// If user not in agent content page, redirect to agent content page
			if (!location.pathname.includes("/agent/contents")) {
				history.push(`/events/${eventCode}/agent/contents`);
			}

			return;
		}
	};

	const handleOpenProfile = () => {
		setProfileModalTabIndex(0);
		openProfileModal();
	};

	const handleOpenCart = () => {
		setProfileModalTabIndex(2); // Tab my saved orders
		openProfileModal();
	};

	const handleOpenNotification = () => {
		//
	};

	return (
		<>
			{/* Desktop View */}
			<div className="menu-bar flex flex-col items-center rounded-tr bg-gray-100 w-full h-full hidden lg:flex">
				<div className="app-logo w-full mb-4 rounded-tr">
					<img src={webConfig.logo_url} alt="logo" className="rounded-tr" />
				</div>

				{eventSetting.channel_enabled === 1 && (
					<MenuButton
						name="Channels"
						icon={isCurrentRoute(`/events/${eventCode}/channel`) ? IconChannel : IconChannelInactive}
						link={`/events/${eventCode}/channel`}
						active={isCurrentRoute(`/events/${eventCode}/channel`)}
					/>
				)}
				{eventSetting.exhibitor_enabled === 1 && (
					<MenuButton
						name="Exhibitors"
						icon={isCurrentRoute(`/events/${eventCode}/exhibitor`) ? IconExhibitor : IconExhibitorInactive}
						link={`/events/${eventCode}/exhibitor`}
						active={isCurrentRoute(`/events/${eventCode}/exhibitor`)}
					/>
				)}

				{isExhibitorOrAgent && (
					<>
						<hr />

						<h6 className="text-agent-dashboard">Agent Dashboard</h6>
						<MenuButton
							name="Engagements"
							icon={
								isCurrentRoute(`/events/${eventCode}/agent/engagements`)
									? IconEngagement
									: IconEngagementInactive
							}
							link={`/events/${eventCode}/agent/engagements`}
							active={isCurrentRoute(`/events/${eventCode}/agent/engagements`)}
						/>
						<MenuButton
							name="Contents"
							icon={
								isCurrentRoute(`/events/${eventCode}/agent/contents`)
									? IconContent
									: IconContentInactive
							}
							link={`/events/${eventCode}/agent/contents`}
							active={isCurrentRoute(`/events/${eventCode}/agent/contents`)}
						/>

						{eventSetting.ecommerce_enabled === 1 && (
							<MenuButton
								name="Orders"
								icon={
									isCurrentRoute(`/events/${eventCode}/agent/orders`)
										? IconOrders
										: IconOrdersInactive
								}
								link={`/events/${eventCode}/agent/orders`}
								active={isCurrentRoute(`/events/${eventCode}/agent/orders`)}
							/>
						)}
					</>
				)}

				<div className="flex flex-col justify-end h-full mb-3">
					{isExhibitorOrAgent && (
						<div className="live-toggle-switch-wrapper mb-3">
							<div className="text-live mb-1">
								GO LIVE! <img src={IconLiveRed} alt="live" className="icon-live" />
							</div>
							<Switch
								checked={isLive}
								onChange={handleSwitchOnChange}
								onColor="#00BF2C"
								handleDiameter={18}
								checkedIcon={false}
								uncheckedIcon={false}
							/>
						</div>
					)}

					{userProfile && (
						<div id="my-profile" className="mb-3 text-center" onClick={handleOpenProfile}>
							<img
								src={userProfile.profile_image_url ?? IconDefaultProfile}
								alt="profile"
								className="w-12 h-12 rounded-lg mb-1 mx-auto"
							/>
							<div className="leading-3 font-bold text-center profile-name px-3">{`${userProfile.first_name} ${userProfile.last_name}`}</div>
						</div>
					)}

					{eventSetting.ecommerce_enabled === 1 && (
						<div className="flex justify-center items-center pr-2">
							<Badge badgeContent={cartItemCount} color="error" onClick={handleOpenCart}>
								<img src={IconCart} alt="cart" className="icon-misc" />
							</Badge>

							{/* <Badge badgeContent={notificationCount} color="error" onClick={handleOpenNotification}>
								<img src={IconNotification} alt="cart" className="icon-misc" />
							</Badge> */}
						</div>
					)}
				</div>
			</div>

			{/* Mobile view */}
			<div id="mobile-menu-bar" className="menu-bar pt-2 pb-1 flex justify-center items-center lg:hidden">
				{eventSetting.channel_enabled === 1 && (
					<MobileMenuButton
						name="Channels"
						icon={
							isCurrentRoute(`/events/${eventCode}/channel`)
								? IconMobileChannelActive
								: IconMobileChannelInactive
						}
						link={`/events/${eventCode}/channel`}
						active={isCurrentRoute(`/events/${eventCode}/channel`)}
					/>
				)}

				{eventSetting.exhibitor_enabled === 1 && (
					<MobileMenuButton
						name="Exhibitors"
						icon={
							isCurrentRoute(`/events/${eventCode}/exhibitor`)
								? IconMobileExhibitorActive
								: IconMobileExhibitorInactive
						}
						link={`/events/${eventCode}/exhibitor`}
						active={isCurrentRoute(`/events/${eventCode}/exhibitor`)}
					/>
				)}

				<MobileMenuButton
					name="Profile"
					icon={
						isCurrentRoute(`/events/${eventCode}/profile`)
							? IconMobileProfileActive
							: IconMobileProfileInactive
					}
					link={`/events/${eventCode}/profile`}
					active={isCurrentRoute(`/events/${eventCode}/profile`)}
				/>

				{/* TODO: to be released when mobile view is done */}
				{/* {isExhibitorOrAgent && (
						<MobileMenuButton
							name="Agent"
							icon={
								isCurrentRoute(`/events/${eventCode}/agent`) ? IconMobileAgentActive : IconMobileAgentInactive
							}
							link={`/events/${eventCode}/agent`}
							active={isCurrentRoute(`/events/${eventCode}/agent`)}
						/>
				)} */}
			</div>

			<ProfileModal />

			{liveSession && (
				<LiveToggleModal
					isLive={liveSession.is_live}
					streamingUrl={liveSession.streaming_url}
					streamingStartAt={liveSession.start_time}
					streamingEndAt={liveSession.end_time}
					streamingCover={liveSession.cover_image}
					onConfirm={handleToggleLive}
				/>
			)}

			<DeleteSavedOrderModal />
			<SwitchExhibitorRoomModal />
			<AddressManagementModal />
			<AddAddressModal />
			<EditAddressModal />
			<ConfirmDeleteAddressModal />
		</>
	);
};

export default withRouter(MenuBar);

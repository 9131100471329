/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Advertisement from "../../components/Advertisement/Advertisement";
import ChannelList from "../../containers/Channel/ChannelList/ChannelList";
import ChannelTabs from "../../containers/Channel/ChannelTabs/ChannelTabs";
import ChannelVideo from "../../containers/Channel/ChannelVideo/ChannelVideo";
import SpeakersContainer from "../../containers/Channel/SpeakersContainer/SpeakersContainer";
import MenuBar from "../../containers/MenuBar/MenuBar";
import { useSelector, useDispatch } from "react-redux";
import ChannelApi from "app/apis/channel";
import { channelSocket } from "app/services/socketService";
import { useParams, withRouter } from "react-router-dom";
import { channelActions } from "app/states/channel";
import BrandLogoMobile from "../../components/BrandLogoMobile/BrandLogoMobile";
import "./style.scss";
import TokenService from "app/services/tokenService";
import moment from "moment";
import LocalStorageService from "app/services/localStorageService";
import { commonActions } from "app/states/common";

// import { ZoomMtg } from "@zoomus/websdk";

let apiKeys = {
	apiKey: process.env.REACT_APP_ZOOM_API_KEY,
	apiSecret: process.env.REACT_APP_ZOOM_API_SECRET_KEY,
};
let meetConfig = {
	apiKey: process.env.REACT_APP_ZOOM_API_KEY,
	meetingNumber: "71289544336",
	userName: "Example",
	userEmail: "example@example.com", // must be the attendee email address
	passWord: "0hZeCd",
	role: 0,
};

const Channel = ({ history }) => {
	const { eventCode, sessionId } = useParams();

	const dispatch = useDispatch();
	const setChannelSessionId = (sessionId) => dispatch(channelActions.setChannelSessionId(sessionId));
	const setChannelSession = (session) => dispatch(channelActions.setChannelSession(session));
	const setChannelChatId = (dialogId) => dispatch(channelActions.setChannelChatId(dialogId));
	const setIsPrivateChat = (isPrivateChat) => dispatch(commonActions.setIsPrivateChat(isPrivateChat));

	const channelSessionId = useSelector(({ channel }) => channel.channelSessionId);

	const [speakers, setSpeakers] = useState([]);

	// When there is session id provided
	useEffect(() => {
		// Set is private chat to false, bcoz channel does not have private chat
		setIsPrivateChat(false);

		if (sessionId) {
			setChannelSessionId(sessionId);
		}
	}, []);

	useEffect(() => {
		// ZoomMtg.preLoadWasm();
		// ZoomMtg.prepareJssdk();
		// ZoomMtg.init()
	}, []);

	// Determine whether socket is ready
	useEffect(() => {
		if (channelSessionId && channelSocket.connected === false) {
			channelSocket.auth.token = TokenService.getAccessToken();
			channelSocket.connect();
		}

		channelSocket.on("ready", () => {
			// console.log(channelSocket);

			if (channelSessionId) {
				// Emit join channel socket event
				channelSocket.emit("join", { session_id: channelSessionId });
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channelSessionId]);

	useEffect(() => {
		// Check if event started. If not started, redirect to landing page
		const eventSetting = LocalStorageService.getEventSetting();
		const eventStartAt = moment(eventSetting.start_at);
		if (moment().isBefore(moment(eventStartAt))) {
			history.replace(`/events/${eventCode}`);
			return;
		}

		// If route url does not have session id, use the first session id get from API (get channel list API)
		if (channelSessionId) {
			history.push(`/events/${eventCode}/channel/session/${channelSessionId}`);
			// window.history.replaceState(null, "Channel", `/events/${eventCode}/channel/session/${channelSessionId}`);
			getChannelSession(channelSessionId);
		}
		else {
			setChannelSession(null);
			setChannelChatId(null);
			setSpeakers([]);
			window.history.replaceState(null, "Channel", `/events/${eventCode}/channel`);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channelSessionId]);

	const getChannelSession = (channelSessionId) => {
		ChannelApi.getChannelSession(channelSessionId)
			.then((response) => {
				const channelSession = response;
				const { speakers } = channelSession;
				const { chats } = channelSession;
				
				setChannelSession(channelSession);
				setSpeakers(speakers);
				setChannelChatId(chats.public?.chat_id);
				
				// Emit join channel socket event
				channelSocket.emit("join", { session_id: channelSessionId });
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<div id="channel-page" className="flex flex-col lg:flex-row w-screen min-h-screen lg:pt-1 lg:pr-1">
			<div className="hidden lg:block lg:mr-1">
				<MenuBar />
			</div>

			<div className="flex flex-col lg:flex-row w-full">
				<div className="col-span-12 block lg:hidden">
					<BrandLogoMobile />
				</div>

				<div className="listing-wrapper col-span-12 lg:col-span-3">
					<ChannelList />
				</div>
				<div className="main-wrapper col-span-12 lg:col-span-6">
					<div className="mb-2 hidden lg:block">
						<Advertisement type="leaderboard_banner" />
					</div>
					<div className="channel-main-container">
						<div className="mb-1">
							<ChannelVideo />
						</div>
						<div>
							<SpeakersContainer speakers={speakers} />
						</div>
					</div>
				</div>
				<div className="misc-wrapper col-span-12 lg:col-span-3">
					<div className="mb-2">
						<Advertisement type="customized_leaderboard_banner" />
					</div>
					<div className="channel-tabs-wrapper">
						<ChannelTabs />
					</div>
				</div>
			</div>

			{/* Mobile View Menu Bar */}
			<div className="col-span-12 block lg:hidden">
				<MenuBar />
			</div>
		</div>
	);
};

export default withRouter(Channel);

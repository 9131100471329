/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "./style.scss";
import VisitorList from "../../VisitorList/VisitorList";
import ExhibitorStore from "../ExhibitorStore/ExhibitorStore";
import ChatContainer from "../../ChatContainer/ChatContainer";
import IconChatActive from "app/assets/Channels_Exhibitors/chat_active.png";
import IconChatInactive from "app/assets/Channels_Exhibitors/chat_inactive.png";
import IconStoreActive from "app/assets/Channels_Exhibitors/store_active.png";
import IconStoreInactive from "app/assets/Channels_Exhibitors/store_inactive.png";
import IconVisitorsActive from "app/assets/Channels_Exhibitors/visitors_active.png";
import IconVisitorsInactive from "app/assets/Channels_Exhibitors/visitors_inactive.png";
import { useSelector, useDispatch } from "react-redux";
import ExhibitorAPI from "app/apis/exhibitor";
import { exhibitorSocket } from "app/services/socketService";
import Badge from "@material-ui/core/Badge";
import { commonActions } from "app/states/common";
import sessionStorageService from "app/services/sessionStorageService";
import LocalStorageService from "app/services/localStorageService";
import { exhibitorActions } from "app/states/exhibitor";

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`exhibitor-tabpanel-${index}`}
			aria-labelledby={`exhibitor-tab-${index}`}
			{...other}>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

const ExhibitorTabs = () => {
	const eventSetting = LocalStorageService.getEventSetting();
	const visitorTabIndex = eventSetting.ecommerce_enabled !== 1 ? 1 : 2;

	const dispatch = useDispatch();
	const setChatUnreadCount = (count) => dispatch(commonActions.setChatUnreadCount(count));
	const setSwitchExhibitorRoom = (isSwitching) => dispatch(exhibitorActions.setSwitchExhibitorRoom(isSwitching));
	const openExhibitorStoreModal = () => dispatch(exhibitorActions.openExhibitorStoreModal());

	const exhibitorRoomId = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomId);
	const isPrivateChat = useSelector(({ common }) => common.isPrivateChat);
	const chatUnreadCount = useSelector(({ common }) => common.chatUnreadCount);
	const isSwitchingExhibitorRoom = useSelector(({ exhibitor }) => exhibitor.isSwitchingExhibitorRoom);
	const exhibitorRoomDetails = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomDetails);

	const [tabIndex, setTabIndex] = useState(isSwitchingExhibitorRoom ? 1 : 0);
	const [visitors, setVisitors] = useState([]);

	useEffect(() => {
		if (isSwitchingExhibitorRoom) {
			setTabIndex(1);
			sessionStorageService.setExhibitorTabIndex(1);
			openExhibitorStoreModal();
		} else {
			sessionStorageService.setExhibitorTabIndex(0);
		}

		return () => {
			// reset state
			setSwitchExhibitorRoom(false);
		};
	}, [isSwitchingExhibitorRoom]);

	const tabProps = (index) => {
		return {
			id: `exhibitor-tabpanel-${index}`,
			"aria-controls": `exhibitor-tabpanel-${index}`,
		};
	};

	const handleTabChange = (event, newTabIndex) => {
		setTabIndex(newTabIndex);
		sessionStorageService.setExhibitorTabIndex(newTabIndex);

		// Set unread count to 0 for chat
		if (newTabIndex === 0) {
			setChatUnreadCount(0);
		}
	};

	useEffect(() => {
		exhibitorSocket.on("room-update", (message) => {
			// do something with incoming message from backend
			// console.log("exhibitorSocket: ", message);

			const { action_code, data } = message;

			// Attendance update (for new joiner)
			if (action_code === 100) {
				const { audience } = data;
				const { user_id } = audience;

				setVisitors((prevStates) => {
					// Find if there is any duplicate
					let visitorsCopy = [...prevStates];
					let duplicateVisitor = visitorsCopy.findIndex((visitor) => {
						return visitor.user_id === user_id;
					});

					// If no duplicate, only append to the list
					if (duplicateVisitor === -1) {
						return [...prevStates, audience];
					}

					return [...prevStates];
				});
			}

			// Attendance update (for user leave)
			if (action_code === 101) {
				const { audience } = data;
				const { user_id } = audience;

				setVisitors((prevStates) => {
					let visitorsCopy = [...prevStates];
					let remainingVisitors = visitorsCopy.filter((visitor) => {
						return visitor.user_id !== user_id;
					});

					return remainingVisitors;
				});
			}
		});

		// return () => {
		// 	// turning of socket listner on unmount
		// 	exhibitorSocket.off("room-update");
		// };
	}, []);

	useEffect(() => {
		if (!exhibitorRoomId) return;

		setVisitors([]);

		ExhibitorAPI.getExhibitorRoomAudiences(exhibitorRoomId)
			.then((response) => {
				const { audiences } = response;
				setVisitors(audiences);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [exhibitorRoomId]);

	return (
		<div className="exhibitor-tabs lg:h-full">
			<Tabs
				value={tabIndex}
				onChange={handleTabChange}
				aria-label="Channel Tabs"
				indicatorColor="primary"
				textColor="primary"
				variant="fullWidth">
				<Tab
					icon={
						<Badge badgeContent={chatUnreadCount} color="error" variant="dot">
							<img
								src={tabIndex === 0 ? IconChatActive : IconChatInactive}
								alt="chat"
								className="tab-icon"
							/>
						</Badge>
					}
					label={<span className="tab-label">Chat {isPrivateChat && "(Private)"}</span>}
					className="tab"
					{...tabProps(0)}
				/>
				{eventSetting.ecommerce_enabled === 1 && (
					<Tab
						icon={
							<img
								src={tabIndex === 1 ? IconStoreActive : IconStoreInactive}
								alt="store"
								className="tab-icon"
							/>
						}
						label={<span className="tab-label">Store</span>}
						className="tab"
						{...tabProps(1)}
					/>
				)}
				<Tab
					icon={
						<img
							src={tabIndex === 2 ? IconVisitorsActive : IconVisitorsInactive}
							alt="visitors"
							className="tab-icon"
						/>
					}
					label={
						<span className="tab-label">
							Visitors
							{exhibitorRoomDetails && !exhibitorRoomDetails.hide_visitor_count
								? `(${visitors.length})`
								: ""}
						</span>
					}
					className="tab"
					{...tabProps(2)}
				/>
			</Tabs>

			<div className="tab-panel" style={tabIndex === 0 ? { display: "block" } : { display: "none" }}>
				<ChatContainer type="exhibitor" />
			</div>
			{eventSetting.ecommerce_enabled === 1 && (
				<TabPanel value={tabIndex} index={1} className="tab-panel">
					<ExhibitorStore />
				</TabPanel>
			)}
			<TabPanel value={tabIndex} index={visitorTabIndex} className="tab-panel">
				<VisitorList visitors={visitors} />
			</TabPanel>
		</div>
	);
};

export default withRouter(ExhibitorTabs);

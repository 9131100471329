/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import appLogoMobile from "app/assets/eBiz_logo_mobile.png";
import "./style.scss";
import LocalStorageService from "app/services/localStorageService";
import EventAPI from "app/apis/event";
import useQueryParams from "app/views/hooks/useQueryParams";

const BrandContainer = () => {
	const { eventCode } = useParams();
	const queryParams = useQueryParams();

	const cachedWebConfig = LocalStorageService.getWebConfig();

	const [eventSetting, setEventSetting] = useState(null);
	const [webConfig, setWebConfig] = useState(cachedWebConfig);

	// Handle webconfig changes
	useEffect(() => {
		const previewToken = queryParams.get("preview_token");
		let requestHeaders = {};
		if (previewToken) {
			requestHeaders["preview-token"] = previewToken;
		}

		EventAPI.getEventSetting(eventCode, requestHeaders)
			.then((response) => {
				const { event, web_config } = response;

				LocalStorageService.setEventIdByEventCode(eventCode, event.id);
				LocalStorageService.setEventSetting(eventCode, JSON.stringify(event));
				LocalStorageService.setWebConfig(eventCode, JSON.stringify(web_config));

				setWebConfig(web_config);
				setEventSetting(event);
			})
			.catch((error) => {
				console.log("Event error: ", error);
			});
	}, [eventCode]);

	return (
		<div className="flex flex-col items-center text-center brand-bg lg:h-full rounded-b-lg lg:rounded-tr lg:rounded-b-none">
			<img src={webConfig.logo_url || appLogoMobile} alt="logo" className="w-5/12 lg:w-8/12 py-5 lg:py-14" />
			<span className="text-md lg:text-xl text-white font-bold mb-1">{webConfig.slogan}</span>
			<span className="text-xs lg:text-base text-white mb-3">{webConfig.sub_slogan}</span>

			<div className="brand-copyright">
				<span className="text-xs text-gray-500 lg:text-white">
					{" "}
					© {eventSetting?.name || "eBIZSTART"} 2021. All rights reserved.
				</span>
			</div>
		</div>
	);
};

export default withRouter(BrandContainer);

import * as types from "./types"

const initialState = {
    channelSessionId: null,
    channelSession: null,
    showMobileChannelListModal: false,
    expandChannelCategory: "",
    channelChatId: null,
    isRefreshChannelList: false,
}


const ChannelReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.SET_CHANNEL_SESSION_ID: 
            return {
                ...state, 
                channelSessionId: action.params.sessionId
            }
        case types.SET_CHANNEL_SESSION: 
            return {
                ...state, 
                channelSession: action.params.session
            }

        // Mobile channel list modal
        case types.OPEN_MOBILE_CHANNEL_LIST_MODAL: 
            return {
                ...state, 
                showMobileChannelListModal: true
            }
        case types.CLOSE_MOBILE_CHANNEL_LIST_MODAL: 
            return {
                ...state, 
                showMobileChannelListModal: false
            }
        
        // Set the channel that should be expanded when selected in mobile view
        case types.SET_EXPAND_CHANNEL_CATEGORY: 
            return {
                ...state, 
                expandChannelCategory: action.params.category
            }
        
        // Set the channel public dialog id
        case types.SET_CHANNEL_CHAT_ID: 
            return {
                ...state, 
                channelChatId: action.params.chatId
            }

        // Refresh channel list
        case types.SET_REFRESH_CHANNEL_LIST: 
            return {
                ...state, 
                isRefreshChannelList: action.params.isRefresh
            }
            
        default:
            return state
    }
}

export default ChannelReducer;
import apiAction from "../services/apiAction"
import LocalStorageService from '../services/localStorageService';

const Channel = {

    /**
     * Get channle list
     * @param {object} queryParams 
     * {limit, offset, keyword, start_at, end_at}
     */
    getChannels: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels`;

            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get channel session details
     * @param {int} sessionId 
     */
    getChannelSession: (sessionId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}`;
            
            apiAction.get(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get channel session audiences
     * @param {int} sessionId 
     * @param {object} queryParams 
     * {limit, offset}
     */
    getChannelSessionAudiences: (sessionId, queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/audiences`;
            
            apiAction.get(url, queryParams)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Like or unlike Channel Session
     * @param {int} sessionId 
     * @param {boolean} like 
     */
    postLikeChannelSession: (sessionId, like) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/like`;
            
            const data = {
                like: like
            }

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Like or unlike Channel Session
     * @param {int} sessionId 
     * @param {string} title 
     * @param {array of string} choices
     */
    postCreatePolls: (sessionId, title, choices) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/polls`;
            
            const data = {
                title,
                choices
            }

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get Channel Session Active Polls
     * @param {int} sessionId 
     * @param {object} queryParams 
     * {limit, offset}
     */
    getChannelSessionActivePolls: (sessionId, queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/polls/active`;
            
            apiAction.get(url, queryParams)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get Channel Session Submitted Polls
     * @param {int} sessionId 
     * @param {object} queryParams 
     * {limit, offset}
     */
    getChannelSessionSubmittedPolls: (sessionId, queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/polls/submitted`;
            
            apiAction.get(url, queryParams)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Cast vote on poll for Channel Session
     * @param {int} sessionId 
     * @param {int} poll_id 
     * @param {int} choice_id
     */
    postCastVoteOnPoll: (sessionId, poll_id, choice_id) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/polls/vote`;
            
            const data = {
                poll_id,
                choice_id
            }

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * List Channel Session Q&A
     * @param {int} sessionId 
     * @param {object} queryParams 
     * {limit, offset}
     */
    getChannelSessionQnA: (sessionId, queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/qna`;
            
            apiAction.get(url, queryParams)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Post Channel Session answer to question
     * @param {int} sessionId 
     * @param {int} question_id 
     * @param {string} answer
     */
    postChannelSessionAnswer: (sessionId, question_id, answer) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/qna/answer`;
            
            const data = {
                question_id,
                answer
            }

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Post Channel Session question
     * @param {int} sessionId 
     * @param {string} question
     */
    postChannelSessionQuestion: (sessionId, question) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/qna/question`;
            
            const data = {
                question,
            }

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Like or unlike Channel Session question
     * @param {int} sessionId 
     * @param {int} question_id
     * @param {tinyInt} like
     */
    postLikeChannelSessionQuestion: (sessionId, question_id, like) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/qna/question/like`;
            
            const data = {
                question_id,
                like
            }

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get chat messages
     * @param {int} sessionId 
     * @param {int} sessionId 
     * @param {object} queryParams 
     * {limit, last_message_id}
     */
    getChatMessages: (sessionId, chatId, queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/chats/${chatId}/messages`;
            
            apiAction.get(url, queryParams)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Post submit chat message
     * @param {int} sessionId 
     * @param {int} chatId 
     * @param {string} message
     */
    postSubmitChatMessage: (sessionId, chatId, message) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/channels/sessions/${sessionId}/chats/${chatId}/messages`;
            
            const data = {
                message
            }

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },
};

export default Channel;

import React from 'react'
import BrandCopyrightMobile from '../components/BrandCopyrightMobile/BrandCopyrightMobile'
import BrandContainer from '../containers/Authentication/BrandContainer/BrandContainer'
import SocialSignUpAddMobileContainer from '../containers/Authentication/SocialSignUpAddMobileContainer/SocialSignUpAddMobileContainer'
import PrivacyPolicyModal from '../containers/Modal/PrivacyPolicyModal/PrivacyPolicyModal'
import TermsConditionModal from '../containers/Modal/TermsConditionModal/TermsConditionModal'

const SocialSignUpAddMobile = () => {

    return (
        <div className="w-screen min-h-screen bg-gray-200 relative">
            <div className="flex flex-col lg:flex-row min-h-screen lg:pt-2">
                <div className="w-full lg:w-4/12">
                    <BrandContainer />
                </div>
                <div className="w-full px-3 py-5 lg:py-10">
                    <div className="grid grid-cols-12">
                        <div className="col-span-full lg:col-start-4 lg:col-end-10">
                            <SocialSignUpAddMobileContainer />
                        </div>
                    </div>
                </div>
            </div>

            <BrandCopyrightMobile />

            <PrivacyPolicyModal />
            <TermsConditionModal />
        </div>
    )
}

export default SocialSignUpAddMobile
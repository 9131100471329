/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import BrandCopyrightMobile from "app/views/components/BrandCopyrightMobile/BrandCopyrightMobile";
import BrandContainer from "app/views/containers/Authentication/BrandContainer/BrandContainer";
import SuccessImg from "app/assets/icon_success.png";
import "./style.scss";
import Authentication from "app/apis/authentication";
import TokenService from "app/services/tokenService";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";

const VerifyEmailSuccess = ({ history, location }) => {
	const { eventCode } = useParams();

	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [success, setSuccess] = useState(true);

	useEffect(() => {
		TokenService.clearTokens();
		confirmVerificationToken();
	}, []);

	const confirmVerificationToken = () => {
		const verificationToken = new URLSearchParams(location.search).get("token");

		if (verificationToken) {
			Authentication.postConfirmVerification(verificationToken)
				.then((response) => {
					setSuccess(true);
				})
				.catch((error) => {
					openAlertSnackbar(error.data.message, "error");
					history.push(`/events/${eventCode}`);
				});
		} else {
			openAlertSnackbar("Invalid token", "error");
			history.push(`/events/${eventCode}`);
		}
	};

	const goToCompleteProfilePage = () => {
		history.push(`/events/${eventCode}/complete-profile`);
	};

	const goToEditProfilePage = () => {};

	return (
		<div className="w-screen min-h-screen bg-gray-200 relative">
			<div className="flex flex-col lg:flex-row min-h-screen lg:pt-2">
				<div className="w-full lg:w-4/12">
					<BrandContainer />
				</div>
				<div className="w-full px-3 py-5 lg:py-10">
					<div className="grid grid-cols-12 h-full">
						<div className="col-span-full lg:col-start-4 lg:col-end-10">
							{success && (
								<div className="verify-email-success-container">
									<div className="flex justify-center lg:justify-start">
										<img src={SuccessImg} alt="success" />
									</div>
									<div className="mb-8 text-center lg:text-left">
										<h1 className="title mb-2">Successfully signed up</h1>
										<p className="desc">
											you have successfully signed up, it's your time to explore the event and
											edit your profile
										</p>
									</div>
									<div className="btn-group-container flex flex-col lg:flex-row justify-end lg:justify-start">
										<button
											type="button"
											className="btn-primary lg:mr-3 mb-3"
											onClick={goToCompleteProfilePage}>
											Complete Your Profile
										</button>
										<button
											type="button"
											className="btn-primary-outline mb-3"
											onClick={goToEditProfilePage}>
											Edit Profile
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<BrandCopyrightMobile />
		</div>
	);
};

export default withRouter(VerifyEmailSuccess);

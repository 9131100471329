/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { exhibitorActions } from "app/states/exhibitor";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import IconStore from "app/assets/Channels_Exhibitors/store_active.png";
import IconClose from "app/assets/icon_close.svg";
import IconCoin from "app/assets/coin.png";
import LocalStorageService from "app/services/localStorageService";
import { PAYMENT_SETTINGS } from "app/config/settings";
import CustomCheckbox from "app/views/components/CustomCheckbox/CustomCheckbox";
import { numberWithCommas } from "app/helper/common";
import OrderAPI from "app/apis/order";
import ProfileAPI from "app/apis/profile";
import SessionStorageService from "app/services/sessionStorageService";
import { DELIVERY_METHODS_NAME, PAYMENT_METHODS_NAME } from "app/config/apiEnums";

const ProductOrderSummaryModal = ({ history }) => {
	const { roomId } = useParams();

	const dispatch = useDispatch();
	const openExhibitorStoreModal = () => dispatch(exhibitorActions.openExhibitorStoreModal());
	const closeProductOrderSummaryModal = () => dispatch(exhibitorActions.closeProductOrderSummaryModal());
	const openJoinPrivateChatModal = () => dispatch(commonActions.openJoinPrivateChatModal());
	const openAddressManagementModal = () => dispatch(commonActions.openAddressManagementModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showModal = useSelector(({ exhibitor }) => exhibitor.showProductOrderSummaryModal);
	const exhibitorRoomDetails = useSelector(({ exhibitor }) => exhibitor.exhibitorRoomDetails);
	const hasPendingPrivateChatInvitation = useSelector(({ exhibitor }) => exhibitor.hasPendingPrivateChatInvitation);
	const eventSetting = useSelector(({ event }) => event.eventSettings);
	const refreshMyCartCount = useSelector(({ order }) => order.refreshMyCartCount);
	const refreshAddressListCount = useSelector(({ profile }) => profile.refreshAddressListCount);
	const exhibitorStoreSettings = useSelector(({ exhibitor }) => exhibitor.exhibitorStoreSettings);

	const [shoppingCart, setShoppingCart] = useState(null);
	const [shoppingCartItemCount, setShoppingCartItemCount] = useState(0);
	const [inputData, setInputData] = useState({
		delivery_method: null,
		payment_method: null,
	});
	const [defaultAddress, setDefaultAddress] = useState(null);

	const [currentView, setCurrentView] = useState("order_summary");
	const [ctaButtonText, setCtaButtonText] = useState("Confirm Order");

	// Auto select first available delivery & payment method
	useEffect(() => {
		if (exhibitorStoreSettings) {
			let firstAvailableDeliveryMethod = Object.keys(exhibitorStoreSettings.delivery_method).find(
				(deliveryMethodKey) => {
					return exhibitorStoreSettings.delivery_method[deliveryMethodKey] === 1;
				}
			);

			let firstAvailablePaymethodMethod = Object.keys(exhibitorStoreSettings.payment_method).find(
				(paymentMethodKey) => {
					return exhibitorStoreSettings.payment_method[paymentMethodKey] === 1;
				}
			);

			setInputData({
				delivery_method: firstAvailableDeliveryMethod ?? null,
				payment_method: firstAvailablePaymethodMethod ?? null,
			});
		}
	}, [exhibitorStoreSettings]);

	const placeOrder = () => {
		const data = {
			room_id: parseInt(roomId),
			store_id: exhibitorRoomDetails.store_ids[0],
			return_url: window.location.href,
			payment_method: PAYMENT_METHODS_NAME[inputData.payment_method]["value"],
			delivery_method: DELIVERY_METHODS_NAME[inputData.delivery_method]["value"],
		};

		if (shoppingCart) {
			// For ordered status viewing (after place order)
			SessionStorageService.setCartProductsOrdered(shoppingCart);

			const cartProducts = shoppingCart.products.map((product) => {
				const { id, sku, quantity } = product;

				return {
					product_id: id,
					sku_id: sku.id,
					quantity: quantity,
				};
			});

			data["products"] = cartProducts;
		}

		if (defaultAddress) {
			data["address_id"] = defaultAddress.id;
		}

		OrderAPI.postPlaceOrder(data)
			.then((response) => {
				// Redirect to payment gateway
				const { payment_url } = response;
				window.location.href = payment_url;
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleClose = () => {
		if (hasPendingPrivateChatInvitation) {
			openJoinPrivateChatModal();
		}

		closeProductOrderSummaryModal();
	};

	const handleDeliveryMethodChange = (key) => {
		setInputData({ ...inputData, delivery_method: key });
	};

	const handlePaymentMethodChange = (key) => {
		setInputData({ ...inputData, payment_method: key });
	};

	const handleCtaClick = () => {
		if (currentView === "order_summary") {
			setCurrentView("shipping_billing");
			setCtaButtonText("Pay Now");
		} else {
			placeOrder();
		}
	};

	const handleBack = () => {
		if (currentView === "shipping_billing") {
			setCurrentView("order_summary");
			setCtaButtonText("Confirm Order");
		} else {
			// Back to product selection modal
			openExhibitorStoreModal();
			closeProductOrderSummaryModal();
		}
	};

	// Get the user's saved order
	useEffect(() => {
		OrderAPI.getSavedOrderByExhibitorRoom(roomId)
			.then((response) => {
				let subtotal = 0;

				if (response.products && response.products.length > 0) {
					response.products.forEach((product) => {
						subtotal += parseFloat(product.sub_total_price);
					});
				}

				response.subtotal = subtotal;
				setShoppingCart(response);
				setShoppingCartItemCount(response.products.length);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshMyCartCount]);

	// Get address list
	useEffect(() => {
		ProfileAPI.getAddressList()
			.then((response) => {
				if (response.addresses.length > 0) {
					// Set the default address id
					response.addresses.forEach((address) => {
						if (address.default === 1) {
							setDefaultAddress(address);
						}
					});
				} else {
					setDefaultAddress(null);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshAddressListCount]);

	const renderShippingAddress = () => {
		if (inputData.delivery_method === "delivery") {
			if (defaultAddress) {
				return (
					<div>
						<div>{defaultAddress.name}</div>
						<div>{defaultAddress.phone_no}</div>
						<div>{defaultAddress.address},</div>
						<div>
							{defaultAddress.postcode} {defaultAddress.city}, {defaultAddress.state}
						</div>
					</div>
				);
			} else {
				<div className="text-no-address">No address set</div>;
			}
		} else if (inputData.delivery_method === "self_pickup") {
			const storeAddress = exhibitorRoomDetails.account.address;
			return <div>{storeAddress}</div>;
		} else {
			return <div>{DELIVERY_METHODS_NAME[inputData.delivery_method]["name"]}</div>;
		}
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="product-order-summary-modal">
			<div id="product-order-summary-modal" className="app-modal mobile-app-modal">
				<section className="modal-header flex justify-between lg:p-5">
					<h1 className="modal-title">
						<img src={IconStore} alt="store" className="icon-store" />
						{exhibitorRoomDetails && exhibitorRoomDetails.title}
					</h1>
					<span className="btn-close">
						<img src={IconClose} alt="Close" onClick={handleClose} />
					</span>
				</section>

				<section className="modal-body lg:px-5">
					<div className="grid grid-cols-12 gap-5">
						<section className="col-span-12 lg:col-span-8">
							<h3 className="section-label">Products Ordered</h3>
							<div className="section-container products-ordered-container">
								{shoppingCart &&
									shoppingCart.products.map((product, index) => {
										const {
											currency,
											image_url,
											name,
											quantity,
											sub_total_price,
											unit_price,
											sku,
										} = product;

										return (
											<CartProduct
												key={`cart-product-ordered-${index}`}
												thumbnail={image_url}
												name={name}
												unit_price={unit_price}
												subtotal_price={sub_total_price}
												quantity={quantity}
												currency={currency}
												sku={sku}
											/>
										);
									})}
							</div>
						</section>

						{currentView === "order_summary" && shoppingCart && (
							<section className="col-span-12 lg:col-span-4">
								<h3 className="section-label">Order Summary ({shoppingCartItemCount} Items)</h3>
								<div className="section-container order-summary-container">
									<section className="order-total-section">
										<div className="items-subtotal flex justify-between items-center">
											<span className="text-label">Items subtotal</span>
											{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
												<span className="flex items-center">
													<img src={IconCoin} alt="coin" className="icon-coin" />
													<span className="text-credit-amount">
														{shoppingCart.total_price}
													</span>
												</span>
											) : (
												<span className="text-label">
													{shoppingCart.currency}
													{shoppingCart.total_price}
												</span>
											)}
										</div>
										<div className="order-total flex justify-between items-center">
											<strong>Order total</strong>
											{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
												<strong className="flex items-center">
													<img src={IconCoin} alt="coin" className="total-icon-coin" />
													<span className="total-text-credit-amount">
														{shoppingCart.total_price}
													</span>
												</strong>
											) : (
												<strong>
													{shoppingCart.currency}
													{shoppingCart.total_price}
												</strong>
											)}
										</div>
									</section>

									{exhibitorStoreSettings && (
										<>
											<section className="delivery-payment-section">
												<div className="mb-1">
													<label className="text-label">Delivery Method</label>
												</div>
												<div className="grid grid-cols-12 gap-3">
													<div className="col-span-12 flex items-center flex-wrap">
														{Object.keys(exhibitorStoreSettings.delivery_method).map(
															(deliveryMethodKey, index) => {
																// Only show those are flag as 1
																if (
																	exhibitorStoreSettings.delivery_method[
																		deliveryMethodKey
																	] === 1
																) {
																	return (
																		<CustomCheckbox
																			key={`delivery-method-${index}`}
																			value={deliveryMethodKey}
																			name={
																				DELIVERY_METHODS_NAME[
																					deliveryMethodKey
																				]["name"]
																			}
																			onChange={handleDeliveryMethodChange}
																			checked={
																				inputData.delivery_method ===
																				deliveryMethodKey
																			}
																		/>
																	);
																}

																return null;
															}
														)}
													</div>
												</div>
											</section>

											<hr />

											<section className="delivery-payment-section">
												<div className="mb-1">
													<label className="text-label">Payment Method</label>
												</div>
												<div className="grid grid-cols-12 gap-3">
													<div className="col-span-12 flex items-center">
														{Object.keys(exhibitorStoreSettings.payment_method).map(
															(paymentMethodKey, index) => {
																// Only show those are flag as 1
																if (
																	exhibitorStoreSettings.payment_method[
																		paymentMethodKey
																	] === 1
																) {
																	return (
																		<CustomCheckbox
																			key={`payment-method-${index}`}
																			value={paymentMethodKey}
																			name={
																				PAYMENT_METHODS_NAME[paymentMethodKey][
																					"name"
																				]
																			}
																			onChange={handlePaymentMethodChange}
																			checked={
																				inputData.payment_method ===
																				paymentMethodKey
																			}
																		/>
																	);
																}

																return null;
															}
														)}
													</div>
												</div>
											</section>

											{eventSetting.payment_settings.includes(
												PAYMENT_SETTINGS["place_booking"]
											) && (
												<p className="text-note">
													**the seller shall contact you after confirming the order to
													configure the delivery & payment details
												</p>
											)}
										</>
									)}
								</div>
							</section>
						)}

						{currentView === "shipping_billing" && (
							<section className="col-span-12 lg:col-span-4">
								<h3 className="section-label">Shipping & Billing</h3>
								<div className="shipping-billing-container">
									<section>
										<div className="shipping-section-header">
											{/* Only show edit button if not self pickup */}
											{inputData.delivery_method === "self_pickup" ? (
												<div>Pickup From</div>
											) : (
												<>
													<div>Deliver To</div>
													<div className="btn-edit" onClick={openAddressManagementModal}>
														Edit
													</div>
												</>
											)}
										</div>
										<div className="shipping-section-content">{renderShippingAddress()}</div>
									</section>
									<section>
										<div className="shipping-section-header">
											<div>Order Summary</div>
										</div>
										{shoppingCart && (
											<div className="shipping-section-content">
												<div className="flex justify-between items-center mb-2">
													<div>Subtotal ({shoppingCartItemCount} items):</div>
													<div className="font-bold">
														{shoppingCart.currency}
														{numberWithCommas(shoppingCart.subtotal)}
													</div>
												</div>
												{inputData.delivery_method === "delivery" && (
													<div className="flex justify-between items-center mb-2">
														<div>Delivery Fees:</div>
														<div className="font-bold">
															{shoppingCart.currency}
															{numberWithCommas(shoppingCart.delivery_fees)}
														</div>
													</div>
												)}
												<hr className="my-3" />
												<div className="flex justify-between items-center mb-2">
													<div>Total:</div>
													<div className="font-bold">
														{shoppingCart.currency}
														{numberWithCommas(shoppingCart.total_price)}
													</div>
												</div>
											</div>
										)}
									</section>
									<section>
										<div className="shipping-section-header">
											<div>Payment Method</div>
										</div>
										<div className="shipping-section-content">
											{inputData.payment_method
												? PAYMENT_METHODS_NAME[inputData.payment_method]["name"]
												: "-"}
										</div>
									</section>
								</div>
							</section>
						)}
					</div>
				</section>

				<section className="modal-footer">
					<div className="credit-balance">
						{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) && (
							<>
								<div className="text-my-balance">My Balance</div>
								<div className="flex items-center">
									<img src={IconCoin} alt="coin" className="icon-coin" />
									<span className="text-credit-amount">950</span>
								</div>
							</>
						)}
					</div>
					<div className="flex justify-end items-center w-full">
						<button type="button" className="btn-back" onClick={handleBack}>
							Back
						</button>
						<button
							type="button"
							className={`btn-cta ${
								!(shoppingCart && shoppingCart.products.length > 0) ? "btn-disabled" : ""
							}`}
							onClick={handleCtaClick}
							disabled={!(shoppingCart && shoppingCart.products.length > 0)}>
							{ctaButtonText}
						</button>
					</div>
				</section>
			</div>
		</Modal>
	);
};

const CartProduct = ({ thumbnail, name, currency, unit_price, quantity, subtotal_price, sku }) => {
	const eventSetting = LocalStorageService.getEventSetting();

	return (
		<div className="cart-product flex">
			<img src={thumbnail} alt="product" className="img-product" />
			<div className="flex flex-col justify-between items-stretch w-full">
				<div className="grid grid-cols-12 gap-2 lg:gap-3">
					<div className="col-span-12 lg:col-span-7">
						<div className="product-name">{name}</div>
					</div>
					<div className="col-span-12 lg:col-span-5 lg:text-right product-price">
						{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
							<span className="flex items-center">
								<img src={IconCoin} alt="coin" className="icon-coin" />
								<span className="text-credit-amount">{unit_price}</span>
							</span>
						) : (
							<span>
								{currency}
								{unit_price}
							</span>
						)}
					</div>
					{sku && (
						<div className="col-span-12 flex items-center product-variant flex-wrap">
							{sku.size && (
								<div className="variant-option">
									Size: <span className="size-value">{sku.size}</span>
								</div>
							)}
							{sku.color && (
								<div className="variant-option">
									Color: <span className="color-value">{sku.color}</span>
								</div>
							)}
							{sku.option && (
								<div className="variant-option">
									Option: <span className="option-value">{sku.option}</span>
								</div>
							)}
						</div>
					)}
				</div>
				<div className="flex justify-between items-center">
					<div>
						<label className="label-quantity">Quantity</label>
						<div className="text-count">{quantity || 0}</div>
					</div>
					<div className="subtotal flex flex-col lg:flex-row items-end lg:items-center">
						<span className="lg:mr-1">Subtotal: </span>
						{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) ? (
							<span className="flex items-center">
								<img src={IconCoin} alt="coin" className="icon-coin" />
								<span className="text-credit-amount">{subtotal_price}</span>
							</span>
						) : (
							<strong>
								{currency}
								{subtotal_price}
							</strong>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ProductOrderSummaryModal);

import LocalStorageService from "app/services/localStorageService";
import TokenService from "app/services/tokenService";
import * as types from "./types";

const initialState = {
	showResetPasswordSuccessModal: false,
	showLoginRegisterModal: false,
	showEventRegistrationModal: false,
	showShippingAddressModal: false,
	loginRegisterTabIndex: 0,
	isLoggedin: TokenService.getAccessToken() ? true : false,
	userProfile: LocalStorageService.getUserProfile() || null,
	showTicketSummaryModal: false,
    showTicketCheckoutStatusModal: false,
    refreshUserEventStatusCount: 0,
	isFromRegisterButton: false,
};

const AuthenticationReducer = (state = initialState, action) => {
	switch (action.type) {
		// Reset password success modal
		case types.OPEN_RESET_PW_SUCCESS_MODAL:
			return {
				...state,
				showResetPasswordSuccessModal: true,
			};
		case types.CLOSE_RESET_PW_SUCCESS_MODAL:
			return {
				...state,
				showResetPasswordSuccessModal: false,
			};

		// Login register modal
		case types.OPEN_LOGIN_REGISTER_MODAL:
			return {
				...state,
				showLoginRegisterModal: true,
			};
		case types.CLOSE_LOGIN_REGISTER_MODAL:
			return {
				...state,
				showLoginRegisterModal: false,
			};

		// Event registration modal
		case types.OPEN_EVENT_REGISTRATION_MODAL:
			return {
				...state,
				showEventRegistrationModal: true,
			};
		case types.CLOSE_EVENT_REGISTRATION_MODAL:
			return {
				...state,
				showEventRegistrationModal: false,
			};

		// shipping address modal
		case types.OPEN_SHIPPING_ADDRESS_MODAL:
			return {
				...state,
				showShippingAddressModal: true,
			};
		case types.CLOSE_SHIPPING_ADDRESS_MODAL:
			return {
				...state,
				showShippingAddressModal: false,
			};

		// login register tab index
		case types.SET_LOGIN_REGISTER_TAB_INDEX:
			return {
				...state,
				loginRegisterTabIndex: action.params.tabIndex,
			};

		// set is logged in
		case types.SET_IS_LOGGED_IN:
			return {
				...state,
				isLoggedin: action.params.isLogin,
			};

		// set user profile
		case types.SET_USER_PROFILE:
			return {
				...state,
				userProfile: action.params.userProfile,
			};

		// ticket summary modal
		case types.OPEN_TICKET_SUMMARY_MODAL:
			return {
				...state,
				showTicketSummaryModal: true,
			};
		case types.CLOSE_TICKET_SUMMARY_MODAL:
			return {
				...state,
				showTicketSummaryModal: false,
			};

        // ticket checkout status modal
		case types.OPEN_TICKET_CHECKOUT_STATUS_MODAL:
			return {
				...state,
				showTicketCheckoutStatusModal: true,
			};
		case types.CLOSE_TICKET_CHECKOUT_STATUS_MODAL:
			return {
				...state,
				showTicketCheckoutStatusModal: false,
			};

        // refresh user event status
        case types.REFRESH_USER_EVENT_STATUS: 
            return {
                ...state, 
                refreshUserEventStatusCount: state.refreshUserEventStatusCount + 1
            }

		// is from register button
        case types.SET_IS_FROM_REGISTER_BUTTON: 
            return {
                ...state, 
                isFromRegisterButton: action.params.isFromRegisterButton
            }
        default:
            return state
    }
};

export default AuthenticationReducer;

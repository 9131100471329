import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import Authentication from "app/apis/authentication";
import TokenService from "app/services/tokenService";
import ResetPasswordSuccessModal from "../ResetPasswordSuccessModal/ResetPasswordSuccessModal";
import { useDispatch } from "react-redux";
import { authenticationActions } from "app/states/authentication";
import { commonActions } from "app/states/common";

const ResetPasswordContainer = ({ history, location }) => {
	const { eventCode } = useParams();

	const dispatch = useDispatch();
	const openResetPasswordSuccessModal = () => dispatch(authenticationActions.openResetPasswordSuccessModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [resetPasswordToken, setResetPasswordToken] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	useEffect(() => {
		TokenService.clearTokens();
		getResetPasswordToken();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getResetPasswordToken = () => {
		const resetPasswordToken = new URLSearchParams(location.search).get("token");

		if (!resetPasswordToken) {
			openAlertSnackbar("Invalid token", "error");
			history.push(`/events/${eventCode}`);
		}

		setResetPasswordToken(resetPasswordToken);
	};

	const handleResetPassword = (event) => {
		event.preventDefault();

		// Validate if password and confirm password is same
		if (newPassword !== confirmNewPassword) {
			openAlertSnackbar("New password and Confirm new password must be same", "error");
			return;
		}

		// Reset password
		Authentication.postUpdatePassword(newPassword, resetPasswordToken)
			.then((response) => {
				openResetPasswordSuccessModal();
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<div className="flex flex-col h-full">
			<label className="reset-password-title mb-5">One more step to reset your password</label>

			<form id="form-reset-password" method="post" onSubmit={handleResetPassword}>
				<div className="form-input">
					<label htmlFor="new-password">Create new password</label>
					<input
						id="new-password"
						type="password"
						name="password"
						required
						onChange={(event) => setNewPassword(event.target.value)}
					/>
				</div>
				<div className="form-input">
					<label htmlFor="confirm-new-password">Confirm new password</label>
					<input
						id="confirm-new-password"
						type="password"
						name="password"
						required
						onChange={(event) => setConfirmNewPassword(event.target.value)}
					/>
				</div>
				<div className="mt-8 mb-3">
					<button type="submit" className="btn-primary">
						Confirm
					</button>
				</div>
			</form>

			<ResetPasswordSuccessModal />
		</div>
	);
};

export default withRouter(ResetPasswordContainer);

import axios from "axios";
import history from "./../routes/history";
import TokenService from "./tokenService";
import LocalStorageService from "./localStorageService";
import { commonActions } from "app/states/common";
import store from "./redux/reduxStore";
import { authenticationActions } from "app/states/authentication";
import { getEventCodeFromUrl } from "app/helper/common";

// Add a request interceptor
export const InitAxiosInterceptor = () => {
	const { dispatch } = store;
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setUserProfile = (userProfile) => dispatch(authenticationActions.setUserProfile(userProfile));
	const setIsLoggedIn = (isLogin) => dispatch(authenticationActions.setIsLoggedIn(isLogin));

	// Request
	axios.interceptors.request.use();

	// Response
	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			const { status } = error.response;

			const eventCode = getEventCodeFromUrl();
			const currentEventCode = eventCode || "ebizstart";

			// If access token expired
			if (status === 410) {
				setUserProfile(null);
				setIsLoggedIn(false);
				TokenService.clearTokens();
				LocalStorageService.clearAll();
				openAlertSnackbar("Your login session has expired. Please login again.", "error");
				history.push(`/events/${currentEventCode}`);
				return;
			}
			else {
				throw error;
			}
		}
	);
};

import React, { useEffect, useState } from "react";
import MenuBar from "app/views/containers/MenuBar/MenuBar";
import BrandLogoMobile from "app/views/components/BrandLogoMobile/BrandLogoMobile";
import ProfileContainer from "app/views/containers/ProfileContainer/ProfileContainer";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import IconProfile from "app/assets/Profile/Profile_active.png";
import IconProfileInactive from "app/assets/Profile/Profile_inactive.png";
import IconOrder from "app/assets/Profile/myorder_active.png";
import IconOrderInactive from "app/assets/Profile/myorder_inactive.png";
import IconCart from "app/assets/icon_cart_active.png";
import IconCartInactive from "app/assets/icon_cart_inactive.png";
import IconLocation from "app/assets/icon_location_active.png";
import IconLocationInactive from "app/assets/icon_location_active.png";
import LocalStorageService from "app/services/localStorageService";
import "./style.scss";
import MyOrdersContainer from "app/views/containers/Order/MyOrdersContainer/MyOrdersContainer";
import AddressManagementContainer from "app/views/containers/Address/AddressManagementContainer";
import { useDispatch, useSelector } from "react-redux";
import { profileActions } from "app/states/profile";
import MobileSavedOrderContainer from "app/views/containers/Order/MobileSavedOrderContainer/MobileSavedOrderContainer";

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`profile-tabpanel-${index}`}
			aria-labelledby={`profile-tabpanel-${index}`}
			{...other}>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

function tabIndexProps(index) {
	return {
		id: `profile-tabpanel-${index}`,
		"aria-controls": `profile-tabpanel-${index}`,
	};
}

const MobileProfilePage = () => {
	const eventSetting = LocalStorageService.getEventSetting();

	const dispatch = useDispatch();
	const setSelectedProfileTabIndex = (tabIndex) => dispatch(profileActions.setSelectedProfileTabIndex(tabIndex));

	const selectedProfileTabIndex = useSelector(({ profile }) => profile.selectedProfileTabIndex);

	const [tabIndex, setTabIndex] = useState(selectedProfileTabIndex || 0);

	const handleTabChange = (event, newTabIndex) => {
		setSelectedProfileTabIndex(newTabIndex);
		setTabIndex(newTabIndex);
	};

	return (
		<div className="grid grid-cols-12 w-screen mobile-profile-page">
			<div className="col-span-12">
				<BrandLogoMobile />
			</div>

			<div className="col-span-12">
				<div className="flex justify-between">
					<Tabs
						value={tabIndex}
						onChange={handleTabChange}
						aria-label="Profile Tabs"
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable">
						<Tab
							icon={
								<img
									src={tabIndex === 0 ? IconProfile : IconProfileInactive}
									alt="My Profile"
									className="tab-icon"
								/>
							}
							label={
								<span className={`tab-label ${tabIndex === 0 ? "tab-label-active" : ""}`}>
									My Profile
								</span>
							}
							{...tabIndexProps(0)}
							className="tab"
						/>
						{eventSetting.ecommerce_enabled === 1 && (
							<Tab
								icon={
									<img
										src={tabIndex === 1 ? IconOrder : IconOrderInactive}
										alt="My Orders"
										className="tab-icon"
									/>
								}
								label={
									<span className={`tab-label ${tabIndex === 1 ? "tab-label-active" : ""}`}>
										My Orders
									</span>
								}
								{...tabIndexProps(1)}
								className="tab"
							/>
						)}
						{eventSetting.ecommerce_enabled === 1 && (
							<Tab
								icon={
									<img
										src={tabIndex === 2 ? IconCart : IconCartInactive}
										alt="My Cart"
										className="tab-icon"
									/>
								}
								label={
									<span className={`tab-label ${tabIndex === 2 ? "tab-label-active" : ""}`}>
										My Cart
									</span>
								}
								{...tabIndexProps(2)}
								className="tab"
							/>
						)}
						<Tab
							icon={
								<img
									src={tabIndex === 3 ? IconLocation : IconLocationInactive}
									alt="My Addresses"
									className="tab-icon"
								/>
							}
							label={
								<span className={`tab-label ${tabIndex === 3 ? "tab-label-active" : ""}`}>
									My Addresses
								</span>
							}
							{...tabIndexProps(3)}
							className="tab"
						/>
					</Tabs>
				</div>
				<div>
					<TabPanel value={tabIndex} index={0} className="tab-panel m__tab-panel profile-tab-panel p-5">
						<ProfileContainer />
					</TabPanel>
					<TabPanel value={tabIndex} index={1} className="tab-panel m__tab-panel order-tab-panel">
						<MyOrdersContainer />
					</TabPanel>
					<TabPanel value={tabIndex} index={2} className="tab-panel m__tab-panel">
						<MobileSavedOrderContainer />
					</TabPanel>
					<TabPanel value={tabIndex} index={3} className="tab-panel m__tab-panel">
						<AddressManagementContainer />
					</TabPanel>
				</div>
			</div>

			{/* Mobile View Menu Bar */}
			<div className="col-span-12 block">
				<MenuBar />
			</div>
		</div>
	);
};

export default MobileProfilePage;

import React from "react";
import EventInfoContainer from "app/views/containers/Authentication/EventInfoContainer/EventInfoContainer";
import LoginRegisterContainer from "app/views/containers/Authentication/LoginRegisterContainer/LoginRegisterContainer";
import PrivacyPolicyModal from "app/views/containers/Modal/PrivacyPolicyModal/PrivacyPolicyModal";
import ResetPasswordModal from "app/views/containers/Modal/ResetPasswordModal/ResetPasswordModal";
import TermsConditionModal from "app/views/containers/Modal/TermsConditionModal/TermsConditionModal";
import { Redirect, useParams } from "react-router-dom";

const LoginRegister = () => {
	const { eventCode } = useParams();

	// return (
	// 	<div id="login-register-page" className="w-screen min-h-screen">
	// 		<div className="grid grid-cols-12 gap-5 min-h-screen">
	// 			<div className="col-span-6 px-10 h-full">
	// 				<EventInfoContainer />
	// 			</div>
	// 			<div className="col-span-6 px-10 h-full">
	// 				<LoginRegisterContainer />
	// 			</div>
	// 		</div>

	// 		<ResetPasswordModal />
	// 		<TermsConditionModal />
	// 		<PrivacyPolicyModal />
	// 	</div>
	// );

	return <Redirect to={`/events/${eventCode}`} />
};

export default LoginRegister;

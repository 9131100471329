import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from "react-redux";
import { commonActions } from 'app/states/common';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertSnackbar = () => {

	const dispatch = useDispatch();
	const closeAlertSnackbar = () => dispatch(commonActions.closeAlertSnackbar());

	const showAlertSnackbar = useSelector(({ common }) => common.showAlertSnackbar);
	const alertSnackbarMessage = useSelector(({ common }) => common.alertSnackbarMessage);
	const alertSnackbarVariant = useSelector(({ common }) => common.alertSnackbarVariant);

	const handleClose = () => {
		closeAlertSnackbar();
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			open={showAlertSnackbar}
			autoHideDuration={3000}
			onClose={handleClose}>
			<Alert onClose={handleClose} severity={alertSnackbarVariant || "success"}>
				{alertSnackbarMessage}
			</Alert>
		</Snackbar>
	);
};

export default AlertSnackbar;

import apiAction from "../services/apiAction"
import LocalStorageService from '../services/localStorageService';

const OrderAPI = {

    /**
     * Get user's orders
     */
    getUserOrders: () => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/orders`;

            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get user's order details
     */
    getUserOrderDetails: (orderId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/orders/${orderId}`;

            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get user's carts order rooms
     */
    getSavedOrderRooms: () => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/orders/carts/rooms`;

            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * View carts order for specific room
     */
    getSavedOrderByExhibitorRoom: (roomId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/orders/carts/rooms/${roomId}`;

            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Place order
     * @param {object} data 
     */
    postPlaceOrder: (data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/orders`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Save order
     * @param {object} data 
     */
    postSaveOrderByExhibitorRoom: (roomId, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/orders/carts/rooms/${roomId}`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Delete carts order
     */
    deleteSavedOrderByExhibitorRoom: (roomId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/orders/carts/rooms/${roomId}`;

            apiAction.delete(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },
};

export default OrderAPI;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import LocalStorageService from "app/services/localStorageService";
import EventAPI from "app/apis/event";
import ReactLoading from "react-loading";
import IconCalendar from "app/assets/icon_calendar_black.png";
import moment from "moment";
import useQueryParams from "app/views/hooks/useQueryParams";

const EventInfoContainer = () => {
	const { eventCode } = useParams();
	const queryParams = useQueryParams();

	const cachedEventSetting = LocalStorageService.getEventSetting();

	const [eventSetting, setEventSetting] = useState(cachedEventSetting);

	// Get event setting
	useEffect(() => {
		if (cachedEventSetting) return;

		const previewToken = queryParams.get("preview_token");
		let requestHeaders = {};
		if (previewToken) {
			requestHeaders["preview-token"] = previewToken;
		}

		EventAPI.getEventSetting(eventCode, requestHeaders)
			.then((response) => {
				const { event, web_config } = response;

				setEventSetting(event);

				LocalStorageService.setEventSetting(eventCode, JSON.stringify(event));
				LocalStorageService.setWebConfig(eventCode, JSON.stringify(web_config));
			})
			.catch((error) => {
				console.log("Event error: ", error);
			});
	}, [eventCode]);

	if (!eventSetting) {
		return (
			<div className="flex justify-center items-center w-full h-full">
				<ReactLoading type={"spinningBubbles"} color="grey" />
			</div>
		);
	}

	return (
		<div id="event-info-container" className="flex flex-col justify-center items-center h-full">
			<div className="event-info-wrapper">
				<h1 className="mb-3 lg:mb-6">{eventSetting.name}</h1>

				<img
					src={eventSetting.image_url || "https://dummyimage.com/600x400.png"}
					alt={eventSetting.name}
					className="img-event mb-3 lg:mb-6"
				/>

				<div className="event-datetime flex items-start justify-start w-full px-2">
					<div className="mr-2">
						<img src={IconCalendar} alt="calendar" className="icon-calendar" />
					</div>
					<div>
						<div className="mb-2">
							{moment(eventSetting.start_at).format("D MMM")} -{" "}
							{moment(eventSetting.end_at).format("D MMM, YYYY")}
						</div>
						<div>
							{moment(eventSetting.start_at).format("h:mmA")} - {moment(eventSetting.end_at).format("h:mmA")}{" "}
							(UTC +8)
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(EventInfoContainer);

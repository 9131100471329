import React, { useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { commonActions } from "app/states/common";
import { useDispatch } from "react-redux";
import "./style.scss";
import COUNTRY_CALLING_CODES from "app/config/country_by_calling_code.json";
import ProfileAPI from "app/apis/profile";
import LocalStorageService from "app/services/localStorageService";
import moment from "moment";

const SocialSignUpAddMobileContainer = ({ history }) => {
	const { eventCode } = useParams();

	const userProfile = LocalStorageService.getUserProfile();
	const eventSetting = LocalStorageService.getEventSetting();

	const dispatch = useDispatch();
	const openTermsConditionModal = () => dispatch(commonActions.openTermsConditionModal());
	const openPrivacyPolicyModal = () => dispatch(commonActions.openPrivacyPolicyModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

    const [recaptchaToken, setRecaptchaToken] = useState("");
	const [registerInput, setRegisterInput] = useState({
		calling_code: 60,
		phone_no: "",
		agree_privacy: false,
		agree_tnc: false,
	});

	const onChangeRecaptcha = (value) => {
		setRecaptchaToken(value);
	};

	const handleRegisterInputChange = (event) => {
		if (event.target.name === "agree_privacy" || event.target.name === "agree_tnc") {
			setRegisterInput({ ...registerInput, [event.target.name]: event.target.checked });
		} else {
			setRegisterInput({ ...registerInput, [event.target.name]: event.target.value });
		}
	};

	const handleRegister = (event) => {
		event.preventDefault();

        // Ensure recaptcha is verified
        if (!recaptchaToken) {
            openAlertSnackbar("Please complete the recaptcha", "error");
			return;
        }

		const data = {
			phone_no: `${registerInput.calling_code}${registerInput.phone_no}`,
		};

		ProfileAPI.postUpdateUserProfile(data)
			.then((response) => {
				// If profile not completed
				if (userProfile.profile_completed === 0) {
					history.push(`/events/${eventCode}/complete-profile`, {
						name: userProfile.first_name || userProfile.email,
					});
					return;
				}

				// Check if event started yet
				const diffDays = moment().diff(moment(eventSetting.start_at), "days");
				if (diffDays >= 0) {
					history.push(`/events/${eventCode}/channel`);
				} else {
					history.push(`/events/${eventCode}/countdown`);
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<div className="social-sign-up-add-mobile-container flex flex-col h-full">
			<label className="title mb-5">One more step to complete your sign up process!</label>

			<form id="form-register" method="POST" onSubmit={handleRegister}>
				<div className="form-input">
					<label htmlFor="register-phone-number">Phone number</label>
					<div className="input-box">
						<select
							className="input-prefix"
							value={registerInput.calling_code}
							name="calling_code"
							onChange={handleRegisterInputChange}
							required>
							<option value={registerInput.calling_code} disabled>
								{registerInput.calling_code}
							</option>
							{COUNTRY_CALLING_CODES.filter((callingCode) => {
								return callingCode.callingCode !== null;
							}).map((callingCode, index) => {
								return (
									<option
										key={`${callingCode.calling_code}-${index}`}
										value={callingCode.calling_code}>
										{callingCode.calling_code} - {callingCode.country}
									</option>
								);
							})}
						</select>
						<input
							id="register-phone-number"
							type="text"
							name="phone_no"
							placeholder="123456789"
							required
							value={registerInput.phone_no}
							onChange={handleRegisterInputChange}
						/>
					</div>
				</div>
				<div className="flex justify-between">
					<div className="pr-3">
						<div className="form-input flex items-center">
							<input
								id="agree_privacy"
								type="checkbox"
								className="app-custom-checkbox"
								required
								name="agree_privacy"
								checked={registerInput.agree_privacy}
								onChange={handleRegisterInputChange}
							/>
							<label htmlFor="agree_privacy">
								I have read and agree to the{" "}
								<span className="text-anchor-link" onClick={openPrivacyPolicyModal}>
									Privacy Policy
								</span>
							</label>
						</div>
						<div className="form-input flex items-center">
							<input
								id="agree_tnc"
								type="checkbox"
								className="app-custom-checkbox"
								required
								name="agree_tnc"
								checked={registerInput.agree_tnc}
								onChange={handleRegisterInputChange}
							/>
							<label htmlFor="agree_tnc">
								I have read and agree to the{" "}
								<span className="text-anchor-link" onClick={openTermsConditionModal}>
									Terms of Use
								</span>
							</label>
						</div>
					</div>
					<div>
						<ReCAPTCHA
							sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
							onChange={onChangeRecaptcha}
							size="compact"
						/>
					</div>
				</div>

				<div className="mb-3">
					<button type="submit" className="btn-primary">
						Sign Up
					</button>
				</div>
			</form>
		</div>
	);
};

export default withRouter(SocialSignUpAddMobileContainer);

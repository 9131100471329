import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconInfo from "app/assets/Agent/info_dark.png";
import { useSelector, useDispatch } from "react-redux";
import AgentAPI from "app/apis/agent";
import { commonActions } from "app/states/common";

const CompanyInfoContainer = () => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const roomDetails = useSelector(({ agent }) => agent.roomDetails);

	const [inputData, setInputData] = useState({
		cs_email: "",
		cs_number: "",
		address: "",
		website: "",
		facebook: "",
		instagram: "",
		youtube: "",
		twitter: "",
		linkedin: "",
	});

	useEffect(() => {
		if (!roomDetails) return;

		setInputData({
			cs_email: roomDetails.account.email,
			cs_number: roomDetails.account.phone_no,
			address: roomDetails.account.address,
			website: roomDetails.account.website,
			facebook: roomDetails.account.facebook_url,
			instagram: roomDetails.account.instagram_url,
			youtube: roomDetails.account.youtube_url,
			twitter: roomDetails.account.twitter_url,
			linkedin: roomDetails.account.linkedin_url,
		});
	}, [roomDetails]);

	const handleInputDataChange = (event) => {
		setInputData({
			...inputData,
			[event.target.name]: event.target.value,
		});
	};

	const submitUpdateCompanyInfo = (event) => {
		event.preventDefault();

		let formData = new FormData();
		formData.append("email", inputData.cs_email);
		formData.append("phone_no", inputData.cs_number);

		if (inputData.address) {
			formData.append("address", inputData.address);
		}
		if (inputData.website) {
			formData.append("website", inputData.website);
		}
		if (inputData.facebook) {
			formData.append("facebook_url", inputData.facebook);
		}
		if (inputData.instagram) {
			formData.append("instagram_url", inputData.instagram);
		}
		if (inputData.youtube) {
			formData.append("youtube_url", inputData.youtube);
		}
		// if (inputData.twitter) {
		// 	formData.append("twitter_url", inputData.twitter);
		// }
		if (inputData.linkedin) {
			formData.append("linkedin_url", inputData.linkedin);
		}

		AgentAPI.postUpdateRoomDetail(formData)
			.then((response) => {
				openAlertSnackbar("Updated Company Info", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<div id="company-info" className="company-info-container mb-3">
			<div className="grid grid-cols-12 gap-3">
				<div className="col-span-2">
					<h3>
						<img src={IconInfo} alt="Company Info" /> Company Info
					</h3>
				</div>
				<div className="col-span-8">
					<form
						id="form-company-info"
						method="post"
						className="form-container mb-3"
						onSubmit={submitUpdateCompanyInfo}>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-3">
								<label>Customer Service Email</label> <span>*</span>
							</div>
							<div className="col-span-5">
								<input
									type="email"
									name="cs_email"
									value={inputData.cs_email}
									onChange={handleInputDataChange}
									required
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-3">
								<label>Customer Service Number</label> <span>*</span>
							</div>
							<div className="col-span-5">
								<input
									type="text"
									name="cs_number"
									value={inputData.cs_number}
									onChange={handleInputDataChange}
									required
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-3">
								<label>Address</label>
							</div>
							<div className="col-span-5">
								<textarea
									name="address"
									onChange={handleInputDataChange}
									rows={3}
									value={inputData.address || ""}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-3">
								<label>Website</label>
							</div>
							<div className="col-span-5">
								<input
									type="url"
									name="website"
									value={inputData.website}
									onChange={handleInputDataChange}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-3">
								<label>Facebook</label>
							</div>
							<div className="col-span-5">
								<input
									type="url"
									name="facebook"
									value={inputData.facebook}
									onChange={handleInputDataChange}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-3">
								<label>Instagram</label>
							</div>
							<div className="col-span-5">
								<input
									type="url"
									name="instagram"
									value={inputData.instagram}
									onChange={handleInputDataChange}
								/>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-3">
								<label>YouTube</label>
							</div>
							<div className="col-span-5">
								<input
									type="url"
									name="youtube"
									value={inputData.youtube}
									onChange={handleInputDataChange}
								/>
							</div>
						</div>
						{/* <div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-3">
								<label>Twitter</label>
							</div>
							<div className="col-span-5">
								<input
									type="url"
									name="twitter"
									value={inputData.twitter}
									onChange={handleInputDataChange}
								/>
							</div>
						</div> */}
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-3">
								<label>LinkedIn</label>
							</div>
							<div className="col-span-5">
								<input
									type="url"
									name="linkedin"
									value={inputData.linkedin}
									onChange={handleInputDataChange}
								/>
							</div>
						</div>
					</form>

					<div className="flex items-center justify-end">
						<button type="submit" className="btn-save ml-5" form={"form-company-info"}>
							Save Changes
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(CompanyInfoContainer);

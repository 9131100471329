/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import IconCoin from "app/assets/coin.png";
import IconClose from "app/assets/Authentication/icon-close.svg";
import IconAdd from "app/assets/Authentication/icon-add.svg";
import IconEdit from "app/assets/icon_profile_edit.png";
import { getAllDaysOfMonth, getAllMonths, getAllYears } from "app/helper/datetime";
import COUNTRIES from "app/config/country_region.json";
import { PAYMENT_SETTINGS, ID_TYPE } from "app/config/settings";
import LocalStorageService from "app/services/localStorageService";
import ProfileAPI from "app/apis/profile";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import { authenticationActions } from "app/states/authentication";

const ProfileContainer = () => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setUserProfile = (userProfile) => dispatch(authenticationActions.setUserProfile(userProfile));

	const eventSetting = LocalStorageService.getEventSetting();
	const userProfile = useSelector(({ auth }) => auth.userProfile);

	const isLocalLogin = userProfile.id_type === ID_TYPE["local"];

	const [showChangePassword, setShowChangePassword] = useState(false);
	const [accountSettings, setAccountSettings] = useState({
		first_name: "",
		last_name: "",
		email: "",
		dob_day: "",
		dob_month: "",
		dob_year: "",
		current_password: "",
		new_password: "",
		confirm_password: "",
	});

	const [days, setDays] = useState(getAllDaysOfMonth());
	const [months] = useState(getAllMonths());
	const [years] = useState(getAllYears());

	const [regions, setRegions] = useState([]);

	const pictureRef = useRef();

	const [profileInput, setProfileInput] = useState({
		picture: "",
		about_yourself: "",
		gender: "",
		country: "",
		city: "",
		interest: "",
		job_title: "",
		company_name: "",
		industry: "",
	});

	const [profilePicture, setProfilePicture] = useState();
	const [interests, setInterests] = useState([]);

	const [getLatestProfile, setGetLatestProfile] = useState(false);

	// Get user profile
	useEffect(() => {
		ProfileAPI.getUserProfile()
			.then((response) => {
				// Prefill account setttings
				const { email, first_name, last_name, dob } = response.user;
				setAccountSettings({
					...accountSettings,
					first_name: first_name,
					last_name: last_name,
					email: email,
					dob_day: dob ? moment(dob).format("D") : "",
					dob_month: dob ? moment(dob).format("MMMM") : "",
					dob_year: dob ? moment(dob).format("YYYY") : "",
				});

				// Prefill profile
				const { about, profile_image_url, gender, country, city, interest, job_title, company_name, industry } =
					response.user;
				setProfileInput({
					...profileInput,
					picture: profile_image_url,
					about_yourself: about,
					gender: gender,
					country: country,
					city: city,
					job_title: job_title,
					company_name: company_name,
					industry: industry,
				});

				// parse interest json if is json string
				let interestArray = [];
				try {
					interestArray = JSON.parse(interest);
				} catch (e) {
					// If is not json string, split the string by comma
					interestArray = interest ? interest.split(",") : [];
				}

				setInterests(interestArray);

				// Get the country's regions
				const countryRegions = COUNTRIES.filter((countries) => {
					return countries.countryShortCode === country;
				});
				setRegions(countryRegions[0].regions);
			})
			.catch((error) => {
				console.error("Profile error: ", error);
			});
	}, []);

	useEffect(() => {
		if (!getLatestProfile) return;

		ProfileAPI.getUserProfile()
			.then((response) => {
				// Reset to false
				setGetLatestProfile(false);

				const { profile_image_url, first_name, last_name } = response.user;

				// Update local storage profile data
				let userProfileCopy = { ...userProfile };

				userProfileCopy = {
					...userProfileCopy,
					profile_image_url: profile_image_url,
					first_name: first_name,
					last_name: last_name,
				};

				setUserProfile(userProfileCopy);
				LocalStorageService.setUserProfile(JSON.stringify(userProfileCopy));
				window.dispatchEvent(new Event("storage"));
			})
			.catch((error) => {
				console.error("Profile error: ", error);
			});
	}, [getLatestProfile]);

	/**
	 * Account Settings Section
	 */
	const handleChangeMonth = (event) => {
		setDays(getAllDaysOfMonth(event.target.value));
		handleAccountSettingsInputChange(event);
	};

	const handleAccountSettingsInputChange = (event) => {
		setAccountSettings({ ...accountSettings, [event.target.name]: event.target.value });
	};

	const handleAccountSettingsSaveChanges = () => {
		let formData = new FormData();
		formData.append("first_name", accountSettings.first_name);
		formData.append("last_name", accountSettings.last_name);

		if (accountSettings.dob_day && accountSettings.dob_month && accountSettings.dob_year) {
			let dobFull = moment(
				`${accountSettings.dob_year}-${accountSettings.dob_month}-${accountSettings.dob_day}`,
				"YYYY-MMMM-D"
			);
			formData.append("dob", moment(dobFull).format("YYYY-MM-DD"));
		}

		// If user want to change password, add to formData
		if (showChangePassword) {
			if (accountSettings.new_password !== accountSettings.confirm_password) {
				openAlertSnackbar("New password and Confirm Password not match.", "error");
				return;
			}

			formData.append("password", accountSettings.current_password);
			formData.append("new_password", accountSettings.new_password);
		}

		postUpdateUserProfile(formData);
	};

	/**
	 * Profile Section
	 */
	const handleprofileInputChange = (event) => {
		setProfileInput({
			...profileInput,
			[event.target.name]: event.target.value,
		});

		if (event.target.name === "country") {
			const selectedCountryRegions = COUNTRIES.filter((countries) => {
				return countries.countryShortCode === event.target.value;
			});

			setRegions(selectedCountryRegions[0].regions);
		}
	};

	const handleChangePicture = (event) => {
		setProfileInput({
			...profileInput,
			[event.target.name]: event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : "",
		});

		setProfilePicture(event.target.files[0]);
	};

	const browsePicture = () => {
		pictureRef.current.click();
	};

	const handleAddInterestOnEnter = (event) => {
		if (profileInput.interest.trim() === "") {
			return;
		}

		if (event.key === "Enter") {
			event.preventDefault();
			setInterests([...interests, profileInput.interest]);
			setProfileInput({
				...profileInput,
				interest: "",
			});
		}
	};

	const handleAddInterestOnBlur = () => {
		if (profileInput.interest.trim() === "") {
			return;
		}

		setInterests([...interests, profileInput.interest]);
		setProfileInput({
			...profileInput,
			interest: "",
		});
	};

	const removeInterest = (index) => {
		let interestsCopy = [...interests];
		interestsCopy.splice(index, 1);
		setInterests(interestsCopy);
	};

	const handleProfileSaveChanges = () => {
		let formData = new FormData();

		if (profilePicture) {
			formData.append("profile_image", profilePicture);
		}

		formData.append("about", profileInput.about_yourself);
		formData.append("gender", profileInput.gender);
		formData.append("country", profileInput.country);
		formData.append("city", profileInput.city);
		formData.append("job_title", profileInput.job_title);
		formData.append("company_name", profileInput.company_name);
		formData.append("industry", profileInput.industry);
		formData.append("interest", interests.join(","));

		postUpdateUserProfile(formData);
	};

	const postUpdateUserProfile = (formData) => {
		ProfileAPI.postUpdateUserProfile(formData)
			.then((response) => {
				// Update local storage userProfile is required
				if (formData.has("first_name") || formData.has("last_name") || formData.has("profile_image")) {
					setGetLatestProfile(true);
				}

				openAlertSnackbar("Updated successfully!", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<div className="profile-container">
			{eventSetting.payment_settings.includes(PAYMENT_SETTINGS["credit"]) && (
				<section className="mb-5">
					<h1>Credit</h1>
					<div className="section-container credit-container">
						<div>
							<span className="text-label mr-5">My Credit balance</span>
							<span className="coin-text">
								<img src={IconCoin} alt="credit" className="icon-coin" /> 790
							</span>
						</div>
						<button className="btn-topup">Top up</button>
					</div>
				</section>
			)}

			<section className="mb-5">
				<h1>Account settings</h1>
				<div className="section-container account-setting-container mb-3">
					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
						<label className="col-span-12 lg:col-span-2 text-label">First Name</label>
						<input
							className="col-span-12 lg:col-span-5 profile-input"
							type="text"
							name="first_name"
							required
							onChange={handleAccountSettingsInputChange}
							value={accountSettings.first_name}
						/>
					</div>
					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
						<label className="col-span-12 lg:col-span-2 text-label">Last Name</label>
						<input
							className="col-span-12 lg:col-span-5 profile-input"
							type="text"
							name="last_name"
							required
							onChange={handleAccountSettingsInputChange}
							value={accountSettings.last_name}
						/>
					</div>
					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
						<label className="col-span-12 lg:col-span-2 text-label">Email address</label>
						<input
							className="col-span-12 lg:col-span-5 profile-input"
							type="email"
							name="email"
							disabled
							onChange={handleAccountSettingsInputChange}
							value={accountSettings.email}
						/>
						{!showChangePassword && isLocalLogin && (
							<button
								className="btn-change-password col-span-5 lg:col-span-2"
								onClick={() => setShowChangePassword(true)}>
								Change Password
							</button>
						)}
					</div>
					{showChangePassword && (
						<>
							<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
								<label className="col-span-12 lg:col-span-2 text-label">Current Password</label>
								<input
									className="col-span-12 lg:col-span-5 profile-input"
									type="password"
									name="current_password"
									required
									onChange={handleAccountSettingsInputChange}
									value={accountSettings.current_password}
								/>
							</div>
							<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
								<label className="col-span-12 lg:col-span-2 text-label">New Password</label>
								<input
									className="col-span-12 lg:col-span-5 profile-input"
									type="password"
									name="new_password"
									required
									onChange={handleAccountSettingsInputChange}
									value={accountSettings.new_password}
								/>
							</div>
							<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
								<label className="col-span-12 lg:col-span-2 text-label">Confirm New Password</label>
								<input
									className="col-span-12 lg:col-span-5 profile-input"
									type="password"
									name="confirm_password"
									required
									onChange={handleAccountSettingsInputChange}
									value={accountSettings.confirm_password}
								/>
							</div>
						</>
					)}

					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center">
						<label className="col-span-12 lg:col-span-2 text-label">Date of birth</label>
						<div className="col-span-12 lg:col-span-5 grid grid-cols-12 gap-3">
							<div className="col-span-4 select-wrapper">
								<select
									name="dob_day"
									className="profile-select"
									required
									value={accountSettings.dob_day}
									onChange={handleAccountSettingsInputChange}>
									<option value="" disabled>
										27
									</option>
									{days.map((day) => {
										return (
											<option key={day} value={day}>
												{day}
											</option>
										);
									})}
								</select>
							</div>
							<div className="col-span-4 select-wrapper">
								<select
									name="dob_month"
									className="profile-select"
									required
									value={accountSettings.dob_month}
									onChange={handleChangeMonth}>
									<option value="" disabled>
										March
									</option>
									{months.map((month) => {
										return (
											<option key={month} value={month}>
												{month}
											</option>
										);
									})}
								</select>
							</div>
							<div className="col-span-4 select-wrapper">
								<select
									name="dob_year"
									className="profile-select"
									required
									value={accountSettings.dob_year}
									onChange={handleAccountSettingsInputChange}>
									<option value="" disabled>
										1985
									</option>
									{years.map((year) => {
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="flex justify-end items-center">
					<button className="btn-save-changes" onClick={handleAccountSettingsSaveChanges}>
						Save Changes
					</button>
				</div>
			</section>

			<section className="mb-5">
				<h1>Profile</h1>
				<div className="section-container profile-container mb-3">
					<div className="grid grid-cols-12 gap-1 lg:gap-3 input-row">
						<label className="col-span-12 lg:col-span-2 text-label" htmlFor="picture">
							Profile picture
						</label>
						<div className="profile-picture" onClick={browsePicture}>
							{profileInput.picture ? (
								<>
									<img src={profileInput.picture} alt="Profile" className="picture" />
									<img src={IconEdit} alt="Edit profile" className="icon-edit-profile" />
								</>
							) : (
								<img src={IconAdd} alt="add profile" className="icon-add" />
							)}
						</div>
						<input
							type="file"
							name="picture"
							onChange={handleChangePicture}
							ref={pictureRef}
							className="hidden"
							accept="image/*"
						/>
					</div>
					<div className="grid grid-cols-12 gap-1 lg:gap-3 input-row">
						<label className="col-span-12 lg:col-span-2 text-label" htmlFor="about_yourself">
							Little about yourself
						</label>
						<textarea
							id="about_yourself"
							name="about_yourself"
							className="col-span-12 lg:col-span-6 profile-input"
							rows="5"
							onChange={handleprofileInputChange}
							required
							value={profileInput.about_yourself}></textarea>
					</div>
					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
						<label className="col-span-12 lg:col-span-2 text-label" htmlFor="gender">
							Gender
						</label>
						<div className="col-span-12 lg:col-span-5 select-wrapper">
							<select
								id="gender"
								name="gender"
								className="profile-input"
								required
								value={profileInput.gender}
								onChange={handleprofileInputChange}>
								<option value="" disabled>
									male, female, others
								</option>
								<option value="1">Male</option>
								<option value="2">Female</option>
								<option value="0">Others</option>
							</select>
						</div>
					</div>
					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
						<label className="col-span-12 lg:col-span-2 text-label" htmlFor="country">
							Country
						</label>
						<div className="col-span-12 lg:col-span-5 select-wrapper">
							<select
								id="country"
								name="country"
								className="profile-input"
								required
								value={profileInput.country}
								onChange={handleprofileInputChange}>
								<option value="" disabled></option>
								{COUNTRIES.map((item) => (
									<option key={item.countryShortCode} value={item.countryShortCode}>
										{item.countryName}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
						<label className="col-span-12 lg:col-span-2 text-label" htmlFor="city">
							City
						</label>
						<div className="col-span-12 lg:col-span-5 select-wrapper">
							<select
								id="city"
								name="city"
								className="profile-input"
								required
								value={profileInput.city}
								onChange={handleprofileInputChange}>
								<option value="" disabled></option>
								{regions.map((item) => (
									<option key={item.name} value={item.name}>
										{item.name}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
						<label className="col-span-12 lg:col-span-2 text-label">Interest</label>
						<input
							className="col-span-12 lg:col-span-5 profile-input"
							type="text"
							name="interest"
							required
							onChange={handleprofileInputChange}
							onKeyPress={handleAddInterestOnEnter}
							onBlur={handleAddInterestOnBlur}
							value={profileInput.interest}
							placeholder="Interest e.g online shopping"
						/>
					</div>

					{interests.length > 0 && (
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
							<div className="hidden lg:block lg:col-span-2"></div>
							<div className="col-span-12 lg:col-span-5 interest-container">
								{interests.map((interest, index) => {
									return (
										<span key={index} className="interest-pill">
											{interest}
											<img
												src={IconClose}
												alt="remove"
												className="btn-remove-interest"
												onClick={() => removeInterest(index)}
											/>
										</span>
									);
								})}
							</div>
						</div>
					)}

					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
						<label className="col-span-12 lg:col-span-2 text-label">Job title</label>
						<input
							className="col-span-12 lg:col-span-5 profile-input"
							type="text"
							name="job_title"
							required
							onChange={handleprofileInputChange}
							value={profileInput.job_title}
							placeholder="marketing executive"
						/>
					</div>
					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
						<label className="col-span-12 lg:col-span-2 text-label">Company name</label>
						<input
							className="col-span-12 lg:col-span-5 profile-input"
							type="text"
							name="company_name"
							required
							onChange={handleprofileInputChange}
							value={profileInput.company_name}
							placeholder="Google"
						/>
					</div>
					<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center input-row">
						<label className="col-span-12 lg:col-span-2 text-label">Industry</label>
						<input
							className="col-span-12 lg:col-span-5 profile-input"
							type="text"
							name="industry"
							required
							onChange={handleprofileInputChange}
							value={profileInput.industry}
							placeholder="Tech"
						/>
					</div>
				</div>
				<div className="flex justify-end items-center">
					<button className="btn-save-changes" onClick={handleProfileSaveChanges}>
						Save Changes
					</button>
				</div>
			</section>
		</div>
	);
};

export default withRouter(ProfileContainer);

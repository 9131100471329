import React, { useRef } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import IconAdd from "app/assets/Authentication/icon-add.svg";
import IconRemove from "app/assets/Agent/icon_remove_media.png";

const MediaItem = ({ id, thumbnail, onUpload, onRemove }) => {
	const mediaRef = useRef();

	const browseMedia = () => {
		mediaRef.current.click();
	};

	return (
		<div className="media-item">
			{thumbnail ? (
				<div className="media-item-thumbnail">
					<img src={thumbnail} alt="media thumbnail" className="media-thumbnail" />
					<img src={IconRemove} alt="remove" className="icon-remove" onClick={onRemove} />
				</div>
			) : (
				<div className="media-item-upload" onClick={browseMedia}>
					<img src={IconAdd} alt="Add Logo" className="icon-add" />
					<input
						ref={mediaRef}
						type="file"
						className="hidden"
						onChange={onUpload}
						accept="image/*"
						value=""
					/>
				</div>
			)}
		</div>
	);
};

export default MediaItem;

MediaItem.propTypes = {
	thumbnail: PropTypes.string,
	onUpload: PropTypes.func,
	onRemove: PropTypes.func,
};

import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import COUNTRIES from "app/config/country_region.json";
import ProfileAPI from "app/apis/profile";
import { profileActions } from "app/states/profile";
import LocalStorageService from "app/services/localStorageService";
import { useMediaQuery } from "react-responsive";
import { addressActions } from "app/states/address";
import IconDeleteRed from "app/assets/icon_delete_red.png";

const defaultInputData = {
	address_name: "",
	recipient_name: "",
	contact_number: "",
	address: "",
	postcode: "",
	country: "",
	state: "",
	default: false,
};

const EditAddressModal = ({ history }) => {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const userProfile = LocalStorageService.getUserProfile();

	const hiddenSubmitButtonRef = useRef();

	const dispatch = useDispatch();
	const closeEditAddressModal = () => dispatch(addressActions.closeEditAddressModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const refreshAddressList = () => dispatch(profileActions.refreshAddressList());
	const openConfirmDeleteAddressModal = () => dispatch(addressActions.openConfirmDeleteAddressModal());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());

	const showModal = useSelector(({ address }) => address.showEditAddressModal);
	const viewingAddress = useSelector(({ address }) => address.viewingAddress);

	const [regions, setRegions] = useState([]);
	const [inputData, setInputData] = useState(defaultInputData);

	useEffect(() => {
		if (viewingAddress) {
			setInputData({
				address_name: viewingAddress.label,
				recipient_name: viewingAddress.name,
				contact_number: viewingAddress.phone_no,
				address: viewingAddress.address,
				postcode: viewingAddress.postcode,
				country: viewingAddress.country,
				state: viewingAddress.state,
				default: viewingAddress.default,
			});

			const selectedCountryRegions = COUNTRIES.find((country) => {
				return (
					country.countryShortCode === viewingAddress.country ||
					country.countryName === viewingAddress.country
				);
			});

			if (selectedCountryRegions) {
				setRegions(selectedCountryRegions.regions);
			}
		}
	}, [viewingAddress]);

	const handleInputChange = (event) => {
		setInputData({
			...inputData,
			[event.target.name]: event.target.value,
		});

		if (event.target.name === "country") {
			const selectedCountryRegions = COUNTRIES.filter((countries) => {
				return countries.countryShortCode === event.target.value;
			});

			setRegions(selectedCountryRegions[0].regions);
		}
	};

	const handleClose = () => {
		closeEditAddressModal();
	};

	const handleSubmitUpdate = (event) => {
		event.preventDefault();

		let data = {
			id: viewingAddress.id,
			label: inputData.address_name,
			name: inputData.recipient_name,
			email: userProfile.email,
			phone_no: inputData.contact_number,
			address: inputData.address,
			postcode: inputData.postcode,
			state: inputData.state,
			country: inputData.country,
			default: inputData.default,
		};

		showLoading();
		ProfileAPI.postUpdateAddress(data)
			.then((response) => {
				// Reset state
				setInputData(defaultInputData);

				// Back to address management modal
				refreshAddressList();
				handleClose();

				openAlertSnackbar("Updated successfully", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleCancel = () => {
		// Back to address management modal
		handleClose();
	};

	const handleDelete = () => {
		openConfirmDeleteAddressModal();
	};

	if (!viewingAddress) {
		handleClose();
	}

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="edit-address-modal">
			<div id="edit-address-modal" className="app-modal mobile-app-modal">
				<section className="modal-header flex justify-between">
					<h1 className="modal-title">Edit Address</h1>
				</section>

				<section className="modal-body">
					<form id="form-add-new-address" method="POST" onSubmit={handleSubmitUpdate}>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label className="col-span-12 lg:col-span-2 text-label">Name</label>
							<input
								className="col-span-12 lg:col-span-4 input-box"
								type="text"
								name="address_name"
								required
								onChange={handleInputChange}
								value={inputData.address_name}
							/>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label className="col-span-12 lg:col-span-2 text-label">Recipient Name</label>
							<input
								className="col-span-12 lg:col-span-4 input-box"
								type="text"
								name="recipient_name"
								required
								onChange={handleInputChange}
								value={inputData.recipient_name}
							/>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label className="col-span-12 lg:col-span-2 text-label">Contact Number</label>
							<input
								className="col-span-12 lg:col-span-4 input-box"
								type="text"
								name="contact_number"
								required
								onChange={handleInputChange}
								value={inputData.contact_number}
							/>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label className="col-span-12 lg:col-span-2 text-label">Address</label>
							<input
								className="col-span-12 lg:col-span-4 input-box"
								type="text"
								name="address"
								required
								onChange={handleInputChange}
								value={inputData.address}
							/>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label className="col-span-12 lg:col-span-2 text-label">Postcode</label>
							<input
								className="col-span-12 lg:col-span-4 input-box input-number-box"
								type="number"
								name="postcode"
								required
								onChange={handleInputChange}
								value={inputData.postcode}
							/>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label htmlFor="country" className="col-span-12 lg:col-span-2 text-label">
								Country
							</label>
							<div className="select-wrapper col-span-12 lg:col-span-4 ">
								<select
									id="country"
									name="country"
									className="input-box"
									required
									value={inputData.country}
									onChange={handleInputChange}>
									<option value="" disabled></option>
									{COUNTRIES.map((item) => (
										<option key={item.countryShortCode} value={item.countryShortCode}>
											{item.countryName}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label htmlFor="state" className="col-span-12 lg:col-span-2 text-label">
								State
							</label>
							<div className="select-wrapper col-span-12 lg:col-span-4 ">
								<select
									id="state"
									name="state"
									className="input-box"
									required
									value={inputData.state}
									onChange={handleInputChange}>
									<option value="" disabled></option>
									{regions.map((item) => (
										<option key={item.name} value={item.name}>
											{item.name}
										</option>
									))}
								</select>
							</div>
						</div>
						<button type="submit" className="hidden" ref={hiddenSubmitButtonRef}>
							Update
						</button>
					</form>
				</section>

				<section className="modal-footer">
					<div className="flex justify-between items-center w-full">
						<div>
							{isTabletOrMobile ? (
								<button type="button" onClick={handleDelete} className="m__btn-delete">
									<img src={IconDeleteRed} alt="delete" className="icon-delete" />
								</button>
							) : (
								<button type="button" className="btn-delete" onClick={handleDelete}>
									<img src={IconDeleteRed} alt="delete" className="icon-delete" /> Delete Address
								</button>
							)}
						</div>
						<div className="flex items-center">
							<button type="button" className="btn-secondary-action" onClick={handleCancel}>
								Cancel
							</button>
							<button
								type="button"
								className="btn-cta"
								onClick={() => hiddenSubmitButtonRef.current.click()}>
								Update
							</button>
						</div>
					</div>
				</section>
			</div>
		</Modal>
	);
};

export default withRouter(EditAddressModal);

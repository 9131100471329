/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconStreaming from "app/assets/Agent/streaming_dark.png";
import DateInput from "app/views/components/DateInput/DateInput";
import IconAdd from "app/assets/Authentication/icon-add.svg";
import { useSelector, useDispatch } from "react-redux";
import AgentAPI from "app/apis/agent";
import { VIDEO_TYPES } from "app/config/settings";
import moment from "moment";
import { agentActions } from "app/states/agent";
import { commonActions } from "app/states/common";

const StreamingDetailsContainer = () => {
	const dispatch = useDispatch();
	const openLiveToggleModal = () => dispatch(agentActions.openLiveToggleModal());
	const setLiveSessionId = (liveSessionId) => dispatch(agentActions.setLiveSessionId(liveSessionId));
	const refreshExhibitorRoomDetails = (refresh) => dispatch(agentActions.refreshExhibitorRoomDetails(refresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const roomDetails = useSelector(({ agent }) => agent.roomDetails);
	const liveSessionId = useSelector(({ agent }) => agent.liveSessionId);

	const [inputData, setInputData] = useState({
		streaming_url: "",
		streaming_platform: VIDEO_TYPES["youtube"]["value"],
		date: "",
		start_time: "",
		end_time: "",
	});

	const coverImageRef = useRef();
	const [coverImage, setCoverImage] = useState();
	const [coverImageFile, setCoverImageFile] = useState();

	const [isLive, setIsLive] = useState(false);

	useEffect(() => {
		if (!roomDetails) return;

		const { live_sessions } = roomDetails;

		if (live_sessions.length > 0) {
			const liveSessionDetails = live_sessions[0];

			const { type, video_url, thumbnail_url, start_at, end_at, is_live } = liveSessionDetails;

			// Check if live session is expired
			if (liveSessionDetails.id) {
				setLiveSessionId(liveSessionDetails.id);
			}

			setInputData({
				streaming_url: video_url,
				date: new Date(start_at),
				start_time: new Date(start_at),
				end_time: new Date(end_at),
				streaming_platform: type,
			});

			setCoverImage(thumbnail_url);
			setIsLive(is_live);
		} else {
			setInputData({
				streaming_url: "",
				streaming_platform: VIDEO_TYPES["youtube"]["value"],
				date: "",
				start_time: "",
				end_time: "",
			});
			setCoverImage(null);
			setCoverImageFile(null);
			setIsLive(false);
		}
	}, [roomDetails]);

	const handleChangeImage = (event) => {
		setCoverImage(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : "");
		setCoverImageFile(event.target.files[0] || null);
	};

	const browseImage = () => {
		coverImageRef.current.click();
	};

	const handleInputDataChange = (event) => {
		setInputData({
			...inputData,
			[event.target.name]: event.target.value,
		});
	};

	const submitUpdateStreamingDetails = () => {
		let formData = new FormData();

		if (coverImageFile) {
			formData.append("cover_image", coverImageFile);
		}

		let selectedDate = moment(inputData.date).format("YYYY-MM-DD");
		let formattedStartTime = moment(inputData.start_time).format("HH:mm");
		let formattedEndTime = moment(inputData.end_time).format("HH:mm");

		let startTime = moment(selectedDate + " " + formattedStartTime, "YYYY-MM-DD HH:mm").valueOf();
		let endTime = moment(selectedDate + " " + formattedEndTime, "YYYY-MM-DD HH:mm").valueOf();

		formData.append("video_url", inputData.streaming_url);
		formData.append("type", parseInt(inputData.streaming_platform));
		formData.append("start_at", startTime);
		formData.append("end_at", endTime);

		AgentAPI.postUpdateLivestreamDetail(formData)
			.then((response) => {
				const { live_session_id } = response;
				setLiveSessionId(live_session_id);
				refreshExhibitorRoomDetails(true);
				openAlertSnackbar("Updated Streaming Details", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleGoLive = () => {
		if (liveSessionId) {
			openLiveToggleModal();
		} else {
			openAlertSnackbar("Please fill in Streaming Details and Save Changes", "error");
			return;
		}
	};

	const handleResetStreamingDetails = () => {
		if (!liveSessionId) {
			openAlertSnackbar("Error! No streaming details", "error");
			return;
		}

		const data = {
			live_session_id: parseInt(liveSessionId),
		};

		AgentAPI.deleteLiveStreamDetail(data)
			.then((response) => {
				setIsLive(false);
				setLiveSessionId(null);
				refreshExhibitorRoomDetails(true);

				openAlertSnackbar("Streaming Details Reset", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	return (
		<div id="streaming-details" className="streaming-details-container mb-3">
			<div className="grid grid-cols-12 gap-3">
				<div className="col-span-2">
					<h3>
						<img src={IconStreaming} alt="Streaming Details" /> Streaming Details
					</h3>
				</div>
				<div className="col-span-8">
					<form className="form-container mb-3">
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Streaming URL</label>
							</div>
							<div className="col-span-7">
								<input
									type="text"
									name="streaming_url"
									value={inputData.streaming_url}
									onChange={handleInputDataChange}
								/>
								<div className="remark">
									Go LIVE by placing your live streaming URL here. Supported platforms: YouTube,
									Facebook.
								</div>
							</div>
							{/* TODO: reopen in CD3 */}
							{/* <div className="col-span-2">
								<button type="button" className="btn-test-preview">
									Test Preview
								</button>
							</div> */}
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Streaming Platform</label>
							</div>
							<div className="col-span-2">
								<div className="select-wrapper">
									<select
										name="streaming_platform"
										value={inputData.streaming_platform}
										className="platform-select"
										onChange={handleInputDataChange}>
										{Object.keys(VIDEO_TYPES)
											.map((key) => {
												const { value, display } = VIDEO_TYPES[key];

												return (
													<option key={key} value={value}>
														{display}
													</option>
												);
											})}
									</select>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Streaming Schedule</label>
							</div>
							<div className="col-span-7">
								<div className="flex items-center">
									<span className="mr-2 datetime-label">Date</span>
									<div className="datetime-select mr-3">
										<DateInput
											dateFormat="EEE, dd MMM"
											selectedDate={inputData.date}
											onChange={(date) => setInputData({ ...inputData, date: date })}
										/>
									</div>
									<span className="mr-2 datetime-label">Start time</span>
									<div className="datetime-select mr-3">
										<DateInput
											dateFormat="hh:mma"
											selectedDate={inputData.start_time}
											showTimeSelectOnly
											showTimeInput
											onChange={(date) => setInputData({ ...inputData, start_time: date })}
										/>
									</div>
									<span className="mr-2 datetime-label">End time</span>
									<div className="datetime-select mr-3">
										<DateInput
											dateFormat="hh:mma"
											selectedDate={inputData.end_time}
											showTimeSelectOnly
											showTimeInput
											onChange={(date) => setInputData({ ...inputData, end_time: date })}
										/>
									</div>
								</div>
								<div className="remark">
									Your schedule represents when you will go live to give viewers an idea of when the
									next live will be.{" "}
								</div>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3 mb-3">
							<div className="col-span-2">
								<label>Cover Image</label>
							</div>
							<div className="col-span-2">
								<div className="cover-image" onClick={browseImage}>
									{coverImage ? (
										<img src={coverImage} alt="Cover" className="cover" />
									) : (
										<img src={IconAdd} alt="Add Cover" className="icon-add" />
									)}
								</div>
								<input
									type="file"
									name="image"
									onChange={handleChangeImage}
									ref={coverImageRef}
									className="hidden"
									accept="image/*"
									value=""
								/>
							</div>
						</div>
					</form>

					<div className="flex items-center justify-end">
						<button type="button" className="btn-reset ml-5" onClick={handleResetStreamingDetails}>
							Reset
						</button>

						<button type="button" className="btn-save ml-5" onClick={submitUpdateStreamingDetails}>
							Save Changes
						</button>

						{isLive ? (
							<button type="button" className="btn-end-live ml-5" onClick={handleGoLive}>
								End Live
							</button>
						) : (
							<button type="button" className="btn-go-live ml-5" onClick={handleGoLive}>
								Go Live!
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(StreamingDetailsContainer);

export const PAYMENT_SETTINGS = {
	ecommerce: 1,
	payment_gateway: 2,
	credit: 3,
	place_booking: 4,
};

export const VIDEO_TYPES = {
	local_upload: {
		value: 1,
		display: "Others",
	},
	youtube: {
		value: 2,
		display: "YouTube",
	},
	facebook: {
		value: 3,
		display: "Facebook",
	},
	// "zoom": {
	//     "value": 4,
	//     "display": "Zoom"
	// }
};

export const MEDIA_TYPES = {
	image: 1,
	video: 2,
};

export const ACTIVITY_CODE = {
	view_ads: 100,
	click_ads: 101,
	watch_channel_live: 201,
	watch_channel_recorded: 202,
	watch_exhibitor_live: 301,
	visit_landing_page: 10,
};

export const GENDER = {
	1: "Male",
	2: "Female",
	0: "Others",
};

export const CHAT_ACTIVITIES = {
	join_chat: 1,
	leave_chat: 2,
	invitation: 3,
};

export const MAX_JOIN_CHAT_ATTEMPTS = 3;
export const MAX_RETRIVE_CHAT_HISTORY_ATTEMPTS = 3;
export const CHAT_ATTEMPT_INTERVAL = 3000;

export const ID_TYPE = {
	local: 1,
	google: 2,
	facebook: 3,
};

export const PRODUCT_VARIANT_COLORS = {
	size: "#34A853",
	color: "#C53929",
	option: "#4285F4",
};

export const ADD_CALENDAR_OPTIONS = [
	{ key: "apple", name: "Apple Calendar" },
	{ key: "google", name: "Google Calendar" },
	{ key: "outlook", name: "Outlook Calendar" },
	{ key: "yahoo", name: "Yahoo Calendar" },
];

export const CURRENCY_SIGN = {
	USD: "$",
	MYR: "RM",
};

import LocalStorageService from "app/services/localStorageService";
import apiAction from "../services/apiAction"

const AdvertisementAPI = {

    /**
     * Get list of advertisements
     * @param {object} queryParams 
     * {type}
     */
    getAdvertisements: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const eventId = LocalStorageService.getEventIdByEventCode();
            const url = `/events/${eventId}/ads`;

            apiAction.get(url, queryParams)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },
};

export default AdvertisementAPI;

import apiAction from "../services/apiAction";
import LocalStorageService from "../services/localStorageService";

const EventAPI = {
	getEventSetting: (event_code = "ebizstart", requestHeaders = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/events/setting/${event_code}`;

			apiAction
				.get(url, {}, requestHeaders)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	postLogActivity: (code, target_id = null) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const url = `/events/${eventId}/activities`;

			let data = {
				code
			};

			if (target_id) {
				data['target_id'] = target_id;
			}

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	postJoinEvent: (data) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/users/events`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	getUserInfoByEventId: () => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const eventId = LocalStorageService.getEventIdByEventCode();
			const url = `/users/events/${eventId}`;

			apiAction
				.get(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	postPurchaseTicketAndJoinEvent: (data) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/users/events/tickets`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},
};

export default EventAPI;

import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Icon } from "@iconify/react";
import live24Regular from "@iconify-icons/fluent/live-24-regular";
import "./style.scss";
import IconLiveSession from "app/assets/Channels_Exhibitors/icon_live_channel.png";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { channelActions } from "app/states/channel";

const ChannelAccordion = ({ history, category, channelSessions, channelDetail, isExpand }) => {
	const { sessionId } = useParams();

	const dispatch = useDispatch();
	const setChannelSessionId = (channelSessionId) => dispatch(channelActions.setChannelSessionId(channelSessionId));
	const closeMobileChannelListModal = () => dispatch(channelActions.closeMobileChannelListModal());

	const [expand, setExpand] = useState(isExpand || false);

	useEffect(() => {
		setExpand(isExpand);
	}, [isExpand]);
	
	const handleExpand = (event, expanded) => {
		setExpand(expanded);
	};

	const viewSession = (channelSessionId) => {
		setChannelSessionId(channelSessionId);
		closeMobileChannelListModal();
	};

	return (
		<Accordion
			elevation={0}
			expanded={expand}
			onChange={handleExpand}
			className={`channel-accordion ${!expand && "no-expand"}`}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="channel-video-list"
				className="channel-accordion-header">
				{category === "live" ? (
					<div className="flex items-center">
						<img
							src={IconLiveSession}
							alt="channel thumbnail"
							className="rounded-full mr-2 header-thumbnail"
						/>
						<span className="badge-live">
							LIVE <Icon icon={live24Regular} className="icon-live" />
						</span>
					</div>
				) : (
					<div className="flex items-center">
						<img
							src={channelDetail ? channelDetail.thumbnail_url : "https://picsum.photos/100"}
							alt="channel thumbnail"
							className="rounded-full mr-2 header-thumbnail"
						/>
						<span className="header-title font-bold">
							{channelDetail ? channelDetail.title : "Channel Title"}
						</span>
					</div>
				)}
			</AccordionSummary>
			<AccordionDetails className="flex flex-col mx-5">
				{channelSessions &&
					channelSessions.map((session, index) => {
						const { id, title, thumbnail_url, is_live, is_next, start_at, end_at } = session;

						const startDate = moment(start_at).format("ddd, Do MMMM");
						const startTime = moment(start_at).format("h:mma");
						const endTime = moment(end_at).format("h:mma");
						const sessionDateTime = `${startTime} - ${endTime}, ${startDate}`;

						const isLast = channelSessions.length - 1 === index;
						const isStarted = moment().valueOf() >= start_at;

						if (is_live) {
							return (
								<div
									key={`session-${id}`}
									className="flex items-start channel-video-list-item live-video"
									onClick={() => viewSession(id)}>
									<img
										src={thumbnail_url}
										alt="channel thumbnail"
										className="list-item-thumbnail thumbnail-live"
									/>
									<div className={`list-item-content ${!isLast && "connector"}`}>
										<div className="mb-1">
											<span className="badge-live">
												LIVE <Icon icon={live24Regular} className="icon-live" />
											</span>
										</div>
										<div className="list-item-title title-live">{title}</div>
										<span className="datetime datetime-live">{sessionDateTime}</span>
									</div>
								</div>
							);
						} else if (!isStarted) {
							return (
								<div
									key={`session-${id}`}
									className="flex items-start channel-video-list-item"
									onClick={() => viewSession(id)}>
									<img
										src={thumbnail_url}
										alt="channel thumbnail"
										className="list-item-thumbnail thumbnail-disabled"
									/>
									<div className={`list-item-content ${!isLast && "connector"}`}>
										{is_next === 1 && (
											<div className="mb-1">
												<span className="badge-next-up">NEXT UP</span>
											</div>
										)}
										<div className="list-item-title title-disabled">{title}</div>
										<span className="datetime">{sessionDateTime}</span>
									</div>
								</div>
							);
						}

						return (
							<div
								key={`session-${id}`}
								className={`flex items-start channel-video-list-item`}
								onClick={() => viewSession(id)}>
								<img src={thumbnail_url} alt="channel thumbnail" className={`list-item-thumbnail`} />
								<div className={`list-item-content ${!isLast && "connector"}`}>
									{is_next === 1 && (
										<div className="mb-1">
											<span className="badge-next-up">NEXT UP</span>
										</div>
									)}
									<div
										className={`list-item-title ${
											parseInt(sessionId) === id ? "current-viewing" : ""
										}`}>
										{title}
									</div>
									<span className={`datetime ${parseInt(sessionId) === id ? "current-viewing" : ""}`}>
										{sessionDateTime}
									</span>
								</div>
							</div>
						);
					})}
			</AccordionDetails>
		</Accordion>
	);
};

export default withRouter(ChannelAccordion);

ChannelAccordion.propTypes = {
	channelDetail: PropTypes.object,
	channelSessions: PropTypes.array,
};

import React, { useState, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import Authentication from "../../../../apis/authentication";
import "./style.scss";
import IconCompleteProfile from "app/assets/Authentication/icon-complete-profile.svg";
import IconAdd from "app/assets/Authentication/icon-add.svg";
import IconClose from "app/assets/Authentication/icon-close.svg";
import COUNTRIES from "app/config/country_region.json";
import moment from "moment";
import LocalStorageService from "app/services/localStorageService";

const CompleteProfileContainer = ({ history, location }) => {
	const { eventCode } = useParams();
	
	// Get "Malaysia" regions
	const MsiaRegions = COUNTRIES.filter((countries) => {
		return countries.countryShortCode === "MY";
	});

	const [regions, setRegions] = useState(MsiaRegions[0].regions);

	const name = location.state && location.state.name ? location.state.name : "there";

	const pictureRef = useRef();

	const [profileInput, setProfileInput] = useState({
		picture: "",
		about_yourself: "",
		gender: "",
		country: "",
		city: "",
		interest: "",
		job_title: "",
		company_name: "",
		industry: "",
	});

	const [profilePicture, setProfilePicture] = useState();
	const [interests, setInterests] = useState([]);

	const handleprofileInputChange = (event) => {
		setProfileInput({
			...profileInput,
			[event.target.name]: event.target.value,
		});

		if (event.target.name === "country") {
			const selectedCountryRegions = COUNTRIES.filter((countries) => {
				return countries.countryShortCode === event.target.value;
			});

			setRegions(selectedCountryRegions[0].regions);
		}
	};

	const handleChangePicture = (event) => {
		setProfileInput({
			...profileInput,
			[event.target.name]: event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : "",
		});

		setProfilePicture(event.target.files[0]);
	};

	const browsePicture = () => {
		pictureRef.current.click();
	};

	const handleAddInterestOnEnter = (event) => {
		if (profileInput.interest.trim() === "") {
			return;
		}

		if (event.key === "Enter") {
			event.preventDefault();
			setInterests([...interests, profileInput.interest]);
			setProfileInput({
				...profileInput,
				interest: "",
			});
		}
	};

	const handleAddInterestOnBlur = () => {
		if (profileInput.interest.trim() === "") {
			return;
		}
		
		setInterests([...interests, profileInput.interest]);
		setProfileInput({
			...profileInput,
			interest: "",
		});
	}

	const removeInterest = (index) => {
		let interestsCopy = [...interests];
		interestsCopy.splice(index, 1);
		setInterests(interestsCopy);
	};

	const handleCompleteProfile = (event) => {
		event.preventDefault();

		if (!profilePicture) {
			alert("Profile picture is required");
			return;
		}

		let formData = new FormData();
		formData.append("profile_image", profilePicture);
		formData.append("about", profileInput.about_yourself);
		formData.append("gender", profileInput.gender);
		formData.append("country", profileInput.country);
		formData.append("city", profileInput.city);
		formData.append("interest", interests);
		formData.append("job_title", profileInput.job_title);
		formData.append("company_name", profileInput.company_name);
		formData.append("industry", profileInput.industry);

		Authentication.postCompleteProfile(formData)
			.then((response) => {
				// Check if event started yet
				const eventSetting = LocalStorageService.getEventSetting();
				const { start_at } = eventSetting;

				const diffDays = moment().diff(moment(start_at), "days");
				if (diffDays >= 0) {
					history.push(`/events/${eventCode}/channel`);
				} else {
					history.push(`/events/${eventCode}/countdown`);
				}
			})
			.catch((error) => {
				const { status, data } = error;
				const { message } = data;
				alert(`${status} - ${message}`);
			});
	};

	return (
		<div id="complete-profile-container" className="flex flex-col h-full">
			<div className="flex justify-center lg:justify-start mb-3">
				<img src={IconCompleteProfile} alt="Complete Profile" className="container-icon" />
			</div>

			<label className="container-title mb-5">
				Hi <strong>{name}</strong>, let's get to know you better
			</label>

			<form id="form-complete-profile" method="post" onSubmit={handleCompleteProfile}>
				<div className="form-input">
					<label htmlFor="picture">Upload a profile picture</label>
					<div className="profile-picture" onClick={browsePicture}>
						{profileInput.picture ? (
							<img src={profileInput.picture} alt="Profile" className="picture" />
						) : (
							<img src={IconAdd} alt="add profile" className="icon-add" />
						)}
					</div>
					<input
						type="file"
						name="picture"
						onChange={handleChangePicture}
						ref={pictureRef}
						className="hidden"
						accept="image/*"
					/>
				</div>
				<div className="form-input">
					<label htmlFor="about_yourself">Little about yourself</label>
					<textarea
						id="about_yourself"
						name="about_yourself"
						rows="5"
						onChange={handleprofileInputChange}
						required
						value={profileInput.about_yourself}></textarea>
				</div>
				<div className="form-input">
					<label htmlFor="gender">Gender</label>
					<select
						id="gender"
						name="gender"
						className="col-span-4"
						required
						value={profileInput.gender}
						onChange={handleprofileInputChange}>
						<option value="" disabled>
							male, female, others
						</option>
						<option value="1">Male</option>
						<option value="2">Female</option>
						<option value="0">Others</option>
					</select>
				</div>
				<div className="form-input">
					<label htmlFor="country">Country</label>
					<select
						id="country"
						name="country"
						className="col-span-4"
						required
						value={profileInput.country}
						onChange={handleprofileInputChange}>
						<option value="" disabled>
							
						</option>
						{COUNTRIES.map((item) => (
							<option key={item.countryShortCode} value={item.countryShortCode}>
								{item.countryName}
							</option>
						))}
					</select>
				</div>
				<div className="form-input">
					<label htmlFor="city">City</label>
					<select
						id="city"
						name="city"
						className="col-span-4"
						required
						value={profileInput.city}
						onChange={handleprofileInputChange}>
						<option value="" disabled>
							
						</option>
						{regions.map((item) => (
							<option key={item.name} value={item.name}>
								{item.name}
							</option>
						))}
					</select>
				</div>

				<div className="form-input">
					<label htmlFor="interest">Interest</label>
					<input
						id="interest"
						type="text"
						name="interest"
						placeholder="Interest e.g online shopping "
						value={profileInput.interest}
						onChange={handleprofileInputChange}
						onKeyPress={handleAddInterestOnEnter}
						onBlur={handleAddInterestOnBlur}
					/>

					{interests.length > 0 && (
						<div className="interest-container mt-5">
							{interests.map((interest, index) => {
								return (
									<span key={index} className="interest-pill">
										{interest}
										<img
											src={IconClose}
											alt="remove"
											className="btn-remove-interest"
											onClick={() => removeInterest(index)}
										/>
									</span>
								);
							})}
						</div>
					)}
				</div>
				<div className="form-input">
					<label htmlFor="job_title">Job Title</label>
					<input
						id="job_title"
						type="text"
						name="job_title"
						required
						placeholder="e.g marketing executive"
						value={profileInput.job_title}
						onChange={handleprofileInputChange}
					/>
				</div>
				<div className="form-input">
					<label htmlFor="company_name">Company Name</label>
					<input
						id="company_name"
						type="text"
						name="company_name"
						required
						placeholder="e.g Google"
						value={profileInput.company_name}
						onChange={handleprofileInputChange}
					/>
				</div>
				<div className="form-input">
					<label htmlFor="industry">Industry</label>
					<input
						id="industry"
						type="text"
						name="industry"
						required
						placeholder="e.g Tech"
						value={profileInput.industry}
						onChange={handleprofileInputChange}
					/>
				</div>

				<div className="text-info mt-8 mb-3">**you can always update about you under your profile</div>
				<div className=" mb-3">
					<button type="submit" className="btn-primary">
						Save
					</button>
				</div>
			</form>
		</div>
	);
};

export default withRouter(CompleteProfileContainer);

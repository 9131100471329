/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import { zeroPad, calcTimeDelta } from "react-countdown";
import moment from "moment";
import LocalStorageService from "app/services/localStorageService";
import appLogoMobile from "app/assets/eBiz_logo_mobile.png";
import Logo1 from "app/assets/Countdown/1-Penang2030.png";
import Logo2 from "app/assets/Countdown/2-MACROKIOSK_White.png";
import Logo3 from "app/assets/Countdown/3-Dattel-Green-Logo.png";
import Logo4 from "app/assets/Countdown/4-huawei-logo.png";
import Logo5 from "app/assets/Countdown/5-MoneySaveNewLogo.png";
import Logo6 from "app/assets/Countdown/6-Enginemailer.png";
import LogoIboxchain from "app/assets/Countdown/icon-logo-iboxchain.png";
import LogoPotboy from "app/assets/Countdown/icon-logo-potboy.png";
import ProfileModal from "app/views/containers/Modal/ProfileModal/ProfileModal";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";

const TimerComponent = ({ label, value }) => {
	return (
		<div className="flex flex-col items-center">
			<div className="timer-label mb-2 lg:mb-0">{label}</div>
			<div className="timer-value">{value}</div>
		</div>
	);
};

const Countdown = ({ history }) => {
	const { eventCode } = useParams();
	const webConfig = LocalStorageService.getWebConfig();

	const dispatch = useDispatch();
	const openProfileModal = () => dispatch(commonActions.openProfileModal());

	const userProfile = LocalStorageService.getUserProfile();
	const userEventRole = LocalStorageService.getUserEventRole();

	const [timeDelta, setTimeDelta] = useState(null);

	useEffect(() => {
		// Get event start time from event config
		const eventSetting = LocalStorageService.getEventSetting();
		if (!eventSetting) {
			history.push(`/events/${eventCode}`);
			return;
		}

		// Set time delta
		const eventStartTime = moment(eventSetting.start_at);
		setTimeDelta(calcTimeDelta(eventStartTime));

		const timerCountdown = setInterval(() => {
			setTimeDelta(calcTimeDelta(eventStartTime));
		}, 1000);

		return () => {
			clearTimeout(timerCountdown);
		};
	}, []);

	const goToAgentContentPage = () => {
		history.push(`/events/${eventCode}/agent/contents`);
	};

	return (
		<div id="countdown-page" className="w-screen min-h-screen bg-gray-200 relative">
			<div className="flex flex-col items-center">
				<div className="logo-container">
					<img src={webConfig.logo_url || appLogoMobile} alt="logo" width="72px" height="72px" />
				</div>
				<div className="countdown-container py-5 lg:py-10 px-3">
					<div className="text-center mb-5 lg:mb-10">
						<h1 className="title">EVENT STARTING</h1>
					</div>

					{timeDelta && (
						<div className="timer-container text-center mb-5 lg:mb-10 flex justify-center items-end px-5">
							<TimerComponent label="Days" value={zeroPad(timeDelta.days)} />

							<span className="timer-divider pb-1 px-3 lg:px-6">:</span>

							<TimerComponent label="Hours" value={zeroPad(timeDelta.hours)} />

							<span className="timer-divider pb-1 px-3 lg:px-6">:</span>

							<TimerComponent label="Minutes" value={zeroPad(timeDelta.minutes)} />

							<span className="timer-divider pb-1 px-3 lg:px-6">:</span>

							<TimerComponent label="Seconds" value={zeroPad(timeDelta.seconds)} />
						</div>
					)}

					<div className="content-container mb-8 p-3">
						<h1 className="heading mt-3 mb-5">True Globalization Cutting Down Boundaries</h1>
						<p className="content-desc mb-5">
							In the virtual world, we are no longer constrained with time and space. With our
							revolutionary platform, we are pushing beyond boundaries, beyond limits and re-creating a
							digital experience with focus on attention, fun and engaging, retention and entertaining;
							and with better control of time for both participants, exhibitors and sponsors. We aim to
							create abundance in business opportunities, brand awareness and sales for all our
							participants.
						</p>
						<div className="partnerships flex flex-wrap justify-center items-center pb-3 lg:pb-0">
							<img src={Logo1} alt="partnership" className="partnership-logo p-3" />
							<img src={Logo2} alt="partnership" className="partnership-logo p-3" />
							<img src={Logo3} alt="partnership" className="partnership-logo p-3" />
							<img src={Logo4} alt="partnership" className="partnership-logo p-3" />
							<img src={Logo5} alt="partnership" className="partnership-logo p-3" />
							<img src={LogoPotboy} alt="partnership" className="partnership-logo logo-square p-3" />
							<img src={LogoIboxchain} alt="partnership" className="partnership-logo p-3" />
							<img src={Logo6} alt="partnership" className="partnership-logo p-3" />
						</div>
					</div>
					<div className="text-center">
						<div className="flex items-center justify-center">
							<button type="button" className="btn-primary btn-view mb-3 mx-3" onClick={openProfileModal}>
								View My Profile
							</button>

							{userEventRole && userEventRole.roles && (userEventRole.roles.includes("agent") || userEventRole.roles.includes("admin")) && (
								<button
									type="button"
									className="btn-primary btn-view mb-3 mx-3"
									onClick={goToAgentContentPage}>
									Manage Exhibitor Room
								</button>
							)}
						</div>
						<p className="footer">Setup Your Profile While Waiting For The Event To Be Ready</p>
					</div>
				</div>
			</div>

			<ProfileModal />
		</div>
	);
};

export default withRouter(Countdown);

const getYoutubeVideoId = (youtubeVideoUrl) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = youtubeVideoUrl.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : youtubeVideoUrl;
}


export const convertYoutubeToEmbedLink = (youtubeLink) => {
    if (youtubeLink.includes('/embed/')) {
        return youtubeLink;
    }
    
    const videoId = getYoutubeVideoId(youtubeLink);
    
    return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
}


export const convertFacebookToEmbedLink = (facebookLink) => {

    let fbVideoUrl = facebookLink;

    if (fbVideoUrl.includes('?href=')) {
        const splittedArray = fbVideoUrl.split('?href=');
        const url = splittedArray[1];
        fbVideoUrl = decodeURIComponent(url);
    }

    if (fbVideoUrl.includes('web.facebook.com')) {
        fbVideoUrl = fbVideoUrl.replace('web.', '');
    }

    return `${fbVideoUrl}`;
}
export const SET_CART = "order/SET_CART";
export const ADD_TO_CART = "order/ADD_TO_CART";
export const REMOVE_FROM_CART = "order/REMOVE_FROM_CART";
export const CLEAR_CART = "order/CLEAR_CART";

export const OPEN_MY_ORDER_PRODUCT_DETAIL_MODAL = "order/OPEN_MY_ORDER_PRODUCT_DETAIL_MODAL";
export const CLOSE_MY_ORDER_PRODUCT_DETAIL_MODAL = "order/CLOSE_MY_ORDER_PRODUCT_DETAIL_MODAL";
export const SET_VIEWING_ORDER_ROOM_ID = "order/SET_VIEWING_ORDER_ROOM_ID";
export const SET_VIEWING_ORDER_STORE_ID = "order/SET_VIEWING_ORDER_STORE_ID";
export const SET_VIEWING_ORDER_PRODUCT_ID = "order/SET_VIEWING_ORDER_PRODUCT_ID";

export const REFRESH_MY_CART = "order/REFRESH_MY_CART";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import IconChatActive from "app/assets/Channels_Exhibitors/chat_active.png";
import IconChatInactive from "app/assets/Channels_Exhibitors/chat_inactive.png";
import IconVisitorsActive from "app/assets/Channels_Exhibitors/visitors_active.png";
import IconVisitorsInactive from "app/assets/Channels_Exhibitors/visitors_inactive.png";
import AgentChatContainer from "app/views/containers/Agent/AgentChatContainer/AgentChatContainer";
import AgentVisitorsContainer from "../AgentVisitorsContainer/AgentVisitorsContainer";
import { agentSocket } from "app/services/socketService";
import LocalStorageService from "app/services/localStorageService";
import TokenService from "app/services/tokenService";

const EngagementContainer = ({ history }) => {
	const { eventCode } = useParams();
	const userEventRole = LocalStorageService.getUserEventRole();

	const [tabIndex, setTabIndex] = useState(0);

	const handleTabChange = (newTabIndex) => {
		setTabIndex(newTabIndex);
	};

	// Connect to agent socket
	useEffect(() => {
		// Connect agent socket if it is somehow not connected
		if (agentSocket.connected === false) {
			agentSocket.auth.token = TokenService.getAccessToken();
			agentSocket.connect();
		}

		if (userEventRole.agent) {
			const roomId = userEventRole.agent.exhibitor_rooms[0].room_id;
			const agentId = userEventRole.agent.exhibitor_rooms[0].agent_id;

			if (agentSocket.connected) {
				// console.log("agent_ready", agentSocket);
				agentSocket.emit("checkin", { room_id: roomId, agent_id: agentId });
			} else {
				agentSocket.on("ready", () => {
					// console.log("agent_ready", agentSocket);
					agentSocket.emit("checkin", { room_id: roomId, agent_id: agentId });
				});
			}
		} else {
			history.replace(`/events/${eventCode}/channel`);
		}
	}, [tabIndex]);

	return (
		<div id="agent-engagement-container">
			<nav className="agent-engagement-tabs">
				<ul className="flex items-start">
					<NavMenu
						tabIndex={0}
						activeIcon={IconChatActive}
						inactiveIcon={IconChatInactive}
						title="Chat"
						active={tabIndex === 0}
						linkTo="#streaming-details"
						onClick={handleTabChange}
					/>
					<NavMenu
						tabIndex={1}
						activeIcon={IconVisitorsActive}
						inactiveIcon={IconVisitorsInactive}
						title="Visitors"
						active={tabIndex === 1}
						linkTo="#chat-settings"
						onClick={handleTabChange}
					/>
				</ul>
			</nav>

			<div className="agent-engagement-content">
				<AgentChatContainer active={tabIndex === 0} />

				{tabIndex === 1 && (
					<div>
						<AgentVisitorsContainer />
					</div>
				)}
			</div>
		</div>
	);
};

const NavMenu = ({ tabIndex, activeIcon, inactiveIcon, title, active, onClick }) => {
	return (
		<li
			className={`nav-menu ${active ? "nav-menu-active" : ""} flex items-center`}
			onClick={() => onClick(tabIndex)}>
			<img src={active ? activeIcon : inactiveIcon} alt="nav-icon" className="nav-icon" />
			<div className={`nav-title ${active ? "nav-title-active" : ""} `}>{title}</div>
		</li>
	);
};

export default withRouter(EngagementContainer);

import React, { useRef } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import IconAdd from "app/assets/Authentication/icon-add.svg";
import IconRemove from "app/assets/Agent/icon_remove_media.png";
import IconPdf from "app/assets/Agent/icon_pdf_small.png";

const ExhibitorFile = ({ id, content_url, fileName, onDelete, onUpload }) => {
	const fileRef = useRef();

	const browseFile = () => {
		fileRef.current.click();
	};

	return (
		<div className="exhibitor-file">
			{content_url ? (
				<div className="file-display mb-1 mr-3">
					<img src={IconPdf} alt="thumbnail" className="file-thumbnail" />
					<img src={IconRemove} alt="edit" className="icon-edit" onClick={onDelete} />
				</div>
			) : (
				<div className="file-display mb-1 mr-3" onClick={browseFile}>
					<img src={IconAdd} alt="Add File" className="icon-add" />
					<input type="file" name="file" onChange={onUpload} ref={fileRef} className="hidden" value="" />
				</div>
			)}
			<div className="file-name">{fileName}</div>
		</div>
	);
};

export default ExhibitorFile;

ExhibitorFile.propTypes = {
	thumbnail: PropTypes.string,
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import MenuBar from "app/views/containers/MenuBar/MenuBar";
import BrandLogoMobile from "app/views/components/BrandLogoMobile/BrandLogoMobile";
import StreamingDetailsContainer from "app/views/containers/Agent/StreamingDetailsContainer/StreamingDetailsContainer";
import ChatSettingsContainer from "app/views/containers/Agent/ChatSettingsContainer/ChatSettingsContainer";
import CompanyInfoContainer from "app/views/containers/Agent/CompanyInfoContainer/CompanyInfoContainer";
import ExhibitorDetailsContainer from "app/views/containers/Agent/ExhibitorDetailsContainer/ExhibitorDetailsContainer";
import StoreDetailsContainer from "app/views/containers/Agent/StoreDetailsContainer/StoreDetailsContainer";
import AgentStaffContainer from "app/views/containers/Agent/AgentStaffContainer/AgentStaffContainer";
import NavigationBar from "app/views/containers/Agent/Navigation/ContentNavbar/NavigationBar";
import AgentAPI from "app/apis/agent";
import { useDispatch } from "react-redux";
import { agentActions } from "app/states/agent";
import AddProductModal from "app/views/containers/Agent/AddProductModal/AddProductModal";
import LocalStorageService from "app/services/localStorageService";
import EditProductModal from "app/views/containers/Agent/EditProductModal/EditProductModal";
import AddMediaModal from "app/views/containers/Agent/AddMediaModal/AddMediaModal";

const AgentContents = ({ history }) => {
	const userEventRole = LocalStorageService.getUserEventRole();
	const eventSetting = LocalStorageService.getEventSetting();

	const dispatch = useDispatch();
	const setRoomDetails = (data) => dispatch(agentActions.setRoomDetails(data));

	useEffect(() => {
		getRoomDetails();
	}, []);

	const getRoomDetails = () => {
		AgentAPI.getRoomDetail()
			.then((response) => {
				setRoomDetails(response);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<div id="agent-contents-page" className="flex flex-col md:flex-row w-screen h-screen md:pt-1">
			<section className="hidden md:block">
				<MenuBar />
			</section>

			<div className="block md:hidden">
				<BrandLogoMobile />
			</div>

			<div className="w-full content-container">
				<NavigationBar />

				<StreamingDetailsContainer />
				<ChatSettingsContainer />
				<ExhibitorDetailsContainer />
				{eventSetting && eventSetting.ecommerce_enabled === 1 && <StoreDetailsContainer />}
				<CompanyInfoContainer />

				{userEventRole.agent.exhibitor_rooms.length > 0 &&
					userEventRole.agent.exhibitor_rooms[0].acls.includes("agent_managements") && <AgentStaffContainer />}
			</div>

			{/* Mobile View Menu Bar */}
			<div className="block md:hidden w-full">
				<MenuBar />
			</div>

			<AddProductModal />
			<EditProductModal />

			<AddMediaModal />
		</div>
	);
};

export default withRouter(AgentContents);

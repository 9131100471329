import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { agentActions } from "app/states/agent";
import { useDispatch, useSelector } from "react-redux";
import IconClose from "app/assets/icon_close.svg";
import IconEdit from "app/assets/Agent/icon_edit_blue.png";
import IconDelete from "app/assets/Agent/icon_delete_dark.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import IconRemoveWhite from "app/assets/icon-close-white.png";
import "./style.scss";
import MediaItem from "app/views/components/MediaItem/MediaItem";
import AgentAPI from "app/apis/agent";
import { commonActions } from "app/states/common";
import ReactLoading from "react-loading";
import { cartesian } from "app/helper/common";
import { PAYMENT_SETTINGS, PRODUCT_VARIANT_COLORS } from "app/config/settings";

const MAX_IMAGE_COUNT = 10;

const EditProductModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeEditProductModal = () => dispatch(agentActions.closeEditProductModal());
	const refreshStoreProducts = (isRefresh) => dispatch(agentActions.refreshStoreProducts(isRefresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showModal = useSelector(({ agent }) => agent.showEditProductModal);
	const roomDetails = useSelector(({ agent }) => agent.roomDetails);
	const viewProductId = useSelector(({ agent }) => agent.viewProductId);
	const eventSettings = useSelector(({ event }) => event.eventSettings);

	const [name, setName] = useState("");
	const [currency, setCurrency] = useState("");
	const [price, setPrice] = useState("");
	const [description, setDescription] = useState("");
	const [externalProductLink, setExternalProductLink] = useState("");
	const [pictures, setPictures] = useState([]);
	const [newImages, setNewImages] = useState([]);
	const [deletedImageIds, setDeletedImageIds] = useState([]);
	const [loading, setLoading] = useState(false);
	const [skus, setSkus] = useState([]);

	const [variants, setVariants] = useState({
		sizes: [],
		colors: [],
		options: [],
	});
	const [variantInput, setVariantInput] = useState({
		sizes: "",
		colors: "",
		options: "",
	});

	const [variantMatrix, setVariantMatrix] = useState([]);
	const [variantMatrixSnapshotCopy, setVariantMatrixSnapshotCopy] = useState([]);

	useEffect(() => {
		if (roomDetails && viewProductId) {
			const storeId = roomDetails.store_ids[0];

			AgentAPI.getExhibitorRoomStoreProductDetail(storeId, viewProductId)
				.then((response) => {
					const { name, currency, price, description, images, variants, skus, external_product_url } = response;

					setName(name);
					setCurrency(currency);
					setPrice(price);
					setDescription(description);
					setExternalProductLink(external_product_url);
					setPictures(images);
					setVariants(variants);
					setSkus(skus);

					// Set the variant matrix
					let matrix = skus.map((sku) => {
						let combinationArray = [];
						combinationArray.push(sku.size);
						combinationArray.push(sku.color);
						combinationArray.push(sku.option);

						return {
							...sku,
							combination: combinationArray,
						};
					});
					setVariantMatrix(matrix);
					setVariantMatrixSnapshotCopy(matrix);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [roomDetails, viewProductId]);

	const resetStates = () => {
		setName("");
		setCurrency("");
		setPrice("");
		setDescription("");
		setPictures([]);
		setNewImages([]);
		setDeletedImageIds([]);
		setLoading(false);
		setVariants({
			sizes: [],
			colors: [],
			options: [],
		});
		setVariantInput({
			sizes: "",
			colors: "",
			options: "",
		});
		setVariantMatrix([]);
		setVariantMatrixSnapshotCopy([]);
	};

	const handleClose = () => {
		closeEditProductModal();
	};

	const handleUpdateProduct = (event) => {
		event.preventDefault();

		setLoading(true);
		const storeId = roomDetails.store_ids[0];

		let formData = new FormData();
		formData.append("product_id", viewProductId);
		formData.append("name", name);
		formData.append("currency", currency);
		formData.append("price", price);
		formData.append("description", description);

		if (externalProductLink) {
			formData.append("external_product_url", externalProductLink);
		}

		for (let i = 0; i < newImages.length; i++) {
			formData.append("image", newImages[i]);
		}

		if (deletedImageIds.length > 0) {
			formData.append("delete_image_ids", deletedImageIds.join(","));
		}

		if (variantMatrix.length > 0) {
			let processedVariants = { ...variants };
			for (let variantKey in processedVariants) {
				if (processedVariants[variantKey].length === 0) {
					delete processedVariants[variantKey];
				}
			}

			let processedVariantMatrix = variantMatrix.map((matrix) => {
				return {
					id: matrix.id,
					size: matrix.size,
					color: matrix.color,
					option: matrix.option,
					sku_code: matrix.sku_code,
					price: matrix.price,
					quantity: matrix.quantity,
					weight: matrix.weight,
					width: matrix.width,
					height: matrix.height,
					length: matrix.length,
					fragile: matrix.fragile,
					available: matrix.available,
				};
			});

			formData.append("variants", JSON.stringify(processedVariants));
			formData.append("skus", JSON.stringify(processedVariantMatrix));
		}

		AgentAPI.putUpdateStoreProduct(storeId, formData)
			.then((response) => {
				// reset states
				resetStates();

				refreshStoreProducts(true);
				closeEditProductModal();

				openAlertSnackbar("Product updated", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDeleteProduct = () => {
		if (window.confirm("Confirm delete product?")) {
			const storeId = roomDetails.store_ids[0];

			AgentAPI.deleteStoreProduct(storeId, viewProductId)
				.then((response) => {
					refreshStoreProducts(true);
					closeEditProductModal();
				})
				.catch((error) => {
					openAlertSnackbar(error.message, "error");
				});
		}
	};

	const handleUpload = (event) => {
		setNewImages([...newImages, event.target.files[0]]);
	};

	const handleRemoveImage = (imageId) => {
		// Store the deleted image id
		setDeletedImageIds([...deletedImageIds, imageId]);

		// Remove from the pictures array
		const picturesLeft = pictures.filter((picture) => {
			return picture.id !== imageId;
		});

		setPictures(picturesLeft);
	};

	const handleRemoveNewImage = (index) => {
		let newImagesCopy = [...newImages];
		newImagesCopy.splice(index, 1);
		setNewImages(newImagesCopy);
	};

	const updateVariantMatrix = (updatedVariants) => {
		// Update variant matrix
		let variantsArray = [];
		let variantKeys = Object.keys(updatedVariants);
		variantKeys.forEach((key) => {
			variantsArray.push(updatedVariants[key]);
		});

		// Filter out empty array
		variantsArray = variantsArray.filter((variantArray) => variantArray.length > 0);

		// Create new matrix
		let matrixResult = [];
		let matrix = cartesian(variantsArray);
		matrix.forEach((matrixCombo) => {
			let size = "";
			let color = "";
			let option = "";

			matrixCombo.forEach((comboKey, index) => {
				if (updatedVariants.sizes.includes(comboKey)) {
					size = comboKey;
				} else if (updatedVariants.colors.includes(comboKey)) {
					color = comboKey;
				} else if (updatedVariants.options.includes(comboKey)) {
					option = comboKey;
				}
			});

			matrixResult.push({
				combination: matrixCombo,
				sku_code: "",
				price: "",
				quantity: "",
				weight: "",
				width: "",
				height: "",
				length: "",
				fragile: 0,
				available: 0,
				size: size,
				color: color,
				option: option,
			});
		});

		// Compare with previous snapshot to retain any existing matrix
		let newMatrixResultCopy = [...matrixResult];
		let combinedMatrix = [];
		matrixResult.forEach((newMatrix, index) => {
			const { combination: newCombination } = newMatrix;

			variantMatrixSnapshotCopy.forEach((previousMatrix) => {
				const { combination: previousCombination } = previousMatrix;

				if (newCombination.join(",") === previousCombination.join(",")) {
					combinedMatrix.push(previousMatrix);

					newMatrixResultCopy = newMatrixResultCopy.filter(
						(newMatrixResultItem) =>
							newMatrixResultItem.combination.join(",") !== previousCombination.join(",")
					);
				}
			});
		});

		let matrixWithExistingSnapshot = [...combinedMatrix, ...newMatrixResultCopy];

		setVariantMatrix(matrixWithExistingSnapshot);
		setVariantMatrixSnapshotCopy(matrixWithExistingSnapshot);
	};

	const handleRemoveVariant = (variantKey, variantValue) => {
		// Remove variant
		let updatedVariants = { ...variants };
		let index = updatedVariants[variantKey].findIndex((value) => value === variantValue);
		updatedVariants[variantKey].splice(index, 1);
		setVariants(updatedVariants);

		updateVariantMatrix(updatedVariants);
	};

	const handleVariantOnEnter = (variantKey, event) => {
		if (event.key === "Enter") {
			event.preventDefault();

			// Add variant
			let updatedVariants = { ...variants };
			updatedVariants[variantKey] = [...updatedVariants[variantKey], event.target.value];
			setVariants(updatedVariants);
			setVariantInput({
				...variantInput,
				[variantKey]: "",
			});

			updateVariantMatrix(updatedVariants);
		}
	};

	const handleVariantOnBlur = (variantKey, event) => {
		if (event.target.value.trim() === "") {
			return;
		}

		// Add variant
		let updatedVariants = { ...variants };
		updatedVariants[variantKey] = [...updatedVariants[variantKey], event.target.value];
		setVariants(updatedVariants);
		setVariantInput({
			...variantInput,
			[variantKey]: "",
		});

		// Update variant matrix
		updateVariantMatrix(updatedVariants);
	};

	const handleVariantInputOnChange = (event) => {
		setVariantInput({
			...variantInput,
			[event.target.name]: event.target.value,
		});
	};

	const handleDeleteAllVariantByKey = (variantKey) => {
		let updatedVariants = { ...variants };
		updatedVariants[variantKey] = [];
		setVariants(updatedVariants);

		updateVariantMatrix(updatedVariants);
	};

	const handleOnChangeVariantMatrixInput = (event, index) => {
		let variantMatrixCopy = [...variantMatrix];

		if (event.target.name === "fragile" || event.target.name === "available") {
			variantMatrixCopy[index][event.target.name] = !variantMatrixCopy[index][event.target.name] ? 1 : 0;
		} else {
			variantMatrixCopy[index][event.target.name] = event.target.value;
		}

		setVariantMatrix(variantMatrixCopy);
		setVariantMatrixSnapshotCopy(variantMatrixCopy);
	};

	const VariantBadge = ({ backgroundColor, value, name, onRemove }) => {
		return (
			<span className="variant-badge" style={{ backgroundColor: backgroundColor }}>
				<div>{name}</div>
				<div onClick={onRemove} className="ml-1">
					<img src={IconRemoveWhite} alt="remove" className="icon-remove" />
				</div>
			</span>
		);
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="agent-edit-product-modal">
			<div className="app-modal agent-edit-product-modal">
				<div className="modal-header flex justify-between lg:mb-4">
					<h1 className="modal-title flex items-center">
						<img src={IconEdit} alt="Add Product" className="icon-header" /> Edit Product{" "}
					</h1>
					<span className="btn-close">
						<img src={IconClose} alt="Close" onClick={handleClose} />
					</span>
				</div>
				<div className="modal-content">
					<form id="form_edit_product" className="modal-form-container mb-5" onSubmit={handleUpdateProduct}>
						<div className="grid grid-cols-12 lg:gap-5">
							<div className="col-span-12 lg:col-span-6">
								<div className="grid grid-cols-12 mb-3">
									<div className="col-span-12">
										<label>Name</label>
									</div>
									<div className="col-span-12">
										<input
											type="text"
											placeholder="your product name - make it clear and popping"
											name="name"
											value={name}
											onChange={(event) => setName(event.target.value)}
											required
										/>
									</div>
								</div>
							</div>
							<div className="col-span-6 lg:col-span-2">
								<div className="grid grid-cols-12 mb-3">
									<div className="col-span-12">
										<label>Price</label>
									</div>
									<div className="col-span-12">
										<input
											type="number"
											placeholder="100"
											name="price"
											value={price}
											onChange={(event) => setPrice(event.target.value)}
											required
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-3">
							<div className="col-span-12 lg:col-span-8">
								<div className="grid grid-cols-12 mb-3">
									<div className="col-span-12">
										<label>Description</label>
									</div>
									<div className="col-span-12">
										<textarea
											placeholder="write a nice description of your product "
											name="description"
											value={description}
											onChange={(event) => setDescription(event.target.value)}
											required
											rows={3}
										/>
									</div>
								</div>
							</div>
						</div>

						{eventSettings.payment_settings.includes(PAYMENT_SETTINGS["ecommerce"]) && (
							<div className="grid grid-cols-12 gap-3 mb-3">
								<div className="col-span-8">
									<div className="grid grid-cols-12">
										<div className="col-span-12">
											<label>External Store's Product Link</label>
										</div>
										<div className="col-span-12">
											<input
												type="url"
												placeholder="URL Link to product at your external store. e.g. Lazada"
												name="external_product_link"
												value={externalProductLink}
												onChange={(event) => setExternalProductLink(event.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="grid grid-cols-12">
							<div className="col-span-12">
								<label>Pictures</label>
							</div>
							<div className="col-span-12">
								<div className="grid grid-cols-10 gap-5">
									{pictures.map((picture, index) => {
										const { id, image_url } = picture;

										return (
											<div key={index} className="col-span-5 lg:col-span-2">
												<MediaItem
													id={id}
													thumbnail={image_url}
													onUpload={handleUpload}
													onRemove={() => handleRemoveImage(id)}
												/>
											</div>
										);
									})}

									{newImages.map((picture, index) => {
										return (
											<div key={`newImage-${index}`} className="col-span-2">
												<MediaItem
													thumbnail={URL.createObjectURL(picture)}
													onUpload={handleUpload}
													onRemove={() => handleRemoveNewImage(index)}
												/>
											</div>
										);
									})}

									{pictures.length < MAX_IMAGE_COUNT && (
										<div className="col-span-5 lg:col-span-2">
											<MediaItem onUpload={handleUpload} />
										</div>
									)}
								</div>
							</div>
						</div>

						<hr className="my-5" />

						<div className="grid grid-cols-12 mb-3 variant-container">
							<div className="col-span-10">
								<label>Variants</label>
							</div>
							<div className="col-span-10">
								<div className="grid grid-cols-12 mb-3 gap-3">
									<div className="col-span-2 variant-label">Size</div>
									<div className="col-span-7 variant-input-wrapper">
										{variants.sizes.map((size, index) => {
											return (
												<VariantBadge
													key={index}
													backgroundColor="#34A853"
													value={size}
													name={size}
													onRemove={() => handleRemoveVariant("sizes", size)}
												/>
											);
										})}

										<span className="variant-input-container">
											<input
												className="col-span-7 variant-input"
												name="sizes"
												value={variantInput.sizes}
												onChange={handleVariantInputOnChange}
												onBlur={(event) => handleVariantOnBlur("sizes", event)}
												onKeyPress={(event) => handleVariantOnEnter("sizes", event)}
											/>
										</span>
									</div>
									<div className="col-span-1 variant-delete">
										<img
											src={IconDeleteGrey}
											alt="delete"
											onClick={() => handleDeleteAllVariantByKey("sizes")}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-10">
								<div className="grid grid-cols-12 mb-3 gap-3">
									<div className="col-span-2 variant-label">Color</div>
									<div className="col-span-7 variant-input-wrapper">
										{variants.colors.map((variant, index) => {
											return (
												<VariantBadge
													key={index}
													backgroundColor="#C53929"
													value={variant}
													name={variant}
													onRemove={() => handleRemoveVariant("colors", variant)}
												/>
											);
										})}

										<span className="variant-input-container">
											<input
												className="col-span-7 variant-input"
												name="colors"
												value={variantInput.colors}
												onChange={handleVariantInputOnChange}
												onBlur={(event) => handleVariantOnBlur("colors", event)}
												onKeyPress={(event) => handleVariantOnEnter("colors", event)}
											/>
										</span>
									</div>
									<div className="col-span-1 variant-delete">
										<img
											src={IconDeleteGrey}
											alt="delete"
											onClick={() => handleDeleteAllVariantByKey("colors")}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-10">
								<div className="grid grid-cols-12 mb-3 gap-3">
									<div className="col-span-2 variant-label">Option</div>
									<div className="col-span-7 variant-input-wrapper">
										{variants.options.map((variant, index) => {
											return (
												<VariantBadge
													key={index}
													backgroundColor="#4285F4"
													value={variant}
													name={variant}
													onRemove={() => handleRemoveVariant("options", variant)}
												/>
											);
										})}

										<span className="variant-input-container">
											<input
												className="col-span-7 variant-input"
												name="options"
												value={variantInput.options}
												onChange={handleVariantInputOnChange}
												onBlur={(event) => handleVariantOnBlur("options", event)}
												onKeyPress={(event) => handleVariantOnEnter("options", event)}
											/>
										</span>
									</div>
									<div className="col-span-1 variant-delete">
										<img
											src={IconDeleteGrey}
											alt="delete"
											onClick={() => handleDeleteAllVariantByKey("options")}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="variant-matrix-container mb-3">
							<table>
								<thead>
									<tr>
										<th className="text-left" style={{ minWidth: "146px" }}>
											Variants Matrix
										</th>
										<th style={{ minWidth: "162px" }}>SKU Code</th>
										<th style={{ minWidth: "141px" }}>Price (RM)</th>
										<th style={{ minWidth: "93px" }}>Stock (Qty)</th>
										<th style={{ minWidth: "93px" }}>Weight (kg)</th>
										<th style={{ minWidth: "93px" }}>Width (cm)</th>
										<th style={{ minWidth: "93px" }}>Height (cm)</th>
										<th style={{ minWidth: "93px" }}>Length (cm)</th>
										<th>Fragile</th>
										<th>Availability</th>
									</tr>
								</thead>
								<tbody>
									{variantMatrix.map((variantMatrixRow, index) => {
										const {
											combination,
											sku_code,
											price,
											quantity,
											weight,
											width,
											height,
											length,
											fragile,
											available,
										} = variantMatrixRow;

										let displayCombination = "";
										combination.forEach((comboKey, index) => {
											let color = "#000000";
											if (variants.sizes.includes(comboKey)) {
												color = PRODUCT_VARIANT_COLORS["size"];
											} else if (variants.colors.includes(comboKey)) {
												color = PRODUCT_VARIANT_COLORS["color"];
											} else if (variants.options.includes(comboKey)) {
												color = PRODUCT_VARIANT_COLORS["option"];
											}

											displayCombination += `<span style="color: ${color}">${comboKey}</span>`;

											if (combination.length - 1 !== index) {
												displayCombination += `<span class="mx-1 font-normal">+</span>`;
											}
										});

										return (
											<tr key={index}>
												<td className="variant-matrix-label">
													<div dangerouslySetInnerHTML={{ __html: displayCombination }}></div>
												</td>
												<td>
													<input
														className="input-box"
														type="text"
														name="sku_code"
														value={sku_code}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="price"
														value={price}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="quantity"
														value={quantity}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="weight"
														value={weight}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="width"
														value={width}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="height"
														value={height}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td>
													<input
														className="input-box"
														type="number"
														name="length"
														value={length}
														onChange={(event) =>
															handleOnChangeVariantMatrixInput(event, index)
														}
													/>
												</td>
												<td className="text-center">
													<div className="app__checkbox">
														<input
															type="checkbox"
															name="fragile"
															checked={fragile === 1 ? true : false}
															onChange={(event) =>
																handleOnChangeVariantMatrixInput(event, index)
															}
														/>
														<div className="app__checkbox-visible"></div>
													</div>
												</td>
												<td className="text-center">
													<div className="app__checkbox">
														<input
															type="checkbox"
															name="available"
															checked={available === 1 ? true : false}
															onChange={(event) =>
																handleOnChangeVariantMatrixInput(event, index)
															}
														/>
														<div className="app__checkbox-visible"></div>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</form>
				</div>

				<div className="action-bar">
					<div className="grid grid-cols-12 gap-3">
						<div className="col-span-6 lg:col-span-8">
							<button type="button" className="btn-delete" onClick={handleDeleteProduct}>
								<img src={IconDelete} alt="delete" className="icon-delete" /> Delete
							</button>
						</div>
						<div className="hidden col-span-2 md:flex justify-center items-center">
							<button type="button" className="btn-cancel" onClick={closeEditProductModal}>
								Cancel
							</button>
						</div>
						<div className="col-span-6 lg:col-span-2 flex justify-center items-center">
							<button type="submit" className="btn-update" form="form_edit_product">
								Update Product
							</button>
						</div>
					</div>
				</div>

				{loading && (
					<div className="app-loading-overlay">
						<ReactLoading type={"spinningBubbles"} color="grey" />
						<p className="mt-3">Loading</p>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default withRouter(EditProductModal);

import { combineReducers } from "redux";
import AuthenticationReducer from "./authentication";
import CommonReducer from "./common";
import ChannelReducer from "./channel";
import ExhibitorReducer from "./exhibitor";
import AgentReducer from "./agent";
import OrderReducer from "./order";
import ProfileReducer from "./profile";
import EventReducer from "./event";
import AddressReducer from "./address";

export default combineReducers({
	auth: AuthenticationReducer,
	common: CommonReducer,
	channel: ChannelReducer,
	exhibitor: ExhibitorReducer,
	agent: AgentReducer,
	order: OrderReducer,
	profile: ProfileReducer,
	event: EventReducer,
	address: AddressReducer
});

import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { authenticationActions } from "app/states/authentication";
import LoginRegisterContainer from "app/views/containers/Authentication/LoginRegisterContainer/LoginRegisterContainer";
import EventInfoContainer from "../EventInfoContainer/EventInfoContainer";

const LoginRegisterModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeLoginRegisterModal = () => dispatch(authenticationActions.closeLoginRegisterModal());

	const showModal = useSelector(({ auth }) => auth.showLoginRegisterModal);

	const handleClose = () => {
		closeLoginRegisterModal();
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="mobile-login-register-modal">
			<div className="app-modal mobile-app-modal mobile-login-register-modal">
				<div className="modal-content">
					<div className="grid grid-cols-12 gap-5 h-full">
						<div className="hidden lg:block col-span-6 px-10 h-full">
							<EventInfoContainer />
						</div>
						<div className="col-span-12 lg:col-span-6 h-full">
							<LoginRegisterContainer />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default withRouter(LoginRegisterModal);

import * as types from "./types";

const initialState = {
	showResetPasswordModal: false,
	showTermsConditionModal: false,
	showPrivacyPolicyModal: false,
	showProfileModal: false,
	showJoinPrivateChatModal: false,
	showExitPrivateChatModal: false,
	showPrivateChatPeopleModal: false,
	isPrivateChat: false,
	showAlertSnackbar: false,
	alertSnackbarMessage: "",
	alertSnackbarVariant: "success",
	showVisitorDetailModal: false,
	viewingVisitorUserId: null,
	chatUnreadCount: 0,
	profileModalTabIndex: 0,
	showDeleteSavedOrdersModal: false,
	viewingSavedOrderRoomId: null,
	viewingSavedOrderRoomName: null,
	showSwitchExhibitorRoomModal: false,
	showAddressManagementModal: false,
	showAddAddressModal: false,
	viewingOrderId: null,
	addressList: [],
	isEnteringEvent: false,
	currentProductId: null,
	showProductDetailViewModal: false,
	showLoading: false,
};

const CommonReducer = (state = initialState, action) => {
	switch (action.type) {
		// RESET PASSWORD MODAL
		case types.OPEN_RESET_PASSWORD_MODAL:
			return {
				...state,
				showResetPasswordModal: true,
			};
		case types.CLOSE_RESET_PASSWORD_MODAL:
			return {
				...state,
				showResetPasswordModal: false,
			};

		// TNC MODAL
		case types.OPEN_TERMS_CONDITION_MODAL:
			return {
				...state,
				showTermsConditionModal: true,
			};
		case types.CLOSE_TERMS_CONDITION_MODAL:
			return {
				...state,
				showTermsConditionModal: false,
			};

		// PRIVACY POLICY MODAL
		case types.OPEN_PRIVACY_POLICY_MODAL:
			return {
				...state,
				showPrivacyPolicyModal: true,
			};
		case types.CLOSE_PRIVACY_POLICY_MODAL:
			return {
				...state,
				showPrivacyPolicyModal: false,
			};

		// PROFILE MODAL
		case types.OPEN_PROFILE_MODAL:
			return {
				...state,
				showProfileModal: true,
			};
		case types.CLOSE_PROFILE_MODAL:
			return {
				...state,
				showProfileModal: false,
			};

		// JOIN PRIVATE CHAT MODAL
		case types.OPEN_JOIN_PRIVATE_CHAT_MODAL:
			return {
				...state,
				showJoinPrivateChatModal: true,
			};
		case types.CLOSE_JOIN_PRIVATE_CHAT_MODAL:
			return {
				...state,
				showJoinPrivateChatModal: false,
			};

		// EXIT PRIVATE CHAT MODAL
		case types.OPEN_EXIT_PRIVATE_CHAT_MODAL:
			return {
				...state,
				showExitPrivateChatModal: true,
			};
		case types.CLOSE_EXIT_PRIVATE_CHAT_MODAL:
			return {
				...state,
				showExitPrivateChatModal: false,
			};

		// PRIVATE CHAT PEOPLE MODAL
		case types.OPEN_PRIVATE_CHAT_PEOPLE_MODAL:
			return {
				...state,
				showPrivateChatPeopleModal: true,
			};
		case types.CLOSE_PRIVATE_CHAT_PEOPLE_MODAL:
			return {
				...state,
				showPrivateChatPeopleModal: false,
			};
		case types.SET_IS_PRIVATE_CHAT:
			return {
				...state,
				isPrivateChat: action.params.isPrivateChat,
			};

		// SHOW ALERT SNACKBAR
		case types.OPEN_ALERT_SNACKBAR:
			return {
				...state,
				showAlertSnackbar: true,
				alertSnackbarMessage: action.params.message,
				alertSnackbarVariant: action.params.variant,
			};
		case types.CLOSE_ALERT_SNACKBAR:
			return {
				...state,
				showAlertSnackbar: false,
			};

		// SHOW VISITOR DETAIL MODAL
		case types.OPEN_VISITOR_DETAIL_MODAL:
			return {
				...state,
				showVisitorDetailModal: true,
				viewingVisitorUserId: action.params.userId,
			};
		case types.CLOSE_VISITOR_DETAIL_MODAL:
			return {
				...state,
				showVisitorDetailModal: false,
			};

		// Chat unread count
		case types.SET_CHAT_UNREAD_COUNT:
			return {
				...state,
				chatUnreadCount: action.params.count,
			};

		// profile modal tab index
		case types.SET_PROFILE_MODAL_TAB_INDEX:
			return {
				...state,
				profileModalTabIndex: action.params.tabIndex,
			};

		// SHOW DELETE SAVED ORDERS MDOAL
		case types.OPEN_DELETE_SAVED_ORDERS_MODAL:
			return {
				...state,
				showDeleteSavedOrdersModal: true,
			};
		case types.CLOSE_DELETE_SAVED_ORDERS_MODAL:
			return {
				...state,
				showDeleteSavedOrdersModal: false,
			};

		// SHOW SWITCH EXHIBITOR ROOM MODAL
		case types.OPEN_SWITCH_EXHIBITOR_ROOM_MODAL:
			return {
				...state,
				showSwitchExhibitorRoomModal: true,
			};
		case types.CLOSE_SWITCH_EXHIBITOR_ROOM_MODAL:
			return {
				...state,
				showSwitchExhibitorRoomModal: false,
			};

		// SHOW ADDRESS MANAGEMENT MODAL
		case types.OPEN_ADDRESS_MANAGEMENT_MODAL:
			return {
				...state,
				showAddressManagementModal: true,
			};
		case types.CLOSE_ADDRESS_MANAGEMENT_MODAL:
			return {
				...state,
				showAddressManagementModal: false,
			};

		case types.SET_ADDRESS_LIST:
			return {
				...state,
				addressList: action.params.addressList,
			};

		// SHOW ADD ADDRESS MODAL
		case types.OPEN_ADD_ADDRESS_MODAL:
			return {
				...state,
				showAddAddressModal: true,
			};
		case types.CLOSE_ADD_ADDRESS_MODAL:
			return {
				...state,
				showAddAddressModal: false,
			};

		// Viewing order id
		case types.SET_VIEWING_ORDER_ID:
			return {
				...state,
				viewingOrderId: action.params.orderId,
			};

		// Viewing saved order room id
		case types.SET_VIEWING_SAVED_ORDER_ROOM_ID:
			return {
				...state,
				viewingSavedOrderRoomId: action.params.roomId,
			};

		// Viewing saved order room name
		case types.SET_VIEWING_SAVED_ORDER_ROOM_NAME:
			return {
				...state,
				viewingSavedOrderRoomName: action.params.roomName,
			};

		// is entering event flag
		case types.SET_IS_ENTERING_EVENT:
			return {
				...state,
				isEnteringEvent: action.params.isEnteringEvent,
			};

		// product detail view modal
		case types.OPEN_PRODUCT_DETAIL_VIEW_MODAL:
			return {
				...state,
				currentProductId: action.params.productId,
				showProductDetailViewModal: true,
			};
		case types.CLOSE_PRODUCT_DETAIL_VIEW_MODAL:
			return {
				...state,
				showProductDetailViewModal: false,
			};

		// show/hide loading
		case types.SHOW_LOADING:
			return {
				...state,
				showLoading: true
			};
		case types.HIDE_LOADING:
			return {
				...state,
				showLoading: false
			};
		default:
			return state;
	}
};

export default CommonReducer;

import * as types from "./types";

/**
 * Reset password modal actions
 */
export const openResetPasswordModal = () => ({
	type: types.OPEN_RESET_PASSWORD_MODAL,
});

export const closeResetPasswordModal = () => ({
	type: types.CLOSE_RESET_PASSWORD_MODAL,
});

/**
 * Terms and condition modal actions
 */
export const openTermsConditionModal = () => ({
	type: types.OPEN_TERMS_CONDITION_MODAL,
});

export const closeTermsConditionModal = () => ({
	type: types.CLOSE_TERMS_CONDITION_MODAL,
});

/**
 * Privacy policy modal actions
 */
export const openPrivacyPolicyModal = () => ({
	type: types.OPEN_PRIVACY_POLICY_MODAL,
});

export const closePrivacyPolicyModal = () => ({
	type: types.CLOSE_PRIVACY_POLICY_MODAL,
});

/**
 * Profile modal actions
 */
export const openProfileModal = () => ({
	type: types.OPEN_PROFILE_MODAL,
});

export const closeProfileModal = () => ({
	type: types.CLOSE_PROFILE_MODAL,
});

/**
 * Join private chat modal actions
 */
export const openJoinPrivateChatModal = () => ({
	type: types.OPEN_JOIN_PRIVATE_CHAT_MODAL,
});

export const closeJoinPrivateChatModal = () => ({
	type: types.CLOSE_JOIN_PRIVATE_CHAT_MODAL,
});

/**
 * Exit private chat modal actions
 */
export const openExitPrivateChatModal = () => ({
	type: types.OPEN_EXIT_PRIVATE_CHAT_MODAL,
});

export const closeExitPrivateChatModal = () => ({
	type: types.CLOSE_EXIT_PRIVATE_CHAT_MODAL,
});

/**
 * Private chat people modal actions
 */
export const openPrivateChatPeopleModal = () => ({
	type: types.OPEN_PRIVATE_CHAT_PEOPLE_MODAL,
});

export const closePrivateChatPeopleModal = () => ({
	type: types.CLOSE_PRIVATE_CHAT_PEOPLE_MODAL,
});

export const setIsPrivateChat = (isPrivateChat) => ({
	type: types.SET_IS_PRIVATE_CHAT,
	params: {
		isPrivateChat,
	},
});

/**
 * Alert snackbar
 */
export const openAlertSnackbar = (message, variant) => ({
	type: types.OPEN_ALERT_SNACKBAR,
	params: {
		message,
		variant,
	},
});

export const closeAlertSnackbar = () => ({
	type: types.CLOSE_ALERT_SNACKBAR,
});

/**
 * Visitor detail modal
 */
export const openVisitorDetailModal = (userId) => ({
	type: types.OPEN_VISITOR_DETAIL_MODAL,
	params: {
		userId,
	},
});

export const closeVisitorDetailModal = () => ({
	type: types.CLOSE_VISITOR_DETAIL_MODAL,
});

/**
 * Chat unread count
 */
export const setChatUnreadCount = (count) => ({
	type: types.SET_CHAT_UNREAD_COUNT,
	params: {
		count,
	},
});

/**
 * Profile modal tab index
 */
export const setProfileModalTabIndex = (tabIndex) => ({
	type: types.SET_PROFILE_MODAL_TAB_INDEX,
	params: {
		tabIndex,
	},
});

/**
 * Delete saved order modal
 */
export const openDeleteSavedOrdersModal = () => ({
	type: types.OPEN_DELETE_SAVED_ORDERS_MODAL,
});

export const closeDeleteSavedOrdersModal = () => ({
	type: types.CLOSE_DELETE_SAVED_ORDERS_MODAL,
});

/**
 * Switch exhibitor room modal
 */
export const openSwitchExhibitorRoomModal = () => ({
	type: types.OPEN_SWITCH_EXHIBITOR_ROOM_MODAL,
});

export const closeSwitchExhibitorRoomModal = () => ({
	type: types.CLOSE_SWITCH_EXHIBITOR_ROOM_MODAL,
});

/**
 * Address management modal
 */
export const openAddressManagementModal = () => ({
	type: types.OPEN_ADDRESS_MANAGEMENT_MODAL,
});

export const closeAddressManagementModal = () => ({
	type: types.CLOSE_ADDRESS_MANAGEMENT_MODAL,
});

export const setAddressList = (addressList) => ({
	type: types.SET_ADDRESS_LIST,
	params: {
		addressList,
	},
});

/**
 * Add address modal
 */
export const openAddAddressModal = () => ({
	type: types.OPEN_ADD_ADDRESS_MODAL,
});

export const closeAddAddressModal = () => ({
	type: types.CLOSE_ADD_ADDRESS_MODAL,
});

/**
 * Viewing order id
 */
export const setViewingOrderId = (orderId) => ({
	type: types.SET_VIEWING_ORDER_ID,
	params: {
		orderId,
	},
});

/**
 * Viewing saved order room id
 */
export const setViewingSavedOrderRoomId = (roomId) => ({
	type: types.SET_VIEWING_SAVED_ORDER_ROOM_ID,
	params: {
		roomId,
	},
});

/**
 * Viewing saved order room id
 */
export const setViewingSavedOrderRoomName = (roomName) => ({
	type: types.SET_VIEWING_SAVED_ORDER_ROOM_NAME,
	params: {
		roomName,
	},
});

/**
 * SET IS ENTERING EVENT
 */
export const setIsEnteringEvent = (isEnteringEvent) => ({
	type: types.SET_IS_ENTERING_EVENT,
	params: {
		isEnteringEvent,
	},
});

/**
 * Product detail view modal actions
 */
export const openProductDetailViewModal = (productId) => ({
	type: types.OPEN_PRODUCT_DETAIL_VIEW_MODAL,
	params: {
		productId,
	},
});

export const closeProductDetailViewModal = () => ({
	type: types.CLOSE_PRODUCT_DETAIL_VIEW_MODAL,
});

/**
 * Show/Hide loading
 */
export const showLoading = () => ({
	type: types.SHOW_LOADING,
});

export const hideLoading = () => ({
	type: types.HIDE_LOADING,
});

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import ProfileAPI from "app/apis/profile";
import { commonActions } from "app/states/common";
import { profileActions } from "app/states/profile";
import { useMediaQuery } from "react-responsive";
import { addressActions } from 'app/states/address';

const AddressManagementContainer = () => {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const dispatch = useDispatch();
	const closeProfileModal = () => dispatch(commonActions.closeProfileModal());
	const openAddAddressModal = () => dispatch(commonActions.openAddAddressModal());
	const refreshAddressList = () => dispatch(profileActions.refreshAddressList());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setAddressList = (addressList) => dispatch(commonActions.setAddressList(addressList));
	const setViewingAddress = (address) => dispatch(addressActions.setViewingAddress(address));
	const openEditAddressModal = () => dispatch(addressActions.openEditAddressModal());
	const setAddressModalOpenFrom = (from) => dispatch(addressActions.setAddressModalOpenFrom(from));

	const refreshAddressListCount = useSelector(({ profile }) => profile.refreshAddressListCount);

	const [addresses, setAddresses] = useState([]);
	const [defaultAddressId, setDefaultAddressId] = useState(null);

	// Get address list
	useEffect(() => {
		ProfileAPI.getAddressList()
			.then((response) => {
				setAddresses(response.addresses);
				setAddressList(response.addresses);

				// Set the default address id
				response.addresses.forEach((address) => {
					if (address.default === 1) {
						setDefaultAddressId(address.id);
					}
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshAddressListCount]);

	const handleAddNewAddress = () => {
		setAddressModalOpenFrom("profile_add_address");
		openAddAddressModal();
		closeProfileModal();
	};

	// Update default address
	const updateDefaultAddress = (addressId) => {
		// Update default address
		let newDefaultAddress = addresses.find((address) => address.id === addressId);
		newDefaultAddress.default = 1;

		ProfileAPI.postUpdateAddress(newDefaultAddress)
			.then((response) => {
				refreshAddressList();
				openAlertSnackbar("Updated default address.", "success");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			});
	};

	const handleOnChangeDefault = (addressId) => {
		setDefaultAddressId(addressId);
		updateDefaultAddress(addressId);
	};

	const handleViewAddress = (address) => {
		setViewingAddress(address);
		openEditAddressModal();
	}

	if (isTabletOrMobile) {
		return (
			<>
				<div id="address-management-container" className="p-3">
					<section className="m__addresses">
						{addresses.map((address) => {
							const { id, label, name, phone_no, address: fullAddress } = address;

							return (
								<div key={id} className="m__address-list-item" onClick={() => handleViewAddress(address)}>
									<div className="flex">
										<div>
											<div className="app__checkbox">
												<input
													type="checkbox"
													name="default"
													checked={defaultAddressId === id}
													onChange={() => handleOnChangeDefault(id)}
												/>
												<div className="app__checkbox-visible"></div>
											</div>
										</div>
										<div>
											<div className="m__label">{label}</div>
											<div className="m__name">{name}</div>
											<div className="m__phone">{phone_no}</div>
											<div className="m__address">{fullAddress}</div>
										</div>
									</div>
								</div>
							);
						})}
					</section>

					<section className="m__footer">
						<div className="flex justify-end items-center w-full">
							<button type="button" className="btn-cta" onClick={handleAddNewAddress}>
								Add New Address
							</button>
						</div>
					</section>
				</div>
			</>
		);
	}

	return (
		<>
			<div id="address-management-container">
				<section className="container-body">
					<h1 className="container-title">Address Management</h1>

					<table>
						<thead>
							<tr>
								<th>Name</th>
								<th>Recipient Name</th>
								<th>Contact Number</th>
								<th>Address</th>
								<th>Default?</th>
							</tr>
						</thead>
						<tbody>
							{addresses.map((address) => {
								const { id, label, name, phone_no, address: fullAddress } = address;

								return (
									<tr key={id} className="address-list-item">
										<td onClick={() => handleViewAddress(address)}>{label}</td>
										<td onClick={() => handleViewAddress(address)}>{name}</td>
										<td onClick={() => handleViewAddress(address)}>{phone_no}</td>
										<td onClick={() => handleViewAddress(address)}>{fullAddress}</td>
										<td className="flex justify-center">
											<div className="app__checkbox">
												<input
													type="checkbox"
													name="default"
													checked={defaultAddressId === id}
													onChange={() => handleOnChangeDefault(id)}
												/>
												<div className="app__checkbox-visible"></div>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</section>

				<section className="modal-footer">
					<div className="flex justify-end items-center w-full">
						<button type="button" className="btn-cta" onClick={handleAddNewAddress}>
							Add New Address
						</button>
					</div>
				</section>
			</div>
		</>
	);
};

export default AddressManagementContainer;

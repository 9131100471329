import React from 'react'
import { withRouter } from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import { exhibitorActions } from "app/states/exhibitor";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import './style.scss';
import StoreProductContainer from '../../Exhibitor/StoreProductContainer/StoreProductContainer';

const ExhibitorStoreModal = ({ history }) => {

    const dispatch = useDispatch();
    const closeExhibitorStoreModal = () => dispatch(exhibitorActions.closeExhibitorStoreModal());
    const openJoinPrivateChatModal = () => dispatch(commonActions.openJoinPrivateChatModal());

    const showModal = useSelector(({ exhibitor }) => exhibitor.showExhibitorStoreModal);
    const hasPendingPrivateChatInvitation = useSelector(({ exhibitor }) => exhibitor.hasPendingPrivateChatInvitation);

    const handleClose = () => {
        if (hasPendingPrivateChatInvitation) {
            openJoinPrivateChatModal();
        }

        closeExhibitorStoreModal();
    }

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="exhibitor-store-modal"
        >
            <div className="app-modal mobile-app-modal exhibitor-store-modal">
                <StoreProductContainer />
            </div>
        </Modal>
    )
}

export default withRouter(ExhibitorStoreModal);
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { commonActions } from "app/states/common";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import COUNTRIES from "app/config/country_region.json";
import ProfileAPI from "app/apis/profile";
import { profileActions } from "app/states/profile";
import LocalStorageService from "app/services/localStorageService";
import { useMediaQuery } from "react-responsive";

const defaultInputData = {
	address_name: "",
	recipient_name: "",
	contact_number: "",
	address: "",
	postcode: "",
	country: "",
	state: "",
};

const AddAddressModal = ({ history }) => {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const userProfile = LocalStorageService.getUserProfile();

	const hiddenSubmitButtonRef = useRef();

	const dispatch = useDispatch();
	const closeAddAddressModal = () => dispatch(commonActions.closeAddAddressModal());
	const openAddressManagementModal = () => dispatch(commonActions.openAddressManagementModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const refreshAddressList = () => dispatch(profileActions.refreshAddressList());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const setProfileModalTabIndex = (tabIndex) => dispatch(commonActions.setProfileModalTabIndex(tabIndex));
	const openProfileModal = () => dispatch(commonActions.openProfileModal());

	const showModal = useSelector(({ common }) => common.showAddAddressModal);
	const addressList = useSelector(({ common }) => common.addressList);
	const addressModalOpenFrom = useSelector(({ address }) => address.addressModalOpenFrom);

	// Get "Malaysia" regions
	const MsiaRegions = COUNTRIES.filter((countries) => {
		return countries.countryShortCode === "MY";
	});

	const [regions, setRegions] = useState(MsiaRegions[0].regions);

	const [inputData, setInputData] = useState(defaultInputData);

	const handleInputChange = (event) => {
		setInputData({
			...inputData,
			[event.target.name]: event.target.value,
		});

		if (event.target.name === "country") {
			const selectedCountryRegions = COUNTRIES.filter((countries) => {
				return countries.countryShortCode === event.target.value;
			});

			setRegions(selectedCountryRegions[0].regions);
		}
	};

	const handleClose = () => {
		if (isTabletOrMobile) {
			closeAddAddressModal();
		} else {
			closeAddAddressModal();
			
			// If user is adding address from profile address management modal, then open back profile modal
			if (addressModalOpenFrom === "profile_add_address") {
				setProfileModalTabIndex(3);
				openProfileModal();
			}
			else if (addressModalOpenFrom === "exhibitor_store_add_address") {
				// If user is adding address from exhibitor store, then open back address management modal
				openAddressManagementModal();
			}
		}
	};

	const handleSubmitCreate = (event) => {
		event.preventDefault();

		// Determine if the address should be default
		let isDefault = 0;
		if (addressList.length === 0) {
			isDefault = 1;
		} else {
			// Check whether existing address list has set any to default.
			// if dont have, the new address created will be the default address
			let existingDefaultAddress = addressList.filter((address) => address.default === 1);
			if (!existingDefaultAddress) {
				isDefault = 1;
			}
		}

		// Add new address
		let data = {
			label: inputData.address_name,
			name: inputData.recipient_name,
			email: userProfile.email,
			phone_no: inputData.contact_number,
			address: inputData.address,
			postcode: inputData.postcode,
			state: inputData.state,
			country: inputData.country,
			default: isDefault,
		};

		showLoading();
		ProfileAPI.postAddAddress(data)
			.then((response) => {
				// Reset state
				setInputData(defaultInputData);

				// Back to address management modal
				refreshAddressList();
				handleClose();
			})
			.catch((error) => {
				openAlertSnackbar(error.data.message, "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleCancel = () => {
		// Back to address management modal
		handleClose();
	};

	return (
		<Modal open={showModal} onClose={handleClose} aria-labelledby="add-address-modal">
			<div id="add-address-modal" className="app-modal mobile-app-modal">
				<section className="modal-header flex justify-between">
					<h1 className="modal-title">Add New Address</h1>
				</section>

				<section className="modal-body">
					<form id="form-add-new-address" method="POST" onSubmit={handleSubmitCreate}>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label className="col-span-12 lg:col-span-2 text-label">Name</label>
							<input
								className="col-span-12 lg:col-span-4 input-box"
								type="text"
								name="address_name"
								required
								onChange={handleInputChange}
								value={inputData.address_name}
							/>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label className="col-span-12 lg:col-span-2 text-label">Recipient Name</label>
							<input
								className="col-span-12 lg:col-span-4 input-box"
								type="text"
								name="recipient_name"
								required
								onChange={handleInputChange}
								value={inputData.recipient_name}
							/>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label className="col-span-12 lg:col-span-2 text-label">Contact Number</label>
							<input
								className="col-span-12 lg:col-span-4 input-box"
								type="text"
								name="contact_number"
								required
								onChange={handleInputChange}
								value={inputData.contact_number}
							/>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label className="col-span-12 lg:col-span-2 text-label">Address</label>
							<input
								className="col-span-12 lg:col-span-4 input-box"
								type="text"
								name="address"
								required
								onChange={handleInputChange}
								value={inputData.address}
							/>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label className="col-span-12 lg:col-span-2 text-label">Postcode</label>
							<input
								className="col-span-12 lg:col-span-4 input-box input-number-box"
								type="number"
								name="postcode"
								required
								onChange={handleInputChange}
								value={inputData.postcode}
							/>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label htmlFor="country" className="col-span-12 lg:col-span-2 text-label">
								Country
							</label>
							<div className="select-wrapper col-span-12 lg:col-span-4 ">
								<select
									id="country"
									name="country"
									className="input-box"
									required
									value={inputData.country}
									onChange={handleInputChange}>
									<option value="" disabled></option>
									{COUNTRIES.map((item) => (
										<option key={item.countryShortCode} value={item.countryShortCode}>
											{item.countryName}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-1 lg:gap-3 items-center mb-3">
							<label htmlFor="state" className="col-span-12 lg:col-span-2 text-label">
								State
							</label>
							<div className="select-wrapper col-span-12 lg:col-span-4 ">
								<select
									id="state"
									name="state"
									className="input-box"
									required
									value={inputData.state}
									onChange={handleInputChange}>
									<option value="" disabled></option>
									{regions.map((item) => (
										<option key={item.name} value={item.name}>
											{item.name}
										</option>
									))}
								</select>
							</div>
						</div>
						<button type="submit" className="hidden" ref={hiddenSubmitButtonRef}>
							Create
						</button>
					</form>
				</section>

				<section className="modal-footer">
					<div className="flex justify-end items-center w-full">
						<button type="button" className="btn-secondary-action" onClick={handleCancel}>
							Cancel
						</button>
						<button type="button" className="btn-cta" onClick={() => hiddenSubmitButtonRef.current.click()}>
							Create
						</button>
					</div>
				</section>
			</div>
		</Modal>
	);
};

export default withRouter(AddAddressModal);
